import { Message, ProjectEmailSettings } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import { projectEmailSettingsErrorKeys } from "../../../schema/emailSettings";
import { splitMessages } from "../../../schema/rule/errors";
import Spaced from "../Spaced";
import { InvitationEmailSettingsEditor } from "./InvitationEmailSettingsEditor";
import { SurveyApprovedEmailSettingsEditor } from "./SurveyApprovedEmailSettingsEditor";
import { SurveyCompleteEmailSettingsEditor } from "./SurveyCompleteEmailSettingsEditor";

export interface ProjectEmailSettingsEditorProps {
  group?: "project" | "team";
  value: ProjectEmailSettings;
  onChange: (value: ProjectEmailSettings) => void;
  messages: Message[];
  disabled?: boolean;
}

export function ProjectEmailSettingsEditor(
  props: ProjectEmailSettingsEditorProps
): ReactElement {
  const { group = "project", value, onChange, messages, disabled } = props;

  const errors = useMemo(
    () => splitMessages(messages, projectEmailSettingsErrorKeys),
    [messages]
  );

  return (
    <Spaced spacing="4">
      <InvitationEmailSettingsEditor
        group={group}
        value={value.invitation}
        onChange={invitation => onChange({ ...value, invitation })}
        messages={errors.invitation}
        disabled={disabled}
      />

      <SurveyCompleteEmailSettingsEditor
        group={group}
        value={value.complete}
        onChange={complete => onChange({ ...value, complete })}
        messages={errors.complete}
        disabled={disabled}
      />

      <SurveyApprovedEmailSettingsEditor
        value={value.approved}
        onChange={approved => onChange({ ...value, approved })}
        messages={errors.approved}
        disabled={disabled}
      />
    </Spaced>
  );
}
