import { selectOption } from "@cartographerio/topo-form";
import { Team, TeamAlias } from "@cartographerio/types";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  PlacementWithLogical,
  Portal,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";

import Link from "./Link";

interface CreateSurveyButtonProps {
  teams: Team[];
  disabled?: boolean;
  placement?: PlacementWithLogical;
  includeNullOption: boolean;
  createSurveyLink: (teamAlias: TeamAlias | null) => string;
  children?: ReactNode;
}

export default function CreateSurveyButton(
  props: CreateSurveyButtonProps
): ReactElement {
  const {
    teams,
    disabled,
    placement,
    includeNullOption,
    createSurveyLink,
    children,
  } = props;

  const createLinks = useMemo(
    () =>
      (includeNullOption ? [null, ...teams] : teams).map(team =>
        selectOption(
          createSurveyLink(team?.alias ?? null),
          team?.name ?? "No Team"
        )
      ),
    [createSurveyLink, includeNullOption, teams]
  );

  return createLinks.length === 1 ? (
    <Link.Internal disabled={disabled} to={createLinks[0].value}>
      {children}
    </Link.Internal>
  ) : (
    <Menu placement={placement}>
      <MenuButton disabled={disabled}>{children}</MenuButton>
      <Portal>
        <MenuList zIndex="popover" fontSize="sm" maxH="30em" overflowY="auto">
          <MenuGroup title="Choose a Team" fontSize="sm" mx="3">
            {createLinks.map(({ label, value }) => (
              <MenuItem
                _hover={{ bg: "gray.100", textDecoration: "none" }}
                key={value}
                as={Link.Internal}
                to={value}
              >
                {label}
              </MenuItem>
            ))}
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
}
