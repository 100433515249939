import { checks } from "@cartographerio/permission";
import {
  projectMemberRole,
  teamMemberRole,
  workspaceActiveRole,
} from "@cartographerio/types";
import { ReactElement, useEffect, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseUserPage from "../base/BaseUserPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceUserCreatePage(
  props: RouteProps<typeof routes.workspace.member.create>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;
  const { project, team } = query;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const multiTeam = useTeamsEnabled(workspace);
  const workspaceGraph = useCurrentWorkspaceGraph();

  useEffect(() => {
    if (team != null && !multiTeam) {
      updateQuery({ ...query, team: undefined });
    }
  }, [multiTeam, query, team, updateQuery]);

  const projectRole = useSuspenseQueryData(
    queries.optional(project, project =>
      queries.project.v2.readOrFail(apiParams, project, workspace.id)
    ),
    project => (project != null ? projectMemberRole(project.id) : null)
  );

  const teamRole = useSuspenseQueryData(
    queries.optional(multiTeam ? team : null, team =>
      queries.team.v2.readOrFail(apiParams, team, workspace.id)
    ),
    team => (team != null ? teamMemberRole(team.id) : null)
  );

  const initialRoles = useMemo(
    () => [
      workspaceActiveRole(workspace.id),
      ...(projectRole != null ? [projectRole] : []),
      ...(teamRole != null ? [teamRole] : []),
    ],
    [projectRole, teamRole, workspace.id]
  );

  useRequirePermission(checks.workspace.grantAccess(workspace));

  usePageTitle(`New Member - ${workspace.name}`);

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="members" user="new" />
      <WorkspacePageHeader workspace={workspace} selected="members" />
      <BaseUserPage
        nextRedirect={routes.workspace.member.list.url([workspace.alias])}
        workspace={workspace}
        workspaceGraph={workspaceGraph}
        blankUserRoles={initialRoles}
      />
    </>
  );
}
