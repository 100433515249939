import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import { GlobalRoleNameEnum, WorkspaceAlias } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageTopBar from "../../components/PageTopBar";
import Select from "../../components/Select";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";
import BaseInvitationListPage from "../base/BaseInvitationListPage";
import AdminPageHeader from "./AdminPageHeader";

export default function AdminInvitationListPage(
  props: RouteProps<typeof routes.admin.invitation.list>
): ReactElement {
  const { query, updateQuery } = props;

  const apiParams = useApiParams();

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.admin.home.url([])
  );

  usePageTitle("Invitations - Admin");

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const selectedWorkspace = useMemo(
    () => workspaces.find(({ alias }) => query.workspace === alias),
    [query.workspace, workspaces]
  );

  const workspaceOptions = useMemo<SelectOption<WorkspaceAlias>[]>(
    () => workspaces.map(({ name, alias }) => ({ label: name, value: alias })),
    [workspaces]
  );

  return (
    <>
      <PageTopBar admin={true} basePage="invitation-single" />
      <AdminPageHeader title="Invitations" />
      <BaseInvitationListPage
        route={routes.admin.invitation.list}
        query={query}
        updateQuery={updateQuery}
        globalRole={query.role}
        roleSelect={
          <Select.Nullable
            value={query.role}
            options={GlobalRoleNameEnum.entries}
            onChange={role => updateQuery({ ...query, role, page: 0 })}
            placeholder="All Roles"
            background="transparent"
            w="fit-content"
            maxW="52"
          />
        }
        workspace={selectedWorkspace}
        workspaceOptions={workspaceOptions}
        onWorkspaceChange={workspace =>
          updateQuery({ ...query, workspace: workspace ?? undefined })
        }
        addButton={
          <ButtonLink.Internal
            colorScheme="blue"
            to={routes.admin.invitation.create.url([])}
          >
            Invite
          </ButtonLink.Internal>
        }
        itemLink={invitation =>
          routes.admin.invitation.view.url([invitation.id])
        }
      />
    </>
  );
}
