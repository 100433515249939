import { CalculationReadOptions, endpoints } from "@cartographerio/client";
import { ApiParams, PricingCalculation } from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type BillingCalculationKey =
  | ["billing", "workspace", "calculation"]
  | [
      "billing",
      "workspace",
      "calculation",
      "v1",
      "read",
      CalculationReadOptions
    ];

export function readOrFail(
  apiParams: ApiParams,
  options: CalculationReadOptions
): UseQueryOpts<PricingCalculation, BillingCalculationKey> {
  return {
    queryKey: ["billing", "workspace", "calculation", "v1", "read", options],
    queryFn: () =>
      endpoints.billing.calculation.v1
        .readOrFail(apiParams, options)
        .unsafeRun(),
  };
}
