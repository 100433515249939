import { DynamicImageState } from "@cartographerio/topo-form";
import { AspectRatio, Box, Icon, Image, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoMdImages } from "react-icons/io";

import MessageLabel from "../components/MessageLabel";

export interface DynamicImageProps {
  state: DynamicImageState;
}

export default function DynamicImage(
  props: DynamicImageProps
): ReactElement | null {
  const { state } = props;

  const { url, urlError, caption, captionError } = state;

  return (
    <chakra.figure
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="stretch"
    >
      <AspectRatio>
        {url == null ? (
          <Box rounded="md" bg="gray.100" w="100%" mt="2" mb="8" mx="auto">
            <Icon as={IoMdImages} w="8" h="8" color="gray.400" />
          </Box>
        ) : (
          <Image
            src={url}
            alt={caption ?? undefined}
            rounded="md"
            objectFit="cover"
            w="100%"
            mx="auto"
          />
        )}
      </AspectRatio>
      {caption == null ? null : (
        <chakra.caption mt="1" textAlign="center" fontSize="sm">
          {caption}
        </chakra.caption>
      )}
      {urlError == null ? null : (
        <MessageLabel
          level="error"
          text={`An error occurred generating this image: ${urlError}`}
        />
      )}
      {captionError == null ? null : (
        <MessageLabel
          level="error"
          text={`An error occurred generating the caption for this image: ${captionError}`}
        />
      )}
    </chakra.figure>
  );
}
