import {
  Attribute,
  Bucket,
  MarkerModeName,
  PropertyValue,
} from "@cartographerio/topo-map";
import { CirclePaint } from "mapbox-gl";
import { useMemo } from "react";

import { MapboxExpr, expr } from "../../mapbox";
import { styleExpr } from "../map/layerHelpers";

// propExpr is undefined if we're looking at a layer whose styling isn't dependent on a property
export default function usePointLayerPaint(
  attribute: Attribute | undefined,
  buckets: Bucket<PropertyValue>[],
  markerMode: MarkerModeName,
  size: number | MapboxExpr,
  invisibilityExpr?: MapboxExpr
): CirclePaint {
  return useMemo(() => {
    const fillColor = styleExpr(
      attribute,
      buckets,
      "fillColor",
      markerMode,
      invisibilityExpr
    );

    const strokeColor = styleExpr(
      attribute,
      buckets,
      "strokeColor",
      markerMode,
      invisibilityExpr
    );

    const strokeWidth = styleExpr(
      attribute,
      buckets,
      "strokeWidth",
      markerMode
    );

    const ans = {
      "circle-color": fillColor,
      "circle-stroke-color": strokeColor,
      "circle-radius": expr.interpolate({
        type: ["linear"],
        input: expr.zoom(),
        stops: [
          [4, expr.mul(4, size)],
          [16, expr.mul(8, size)],
        ],
      }),
      "circle-stroke-width": expr.interpolate({
        type: ["linear"],
        input: expr.zoom(),
        stops: [
          [4, expr.mul(1, strokeWidth)],
          [16, expr.mul(3, strokeWidth)],
        ],
      }),
      // "circle-pitch-alignment": "map",
    };

    return ans;
  }, [attribute, buckets, invisibilityExpr, markerMode, size]);
}
