import { errorMessage } from "@cartographerio/topo-core";
import { InvitationCreate } from "@cartographerio/types";

import { rule } from "./rule";

export const { rule: invitationRule, keys: invitationErrorKeys } = rule
  .build<InvitationCreate>()
  .field(
    "firstName",
    rule.nonEmpty([errorMessage("You must enter a first name")])
  )
  .field(
    "lastName",
    rule.nonEmpty([errorMessage("You must enter a last name")])
  )
  .field("email", rule.email([errorMessage("You must enter an email address")]))
  .field(
    "roles",
    rule.nonEmpty([errorMessage("You must select at least one role")])
  )
  .finish();

export const { rule: invitationModalRule, keys: invitationModalErrorKeys } =
  rule
    .build<InvitationCreate>()
    .field(
      "firstName",
      rule.nonEmpty([errorMessage("You must enter a first name")])
    )
    .field(
      "lastName",
      rule.nonEmpty([errorMessage("You must enter a last name")])
    )
    .field(
      "email",
      rule.email([errorMessage("You must enter an email address")])
    )
    .finish();
