import { Feature, FeatureCollection } from "@cartographerio/geometry";
import { MapLayerId, unsafeMapLayerId } from "@cartographerio/topo-map";
import { raise } from "@cartographerio/util";
import { chain, uniq, uniqBy } from "lodash";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Setter } from "../../hooks/useRecord";
import { useVolatileRecord } from "../../hooks/useVolatileRecord";
import { useVolatileState } from "../../hooks/useVolatileState";
import { layerPrimaryKey } from "../layerHelpers";
import { useMapLayer, useMapLayers } from "./MapSchemaContext";
import { useVisibleFeaturesContext } from "./VisibleFeaturesContext";

const MAX_SELECTED_FEATURES = 50;

type FeatureKey = string | number | boolean | null;

interface SelectedFeaturesContextValue {
  selectedKeys: Record<MapLayerId, FeatureKey[]>;
  selectedFeatures: Record<MapLayerId, Feature[]>;
  setSelectedKeys: Setter<MapLayerId, FeatureKey[]>;
  setKeySelected: (
    layerId: MapLayerId,
    feature: FeatureKey,
    selected: boolean
  ) => void;
  clearSelectedKeys: (layerId: MapLayerId) => void;
}

const SelectedFeaturesContext =
  createContext<SelectedFeaturesContextValue | undefined>(undefined);

export function useSelectedFeaturesContext(): SelectedFeaturesContextValue {
  return (
    useContext(SelectedFeaturesContext) ??
    raise(new Error("No current topo map context"))
  );
}

export interface UseSelectedFeaturesResult {
  selectedKeys: FeatureKey[];
  selectedFeatures: Feature[];
  uniqueSelectedFeatures: Feature[];
  setSelection: (features: FeatureKey[]) => void;
  setSelected: (feature: FeatureKey, selected: boolean) => void;
  clearSelection: () => void;
}

export function useSelectedFeatures(
  layerId: MapLayerId
): UseSelectedFeaturesResult {
  const {
    selectedKeys: _keys,
    selectedFeatures: _features,
    setKeySelected: _setOne,
    setSelectedKeys: _setAll,
    clearSelectedKeys: _clear,
  } = useSelectedFeaturesContext();

  const layer = useMapLayer(layerId);

  const layerKey = layer != null ? layerPrimaryKey(layer) : null;

  const selectedKeys = useMemo(() => _keys[layerId] ?? [], [_keys, layerId]);

  const selectedFeatures = useMemo(
    () => _features[layerId] ?? [],
    [_features, layerId]
  );

  const uniqueSelectedFeatures = useMemo(
    () => (layerKey != null ? uniqBy(selectedFeatures, layerKey) : []),
    [layerKey, selectedFeatures]
  );

  const setSelection = useCallback(
    (features: FeatureKey[]) => {
      _setAll(layerId, features);
    },
    [layerId, _setAll]
  );

  const setSelected = useCallback(
    (feature: FeatureKey, selected: boolean) => {
      _setOne(layerId, feature, selected);
    },
    [_setOne, layerId]
  );

  const clearSelection = useCallback(() => {
    _clear(layerId);
  }, [_clear, layerId]);

  return {
    selectedKeys,
    selectedFeatures,
    uniqueSelectedFeatures,
    setSelection,
    setSelected,
    clearSelection,
  };
}

interface SelectedFeaturesContextProviderProps {
  defaultExternalSelection?: Record<MapLayerId, FeatureCollection>;
  children: ReactNode;
}

export function SelectedFeaturesContextProvider(
  props: SelectedFeaturesContextProviderProps
) {
  const { defaultExternalSelection, children } = props;

  const layers = useMapLayers();

  // A selection that comes from an external source (e.g. specified when the map loads):
  const [externalSelection, setExternalSelection] = useVolatileState(
    useCallback(
      () => defaultExternalSelection ?? {},
      [defaultExternalSelection]
    )
  );

  const [selectedKeys, internalSetSelectedKeys] = useVolatileRecord<
    MapLayerId,
    FeatureKey[]
  >(
    useCallback(
      () =>
        // If the user specified an externalSelection,
        // initialise selectedKeys to emphasise the relevant markers on the map.
        // Otherwise initialise it to `{}`.
        chain(layers)
          .flatMap(layer => {
            const layerKey = layerPrimaryKey(layer);

            const keys =
              defaultExternalSelection?.[layer.layerId]?.features.map(layerKey);

            return keys == null ? [] : [[layer.layerId, keys]];
          })
          .fromPairs()
          .value(),
      [defaultExternalSelection, layers]
    )
  );

  const { visibleFeatures } = useVisibleFeaturesContext();

  const selectedFeatures = useMemo(() => {
    const ans: Record<MapLayerId, Feature[]> = {};

    for (const _layerId in visibleFeatures) {
      const layerId = unsafeMapLayerId(_layerId);

      if (externalSelection[layerId] != null) {
        ans[layerId] = externalSelection[layerId].features;
        continue;
      }

      const keys = selectedKeys[layerId] ?? [];
      const layer = layers.find(layer => layer.layerId === layerId);

      // If the user changes maps without unmounting and remounting the page,
      // we can temporarily get missing layers.
      // We ignore them silently until they are removed from local state.
      if (layer == null) {
        continue;
      }

      const layerKey = layerPrimaryKey(layer);

      const visible = visibleFeatures[layerId];
      const features: Feature[] = [];

      for (const feature of visible) {
        const key = layerKey(feature);

        if (keys.includes(key)) {
          features.push(feature);
        }

        if (features.length >= MAX_SELECTED_FEATURES) {
          break;
        }
      }

      ans[layerId] = features;
    }

    return ans;
  }, [visibleFeatures, externalSelection, selectedKeys, layers]);

  const setSelectedKeys = useCallback(
    (key: MapLayerId, value: FeatureKey[] | undefined) => {
      internalSetSelectedKeys(key, value);

      // If the user specified an external selection when the map loaded,
      // we get rid of it to allow our manual selection to take precedence.
      setExternalSelection({});
    },
    [internalSetSelectedKeys, setExternalSelection]
  );

  const setKeySelected = useCallback(
    (layerId: MapLayerId, feature: FeatureKey, selected: boolean) => {
      const layer = layers.find(l => l.layerId === layerId);
      if (layer == null) return;

      const original = selectedKeys[layerId] ?? [];

      if (selected) {
        setSelectedKeys(layerId, uniq([...original, feature]));
      } else {
        setSelectedKeys(
          layerId,
          original.filter(f => f !== feature)
        );
      }
    },
    [layers, selectedKeys, setSelectedKeys]
  );

  const clearSelectedKeys = useCallback(
    (layerId: MapLayerId) => {
      setSelectedKeys(layerId, []);
    },
    [setSelectedKeys]
  );

  const value = useMemo<SelectedFeaturesContextValue>(
    () => ({
      selectedKeys,
      selectedFeatures,
      setSelectedKeys,
      setKeySelected,
      clearSelectedKeys,
    }),
    [
      selectedKeys,
      selectedFeatures,
      setSelectedKeys,
      setKeySelected,
      clearSelectedKeys,
    ]
  );

  return (
    <SelectedFeaturesContext.Provider value={value}>
      {children}
    </SelectedFeaturesContext.Provider>
  );
}
