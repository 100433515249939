import {
  isFeature,
  isLineString,
  isPicked,
  isPoint,
  isPolygon,
} from "@cartographerio/geometry";
import { Guard, isArrayOf, isOptional, isUnion } from "@cartographerio/guard";
import {
  FieldState,
  changeAction,
  fieldIsNullable,
} from "@cartographerio/topo-form";
import {
  isAttachmentFolder,
  isTeamId,
  isTimestamp,
  isUserRef,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Flex, FormControl, Image, VStack } from "@chakra-ui/react";
import { isBoolean, isEqual, isNumber, isString } from "lodash";
import { ReactElement, ReactNode, memo, useCallback } from "react";

import Autocomplete from "../components/Autocomplete";
import Checkbox from "../components/Checkbox";
import CheckboxGroup from "../components/CheckboxGroup";
import FormLabels from "../components/FormLabels";
import HelpText from "../components/HelpText";
import JsonView from "../components/JsonView";
import MessageLabel from "../components/MessageLabel";
import RadioGroup from "../components/RadioGroup";
import Select from "../components/Select";
import TextArea from "../components/TextArea";
import TextField from "../components/TextField";
import TimestampField from "../components/TimestampField";
import AttachmentField from "./AttachmentField";
import { useFormContext } from "./context/FormContext";
import FeatureField from "./FeatureField";
import FormLineStringField from "./FormLineStringField";
import FormPointField from "./FormPointField";
import FormPolygonField from "./FormPolygonField";
import InvalidValueView from "./InvalidValueView";
import { SurveyorField } from "./SurveyorField";
import TeamField from "./TeamField";

export interface FieldViewProps {
  fieldKey: string;
  fieldState: FieldState;
  disabled?: boolean;
}

export const defaultSurveyFormTextFieldDebounce = 100;

const isSelectValue = isUnion(isString, isUnion(isNumber, isBoolean));

function FieldView(props: FieldViewProps): ReactElement {
  const { fieldKey, fieldState, disabled: _disabled } = props;

  const {
    field,
    absolutePath,
    value,
    required,
    image,
    messages,
    highlight,
    errorCount,
  } = fieldState;

  const {
    formDispatch,
    onAttachmentMetadataUpdate,
    editable,
    helpVisible,
    debugInfoVisible,
    survey,
  } = useFormContext();

  const disabled = _disabled || !editable;

  const onChange = useCallback(
    (value: unknown) => {
      formDispatch(changeAction(absolutePath, value));
    },
    [formDispatch, absolutePath]
  );

  const isInvalid = errorCount > 0;

  function wrap<A>(
    guard: Guard<A>,
    hint: string,
    render: (value: A) => ReactNode
  ): ReactElement {
    return (
      <FormControl isInvalid={isInvalid} isRequired={required}>
        <VStack align="stretch" spacing="2">
          <FormLabels primary={field.label} secondary={field.secondaryLabel} />

          {guard(value) ? render(value) : <InvalidValueView hint={hint} />}

          {messages.length > 0 &&
            messages.map((message, index) => (
              <MessageLabel
                key={index}
                level={message.level}
                text={message.text}
              />
            ))}

          {helpVisible && image && (
            // Image width is the same as in Markdown (for help text):
            <Image
              borderRadius="md"
              src={image}
              w="100%"
              maxW="44ch"
              mb="6"
              alignSelf="center"
            />
          )}

          {helpVisible && field.help != null && <HelpText text={field.help} />}

          {debugInfoVisible && (
            <JsonView
              fontSize="sm"
              label={`Field ${fieldKey}`}
              value={fieldState}
              copyToClipboard={true}
            />
          )}
        </VStack>
      </FormControl>
    );
  }

  switch (field.type) {
    case "AttachmentField":
      return wrap(isAttachmentFolder, "folder name", value => (
        <AttachmentField
          folder={value}
          survey={survey}
          disabled={disabled}
          onUpdate={onAttachmentMetadataUpdate}
          suggestedCategories={field.suggestedCategories}
          maxAttachments={field.maxFiles}
        />
      ));

    case "Autocomplete":
      return wrap(isOptional(isString), "text/blank value", value => (
        <Autocomplete.NullableString
          defaultValue={value ?? null}
          onChange={onChange}
          highlight={highlight}
          ariaLabel={field.label ?? undefined}
          placeholder={field.placeholder}
          units={field.units}
          disabled={disabled}
          debounce={defaultSurveyFormTextFieldDebounce}
          options={field.source.options}
        />
      ));

    case "Checkbox":
      return wrap(isOptional(isBoolean), "boolean/blank value", value => (
        <Flex h="10" align="center">
          <Checkbox
            value={value ?? field.checkedWhenNull ?? false}
            onChange={onChange}
            disabled={disabled}
            highlight={highlight}
            checkboxLabel={
              field.checkboxLabel ?? field.label ?? "Unlabeled checkbox"
            }
          />
        </Flex>
      ));

    case "FeatureField":
      return wrap(isOptional(isFeature), "map feature/blank value", value => (
        <FeatureField.Default
          value={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          mapOptions={field.mapOptions}
        />
      ));

    case "IntegerField":
      return wrap(isOptional(isNumber), "numeric/blank value", value => (
        <TextField.NullableNumber
          defaultValue={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          placeholder={field.placeholder}
          units={field.units}
          ariaLabel={field.label ?? undefined}
          decimalPlaces={0}
          debounce={defaultSurveyFormTextFieldDebounce}
        />
      ));

    case "LineStringField":
      return wrap(isOptional(isLineString), "line/blank value", value => (
        <FormLineStringField
          value={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          selectMinZoom={field.selectMinZoom}
        />
      ));

    case "MultiSelect": {
      return wrap(
        isOptional(isArrayOf(isSelectValue)),
        "set of selectable values",
        value => (
          <CheckboxGroup
            value={value ?? []}
            onChange={onChange}
            options={field.options}
            hiddenOptions={field.hiddenOptions}
            noneOption={field.noneOption}
            highlight={highlight}
            disabled={disabled}
            columns={field.columns}
          />
        )
      );
    }

    case "NearestFeatureField":
      return wrap(
        isOptional(isPicked),
        "nearest map feature/blank value",
        value => (
          <FeatureField.Nearest
            value={value ?? null}
            onChange={onChange}
            disabled={disabled}
            highlight={highlight}
            mapOptions={field.mapOptions}
          />
        )
      );

    case "NumberField":
      return wrap(isOptional(isNumber), "numeric/blank value", value => (
        <TextField.NullableNumber
          defaultValue={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          placeholder={field.placeholder}
          units={field.units}
          ariaLabel={field.label ?? undefined}
          decimalPlaces={field.decimalPlaces}
          debounce={defaultSurveyFormTextFieldDebounce}
        />
      ));

    case "PointField":
      return wrap(isOptional(isPoint), "location/blank value", value => (
        <FormPointField
          value={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          selectMinZoom={field.selectMinZoom}
        />
      ));

    case "PolygonField":
      return wrap(isOptional(isPolygon), "polygon/blank value", value => (
        <FormPolygonField
          value={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          selectMinZoom={field.selectMinZoom}
        />
      ));

    case "Select":
      return wrap(isOptional(isSelectValue), "selectable value", value => {
        switch (field.appearance) {
          case "radiogroup":
            return (
              <RadioGroup
                value={value ?? null}
                onChange={onChange}
                options={field.options}
                hiddenOptions={field.hiddenOptions}
                highlight={highlight}
                disabled={disabled}
                columns={field.columns}
              />
            );

          case "searchable":
            return (
              <Select.Searchable
                defaultValue={value ?? null}
                onChange={onChange}
                options={field.options}
                hiddenOptions={field.hiddenOptions}
                placeholder={field.placeholder}
                highlight={highlight}
                disabled={disabled}
                debounce={defaultSurveyFormTextFieldDebounce}
              />
            );

          case "select":
          case undefined:
            return (
              <Select.Nullable
                value={value ?? null}
                onChange={onChange}
                options={field.options}
                hiddenOptions={field.hiddenOptions}
                showValues={field.showValues}
                highlight={highlight}
                disabled={disabled}
              />
            );

          default:
            return checkExhausted(field);
        }
      });

    case "TeamField":
      return wrap(isOptional(isTeamId), "team value", value => (
        <TeamField
          defaultValue={value}
          onChange={onChange}
          project={field.project}
          disabled={disabled}
          highlight={highlight}
        />
      ));

    case "TextArea":
      return wrap(isOptional(isString), "text/blank value", value => (
        <TextArea.Nullable
          defaultValue={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
          ariaLabel={field.label ?? undefined}
          placeholder={field.placeholder}
          rows={field.rows}
          debounce={defaultSurveyFormTextFieldDebounce}
        />
      ));

    case "TextField":
      return wrap(isOptional(isString), "text/blank value", value => (
        <TextField.NullableString
          defaultValue={value ?? null}
          onChange={onChange}
          highlight={highlight}
          ariaLabel={field.label ?? undefined}
          placeholder={field.placeholder}
          units={field.units}
          disabled={disabled}
          debounce={defaultSurveyFormTextFieldDebounce}
        />
      ));

    case "TimestampField":
      return wrap(isOptional(isTimestamp), "date/time/blank value", value => (
        <TimestampField
          value={value ?? null}
          onChange={onChange}
          minValue={field.bounds?.minValue}
          maxValue={field.bounds?.maxValue}
          validateTime={field.bounds?.validateTime}
          highlight={highlight}
          disabled={disabled}
          nullable={fieldIsNullable(field)}
        />
      ));

    case "UserRefField":
      return wrap(isOptional(isUserRef), "name or Cartographer user", value => (
        <SurveyorField
          defaultValue={value ?? null}
          onChange={onChange}
          disabled={disabled}
          highlight={highlight}
        />
      ));

    default:
      return checkExhausted(field);
  }
}

export default memo(FieldView, (x, y) => {
  return isEqual(x, y);
});
