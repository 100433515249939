import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { Workspace, WorkspaceRef } from "@cartographerio/types";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import Button from "../components/Button";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import useIsMounted from "../hooks/useIsMounted";
import { routes } from "../routes";

const POLLING_INTERVAL = 1000; // milliseconds

interface CheckoutSuccessPanelProps {
  workspace: Workspace;
  clearQuery: () => void;
}

export default function CheckoutSuccessPanel(
  props: CheckoutSuccessPanelProps
): ReactElement {
  const { workspace, clearQuery } = props;

  const navigate = useNavigate();

  useOnSuccessUpdate(workspace.id, clearQuery);

  return (
    <Spaced spacing="6">
      <Para fontSize="large" textAlign="center">
        🎉 Thanks for selecting a plan! 🎉
      </Para>

      <Para textAlign="center">
        We&apos;re just talking to our payment processor. This page will refresh
        in a few seconds.
      </Para>

      <Para textAlign="center">
        <Button
          label="Refresh Now"
          onClick={() =>
            navigate(routes.workspace.billing.settings.url([workspace.alias]), {
              replace: true,
            })
          }
        />
      </Para>
    </Spaced>
  );
}

function useOnSuccessUpdate(
  workspaceRef: WorkspaceRef,
  clearQuery: () => void
) {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();

  const mounted = useIsMounted();

  useInterval(function pollSubscription() {
    if (mounted.current) {
      endpoints.billing.subscription.v1
        .readOption(apiParams, workspaceRef)
        .flatMap(opt =>
          opt.fold(
            () => IO.noop(),
            () =>
              IO.fromPromise(() =>
                queryClient.invalidateQueries(["billing", "workspace"])
              ).tap(clearQuery)
          )
        )
        .unsafeRun();
    }
  }, POLLING_INTERVAL);
}
