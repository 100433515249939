import { PlanLimits, WorkspaceUsage } from "@cartographerio/types";
import {
  Box,
  BoxProps,
  Card,
  CardBody,
  List,
  ListItem,
} from "@chakra-ui/react";

import Para from "../components/Para";
import ProgressBar from "../components/ProgressBar";
import Spaced from "../components/Spaced";
import { pluralize } from "./helpers";

export interface UsageCardProps {
  usage: WorkspaceUsage;
  limits?: PlanLimits;
  mx?: BoxProps["mx"];
}

export default function UsageCard({ usage, limits, mx }: UsageCardProps) {
  return (
    <Card w="30ch" shadow="xs" mx={mx}>
      <CardBody>
        <Spaced spacing="4">
          <Para textAlign="center" fontWeight="bold">
            Your Usage
          </Para>
          <List spacing="2">
            <UsageItem
              value={usage.numProjects}
              max={limits?.maxProjects}
              showMax={limits != null}
              unit="project"
            />
            <UsageItem
              value={usage.numUsers}
              max={limits?.maxUsers}
              showMax={limits != null}
              unit="user"
            />
          </List>
        </Spaced>
      </CardBody>
    </Card>
  );
}

interface UsageItemProps {
  value: number;
  max?: number | null;
  showMax: boolean;
  unit: string;
}

function UsageItem(props: UsageItemProps) {
  const { value, max, showMax, unit } = props;

  const barCount = max == null ? 1 : value <= max ? value : max;
  const maxCount = max == null ? 100 : value <= max ? max : value;

  const barColor =
    max == null
      ? "green.500"
      : value >= max
      ? "red.500"
      : value <= max / 2
      ? "green.500"
      : "yellow.500";

  return (
    <ListItem>
      <Spaced spacing="1">
        <Box>
          {value} {pluralize(value, unit)}
          {showMax && <> of {max ?? "∞"} on your plan</>}
        </Box>
        <ProgressBar barColor={barColor} count={barCount} maxCount={maxCount} />
      </Spaced>
    </ListItem>
  );
}
