import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  Plan,
  PlanId,
  PlanOptions,
  WorkspaceRef,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type BillingPlanKey =
  | ["billing", "plan"]
  | ["billing", "plan", "v1", "list"]
  | ["billing", "plan", "v1", "options", WorkspaceRef]
  | ["billing", "plan", "v1", "read", PlanId];

export function list(
  apiParams: ApiParams
): UseQueryOpts<Plan[], BillingPlanKey> {
  return {
    queryKey: ["billing", "plan", "v1", "list"],
    queryFn: () => endpoints.billing.plan.v1.list(apiParams).unsafeRun(),
  };
}

export function options(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<PlanOptions, BillingPlanKey> {
  return {
    queryKey: ["billing", "plan", "v1", "options", workspaceRef],
    queryFn: () =>
      endpoints.billing.plan.v1.options(apiParams, workspaceRef).unsafeRun(),
  };
}

export function read(
  apiParams: ApiParams,
  planId: PlanId
): UseQueryOpts<Plan, BillingPlanKey> {
  return {
    queryKey: ["billing", "plan", "v1", "read", planId],
    queryFn: () =>
      endpoints.billing.plan.v1.read(apiParams, planId).unsafeRun(),
  };
}
