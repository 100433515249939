import { errorMessage } from "@cartographerio/topo-core";
import { FormState } from "@cartographerio/topo-form";
import { Message, SurveyStatus } from "@cartographerio/types";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  VStack,
  chakra,
  useBoolean,
} from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";

import Checkbox from "../../components/Checkbox";
import HelpPopover from "../../components/HelpPopover";
import SaveButton from "../../components/SaveButton";
import SurveyStatusBadge from "../../components/SurveyStatusBadge";
import {
  SaveOption,
  approved,
  complete,
  draft,
  rejected,
  review,
} from "./saveOption";

export interface SaveProps {
  status: SurveyStatus;
  redirect: boolean;
  sendEmailNotifications: boolean;
  page?: number;
}

interface FormSaveButtonProps {
  canApprove: boolean;
  status: SurveyStatus;
  formState: FormState;
  saving?: boolean;
  onSave: (props: SaveProps) => void;
  onUnlock: (props: SaveProps) => void;
}

export function saveOptions(
  canApprove: boolean,
  locked: boolean
): SaveOption[] {
  return locked
    ? canApprove
      ? [draft, complete, review]
      : []
    : canApprove
    ? [draft, complete, approved, review, rejected]
    : [draft, complete];
}

const SEND_EMAILS_TEXT = outdent`
Cartographer can send notification emails
to inform people of changes in publication status.
Uncheck this box to prevent any emails being sent on this occasion.
[Read more about notification emails](https://help.cartographer.io/survey-approval).
`;

export default function FormSaveButton(
  props: FormSaveButtonProps
): ReactElement {
  const { canApprove, status, formState, saving, onSave, onUnlock } = props;

  const isApproved = status === "approved";
  const isRejected = status === "rejected";
  const locked = isApproved || isRejected;

  const saveDisabled =
    (locked && !canApprove) || (!locked && formState.errorCount > 0) || saving;
  const saveLabel = !locked ? "Save" : isApproved ? "Unapprove" : "Unreject";

  const [isOpen, { on: onOpen, off: onClose }] = useBoolean();
  const [saveProps, setSaveProps] = useState<SaveProps>({
    status: status,
    redirect: !locked,
    sendEmailNotifications: true,
  });

  const radioButtons: SaveOption[] = useMemo(
    () => saveOptions(canApprove, locked),
    [canApprove, locked]
  );

  const handleSave = useCallback(() => {
    onClose();
    if (locked) {
      onUnlock(saveProps);
    } else {
      onSave(saveProps);
    }
  }, [locked, onClose, onSave, onUnlock, saveProps]);

  const pageErrors: Message[] = useMemo(
    () =>
      formState.pageStates.flatMap(pageState =>
        pageState.errorCount > 0
          ? [
              errorMessage(
                // prettier-ignore
                pageState.page.title != null
                  ? `The ${pageState.page.title} page still has ${pageState.errorCount} validation error${pageState.errorCount > 1 ? "s" : ""}`
                  : `There are ${pageState.errorCount} validation error${pageState.errorCount > 1 ? "s" : ""} on the form`
              ),
            ]
          : []
      ),
    [formState.pageStates]
  );

  const sendCompleteEmail =
    saveProps.status === "complete" &&
    status !== "complete" &&
    status !== "approved";

  const sendApprovedEmail =
    saveProps.status === "approved" && status !== "approved";

  const modalRef = useRef<HTMLElement>(null);

  return (
    <>
      <SaveButton
        label={saveLabel}
        messages={saveDisabled ? pageErrors : []}
        onClick={onOpen}
        mb="0"
        disabled={saveDisabled}
        placement="bottom-end"
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent ref={modalRef}>
          <ModalHeader>Save</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              value={saveProps.status}
              onChange={(nextValue: SurveyStatus) =>
                setSaveProps({ ...saveProps, status: nextValue })
              }
            >
              <VStack spacing="4" alignItems="stretch">
                {radioButtons.map((radioButton, index) => (
                  <Radio key={index} value={radioButton.value}>
                    <SurveyStatusBadge mr="2" status={radioButton.value} />
                    <chakra.div fontSize="xs">
                      {radioButton.helpText}
                    </chakra.div>
                  </Radio>
                ))}
              </VStack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter mb={3}>
            <VStack w="100%" alignItems="start" gap="3">
              {canApprove && (
                <Checkbox
                  value={
                    (sendCompleteEmail || sendApprovedEmail) &&
                    saveProps.sendEmailNotifications
                  }
                  checkboxLabel={
                    <>
                      {sendCompleteEmail
                        ? "Send survey complete notification email"
                        : sendApprovedEmail
                        ? "Send survey approved notification email"
                        : "Send notification emails (none applicable)"}
                      <HelpPopover
                        text={SEND_EMAILS_TEXT}
                        portalContainerRef={modalRef}
                      />
                    </>
                  }
                  onChange={sendEmailNotifications =>
                    setSaveProps({ ...saveProps, sendEmailNotifications })
                  }
                  disabled={!sendCompleteEmail && !sendApprovedEmail}
                />
              )}
              <Checkbox
                value={!saveProps.redirect}
                checkboxLabel="Continue editing after save"
                onChange={continueEditing =>
                  setSaveProps({ ...saveProps, redirect: !continueEditing })
                }
              />
              <ButtonGroup w="100%" justifyContent="space-between">
                <Button variant="ghost" onClick={onClose} mr="3">
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  isDisabled={saveDisabled}
                  isLoading={saving}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
