import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseUserPage from "../base/BaseUserPage";

export default function AdminUserCreatePage(
  _props: RouteProps<typeof routes.admin.member.create>
): ReactElement {
  const apiParams = useApiParams();

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.home.url([])
  );

  usePageTitle("New User - Admin");

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );
  const projects = useSuspenseSearchResults(queries.project.v2.all(apiParams));
  const teams = useSuspenseSearchResults(queries.team.v2.all(apiParams));
  const workspaceGraph = useWorkspaceGraph(workspaces, projects, teams);

  return (
    <>
      <PageTopBar admin={true} basePage="members" user="new" />
      <BaseUserPage
        asTitle={true}
        nextRedirect={routes.admin.member.list.url([])}
        workspaceGraph={workspaceGraph}
      />
    </>
  );
}
