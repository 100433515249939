import { checks } from "@cartographerio/permission";
import {
  KnownWorkspaceFeature,
  KnownWorkspaceFeatureEnum,
  Project,
  Workspace,
} from "@cartographerio/types";
import { Box, Flex, HStack, StackDivider, VStack } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { IoSendSharp, IoSettingsSharp } from "react-icons/io5";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import Button from "../../../components/Button";
import ButtonLink from "../../../components/ButtonLink";
import Container from "../../../components/Container";
import PageTopBar from "../../../components/PageTopBar";
import ReformModal from "../../../components/ReformModal";
import Spaced from "../../../components/Spaced";
import { useApiParams, useCredentials } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { routes } from "../../../routes";
import ProjectPageHeader from "./ProjectPageHeader";

interface ComingSoonIntegration {
  type: "comingsoon";
  label: string;
  reformOption: string;
}

interface EnabledIntegration {
  type: "enabled";
  label: string;
  to: string;
}

interface DisabledIntegration {
  type: "disabled";
  label: string;
}

type Integration =
  | ComingSoonIntegration
  | EnabledIntegration
  | DisabledIntegration;

export default function ProjectIntegrationsPage(
  props: RouteProps<typeof routes.workspace.project.integrations.list>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  useRequirePermissionRedirect(checks.project.viewSettings(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  usePageTitle(`Integrations - ${project.name} - ${workspace.name}`);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="integrations"
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="integrations"
      />
      <Container>
        <VStack mt="4" spacing="2" divider={<StackDivider />}>
          <Integration
            label="ArcGIS Online"
            description="Send new map data to ArcGIS Online"
            workspace={workspace}
            project={project}
            required={KnownWorkspaceFeatureEnum.EnableArcgisIntegration}
            to={routes.workspace.project.integrations.arcgis.list.url([
              workspace.alias,
              project.alias,
            ])}
          />

          <IntegrationComingSoon
            label="Google Drive"
            description="Send new survey data to Google Drive"
            reformOption="Google Drive"
          />

          <IntegrationComingSoon
            label="Mailchimp"
            description="Sync project or team members to Mailchimp mailing lists"
            reformOption="Mailchimp"
          />
        </VStack>
      </Container>
    </>
  );
}

interface IntegrationProps {
  label: string;
  description: string;
  to: string;
  workspace: Workspace;
  project: Project;
  required?: KnownWorkspaceFeature;
}

function Integration(props: IntegrationProps): ReactElement {
  const { label, description, to, workspace, project: _, required } = props;

  return (
    <IntegrationPanel label={label} description={description}>
      {required == null || workspace.features.includes(required) ? (
        <ButtonLink.Internal
          buttonProps={{ w: "16ch" }}
          variant="outline"
          leftIcon={<IoSettingsSharp />}
          to={to}
        >
          Configure
        </ButtonLink.Internal>
      ) : (
        <ButtonLink.External
          buttonProps={{ w: "16ch" }}
          variant="outline"
          to={`mailto:hello@cartographer.io?subject=ArcGIS%20Integration`}
        >
          Request Access
        </ButtonLink.External>
      )}
    </IntegrationPanel>
  );
}

interface IntegrationComingSoonProps {
  label: string;
  description: string;
  reformOption: string;
}

function IntegrationComingSoon(
  props: IntegrationComingSoonProps
): ReactElement {
  const { label, description, reformOption } = props;

  const { identity } = useCredentials();

  return (
    <IntegrationPanel label={label} description={description}>
      <HStack>
        <ReformModal
          url="https://forms.reform.app/spacecatio/crt-integration-request/5oazh0"
          prepopulate={{
            "a53f22fe-db7c-4978-bd4b-84dcca7d1a19": `${identity.firstName} ${identity.lastName}`,
            "8e7a341f-2239-4471-8a18-d89bca004fb8": identity.email,
            "6eb41d30-6b4e-4dcd-a61e-0b661f3b3b4e":
              encodeURIComponent(reformOption),
          }}
          embedHeight="800px"
        >
          {onOpen => (
            <Button
              w="16ch"
              variant="outline"
              leftIcon={<IoSendSharp />}
              label="Join Waitlist"
              onClick={onOpen}
            />
          )}
        </ReformModal>
      </HStack>
    </IntegrationPanel>
  );
}

interface IntegrationPanelProps {
  label: string;
  description: string;
  children: ReactNode;
}

function IntegrationPanel(props: IntegrationPanelProps): ReactElement {
  const { label, description, children } = props;

  return (
    <Flex py="4" w="100%" justify="stretch" align="center">
      <Flex grow={1} shrink={1}>
        <Spaced spacing="2">
          <Box fontWeight="bold">{label}</Box>
          <Box>{description}</Box>
        </Spaced>
      </Flex>
      <Flex grow={0} shrink={0}>
        {children}
      </Flex>
    </Flex>
  );
}
