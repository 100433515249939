import { MessageLevel } from "@cartographerio/topo-core";
import { SystemProps, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

import { useTextHighlight } from "../hooks/highlight";

export interface MessageLabelProps extends SystemProps {
  level: MessageLevel;
  text: string;
}

export default function MessageLabel(props: MessageLabelProps): ReactElement {
  const { level, text, ...rest } = props;
  const { textColor } = useTextHighlight(level);

  return (
    <chakra.p
      my="1"
      fontSize="sm"
      fontWeight="semibold"
      textColor={textColor}
      {...rest}
    >
      {text}
    </chakra.p>
  );
}
