import { DynamicGridState } from "@cartographerio/topo-form";
import {
  Flex,
  FormControl,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import FormLabels from "../components/FormLabels";
import HelpText from "../components/HelpText";
import { useFormContext } from "./context/FormContext";
import { formatDynamicValue } from "./DynamicValue";

export interface DynamicGridProps {
  state: DynamicGridState;
}

export default function DynamicGrid(props: DynamicGridProps): ReactElement {
  const {
    state: {
      attributes,
      block: { label, secondaryLabel, help, columns = 2 },
    },
  } = props;

  const anyError = useMemo(
    () => attributes.some(attr => attr.error != null),
    [attributes]
  );

  const { helpVisible } = useFormContext();

  return (
    <FormControl isInvalid={anyError}>
      <VStack align="stretch" spacing="2">
        <FormLabels primary={label} secondary={secondaryLabel} />

        <SimpleGrid
          columns={[1, columns, columns]}
          gap="4"
          borderRadius="md"
          bg="gray.200"
          px="2"
          py="2"
        >
          {attributes.map(
            ({ label, valueType, value, units, decimalPlaces }, index) => (
              <Stat key={index}>
                <StatLabel>{label}</StatLabel>
                <StatNumber fontSize="sm">
                  <Flex direction="row" justifyContent="flex-start" gap="1">
                    <Flex grow={0} shrink={0}>
                      {formatDynamicValue(valueType, value, decimalPlaces)}
                    </Flex>
                    {units != null && value != null && (
                      <Flex grow={0} shrink={0}>
                        {units}
                      </Flex>
                    )}
                  </Flex>
                </StatNumber>
              </Stat>
            )
          )}
        </SimpleGrid>

        {helpVisible && help != null && <HelpText text={help} />}
      </VStack>
    </FormControl>
  );
}
