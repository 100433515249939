import { checks } from "@cartographerio/permission";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { invitationCodeRule } from "../../../schema/invitationCode";
import { useIOErrorAlert } from "../../components/Alert";
import InvitationCodeEditor from "../../components/InvitationCodeEditor";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import { blankInvitationCode } from "../workspace/WorkspaceInvitationCodeCreatePage";

export default function AdminInvitationCodeCreatePage(
  _props: RouteProps<typeof routes.admin.invitation.code.create>
): ReactElement {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.admin.home.url([])
  );

  usePageTitle("New Invitation Code - Admin");

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );
  const projects = useSuspenseSearchResults(queries.project.v2.all(apiParams));
  const teams = useSuspenseSearchResults(queries.team.v2.all(apiParams));
  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const workspaceGraph = useWorkspaceGraph(workspaces, projects, teams);

  const [code, setCode] = useState(blankInvitationCode());
  const messages = useMemo(() => invitationCodeRule(code), [code]);

  const handleSave = useCallback(() => {
    if (messages.length === 0) {
      queries.invitation.code.v3
        .create(queryClient, apiParams, code)
        .tap(() =>
          toast({
            title: "Invitation sent",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tap(() => navigate(routes.admin.invitation.code.list.url([])))
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [
    apiParams,
    errorAlert,
    code,
    messages.length,
    navigate,
    queryClient,
    toast,
  ]);

  return (
    <>
      <PageTopBar admin={true} basePage="invitation-code" invitation="new" />
      <PageContainer width="narrow">
        <Spaced>
          <PageHeader title="New Invitation Code" />
          <InvitationCodeEditor
            value={code}
            onChange={setCode}
            messages={messages}
            workspaceGraph={workspaceGraph}
            qualifications={qualifications}
            roleEditorSingleWorkspace={false}
          />
          <SaveButton label="Save" onClick={handleSave} messages={messages} />
        </Spaced>
      </PageContainer>
    </>
  );
}
