import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Input,
  InputElementProps,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";

import { useInputHighlight } from "../../hooks/highlight";
import useDebouncedTextValue from "../../hooks/useDebouncedTextValue";
import useFuse, { FuseResult } from "../../hooks/useFuse";
import { useInputFocus } from "../../hooks/useInputFocus";
import { BaseTextFieldProps, MappedTextFieldProps } from "../TextField/base";

export interface DefaultAutocompleteProps<A>
  extends BaseTextFieldProps<A>,
    MappedTextFieldProps<A>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  options: string[];
}

const fuseOptions = {
  includeMatches: true,
  minMatchCharLength: 1,
};

export default function DefaultAutocomplete<A>(
  props: DefaultAutocompleteProps<A>
): ReactElement {
  const {
    value,
    defaultValue,
    options,
    onChange,
    ariaLabel,
    placeholder,
    format,
    validate,
    disabled,
    maxLength,
    highlight,
    debounce,
    width,
  } = props;

  const [fuseResults, fuseOnChange] = useFuse(options, fuseOptions, 10);

  const _handleChange = useCallback(
    (value: A) => {
      onChange?.(value);
      fuseOnChange(format(value));
    },
    [format, fuseOnChange, onChange]
  );

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const {
    textValue,
    localError,
    handleBlur,
    handleFocus,
    handleTextValueChange,
    handleTextChange,
  } = useDebouncedTextValue<A, HTMLInputElement>({
    value,
    defaultValue,
    format,
    validate,
    onFocus: _handleFocus,
    onBlur: _handleBlur,
    onChange: _handleChange,
    debounce,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue: textValue,
    items: fuseResults,
    itemToString: result => result?.item ?? "",
    onInputValueChange: ({ inputValue }) =>
      handleTextValueChange(inputValue ?? ""),
  });

  const { borderColor, borderWidth } = useInputHighlight(
    localError ? "error" : highlight,
    focused
  );

  const inputProps = getInputProps({
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: handleTextChange,
    "aria-label": ariaLabel,
    placeholder,
    disabled,
    maxLength,
  });

  return (
    <Popover isOpen={isOpen} autoFocus={false} placement="bottom-start">
      <PopoverAnchor>
        <InputGroup {...getComboboxProps()}>
          <Input
            {...inputProps}
            borderColor={borderColor}
            borderWidth={borderWidth}
            width={width}
          />

          <InputRightElement alignItems="center" {...getToggleButtonProps()}>
            <ChevronDownIcon />
          </InputRightElement>
        </InputGroup>
      </PopoverAnchor>
      <PopoverContent boxShadow="lg">
        <List {...getMenuProps()}>
          {fuseResults.length === 0 && (
            <ListItem py="2" px="4">
              Type for suggestions...
            </ListItem>
          )}
          {fuseResults.map((result, index) => (
            <ListItem
              key={index}
              py="1"
              px="4"
              bg={index === highlightedIndex ? "teal.100" : undefined}
              cursor="pointer"
              {...getItemProps({
                item: result,
                index,
              })}
            >
              <Highlighted result={result} />
            </ListItem>
          ))}
        </List>
      </PopoverContent>
    </Popover>
  );
}

function highlight(
  value: string,
  indices: readonly [number, number][],
  pos: number = 1
): ReactNode {
  const pair = indices[indices.length - pos];
  return pair == null ? (
    value
  ) : (
    <>
      {highlight(value.substring(0, pair[0]), indices, pos + 1)}
      <strong>{value.substring(pair[0], pair[1] + 1)}</strong>
      {value.substring(pair[1] + 1)}
    </>
  );
}

type HighlightedProps<T> =
  | {
      result: FuseResult;
    }
  | {
      result: FuseResult<T>;
      toText: (item: T) => string;
    };

export function Highlighted<T>(props: HighlightedProps<T>): ReactElement {
  const {
    result: { matches },
  } = props;
  const textValue = useMemo(
    () =>
      "toText" in props ? props.toText(props.result.item) : props.result.item,
    [props]
  );
  return (
    <>
      {matches == null ? textValue : highlight(textValue, matches[0].indices)}
    </>
  );
}
