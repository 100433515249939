import {
  AnonymisedUser,
  Project,
  Team,
  Workspace,
  WorkspaceRef,
  internalError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  UserWorkspaceGraph,
  WorkspaceGraph,
  userWorkspaceGraph,
  workspaceGraph,
} from "@cartographerio/workspace-graph";
import { useMemo } from "react";

import queries from "../../queries";
import { useApiParams, useCredentials } from "../contexts/auth";
import { useSuspenseQueryData } from "./useSuspenseQueryData";
import { useSuspenseSearchResults } from "./useSuspenseSearchResults";

export function useWorkspaceGraph(
  workspaces: Workspace | Workspace[],
  projects: Project[],
  teams: Team[]
): WorkspaceGraph<Workspace, Project, Team> {
  return useMemo(
    () => workspaceGraph(workspaces, projects, teams),
    [workspaces, projects, teams]
  );
}

export function useUserWorkspaceGraph(
  workspaces: Workspace | Workspace[],
  projects: Project[],
  teams: Team[],
  users: AnonymisedUser[]
): UserWorkspaceGraph<Workspace, Project, Team, AnonymisedUser> {
  return useMemo(
    () => userWorkspaceGraph(workspaces, projects, teams, users),
    [workspaces, projects, teams, users]
  );
}

export function useCurrentWorkspaceGraph(
  workspaceRef?: WorkspaceRef
): WorkspaceGraph {
  const apiParams = useApiParams();
  const {
    identity: { userId },
  } = useCredentials();

  const workspace = useSuspenseQueryData(
    queries.optional(workspaceRef, workspace =>
      queries.workspace.v2.readOrFail(apiParams, workspace)
    )
  );

  return useWorkspaceGraph(
    useSuspenseSearchResults(
      queries.when(workspace == null, () =>
        queries.workspace.v2.registered(apiParams, userId)
      )
    ) ??
      workspace ??
      raise(internalError("Could not retrieve workspaces for workspace graph")),
    useSuspenseSearchResults(
      queries.project.v2.visible(apiParams, userId, workspace?.id)
    ),
    useSuspenseSearchResults(
      queries.team.v2.visible(apiParams, userId, workspace?.id)
    )
  );
}
