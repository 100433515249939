import { ContentState } from "@cartographerio/topo-form";
import { checkExhausted } from "@cartographerio/util";
import { ReactElement } from "react";

import Heading from "../components/Heading";
import MessageFormControl from "../components/MessageFormControl";
import BlockView from "./BlockView";
import CardView from "./CardView";
import { useFormContext } from "./context/FormContext";
import DynamicGrid from "./DynamicGrid";
import DynamicImage from "./DynamicImage";
import DynamicValue from "./DynamicValue";
import GroupView from "./GroupView";
import { PrimarySurveyorField } from "./PrimarySurveyorField";
import { PrimaryTeamField } from "./PrimaryTeamField";
import RepeatView from "./RepeatView";
import RequireRoleView from "./RequireRoleView";
import TextView from "./TextView";
import VisibilityView from "./VisibilityView";

export interface ContentViewProps {
  blockKey: string;
  blockState: ContentState;
  disabled?: boolean;
}

export default function ContentView(props: ContentViewProps): ReactElement {
  const { blockKey, blockState, disabled } = props;

  const { editable } = useFormContext();

  switch (blockState.type) {
    case "CardState":
      return (
        <CardView
          blockKey={blockKey}
          blockState={blockState}
          disabled={disabled}
        />
      );

    case "DynamicValueState":
      return <DynamicValue state={blockState} />;

    case "DynamicGridState":
      return <DynamicGrid state={blockState} />;

    case "DynamicImageState":
      return <DynamicImage state={blockState} />;

    case "FullWidthState":
      return (
        <BlockView
          blockKey={blockKey}
          blockState={blockState.childState}
          disabled={disabled}
        />
      );

    case "GroupState":
      return (
        <GroupView
          columns={blockState.block.columns}
          parentKey={blockKey}
          blockStates={blockState.blockStates}
          disabled={disabled}
        />
      );

    case "HeadingState": {
      return (
        <Heading level={blockState.block.level} mt="0">
          {blockState.block.text}
        </Heading>
      );
    }

    case "RepeatState":
      return (
        <RepeatView
          parentKey={blockKey}
          state={blockState}
          disabled={disabled}
        />
      );

    case "RequireRoleState":
      return (
        <RequireRoleView
          blockKey={blockKey}
          state={blockState}
          disabled={disabled}
        />
      );

    case "ScopeState":
      return (
        <BlockView
          blockKey={`${blockKey}_scope${blockState.block.path.join(".")}`}
          blockState={blockState.childState}
          disabled={disabled}
        />
      );

    case "TextState":
      return <TextView blockKey={blockKey} blockState={blockState} />;

    case "VisibilityState":
      return (
        <VisibilityView
          blockKey={blockKey}
          blockState={blockState}
          disabled={disabled}
        />
      );

    case "PrimarySurveyorFieldState":
      return (
        <MessageFormControl
          label="Surveyor"
          messages={blockState.messages}
          help="Who was the surveyor?"
          isRequired={true}
        >
          <PrimarySurveyorField state={blockState} disabled={!editable} />
        </MessageFormControl>
      );

    case "PrimaryTeamFieldState":
      return (
        <MessageFormControl
          label="Team"
          messages={blockState.messages}
          help="Which team is the person surveying for?"
        >
          <PrimaryTeamField state={blockState} disabled={!editable} />
        </MessageFormControl>
      );

    default:
      return checkExhausted(blockState);
  }
}
