import { UserSortKey } from "@cartographerio/client";
import { PermissionCheckRunner, checks } from "@cartographerio/permission";
import {
  Project,
  ProjectId,
  ProjectRoleName,
  ProjectRoleNameEnum,
  Qualification,
  QualificationId,
  QualificationRoleName,
  QualificationRoleNameEnum,
  Role,
  Team,
  TeamId,
  TeamRoleName,
  TeamRoleNameEnum,
  User,
  UserId,
  Workspace,
  WorkspaceId,
  WorkspaceRoleName,
  projectRole,
  projectRoleName,
  qualificationRoleName,
  roleProjectId,
  roleQualificationId,
  roleTeamId,
  roleWorkspaceId,
  teamRole,
  teamRoleName,
  workspaceRole,
  workspaceRoleName,
} from "@cartographerio/types";
import { findAndMap, raise } from "@cartographerio/util";
import { IconButton } from "@chakra-ui/react";
import { FaMask } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";

import Link from "../Link";
import { ActionsColumn, Column } from "../SearchResultsList/column";
import SecondaryLabel from "../SecondaryLabel";
import Select from "../Select";
import { workspaceRoleOptions } from "./role";

export type OnUserAction = (user: User) => void;
export type OnUserRoleChange = (
  user: User,
  role: Role,
  include?: boolean
) => void;
export type OnUserQualificationChange = (
  user: User,
  qualificationId: QualificationId,
  roleName: QualificationRoleName | null
) => void;

export function col(params: Column<User, UserSortKey>) {
  return params;
}

export const nameColumn = col({
  title: "Name",
  orderBy: "name",
  width: "auto",
  render: user => {
    const name = `${user.firstName} ${user.lastName}`;
    return name === user.screenName ? (
      name
    ) : (
      <SecondaryLabel text={name} secondaryText={user.screenName} />
    );
  },
});

export const emailColumn = col({
  title: "Email",
  orderBy: "email",
  width: "13ch",
  render: user => (
    <Link.External to={`mailto:${user.email}`}>{user.email}</Link.External>
  ),
});

interface WorkspaceRoleColumnProps {
  workspace: WorkspaceId;
  onRoleChange?: OnUserRoleChange | null;
}

export function workspaceRoleColumn(props: WorkspaceRoleColumnProps) {
  const { workspace, onRoleChange } = props;
  return col({
    title: "Workspace Role",
    render: user => {
      const roleName =
        findAndMap(user.roles, role =>
          roleWorkspaceId(role) === workspace ? workspaceRoleName(role) : null
        ) ?? raise<WorkspaceRoleName>("Workspace role not found");
      return (
        <Select.Standard
          w="20ch"
          options={workspaceRoleOptions}
          value={roleName}
          onChange={roleName =>
            onRoleChange?.(user, workspaceRole(roleName, workspace), true)
          }
          size="sm"
        />
      );
    },
  });
}

interface ProjectRoleColumnProps {
  project: ProjectId;
  title?: string;
  onRoleChange?: OnUserRoleChange | null;
  toggle?: boolean;
}

export function projectRoleColumn(props: ProjectRoleColumnProps) {
  const {
    project,
    title = "Project Role",
    onRoleChange,
    toggle = false,
  } = props;
  return col({
    title,
    render: user => {
      const role = user.roles.find(role => roleProjectId(role) === project);
      return toggle ? (
        <Select.Nullable
          w="20ch"
          options={ProjectRoleNameEnum.entries}
          value={role != null ? projectRoleName(role) : null}
          onChange={roleName =>
            roleName != null || role != null
              ? onRoleChange?.(
                  user,
                  roleName != null
                    ? projectRole(roleName, project)
                    : (role as Role),
                  roleName != null
                )
              : undefined
          }
          placeholder="No Role"
          size="sm"
          disabled={onRoleChange == null}
        />
      ) : (
        <Select.Standard
          w="20ch"
          options={ProjectRoleNameEnum.entries}
          value={projectRoleName(role as Role) as ProjectRoleName}
          onChange={roleName =>
            onRoleChange?.(user, projectRole(roleName, project), true)
          }
          size="sm"
          disabled={onRoleChange == null}
        />
      );
    },
  });
}

interface TeamRoleColumnProps {
  team: TeamId;
  title?: string;
  onRoleChange?: OnUserRoleChange | null;
  toggle?: boolean;
}

export function teamRoleColumn(props: TeamRoleColumnProps) {
  const { team, title = "Team Role", onRoleChange, toggle = false } = props;

  return col({
    title,
    render: user => {
      const role = user.roles.find(role => roleTeamId(role) === team);

      return toggle ? (
        <Select.Nullable
          w="20ch"
          options={TeamRoleNameEnum.entries}
          value={role != null ? teamRoleName(role) : null}
          onChange={roleName =>
            roleName != null || role != null
              ? onRoleChange?.(
                  user,
                  roleName != null ? teamRole(roleName, team) : (role as Role),
                  roleName != null
                )
              : undefined
          }
          placeholder="No Role"
          size="sm"
          disabled={onRoleChange == null}
        />
      ) : (
        <Select.Standard
          w="20ch"
          options={TeamRoleNameEnum.entries}
          value={teamRoleName(role as Role) as TeamRoleName}
          onChange={roleName =>
            onRoleChange?.(user, teamRole(roleName, team), true)
          }
          size="sm"
          disabled={onRoleChange == null}
        />
      );
    },
  });
}

interface QualificationColumnProps {
  qualification: Qualification;
  onQualificationChange?: OnUserQualificationChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function qualificationRoleColumn(props: QualificationColumnProps) {
  const { qualification, onQualificationChange, permissionCheckPasses } = props;
  return col({
    title: qualification.name,
    render: user => {
      const role = user.qualificationRoles.find(
        role => roleQualificationId(role) === qualification.id
      );
      return (
        <Select.Nullable<QualificationRoleName>
          w="16ch"
          options={QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(
              checks.qualification.grant(value, qualification.id)
            )
          )}
          value={role != null ? qualificationRoleName(role) : null}
          onChange={roleName =>
            onQualificationChange?.(user, qualification.id, roleName)
          }
          placeholder="Not granted"
          size="sm"
        />
      );
    },
  });
}

interface SelectedWorkspaceColumnsProps {
  workspaces: Workspace[];
  selectedWorkspace?: WorkspaceId | null;
  onRoleChange: OnUserRoleChange;
}

export function selectedWorkspaceColumns(props: SelectedWorkspaceColumnsProps) {
  const { workspaces, selectedWorkspace, onRoleChange } = props;

  const workspacesLookup: Record<
    WorkspaceId,
    { workspace: Workspace; sortIndex: number }
  > = workspaces.reduce(
    (acc, workspace) => ({
      ...acc,
      [workspace.id]: {
        workspace,
        sortIndex: workspaces.reduce(
          (acc, other) => acc + Number(workspace.name > other.name),
          0
        ),
      },
    }),
    {}
  );

  const getRelevantWorkspaces = (roles: Role[]) =>
    roles
      .map(roleWorkspaceId)
      .filter(
        (id): id is WorkspaceId => id != null && workspacesLookup[id] != null
      )
      .sort((a, b) =>
        b === selectedWorkspace ||
        (a !== selectedWorkspace &&
          workspacesLookup[a].sortIndex > workspacesLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => workspacesLookup[id].workspace);

  return workspaces.length > 0
    ? [
        col({
          title: "Workspace",
          render: user => {
            const relevantWorkspaces = getRelevantWorkspaces(user.roles);
            return relevantWorkspaces.length === 0 ? (
              "-"
            ) : relevantWorkspaces.length === 1 ? (
              <span>{relevantWorkspaces[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantWorkspaces[0].name ?? "-"}
                secondaryText={
                  relevantWorkspaces.length === 2
                    ? "+1 other workspace"
                    : `+${relevantWorkspaces.length - 1} other workspaces`
                }
              />
            );
          },
        }),
        col({
          title: "Workspace Role",
          render: user => {
            const relevantWorkspace = getRelevantWorkspaces(user.roles)[0] as
              | Workspace
              | undefined;
            const role = user.roles.find(
              role => roleWorkspaceId(role) === relevantWorkspace?.id
            );
            return role != null && relevantWorkspace != null ? (
              <Select.Nullable
                w="20ch"
                options={workspaceRoleOptions}
                value={workspaceRoleName(role)}
                onChange={roleName =>
                  roleName != null
                    ? onRoleChange(
                        user,
                        workspaceRole(roleName, relevantWorkspace.id),
                        true
                      )
                    : undefined
                }
                size="sm"
              />
            ) : (
              "-"
            );
          },
        }),
      ]
    : [];
}

interface SelectedProjectColumnsProps {
  projects: Project[];
  selectedProject?: ProjectId | null;
  onRoleChange?: OnUserRoleChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function selectedProjectColumns(props: SelectedProjectColumnsProps) {
  const { projects, selectedProject, onRoleChange, permissionCheckPasses } =
    props;

  const projectsLookup: Record<
    ProjectId,
    { project: Project; sortIndex: number }
  > = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: {
        project,
        sortIndex: projects.reduce(
          (acc, other) => acc + Number(project.name > other.name),
          0
        ),
      },
    }),
    {}
  );

  const getRelevantProjects = (roles: Role[]) =>
    roles
      .map(roleProjectId)
      .filter((id): id is ProjectId => id != null && projectsLookup[id] != null)
      .sort((a, b) =>
        b === selectedProject ||
        (a !== selectedProject &&
          projectsLookup[a].sortIndex > projectsLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => projectsLookup[id].project);

  return projects.length > 0
    ? [
        col({
          title: "Project",
          render: user => {
            const relevantProjects = getRelevantProjects(user.roles);
            return relevantProjects.length === 0 ? (
              "-"
            ) : relevantProjects.length === 1 ? (
              <span>{relevantProjects[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantProjects[0].name ?? "-"}
                secondaryText={
                  relevantProjects.length === 2
                    ? "+1 other project"
                    : `+${relevantProjects.length - 1} other projects`
                }
              />
            );
          },
        }),
        col({
          title: "Project Role",
          render: user => {
            const relevantProject = getRelevantProjects(user.roles)[0] as
              | Project
              | undefined;
            const role = findAndMap(user.roles, role =>
              roleProjectId(role) === relevantProject?.id
                ? projectRoleName(role)
                : null
            );
            const canGrantRole =
              relevantProject != null
                ? permissionCheckPasses(
                    checks.project.grantAccess(relevantProject)
                  )
                : false;
            return role != null && relevantProject != null ? (
              <Select.Standard
                w="20ch"
                options={ProjectRoleNameEnum.entries}
                value={role}
                onChange={roleName =>
                  canGrantRole
                    ? onRoleChange?.(
                        user,
                        projectRole(roleName, relevantProject.id),
                        true
                      )
                    : undefined
                }
                size="sm"
                disabled={!canGrantRole}
              />
            ) : (
              "-"
            );
          },
        }),
      ]
    : [];
}

interface SelectedTeamColumnsProps {
  teams: Team[];
  selectedTeam?: TeamId | null;
  onRoleChange?: OnUserRoleChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function selectedTeamColumns(props: SelectedTeamColumnsProps) {
  const { teams, selectedTeam, onRoleChange, permissionCheckPasses } = props;

  const teamsLookup: Record<TeamId, { team: Team; sortIndex: number }> =
    teams.reduce(
      (acc, team) => ({
        ...acc,
        [team.id]: {
          team,
          sortIndex: teams.reduce(
            (acc, other) => acc + Number(team.name > other.name),
            0
          ),
        },
      }),
      {}
    );

  const getRelevantTeams = (roles: Role[]) =>
    roles
      .map(roleTeamId)
      .filter((id): id is TeamId => id != null && teamsLookup[id] != null)
      .sort((a, b) =>
        b === selectedTeam ||
        (a !== selectedTeam &&
          teamsLookup[a].sortIndex > teamsLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => teamsLookup[id].team);

  return teams.length > 0
    ? [
        col({
          title: "Team",
          render: user => {
            const relevantTeams = getRelevantTeams(user.roles);
            return relevantTeams.length === 0 ? (
              "-"
            ) : relevantTeams.length === 1 ? (
              <span>{relevantTeams[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantTeams[0].name ?? "-"}
                secondaryText={
                  relevantTeams.length === 2
                    ? "+1 other team"
                    : `+${relevantTeams.length - 1} other teams`
                }
              />
            );
          },
        }),
        col({
          title: "Team Role",
          render: user => {
            const relevantTeam = getRelevantTeams(user.roles)[0] as
              | Team
              | undefined;
            const role = user.roles.find(
              role => roleTeamId(role) === relevantTeam?.id
            );
            const canGrantRole =
              relevantTeam != null
                ? permissionCheckPasses(checks.team.grantAccess(relevantTeam))
                : false;
            return role != null && relevantTeam != null ? (
              <Select.Nullable
                w="20ch"
                options={TeamRoleNameEnum.entries}
                value={teamRoleName(role)}
                onChange={roleName =>
                  roleName != null
                    ? onRoleChange?.(
                        user,
                        teamRole(roleName, relevantTeam.id),
                        true
                      )
                    : undefined
                }
                size="sm"
                disabled={!canGrantRole}
              />
            ) : (
              "-"
            );
          },
        }),
      ]
    : [];
}

export interface ActionsColumnProps {
  currentUserId: UserId;
  canImpersonate: boolean;
  canRemove: (user: User) => boolean;
  onImpersonate?: OnUserAction | null;
  onRemove?: OnUserAction | null;
}

export function actionsColumn(props: ActionsColumnProps): ActionsColumn<User> {
  const { currentUserId, canImpersonate, canRemove, onImpersonate, onRemove } =
    props;

  return {
    renderButtons: user => [
      user.id !== currentUserId && canImpersonate && onImpersonate != null && (
        <IconButton
          key="impersonate"
          variant="outline"
          title="Impersonate"
          aria-label="Impersonate"
          icon={<FaMask />}
          onClick={evt => {
            evt.stopPropagation();
            onImpersonate(user);
          }}
        />
      ),
      canRemove(user) && onRemove != null && (
        <IconButton
          key="remove user"
          variant="outline"
          title="Remove user"
          aria-label="Remove user"
          icon={<IoCloseSharp size="1.25rem" />}
          onClick={evt => {
            evt.stopPropagation();
            onRemove(user);
          }}
        />
      ),
    ],
  };
}
