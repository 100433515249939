import {
  SortOrder,
  WorkspaceBillingSummarySortKey,
} from "@cartographerio/client";
import {
  UserId,
  WorkspaceBillingSummary,
  WorkspaceId,
  WorkspaceRoleName,
} from "@cartographerio/types";
import { useQuery } from "@tanstack/react-query";
import { ReactElement } from "react";

import queries from "../../../queries";
import { useApiParams } from "../../contexts/auth";
import SearchResultsList from "../SearchResultsList";
import { workspaceActions, workspaceColumns } from "./column";

interface WorkspaceBillingSummaryListProps {
  searchTerm?: string;
  page: number;
  count: number;
  order?: SortOrder<WorkspaceBillingSummarySortKey>;
  user?: UserId | null;
  role?: WorkspaceRoleName | null;
  itemLink?: (item: WorkspaceBillingSummary) => string;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<WorkspaceBillingSummarySortKey>) => void;
}

export default function WorkspaceBillingSummaryList(
  props: WorkspaceBillingSummaryListProps
): ReactElement {
  const {
    searchTerm,
    page,
    count,
    order,
    user,
    role,
    itemLink,
    onPageChange,
    onOrderChange,
  } = props;

  const apiParams = useApiParams();

  const { data, error } = useQuery(
    queries.billing.summary.v1.search(apiParams, {
      user,
      role,
      q: searchTerm,
      order,
      skip: page * count,
      limit: count,
    })
  );

  return (
    <SearchResultsList<
      WorkspaceBillingSummary,
      WorkspaceBillingSummarySortKey,
      WorkspaceId
    >
      results={data}
      error={error}
      columns={workspaceColumns}
      actions={workspaceActions}
      page={page}
      count={count}
      itemLink={itemLink}
      onPageChange={onPageChange}
      order={order}
      onOrderChange={onOrderChange}
      itemKey={summary => summary.workspaceId}
    />
  );
}
