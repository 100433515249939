import { SelectOption } from "@cartographerio/topo-form";
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoMdPin } from "react-icons/io";

import IconButtonLink from "./IconButtonLink";
import MenuItemLink from "./MenuItemLink";

interface ViewOnMapProps {
  viewOnMapOptions?: SelectOption<string>[];
  onViewOnMap?: () => void;
  size?: IconButtonProps["size"];
}

export default function ViewOnMap(props: ViewOnMapProps): ReactElement {
  const { viewOnMapOptions, onViewOnMap, size } = props;

  return onViewOnMap != null ? (
    <IconButton
      icon={<IoMdPin />}
      variant="outline"
      aria-label="View on Map"
      title="View on Map"
      onClick={onViewOnMap}
      size={size}
    />
  ) : viewOnMapOptions != null && viewOnMapOptions.length > 0 ? (
    viewOnMapOptions.length === 1 ? (
      <IconButtonLink.Internal
        icon={<IoMdPin />}
        variant="outline"
        label="View on Map"
        to={viewOnMapOptions[0].value}
        color="inherit"
        _hover={{ textDecoration: "inherit", background: "gray.200" }}
        _active={{ textDecoration: "inherit" }}
        size={size}
      />
    ) : (
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          variant="outline"
          icon={<IoMdPin />}
          aria-label="View on Map"
          title="View on Map"
          color="inherit"
          _hover={{
            textDecoration: "inherit",
            background: "gray.200",
          }}
          _active={{ textDecoration: "inherit" }}
          size={size}
        />
        <MenuList zIndex="popover" fontSize="sm" maxH="30em" overflowY="auto">
          <MenuGroup title="Choose which map to view on" fontSize="sm" mx="3">
            {viewOnMapOptions.map(({ label, value }) => (
              <MenuItemLink.Internal key={value} to={value}>
                {label}
              </MenuItemLink.Internal>
            ))}
          </MenuGroup>
        </MenuList>
      </Menu>
    )
  ) : (
    <></>
  );
}
