import { errorMessage } from "@cartographerio/topo-core";
import { Project } from "@cartographerio/types";

import { aliasRule } from "./helpers";
import { rule } from "./rule";

export const { rule: projectRule, keys: projectErrorKeys } = rule
  .build<Project>()
  .field(
    "name",
    rule.nonEmpty([errorMessage("You must specify a project name")])
  )
  .field("alias", rule.nonEmpty([errorMessage("You must specify an alias")]))
  .field("alias", rule.nullable(aliasRule))
  .finish();
