import {
  PartialParams,
  TeamSearchOptions,
  UrlParts,
  contentAs,
  endpoints,
  fetch,
} from "@cartographerio/client";
import {
  ApiParams,
  MapVisibility,
  ProjectId,
  SearchResults,
  Team,
  TeamId,
  TeamRef,
  UserId,
  WorkspaceId,
  WorkspaceRef,
  isTeam,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../base";

export type TeamKey =
  | ["team"]
  | ["team", "v2", "read", TeamRef, WorkspaceRef | undefined]
  | ["team", "v2", "search", PartialParams<TeamSearchOptions>];

export function search(
  apiParams: ApiParams,
  options: PartialParams<TeamSearchOptions>
): UseQueryOpts<SearchResults<Team>, TeamKey> {
  return {
    queryKey: ["team", "v2", "search", options],
    queryFn: () => endpoints.team.v2.search(apiParams, options).unsafeRun(),
  };
}

export function forProject(
  apiParams: ApiParams,
  project: ProjectId,
  maps?: MapVisibility
): UseQueryOpts<SearchResults<Team>, TeamKey> {
  return search(apiParams, { project, maps });
}

export function forWorkspace(
  apiParams: ApiParams,
  workspace: WorkspaceId,
  maps?: MapVisibility
): UseQueryOpts<SearchResults<Team>, TeamKey> {
  return search(apiParams, { workspace, maps });
}

export function all(
  apiParams: ApiParams
): UseQueryOpts<SearchResults<Team>, TeamKey> {
  return search(apiParams, {});
}

export function visible(
  apiParams: ApiParams,
  user: UserId,
  workspace?: WorkspaceRef | null
): UseQueryOpts<SearchResults<Team>, TeamKey> {
  return search(apiParams, { user, workspace });
}

export function readOrFail(
  apiParams: ApiParams,
  teamId: TeamId
): UseQueryOpts<Team, TeamKey>;
export function readOrFail(
  apiParams: ApiParams,
  teamRef: TeamRef,
  workspaceRef?: WorkspaceRef
): UseQueryOpts<Team, TeamKey>;
export function readOrFail(
  apiParams: ApiParams,
  teamRef: TeamRef,
  workspace?: WorkspaceRef
): UseQueryOpts<Team, TeamKey> {
  const url: UrlParts = {
    path: `/team/v2/${teamRef}`,
    query: { workspace },
  };

  return {
    queryKey: ["team", "v2", "read", teamRef, workspace],
    queryFn: () =>
      fetch
        .get({
          apiParams,
          url,
        })
        .flatMap(contentAs("Team", isTeam))
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  team: Team
) {
  return endpoints.team.v2.save(apiParams, team).tap(_ => {
    queryClient.invalidateQueries(["team"]);
  });
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  teamId: TeamId
) {
  return endpoints.team.v2.remove(apiParams, teamId).tap(_ => {
    queryClient.invalidateQueries(["team"]);
  });
}
