import { SelectOption } from "@cartographerio/topo-form";
import {
  GlobalRoleName,
  InvitationCode,
  Project,
  ProjectAlias,
  ProjectRoleName,
  Team,
  TeamAlias,
  TeamRoleName,
  Workspace,
  WorkspaceAlias,
  WorkspaceRoleName,
} from "@cartographerio/types";
import { ReactElement, ReactNode } from "react";

import { AnyPathPart, AnyQueryParams } from "../../../routes/base";
import {
  Route,
  RouteQueryProps,
  RouteQueryUpdate,
} from "../../../routes/Route";
import Container from "../../components/Container";
import InvitationCodeList from "../../components/InvitationCodeList";
import ResetFiltersAlert from "../../components/ResetFiltersAlert";

export interface GraphItemInvitationCodeListProps {
  workspace?: Workspace | null;
  project?: Project | null;
  team?: Team | null;
  globalRole?: GlobalRoleName | null;
  workspaceRole?: WorkspaceRoleName | null;
  projectRole?: ProjectRoleName | null;
  teamRole?: TeamRoleName | null;
  roleSelect?: ReactNode;
  workspaceOptions?: SelectOption<WorkspaceAlias>[];
  projectOptions?: SelectOption<ProjectAlias>[];
  teamOptions?: SelectOption<TeamAlias>[];
  addButton?: ReactNode;
  itemLink?: (item: InvitationCode) => string;
  onWorkspaceChange?: (workspace: WorkspaceAlias | null) => void;
  onProjectChange?: (project: ProjectAlias | null) => void;
  onTeamChange?: (team: TeamAlias | null) => void;
}

interface BaseInvitationListPageProps<
  R extends Route<AnyPathPart[], AnyQueryParams>
> extends GraphItemInvitationCodeListProps {
  route: R;
  query: RouteQueryProps<R>;
  updateQuery: RouteQueryUpdate<R>;
}

export default function BaseInvitationCodeListPage<
  R extends Route<AnyPathPart[], AnyQueryParams>
>(props: BaseInvitationListPageProps<R>): ReactElement {
  const { route, query, updateQuery, ...listProps } = props;
  const { order = "created-desc", page, status, count, q: searchTerm } = query;

  return (
    <Container width="wide">
      <InvitationCodeList
        order={order}
        page={page}
        count={count}
        searchTerm={searchTerm}
        status={status}
        onOrderChange={order => updateQuery({ ...query, order })}
        onPageChange={page => updateQuery({ ...query, page })}
        onSearchTermChange={q =>
          updateQuery({ ...query, q: q ?? undefined, page: 0 })
        }
        onStatusChange={status => updateQuery({ ...query, status })}
        resetFilters={
          <ResetFiltersAlert
            route={route}
            query={query}
            updateQuery={updateQuery}
          />
        }
        {...listProps}
      />
    </Container>
  );
}
