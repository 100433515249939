import { MemberSortKey } from "@cartographerio/client";
import { Member, Workspace, WorkspaceId } from "@cartographerio/types";

import {
  OnMemberRoleChange,
  emailColumn,
  memberTypeColumn,
  nameColumn,
  selectedWorkspaceColumns,
} from "../../../components/MemberList/column";
import { Column } from "../../../components/SearchResultsList/column";

export type RoleColumn = "workspace" | "project" | "team";

interface AdminMemberListColumnsProps {
  workspaces: Workspace[];
  selectedWorkspace?: WorkspaceId | null;
  onRoleChange: OnMemberRoleChange;
}

export function adminMemberListColumns(
  props?: AdminMemberListColumnsProps
): Column<Member, MemberSortKey>[] {
  return [
    memberTypeColumn,
    nameColumn,
    emailColumn,
    ...(props != null ? selectedWorkspaceColumns(props) : []),
  ];
}
