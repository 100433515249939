import {
  KnownTeamFeatureEnum,
  Message,
  ProjectEmailSettings,
  ProjectId,
  ProjectMapSettings,
  Team,
  unsafeTeamAlias,
} from "@cartographerio/types";
import outdent from "outdent";
import { ReactElement, useMemo } from "react";

import { splitMessages } from "../../schema/rule/errors";
import { teamErrorKeys } from "../../schema/team";
import { useControlledSubset } from "../hooks/useSubset";
import AdminContent from "./AdminContent";
import AssociationsEditor, { AssociationItem } from "./AssociationsEditor";
import Checkbox from "./Checkbox";
import { TeamEmailSettingsEditor } from "./EmailSettingsEditor";
import Fieldset from "./Fieldset";
import MapSettingsEditor from "./MapSettingsEditor";
import MessageFormControl from "./MessageFormControl";
import MessageList from "./MessageList";
import PanelRow from "./PanelRow";
import Spaced from "./Spaced";
import TestTarget from "./TestTarget";
import TextField from "./TextField";

const NAME_HELP = outdent`
  The name of the project (e.g. 'Sussex Anglers' or 'Springfield Wildlife Trust').
`;

const ALIAS_HELP = outdent`
  A short name that appears in web addresses pointing to this team.
  The alias must be unique within the project
  and can only consist of lowercase letters and numbers.

  For example:

  \`\`\`
  https://app.cartographer.io/ws/myworkspace/team/myteam
  \`\`\`

  is the web address of:

  - the team with alias \`myteam\`;
  - in the workspace with alias \`myworkspace\`.
`;

interface TeamEditorProps {
  team: Team;
  onTeamChange: (value: Team) => void;
  projectOptions: AssociationItem<ProjectId>[];
  teamMessages: Message[];
  emailSettings: ProjectEmailSettings | null;
  defaultEmailSettings: ProjectEmailSettings;
  onEmailSettingsChange: (value: ProjectEmailSettings | null) => void;
  emailSettingsMessages: Message[];
  mapSettings: ProjectMapSettings | null;
  defaultMapSettings: ProjectMapSettings;
  onMapSettingsChange: (value: ProjectMapSettings | null) => void;
  mapSettingsMessages: Message[];
  disabled?: boolean;
}

export default function TeamEditor(props: TeamEditorProps): ReactElement {
  const {
    team,
    onTeamChange,
    projectOptions,
    teamMessages,
    emailSettings,
    defaultEmailSettings,
    onEmailSettingsChange,
    emailSettingsMessages,
    mapSettings,
    defaultMapSettings,
    onMapSettingsChange,
    mapSettingsMessages,
    disabled,
  } = props;

  const { has: hasFeature, toggle: toggleFeature } = useControlledSubset(
    team.features,
    features => onTeamChange({ ...team, features })
  );

  const errors = useMemo(
    () => splitMessages(teamMessages, teamErrorKeys),
    [teamMessages]
  );

  return (
    <Spaced spacing="8">
      <Fieldset legend="Team Settings">
        <MessageFormControl
          label="Name"
          messages={errors.name}
          help={NAME_HELP}
        >
          <TextField.String
            value={team.name}
            onChange={name => onTeamChange({ ...team, name })}
            disabled={disabled}
          />
        </MessageFormControl>

        <MessageFormControl
          label="Alias"
          messages={errors.alias}
          help={ALIAS_HELP}
        >
          <TextField.String
            value={team.alias}
            onChange={alias =>
              onTeamChange({ ...team, alias: unsafeTeamAlias(alias) })
            }
            disabled={disabled || hasFeature(KnownTeamFeatureEnum.LockAlias)}
          />
        </MessageFormControl>
      </Fieldset>

      <AdminContent fallback={<TestTarget testId="features-disabled" />}>
        <Fieldset legend="Features">
          {KnownTeamFeatureEnum.entries.map(({ value, label }) => (
            <PanelRow key={value}>
              <Checkbox
                checkboxLabel={label}
                value={hasFeature(value)}
                onChange={isOn => toggleFeature(value, isOn)}
                disabled={disabled}
              />
            </PanelRow>
          ))}
        </Fieldset>
      </AdminContent>

      <Fieldset legend="Project Associations" help={PROJECT_ASSOCIATIONS_HELP}>
        <AssociationsEditor
          value={team.projectIds}
          options={projectOptions}
          onChange={projectIds => onTeamChange({ ...team, projectIds })}
        />
      </Fieldset>

      <Fieldset legend="Map Settings">
        <MapSettingsEditor
          initial={mapSettings}
          defaultValue={defaultMapSettings}
          nullable={true}
          onChange={onMapSettingsChange}
          messages={mapSettingsMessages}
          disabled={disabled}
        />
      </Fieldset>

      <Fieldset legend="Email Settings">
        <TeamEmailSettingsEditor
          value={emailSettings}
          defaultValue={defaultEmailSettings}
          onChange={onEmailSettingsChange}
          messages={emailSettingsMessages}
          disabled={disabled}
        />
      </Fieldset>

      {errors._rest_.length > 0 && <MessageList messages={errors._rest_} />}
    </Spaced>
  );
}

const PROJECT_ASSOCIATIONS_HELP = outdent`
  Associate this team with one or more projects
  to give its members the ability to interact with them.
  Team Surveyors can contribute surveys to associated projects,
  and Team Approvers can QA submissions by other team members.
`;
