import { FormState, PageState, SelectOption } from "@cartographerio/topo-form";
import { useFormPermissionsContext } from "@cartographerio/topo-form-context";
import { SurveyStatus } from "@cartographerio/types";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoDuplicateOutline, IoSettingsSharp } from "react-icons/io5";

import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import ViewOnMap from "../../components/ViewOnMap";
import FormSaveButton, { SaveProps } from "./FormSaveButton";
import Pager from "./Pager";

export interface FormTopBarProps {
  title: string;
  status: SurveyStatus;
  formState: FormState;
  saving?: boolean;
  pageStates: PageState[];
  currentPageIndex: number;
  viewOnMapOptions?: SelectOption<string>[];
  onPrevPage: () => void;
  onNextPage: () => void;
  onPageChange: (index: number) => void;
  onOptionsClick: () => void;
  onSave: (props: SaveProps) => void;
  onUnlock: (props: SaveProps) => void;
  onCopy: () => void;
  onViewOnMap?: () => void;
}

export default function FormTopBar(props: FormTopBarProps): ReactElement {
  const {
    title,
    status,
    formState,
    saving,
    pageStates,
    currentPageIndex,
    viewOnMapOptions,
    onPrevPage,
    onNextPage,
    onPageChange,
    onOptionsClick,
    onSave,
    onCopy,
    onUnlock,
    onViewOnMap,
  } = props;

  const { canUpdateStatus, canApprove, canCopy, canViewOnMap } =
    useFormPermissionsContext();

  return (
    <PageHeader
      title={title}
      right={
        <HStack spacing={2}>
          {canCopy && (
            <IconButton
              icon={<IoDuplicateOutline />}
              variant="outline"
              aria-label="Duplicate Survey"
              title="Duplicate Survey"
              onClick={onCopy}
            />
          )}
          {canViewOnMap && (
            <ViewOnMap
              viewOnMapOptions={viewOnMapOptions}
              onViewOnMap={onViewOnMap}
            />
          )}
          <Button
            aria-label="Options"
            leftIcon={<IoSettingsSharp />}
            onClick={onOptionsClick}
            variant="outline"
            // size="sm"
            label="Options"
            responsive={true}
          />
          {canUpdateStatus && (
            <FormSaveButton
              canApprove={canApprove}
              status={status}
              formState={formState}
              saving={saving}
              onSave={onSave}
              onUnlock={onUnlock}
            />
          )}
        </HStack>
      }
      // For error borders
      mx="-1"
      px="1"
      pb="0"
      borderBottomWidth={0}
    >
      {pageStates.length > 1 ? (
        <Pager
          pageStates={pageStates}
          currentPageIndex={currentPageIndex}
          onPrevPageClick={onPrevPage}
          onNextPageClick={onNextPage}
          onPageTabClick={onPageChange}
        />
      ) : (
        <Box h="0" borderBottomWidth={2} borderBottomColor="gray.100" />
      )}
    </PageHeader>
  );
}
