import { SelectProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { Highlight } from "../../hooks/highlight";
import StandardSelect, { SelectOption, SelectValue } from "./Standard";

type omitted = "value" | "onChange";

export interface NullableSelectProps<A extends SelectValue>
  extends Omit<SelectProps, omitted> {
  value: A | null;
  placeholder?: string;
  options: SelectOption<A | null>[];
  hiddenOptions?: (A | null)[];
  disabledOptions?: (A | null)[];
  showValues?: boolean;
  onChange?: (value: A | null) => void;
  highlight?: Highlight;
  disabled?: boolean;
}

function nullableSelectOptions<A extends SelectValue>(
  options: SelectOption<A>[],
  placeholder: string
): SelectOption<A | null>[] {
  return options.some(opt => opt.value == null)
    ? options
    : [{ label: placeholder, value: null }, ...options];
}

export default function NullableSelect<A extends SelectValue>(
  props: NullableSelectProps<A>
): ReactElement {
  const {
    disabled,
    options,
    showValues,
    placeholder = "- Select a value -",
    ...rest
  } = props;

  const allOptions = useMemo(
    () => nullableSelectOptions(options, placeholder),
    [options, placeholder]
  );

  return (
    <StandardSelect
      disabled={disabled}
      options={allOptions}
      showValues={showValues}
      {...rest}
    />
  );
}
