import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";

import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import ResetFiltersAlert from "../../components/ResetFiltersAlert";
import Spaced from "../../components/Spaced";
import WorkspaceBillingSummaryList from "../../components/WorkspaceBillingSummaryList";
import WorkspaceListToolbar from "../../components/WorkspaceListToolbar";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { routes } from "../../routes";
import AdminPageHeader from "./AdminPageHeader";

export default function AdminBillingListPage(
  props: RouteProps<typeof routes.admin.billing.list>
): ReactElement {
  const { query, updateQuery } = props;

  const { q: searchTerm, order = "name-asc", page, count } = query;

  useRequirePermissionRedirect(checks.billing.global, () => {
    console.log("NOPE");
    return routes.admin.home.url([]);
  });

  usePageTitle("Billing - Admin");

  return (
    <>
      <PageTopBar admin={true} basePage="workspaces" />
      <AdminPageHeader
        title="Billing"
        right={
          <ButtonLink.Internal
            to={routes.admin.workspace.create.url([])}
            colorScheme="blue"
          >
            Add
          </ButtonLink.Internal>
        }
      />
      <PageContainer width="wide">
        <Spaced>
          <WorkspaceListToolbar
            searchTerm={searchTerm}
            order={order}
            onSearchTermChange={q =>
              updateQuery({ ...query, q: q ?? undefined, page: 0 })
            }
          />
          <ResetFiltersAlert
            route={routes.admin.billing.list}
            query={query}
            updateQuery={updateQuery}
          />
          <WorkspaceBillingSummaryList
            searchTerm={searchTerm}
            page={page}
            count={count}
            order={order}
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
            itemLink={summary =>
              routes.workspace.settings.url([summary.workspaceAlias])
            }
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
