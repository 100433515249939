import { checks } from "@cartographerio/permission";
import {
  Project,
  Workspace,
  roleGte,
  teamSurveyorRole,
} from "@cartographerio/types";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { Box, Icon } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { BiPlus } from "react-icons/bi";
import { IoMdDocument, IoMdMap } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";

import { useCredentials } from "../../contexts/auth";
import usePartitionedProjects from "../../hooks/usePartitionedProjects";
import { usePermissionCheckPasses } from "../../hooks/usePermissionCheckPasses";
import { useVisibleMaps } from "../../hooks/useVisibleMaps";
import { useVisibleSurveyModules } from "../../hooks/useVisibleSurveyModules";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import CreateSurveyButton from "../CreateSurveyButton";
import CreateSurveyPermissionPopover from "../CreateSurveyPermissionPopover";
import Link from "../Link";
import ProjectLabel from "../ProjectLabel";
import ProjectTeamSettingsButton from "../ProjectTeamSettingsButton";
import RequirePermission from "../RequirePermission";
import Spaced from "../Spaced";
import WithPermission from "../WithPermission";
import { projectMapLabel, projectSurveyModuleLabel } from "./base";
import SidebarHeader from "./SidebarHeader";
import { SidebarMenu } from "./SidebarMenu";
import SidebarMenuHeader from "./SidebarMenuHeader";
import SidebarMenuItem from "./SidebarMenuItem";
import SidebarSection from "./SidebarSection";

export interface WorkspaceSidebarProps {
  workspace: Workspace;
}

export default function WorkspaceSidebar(
  props: WorkspaceSidebarProps
): ReactElement | null {
  const { workspace } = props;

  const graph = useCurrentWorkspaceGraph();

  const [myProjects, otherProjects] = usePartitionedProjects(
    workspace.id,
    graph
  );

  const workspaceAdminHighlighted = useMemo(
    () =>
      !routes.workspace.home.isEqual([workspace.alias]) &&
      routes.workspace.home.isParent([workspace.alias]) &&
      !graph
        .findProjectsByWorkspaceId(workspace.id)
        .some(project =>
          routes.workspace.project.home.isParent([
            workspace.alias,
            project.alias,
          ])
        ),
    [graph, workspace.alias, workspace.id]
  );

  return useMemo(
    () => (
      <Spaced spacing="6">
        <Box>
          <SidebarHeader
            title={workspace.name}
            linkTo={routes.workspace.home.url([workspace.alias])}
          />

          <RequirePermission check={checks.workspace.admin(workspace)}>
            <SidebarMenuHeader
              text="Workspace Admin"
              linkTo={routes.workspace.settings.url([workspace.alias])}
              isHighlighted={workspaceAdminHighlighted}
            />
          </RequirePermission>
        </Box>

        {myProjects.length > 0 && (
          <SidebarSection title="My Projects">
            <Spaced spacing="4">
              {myProjects.map(project => (
                <SidebarProject
                  key={project.id}
                  project={project}
                  graph={graph}
                />
              ))}
            </Spaced>
          </SidebarSection>
        )}

        {otherProjects.length > 0 && (
          <SidebarSection
            title="Other Projects"
            collapsible={true}
            defaultIsOpen={true}
          >
            <Spaced spacing="4">
              {otherProjects.map(project => (
                <SidebarProject
                  key={project.id}
                  project={project}
                  graph={graph}
                />
              ))}
            </Spaced>
          </SidebarSection>
        )}
      </Spaced>
    ),
    [graph, myProjects, otherProjects, workspace, workspaceAdminHighlighted]
  );
}

interface SidebarProjectProps {
  project: Project;
  graph: WorkspaceGraph;
}

function SidebarProject(props: SidebarProjectProps): ReactElement {
  const { project, graph: workspaceGraph } = props;

  const modules = useVisibleSurveyModules(project);
  const maps = useVisibleMaps(project);
  const { identity } = useCredentials();

  const workspace = useMemo(
    () => workspaceGraph.findWorkspaceById(project.workspaceId),
    [project.workspaceId, workspaceGraph]
  );
  const teams = useMemo(
    () => workspaceGraph.findTeamsByProjectId(project.id),
    [project.id, workspaceGraph]
  );

  const surveyableTeams = useMemo(
    () =>
      teams.filter(team =>
        identity.roles.some(role => roleGte(role, teamSurveyorRole(team.id)))
      ),
    [identity.roles, teams]
  );

  const canCreateWithNoTeam = usePermissionCheckPasses(
    checks.survey.createWithTeam(project, null)
  );

  const unimplementedModules = useMemo(
    () =>
      project.moduleIds.filter(
        id => !modules.some(module => module.moduleId === id)
      ),
    [modules, project.moduleIds]
  );

  const unimplementedMaps = useMemo(
    () => project.mapIds.filter(id => !maps.some(map => map.mapId === id)),
    [maps, project.mapIds]
  );

  const location = useLocation();

  const headerHighlighted = useMemo(
    () =>
      routes.workspace.project.home.isParent(
        [workspace.alias, project.alias],
        location.pathname
      ) &&
      modules.every(
        module =>
          !routes.workspace.project.survey.list.isParent(
            [workspace.alias, project.alias, module.moduleId],
            location.pathname
          )
      ) &&
      maps.every(
        map =>
          !routes.workspace.project.map.isParent(
            [workspace.alias, project.alias, map.mapId],
            location.pathname
          )
      ),
    [maps, modules, project.alias, workspace.alias, location.pathname]
  );

  return (
    <Box>
      <SidebarMenuHeader
        title={project.name}
        text={<ProjectLabel project={project} />}
        linkTo={routes.workspace.project.home.url([
          workspace.alias,
          project.alias,
        ])}
        right={
          <ProjectTeamSettingsButton
            workspace={workspace}
            project={project}
            teams={teams}
            ButtonElement={SettingsButton}
          />
        }
        isHighlighted={headerHighlighted}
      />

      <SidebarMenu>
        {modules.map(module => (
          <WithPermission
            key={module.moduleId}
            check={checks.survey.view(project)}
          >
            {passes => (
              <SidebarMenuItem
                linkTo={
                  passes
                    ? routes.workspace.project.survey.list.url([
                        workspace.alias,
                        project.alias,
                        module.moduleId,
                      ])
                    : undefined
                }
                right={
                  <CreateSurveyPermissionPopover project={project}>
                    {passes =>
                      passes ? (
                        <CreateSurveyButton
                          teams={surveyableTeams}
                          includeNullOption={canCreateWithNoTeam}
                          createSurveyLink={teamAlias =>
                            routes.workspace.project.survey.create.url(
                              [workspace.alias, project.alias, module.moduleId],
                              { team: teamAlias ?? undefined }
                            )
                          }
                          placement="right"
                        >
                          <BiPlus size="1.25em" />
                        </CreateSurveyButton>
                      ) : null
                    }
                  </CreateSurveyPermissionPopover>
                }
                icon={<Icon as={IoMdDocument} />}
                text={projectSurveyModuleLabel(project, module)}
              />
            )}
          </WithPermission>
        ))}
        {unimplementedModules.map(moduleId => (
          <SidebarMenuItem
            key={moduleId}
            icon={<Icon as={IoMdDocument} />}
            text={`(${moduleId})`}
            color="gray.400"
          />
        ))}
        {maps.map(map => (
          <WithPermission key={map.mapId} check={checks.map.view(project)}>
            {passes => (
              <SidebarMenuItem
                linkTo={
                  passes
                    ? routes.workspace.project.map.url([
                        workspace.alias,
                        project.alias,
                        map.mapId,
                      ])
                    : undefined
                }
                icon={<Icon as={IoMdMap} />}
                text={projectMapLabel(project, map)}
              />
            )}
          </WithPermission>
        ))}
        {unimplementedMaps.map(mapId => (
          <SidebarMenuItem
            key={mapId}
            icon={<Icon as={IoMdMap} />}
            text={`(${mapId})`}
            color="gray.400"
          />
        ))}
      </SidebarMenu>
    </Box>
  );
}
interface SettingsButtonProps {
  label?: string;
  value?: string;
}

function SettingsButton(props: SettingsButtonProps): ReactElement {
  const { label, value } = props;
  return value != null ? (
    <Link.Internal
      to={value}
      aria-label={
        label ? `${label} Settings` : "Choose a team's settings to view"
      }
    >
      <IoSettingsSharp />
    </Link.Internal>
  ) : (
    <IoSettingsSharp
      aria-label={
        label ? `${label} Settings` : "Choose a team's settings to view"
      }
    />
  );
}
