import { BoxProps, ButtonProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { MdOutlineRateReview } from "react-icons/md";

import { useOptCredentials } from "../contexts/auth";
import LinkButton from "./LinkButton";
import ReformModal from "./ReformModal";

export interface FeedbackButtonProps extends Omit<BoxProps, "children"> {
  buttonProps?: Omit<ButtonProps, "children">;
  display?: BoxProps["display"];
  children?: (onOpen: () => void) => ReactNode;
}

export default function FeedbackButton(
  props: FeedbackButtonProps
): ReactElement {
  const { buttonProps, display = "block", children, ...rest } = props;

  const credentials = useOptCredentials();

  return (
    <>
      {/* Embed pages are used as map widgets. We don't want to show the feedback button there. */}
      {credentials != null && (
        <ReformModal
          display={display}
          url="https://forms.reform.app/spacecatio/crt-bug-report/uihriu"
          prepopulate={{
            "6cb0fdc2-9420-4668-a7d2-082290efa6d1": location.href,
            "4115c845-a2ce-4497-ab07-1105ed56ea6e": `${credentials.identity.firstName} ${credentials.identity.lastName}`,
            "3a37c384-f543-438f-a99e-c1162cc3c8db": credentials.identity.email,
          }}
          embedHeight="1500px"
          {...rest}
        >
          {children ??
            (onOpen => (
              <LinkButton
                className="feedback-button"
                variant="ghost"
                aria-label="Give us Feedback"
                title="Give us Feedback"
                onClick={onOpen}
                fontSize="sm"
                color="gray.600"
                px="2"
                alignItems="center"
                {...buttonProps}
              >
                <MdOutlineRateReview />
              </LinkButton>
            ))}
        </ReformModal>
      )}
    </>
  );
}
