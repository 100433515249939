import { checks } from "@cartographerio/permission";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseInvitationCodeViewPage from "../base/BaseInvitationCodeViewPage";

export default function AdminInvitationCodeViewPage(
  props: RouteProps<typeof routes.admin.invitation.code.view>
): ReactElement {
  const {
    path: { invitationAlias },
  } = props;

  const apiParams = useApiParams();

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const projects = useSuspenseSearchResults(queries.project.v2.all(apiParams));
  const teams = useSuspenseSearchResults(queries.team.v2.all(apiParams));

  const workspaceGraph = useWorkspaceGraph(workspaces, projects, teams);

  const defaultCode = useSuspenseQueryData(
    queries.invitation.code.v3.readOrFail(apiParams, invitationAlias)
  );

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.admin.home.url([])
  );

  usePageTitle(
    useMemo(() => {
      const { name } = defaultCode;
      return `${name || "Invitation Code"} - Admin`;
    }, [defaultCode])
  );

  return (
    <>
      <PageTopBar
        admin={true}
        basePage="invitation-code"
        invitationCode={defaultCode}
      />
      <BaseInvitationCodeViewPage
        asTitle={true}
        defaultCode={defaultCode}
        workspaceGraph={workspaceGraph}
        onDeleteGo={routes.admin.invitation.code.list.url([])}
      />
    </>
  );
}
