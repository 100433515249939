import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Email,
  InvitationCode,
  InvitationCodeRef,
  InvitationCodeSignupApprovalResponse,
  UserId,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { useToast } from "@chakra-ui/react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { useIOErrorAlert } from "../../components/Alert";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export type InvitationCodeSignupAction = "approve" | "reject";

export interface InvitationCodeSignupActionPageProps {
  invitationCodeRef: InvitationCodeRef;
  userIdOrEmail: UserId | Email;
  action: InvitationCodeSignupAction;
}

export function InvitationCodeSignupActionPage(
  props: InvitationCodeSignupActionPageProps
) {
  const { invitationCodeRef, userIdOrEmail, action } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const code = useSuspenseQueryData(
    queries.invitation.code.v3.readOrFail(apiParams, invitationCodeRef)
  );

  const workspace = useSuspenseQueryData(
    queries.optional(code.workspaceId, workspaceId =>
      queries.workspace.v2.readOrNull(apiParams, workspaceId)
    )
  );

  const showResponseToast = useCallback(
    (response: InvitationCodeSignupApprovalResponse) => {
      switch (response.type) {
        case "InvitationCodeSignupAlreadyApproved":
          return toast({
            status: action === "approve" ? "info" : "warning",
            title: "Signup already approved",
          });

        case "InvitationCodeSignupAlreadyRejected":
          return toast({
            status: action === "reject" ? "info" : "warning",
            title: "Signup already rejected",
          });

        case "InvitationCodeSignupApproved":
          return toast({
            status: action === "approve" ? "success" : "error",
            title: "Signup approved",
          });

        case "InvitationCodeSignupRejected":
          return toast({
            status: action === "reject" ? "success" : "error",
            title: "Signup rejected",
          });

        default:
          return checkExhausted(response);
      }
    },
    [action, toast]
  );

  useEffect(() => {
    approveOrReject(queryClient, apiParams, action, code, userIdOrEmail)
      .tap(showResponseToast)
      .tapError(errorAlert)
      .cleanup(() =>
        navigate(
          workspace != null
            ? routes.workspace.home.url([workspace.alias])
            : routes.admin.home.url([])
        )
      )
      .unsafeRun();
  }, [
    action,
    apiParams,
    code,
    errorAlert,
    navigate,
    queryClient,
    showResponseToast,
    userIdOrEmail,
    workspace,
  ]);

  return <LoadingPlaceholder label="Approving signup..." />;
}

function approveOrReject(
  queryClient: QueryClient,
  apiParams: ApiParams,
  action: InvitationCodeSignupAction,
  code: InvitationCode,
  userIdOrEmail: UserId | Email
): IO<InvitationCodeSignupApprovalResponse> {
  const options = { invitationCode: code.id, user: userIdOrEmail };

  switch (action) {
    case "approve":
      return queries.invitation.code.signup.v3.approve(
        queryClient,
        apiParams,
        options
      );
    case "reject":
      return queries.invitation.code.signup.v3.reject(
        queryClient,
        apiParams,
        options
      );
    default:
      return checkExhausted(action);
  }
}
