import { endpoints } from "@cartographerio/client";
import { ApiParams, Coupon, StripeCouponId } from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type BillingCouponKey =
  | ["billing", "coupon"]
  | ["billing", "coupon", "v1", "list"]
  | ["billing", "coupon", "v1", "read", StripeCouponId];

export function list(
  apiParams: ApiParams
): UseQueryOpts<Coupon[], BillingCouponKey> {
  return {
    queryKey: ["billing", "coupon", "v1", "list"],
    queryFn: () => endpoints.billing.coupon.v1.list(apiParams).unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  couponId: StripeCouponId
): UseQueryOpts<Coupon, BillingCouponKey> {
  return {
    queryKey: ["billing", "coupon", "v1", "read", couponId],
    queryFn: () =>
      endpoints.billing.coupon.v1.readOrFail(apiParams, couponId).unsafeRun(),
  };
}
