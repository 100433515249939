import { SurveySearchKeyV3 } from "@cartographerio/client";
import {
  SurveySummary,
  Team,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";

import { Column } from "../../../../components/SearchResultsList/column";
import SurveyDescriptionCell from "../../SurveyListPage/SurveyDescriptionCell";

function col(params: Column<SurveySummary, SurveySearchKeyV3>) {
  return params;
}

const timestampColumn = col({
  title: "Date",
  orderBy: "timestamp",
  defaultOrder: "desc",
  width: "13ch",
  mobile: false,
  render: survey =>
    survey.data.timestamp == null
      ? null
      : formatTimestamp(survey.data.timestamp, { format: ddmmyyyy }),
});

const descriptionColumn = col({
  title: "Description",
  ellipsis: false,
  orderBy: "description",
  width: "auto",
  render: survey => <SurveyDescriptionCell survey={survey} />,
});

const surveyorColumn = col({
  title: "Surveyor",
  orderBy: "surveyor",
  width: "14ch",
  render: survey =>
    survey.surveyor.screenName ?? survey.surveyor.userId ?? undefined,
});

function teamColumn(teams: Team[]) {
  const teamNames = new Map(teams.map(team => [team.id, team.name]));
  return col({
    title: "Team",
    ellipsis: false,
    orderBy: "team",
    width: "20ch",
    mobile: false,
    render: survey => {
      const name = survey.teamId == null ? null : teamNames.get(survey.teamId);
      return name ?? "(No team)";
    },
  });
}

interface SurveyListColumnProps {
  teams: Team[];
}

function surveyListColumns(props: SurveyListColumnProps) {
  const { teams } = props;
  return [
    timestampColumn,
    descriptionColumn,
    surveyorColumn,
    ...(teams.length > 0 ? [teamColumn(teams)] : []),
  ];
}

export default surveyListColumns;
