import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import {
  BBox4,
  Feature,
  Geometry,
  commonLocations,
  toBBox4,
} from "@cartographerio/geometry";
import { Box } from "@chakra-ui/react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { centerOfMass } from "@turf/turf";
import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactMapGL, { MapRef, ViewStateChangeEvent } from "react-map-gl";

import {
  DEFAULT_SATELLITE_MAP_STYLE,
  DEFAULT_TERRAIN_MAP_STYLE,
} from "../../../config";
import { useMapboxToken } from "../../contexts/MapboxToken";
import { Highlight } from "../../hooks/highlight";
import useResizeObserver from "../../hooks/useResizeObserver";
import { calcViewState, fromBounds, fromGeometry } from "../../map/mapHelpers";
import FieldMapControls from "../FieldMapControls";
import { DEFAULT_MAP_CSS, pointToCoordinate } from "../mapFieldHelpers";
import DrawControl from "./DrawControl";

const DEFAULT_MAX_ZOOM_ON_CHANGE = 16;
const DEFAULT_MIN_ZOOM_ON_CHANGE = 10;

export interface BaseGeometryFieldProps<G extends Geometry> {
  mapboxDraw: MapboxDraw;
  mapRef?: RefObject<MapRef>;
  value?: G | null;
  highlight?: Highlight;
  disabled?: boolean;
  defaultBounds?: BBox4;
  maxZoomOnChange?: number;
  minZoomOnChange?: number;
  easeTo?: (...args: Parameters<MapRef["easeTo"]>) => void;
  onChange?: (newValue: G | null) => void;
  onMove?: (evt: ViewStateChangeEvent) => void;
  onMoveEnd?: (evt: ViewStateChangeEvent) => void;
  children?: ReactNode;
}

export default function BaseGeometryField<G extends Geometry>(
  props: BaseGeometryFieldProps<G>
): ReactElement {
  const {
    mapboxDraw,
    mapRef: passedMapRef,
    value,
    highlight,
    disabled,
    defaultBounds = toBBox4(commonLocations.greatBritain.bounds),
    maxZoomOnChange = DEFAULT_MAX_ZOOM_ON_CHANGE,
    minZoomOnChange = DEFAULT_MIN_ZOOM_ON_CHANGE,
    easeTo: _easeTo,
    onChange,
    onMove,
    onMoveEnd,
    children,
  } = props;

  const localMapRef = useRef<MapRef>(null);

  const mapRef = passedMapRef ?? localMapRef;

  const easeTo = useMemo(
    () => _easeTo ?? mapRef.current?.easeTo,
    [_easeTo, mapRef]
  );

  const center = useMemo(
    () => (value != null ? centerOfMass(value).geometry : null),
    [value]
  );

  const handleChange = useCallback(
    (feature: Feature<G> | null) => {
      onChange?.(feature?.geometry ?? null);
    },
    [onChange]
  );

  const initialViewState = useMemo(
    () =>
      calcViewState(
        () => value != null && fromGeometry(value, minZoomOnChange),
        () => fromBounds(defaultBounds)
      ),
    [defaultBounds, minZoomOnChange, value]
  );

  const [satelliteView, setSatelliteView] = useState(false);

  const [dragging, setDragging] = useState(false);

  const handleFindMarker = useCallback(() => {
    if (mapRef.current != null && center != null) {
      easeTo?.({
        center: center.coordinates as [number, number],
      });
    }
  }, [center, easeTo, mapRef]);

  useEffect(() => {
    center != null &&
      mapRef.current != null &&
      easeTo?.({
        center: pointToCoordinate(center),
        zoom: Math.max(
          Math.min(mapRef.current.getZoom(), maxZoomOnChange),
          minZoomOnChange
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(mapContainerRef, () => mapRef.current?.resize());

  const mapboxToken = useMapboxToken();

  return (
    <Box
      ref={mapContainerRef}
      borderRadius="md"
      overflow="hidden"
      __css={{
        "mapbox-gl-draw_ctrl-draw-btn": {
          position: "relative",
        },
        ".active": {
          backgroundColor: "var(--chakra-colors-gray-300)",
        },
        ".active:before": {
          position: "absolute",
          top: "0",
          bottom: "0",
          right: "0",
          left: "0",
          backgroundColor: "inherit",
        },
      }}
    >
      <ReactMapGL
        ref={mapRef}
        mapboxAccessToken={mapboxToken}
        style={{ ...DEFAULT_MAP_CSS, height: "500px" }}
        cursor={dragging ? "grabbing" : "crosshair"}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
        onMove={onMove}
        onMoveEnd={onMoveEnd}
        initialViewState={initialViewState}
        scrollZoom={false}
        mapStyle={
          satelliteView
            ? DEFAULT_SATELLITE_MAP_STYLE
            : DEFAULT_TERRAIN_MAP_STYLE
        }
      >
        {children}

        <DrawControl
          position="top-right"
          mapboxDraw={mapboxDraw}
          onSave={!disabled ? handleChange : undefined}
        />

        <FieldMapControls
          onZoomIn={() => mapRef.current?.zoomIn()}
          onZoomOut={() => mapRef.current?.zoomOut()}
          defaultSearchPoint={center}
          onFindMarker={handleFindMarker}
          highlight={highlight}
          showGeolocate={true}
          satelliteView={satelliteView}
          onSatelliteViewChange={setSatelliteView}
        />
      </ReactMapGL>
    </Box>
  );
}
