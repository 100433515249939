import {
  Credentials,
  InvitationCodeAlias,
  InvitationCodeChallenge,
  InvitationCodeSummary,
  Message,
  ScreenName,
  UnencryptedPassword,
} from "@cartographerio/types";

export interface SigninDetails {
  password?: UnencryptedPassword;
  code?: InvitationCodeAlias;
  reacceptTerms: boolean;
  acceptTerms?: boolean;
  messages?: Message[];
}

export interface SignupDetails {
  firstName: string;
  lastName: string;
  screenName: ScreenName;
  acceptTerms: boolean;
  messages?: Message[];
}

export interface BaseState {
  email: string;
  signin: SigninDetails;
  loading: boolean;
}

export interface EnterEmail extends BaseState {
  type: "EnterEmail";
}

export interface EnterPassword extends BaseState {
  type: "EnterPassword";
  credentials?: Credentials;
  codeChallenge?: InvitationCodeChallenge | null;
}

export interface EnterCode extends BaseState {
  type: "EnterCode";
}

export interface Signup extends BaseState {
  type: "Signup";
  credentials?: Credentials;
  code: InvitationCodeSummary;
  signup: SignupDetails;
}

export interface NeedsEmailVerification extends BaseState {
  type: "NeedsEmailVerification";
  invitation: InvitationCodeSummary;
}

export type State =
  | EnterEmail
  | EnterPassword
  | EnterCode
  | Signup
  | NeedsEmailVerification;

export function enterEmail(props: Omit<EnterEmail, "type">): EnterEmail {
  return { type: "EnterEmail", ...props };
}

export function enterPassword(
  props: Omit<EnterPassword, "type">
): EnterPassword {
  return { type: "EnterPassword", ...props };
}

export function enterCode(props: Omit<EnterCode, "type">): EnterCode {
  return { type: "EnterCode", ...props };
}

export function signup(props: Omit<Signup, "type">): Signup {
  return { type: "Signup", ...props };
}

export function needsEmailVerification(
  props: Omit<NeedsEmailVerification, "type">
): NeedsEmailVerification {
  return { type: "NeedsEmailVerification", ...props };
}
