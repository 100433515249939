import { errorMessage } from "@cartographerio/topo-core";
import { InvitationCodeCreate } from "@cartographerio/types";

import { namedEmailRule } from "./emailSettings";
import { rule } from "./rule";

export const { rule: invitationCodeRule, keys: invitationCodeErrorKeys } = rule
  .build<InvitationCodeCreate>()
  .field(
    "name",
    rule.nonEmpty([errorMessage("You must name your invitation code")])
  )
  .conditionalField(
    ({ requireApproval }) => requireApproval,
    "approvalEmails",
    rule.nullable(
      rule.and(
        rule.array(namedEmailRule),
        rule.lengthGte(1, [
          errorMessage(
            "You must specify at least one email address to receive approval requests"
          ),
        ])
      )
    )
  )
  .conditionalField(
    ({ requireApproval }) => requireApproval,
    "approvalDomainAllowList",
    rule.nullable(
      rule.array(
        rule.domain([
          errorMessage(
            "Enter an email domain (everything following the '@' sign)"
          ),
        ])
      )
    )
  )
  .field(
    "roles",
    rule.nonEmpty([errorMessage("You must select at least one role")])
  )
  .finish();
