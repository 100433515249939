import { checks } from "@cartographerio/permission";
import {
  BannerMessage,
  CheckWorkspaceAccessResult,
  Timestamp,
  Workspace,
  WorkspaceRef,
  timestampEpoch,
  timestampToDate,
  unsafeQualificationAlias,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { addMonths, formatDistanceToNowStrict } from "date-fns";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { Navigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useIOErrorAlert } from "../../components/Alert";
import Button from "../../components/Button";
import Link from "../../components/Link";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import PageTopBar from "../../components/PageTopBar";
import Placeholder from "../../components/Placeholder";
import RequirePermission from "../../components/RequirePermission";
import RequirePermissionLink from "../../components/RequirePermissionLink";
import SectionedProjectGrids from "../../components/SectionedProjectGrids";
import Spaced from "../../components/Spaced";
import WithPermission from "../../components/WithPermission";
import WorkspaceBanner from "../../components/WorkspaceBanner";
import { useApiParams, useCredentials } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";

export default function WorkspaceHomePage(
  props: RouteProps<typeof routes.workspace.home>
): ReactElement {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrNull(apiParams, workspaceRef)
  );

  return workspace == null ? (
    <Navigate to={routes.home.url([])} />
  ) : (
    <RequirePermission
      check={checks.workspace.active(workspace)}
      go={routes.workspace.holding.url([workspace.alias])}
    >
      <WorkspaceHomeContent workspace={workspace} />
    </RequirePermission>
  );
}

interface WorkspaceHomeContent {
  workspace: Workspace;
}

function WorkspaceHomeContent(props: WorkspaceHomeContent): ReactElement {
  const { workspace } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const graph = useCurrentWorkspaceGraph();

  useRequirePermission(checks.workspace.active(workspace));

  usePageTitle(`Home - ${workspace.name}`);

  const banner = useSuspenseQueryData(
    queries.workspace.banner.v1.readOrNull(apiParams, workspace.id)
  );

  const access = useSuspenseQueryData(
    queries.auth.v2.readWorkspaceAccess(apiParams, workspace.id)
  );

  const onSaveBanner = useCallback(
    (banner: BannerMessage) =>
      queries.workspace.banner.v1
        .save(queryClient, apiParams, workspace.id, banner)
        .tap(() => toast({ status: "success", description: "Banner Saved" }))
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, queryClient, toast, workspace.id]
  );

  const onDeleteBanner = useCallback(
    () =>
      queries.workspace.banner.v1
        .remove(queryClient, apiParams, workspace.id)
        .tap(() => toast({ status: "success", description: "Banner Deleted" }))
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, queryClient, toast, workspace.id]
  );

  return (
    <>
      <PageTopBar workspace={workspace} />
      <PageContainer width="wide">
        <PageHeader
          title="Home"
          right={
            <RequirePermissionLink.Internal
              check={checks.workspace.admin(workspace)}
              to={routes.workspace.settings.url([workspace.alias])}
              alignSelf="start"
              failMode="hidden"
            >
              <Button
                leftIcon={<IoSettingsSharp />}
                label="Workspace Admin"
                variant="outline"
              />
            </RequirePermissionLink.Internal>
          }
        />

        <Stack
          w="100%"
          direction={["column", "column", "row-reverse"]}
          justifyContent="stretch"
          alignItems={["stretch", "stretch", "flex-start"]}
          gap="4"
        >
          <WorkspaceHomeSidebar
            workspace={workspace}
            access={access}
            banner={banner}
            onSaveBanner={onSaveBanner}
            onDeleteBanner={onDeleteBanner}
          />

          <Box
            flexGrow={1}
            flexShrink={1}
            alignItems="stretch"
            width={["100%", null, null]}
          >
            <Spaced>
              <SectionedProjectGrids
                workspace={workspace}
                graph={graph}
                fallback={
                  <Placeholder direction="column" gap="2">
                    Welcome to your new workspace!
                    <p>
                      We&apos;re still setting things up here. Watch this space!
                    </p>
                  </Placeholder>
                }
              />
            </Spaced>
          </Box>
        </Stack>
      </PageContainer>
    </>
  );
}

interface WorkspaceHomeSidebar {
  workspace: Workspace;
  access: CheckWorkspaceAccessResult;
  banner: BannerMessage | null;
  onSaveBanner: (banner: BannerMessage) => void;
  onDeleteBanner: () => void;
}

function WorkspaceHomeSidebar(
  props: WorkspaceHomeSidebar
): ReactElement | null {
  const { workspace, access, banner, onSaveBanner, onDeleteBanner } = props;
  const apiParams = useApiParams();
  const { identity } = useCredentials();
  const permissionCheckPasses = usePermissionCheckRunner();

  const promoteQualificationRegister = useMemo(
    () => workspace.features.includes("PromoteQualificationRegister"),
    [workspace.features]
  );

  const rcaQualificationId = useSuspenseQueryData(
    queries.when(promoteQualificationRegister, () =>
      queries.qualification.v1.readOrFail(
        apiParams,
        unsafeQualificationAlias("rca")
      )
    )
  )?.id;

  const showQualificationRegisterCta = useSuspenseQueryData(
    queries.optional(rcaQualificationId, () =>
      queries.qualification.register.settings.v1.readAll(
        apiParams,
        identity.userId
      )
    ),
    settings =>
      rcaQualificationId != null &&
      settings?.[rcaQualificationId]?.sharing === "Private"
  );

  const showAccessCta = useMemo(() => {
    switch (access.type) {
      case "WorkspaceAccessGranted":
        return (access.pendingRoles?.length ?? 0) > 0;
      case "WorkspaceAccessUnapproved":
      case "WorkspaceAccessDenied":
        return false;
      default:
        return checkExhausted(access);
    }
  }, [access]);

  const trialEnd = useSuspenseQueryData(
    queries.when(permissionCheckPasses(checks.billing.global), () =>
      queries.billing.summary.v1.readOrFail(apiParams, workspace.alias)
    ),
    summary =>
      summary?.type === "WorkspaceUnsubscribed" &&
      summary.settings.billingType !== "Free" &&
      summary.settings.unsubscribedTrialEnd != null &&
      timestampEpoch(summary.settings.unsubscribedTrialEnd) > Date.now() &&
      timestampEpoch(summary.settings.unsubscribedTrialEnd) <
        addMonths(Date.now(), 1).getTime()
        ? summary.settings.unsubscribedTrialEnd
        : null
  );

  const showBanner = useMemo(() => !!banner?.text, [banner]);

  const showSidebar = useMemo(
    () =>
      showAccessCta ||
      showBanner ||
      showQualificationRegisterCta ||
      trialEnd != null,
    [showAccessCta, showBanner, showQualificationRegisterCta, trialEnd]
  );

  return showSidebar ? (
    <Spaced
      flexGrow={0}
      flexShrink={0}
      flexBasis="25%"
      maxW={[null, null, "32ch"]}
      mt="4px"
      pt={[null, null, "16"]}
      fontSize="sm"
    >
      {showBanner && (
        <WithPermission check={checks.workspace.admin(workspace)}>
          {passes =>
            // Guard against null, undefined, and "":
            banner?.text && (
              <WorkspaceBanner
                banner={banner}
                editable={passes}
                onSave={onSaveBanner}
                onDelete={onDeleteBanner}
              />
            )
          }
        </WithPermission>
      )}

      {trialEnd != null && (
        <TrialEndCta trialEnd={trialEnd} workspaceRef={workspace.alias} />
      )}

      <WorkspaceAccessCta access={access} />

      {showQualificationRegisterCta && <QualificationRegisterCta />}
    </Spaced>
  ) : null;
}

function TrialEndCta({
  trialEnd,
  workspaceRef,
}: {
  trialEnd: Timestamp;
  workspaceRef: WorkspaceRef;
}) {
  const distance = useMemo(
    () =>
      formatDistanceToNowStrict(timestampToDate(trialEnd), {
        roundingMethod: "floor",
      }),
    [trialEnd]
  );

  return (
    <WorkspaceSidebarPanel title="⚠ Trial Ending">
      <p>You have {distance} left on your free trial.</p>
      <p>
        Please{" "}
        <Link.Internal
          fontWeight="bold"
          to={routes.workspace.billing.settings.url([workspaceRef])}
        >
          setup payment details
        </Link.Internal>{" "}
        for continued uninterrupted use of Cartographer.
      </p>
    </WorkspaceSidebarPanel>
  );
}

function WorkspaceAccessCta({
  access,
}: {
  access: CheckWorkspaceAccessResult;
}) {
  switch (access.type) {
    case "WorkspaceAccessGranted":
      return (access.pendingRoles?.length ?? 0) > 0 ? (
        <WorkspaceSidebarPanel title="🔑 Permission Changes">
          <p>
            Some changes to your account are awaiting approval by a workspace
            administrator.
          </p>
          <p>Your access will increase once they are approved.</p>
        </WorkspaceSidebarPanel>
      ) : null;
    case "WorkspaceAccessUnapproved":
    case "WorkspaceAccessDenied":
      return null;
    default:
      return checkExhausted(access);
  }
}

function QualificationRegisterCta() {
  return (
    <WorkspaceSidebarPanel title="🎓 RCA Surveyor Register">
      <p>We&apos;re launching a public register of qualified RCA surveyors.</p>
      <p>
        Opt in on your{" "}
        <Link.Internal to={routes.account.url([])} fontWeight="bold">
          My Account
        </Link.Internal>{" "}
        page to appear on the register and publish your RCA certificate.
      </p>
    </WorkspaceSidebarPanel>
  );
}

interface WorkspaceSidebarPanelProps {
  title: ReactNode;
  children: ReactNode;
}

function WorkspaceSidebarPanel(props: WorkspaceSidebarPanelProps) {
  const { title, children } = props;

  return (
    <Alert
      flexDirection="column"
      alignItems="stretch"
      variant="subtle"
      rounded="md"
      bg="gray.100"
    >
      <Flex direction="row" mb="2">
        <AlertTitle>{title}</AlertTitle>
      </Flex>
      <AlertDescription>
        <Spaced spacing="2" w="100%">
          {children}
        </Spaced>
      </AlertDescription>
    </Alert>
  );
}
