import { ReactElement } from "react";
import { FaEnvelope } from "react-icons/fa";

import Link, { ExternalLinkProps } from "./Link";

interface EmailUsLinkProps extends ExternalLinkProps {}

export default function EmailUsLink(props: EmailUsLinkProps): ReactElement {
  return (
    <Link.External
      to="mailto:hello@cartographer.io"
      display="flex"
      flexDirection="row"
      alignItems="center"
      color="inherit"
      gap="1"
      {...props}
    >
      <FaEnvelope />
      hello@cartographer.io
    </Link.External>
  );
}
