import { SearchResultsFormat, endpoints } from "@cartographerio/client";
import { Result } from "@cartographerio/fp";
import {
  BBox4,
  bboxNe,
  bboxSw,
  isBBox4,
  point,
  toBBox4,
} from "@cartographerio/geometry";
import { SelectOption } from "@cartographerio/inventory-enums";
import {
  NamedInterval,
  OpenInterval,
  Project,
  ProjectMapSettings,
  SurveyId,
  SurveyModuleId,
  SurveyStatusEnum,
  TeamAlias,
  Workspace,
  emptyOpenInterval,
  isValidUuid,
  namedToOpenInterval,
  unsafeSurveyId,
} from "@cartographerio/types";
import { checkExhausted, labeled } from "@cartographerio/util";
import {
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import {
  HiCalendar,
  HiLocationMarker,
  HiOutlineCalendar,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { useOnClickOutside } from "usehooks-ts";

import queries from "../../../../queries";
import { RouteQueryProps, RouteQueryUpdate } from "../../../../routes";
import BBoxField from "../../../components/BBoxField";
import Calendar from "../../../components/Calendar";
import DownloadMenu from "../../../components/DownloadMenu";
import FormLabel from "../../../components/FormLabel";
import PointTextField from "../../../components/PointTextField";
import ResetFiltersAlert from "../../../components/ResetFiltersAlert";
import SearchField, {
  SearchFieldMethods,
} from "../../../components/SearchField";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import TextArea from "../../../components/TextArea";
import { useApiParams } from "../../../contexts/auth";
import { useApiUrlFormatter } from "../../../hooks/useApiUrl";
import { useProjectHasTeams } from "../../../hooks/useProjectHasTeams";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useVolatileState } from "../../../hooks/useVolatileState";
import { routes } from "../../../routes";

const namedIntervalOptions: SelectOption<NamedInterval["type"]>[] = [
  { label: "Last Week", value: "week" },
  { label: "Last Month", value: "month" },
  { label: "Last Year", value: "year" },
  { label: "Custom", value: "custom" },
];

interface SurveyListToolbarProps {
  query: RouteQueryProps<typeof routes.workspace.project.survey.list>;
  updateQuery: RouteQueryUpdate<typeof routes.workspace.project.survey.list>;
  module: SurveyModuleId;
  workspace: Workspace;
  project: Project;
  onSearchById?: (surveys: SurveyId[]) => void;
}

export default function SurveyListToolbar(
  props: SurveyListToolbarProps
): ReactElement {
  const { query, updateQuery, module, workspace, project, onSearchById } =
    props;

  const apiParams = useApiParams();

  const multiTeam = useProjectHasTeams(workspace, project);

  const teams = useQuery(
    queries.when(multiTeam, () =>
      queries.team.v2.forProject(apiParams, project.id)
    )
  );

  const mapSettings = useSuspenseQueryData(
    queries.project.mapSettings.v1.readOrDefault(apiParams, project.id)
  );

  const teamOptions: SelectOption<TeamAlias>[] | null = useMemo(
    () =>
      teams.data?.results.map(team => ({
        label: team.name,
        value: team.alias,
      })) ?? null,
    [teams]
  );

  const teamValue = useMemo(
    () =>
      teams.data?.results.find(
        team => team.alias === query.team || team.id === query.team
      )?.alias ?? null,
    [query.team, teams]
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string => {
      const interval: OpenInterval =
        query.when == null
          ? emptyOpenInterval
          : namedToOpenInterval(query.when);

      return formatApiUrl(
        endpoints.survey.v3.searchUrl(module, {
          workspace: workspace.id,
          project: project.alias,
          q: query.q,
          team: query.team,
          from: interval.from,
          to: interval.to,
          sw: query.where != null ? point(...bboxSw(query.where)) : undefined,
          ne: query.where != null ? point(...bboxNe(query.where)) : undefined,
          status: query.status,
          order: query.order,
          format,
        })
      );
    },
    [
      formatApiUrl,
      module,
      project.alias,
      query.order,
      query.q,
      query.status,
      query.team,
      query.when,
      query.where,
      workspace.id,
    ]
  );

  const { icon: rangeFilterIcon, label: rangeFilterLabel } = useMemo(() => {
    switch (query.when?.type) {
      case undefined:
        return { icon: <HiOutlineCalendar />, label: "All Dates" };
      case "week":
        return { icon: <HiCalendar />, label: "Last Week" };
      case "month":
        return { icon: <HiCalendar />, label: "Last Month" };
      case "year":
        return { icon: <HiCalendar />, label: "Last Year" };
      case "custom":
        return { icon: <HiCalendar />, label: "Selected Dates" };
      default:
        return checkExhausted(query.when);
    }
  }, [query.when]);

  const { icon: bboxFilterIcon, label: bboxFilterLabel } =
    query.where == null
      ? { icon: <HiOutlineLocationMarker />, label: "All Locations" }
      : { icon: <HiLocationMarker />, label: "Selected Locations" };

  const searchFieldRef = useRef<SearchFieldMethods>(null);

  const {
    isOpen: isRangeOpen,
    onOpen: onRangeOpen,
    onClose: onRangeClose,
  } = useDisclosure();
  const {
    isOpen: isBboxOpen,
    onOpen: onBboxOpen,
    onClose: onBboxClose,
  } = useDisclosure();

  return (
    <VStack w="100%" align="stretch">
      <HStack justifyContent="stretch" wrap="wrap">
        <SearchField
          ref={searchFieldRef}
          defaultValue={query.q}
          onChange={q => updateQuery({ ...query, q: q ?? undefined, page: 0 })}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        {onSearchById != null && <SearchById onSearch={onSearchById} />}
        {teamOptions != null && teamOptions.length > 0 && (
          <Select.Nullable
            value={teamValue}
            options={teamOptions}
            onChange={teamAlias =>
              updateQuery({ ...query, team: teamAlias ?? undefined, page: 0 })
            }
            placeholder="All teams"
            background="transparent"
            w="fit-content"
            maxW="52"
            borderWidth="2px"
          />
        )}
        <Select.Nullable
          value={query.status ?? null}
          onChange={status =>
            updateQuery({ ...query, status: status ?? undefined, page: 0 })
          }
          bg="transparent"
          placeholder="All Statuses"
          options={SurveyStatusEnum.entries}
          maxW="36"
        />
        <Button
          variant="outline"
          leftIcon={rangeFilterIcon}
          onClick={onRangeOpen}
          fontWeight="normal"
        >
          {rangeFilterLabel}
        </Button>
        <DateRangeModal
          value={query.when ?? null}
          onChange={when =>
            updateQuery({ ...query, when: when ?? undefined, page: 0 })
          }
          isOpen={isRangeOpen}
          onClose={onRangeClose}
        />
        <Button
          variant="outline"
          leftIcon={bboxFilterIcon}
          onClick={onBboxOpen}
          fontWeight="normal"
        >
          {bboxFilterLabel}
        </Button>
        <BBoxFilterModal
          value={query.where ?? null}
          onChange={where =>
            updateQuery({ ...query, where: where ?? undefined, page: 0 })
          }
          mapSettings={mapSettings}
          isOpen={isBboxOpen}
          onClose={onBboxClose}
        />
        <DownloadMenu downloadUrl={downloadUrl} />
      </HStack>
      <ResetFiltersAlert
        route={routes.workspace.project.survey.list}
        query={query}
        updateQuery={updateQuery}
      />
    </VStack>
  );
}

interface SearchByIdProps {
  onSearch: (surveys: SurveyId[]) => void;
}

function SearchById(props: SearchByIdProps): ReactElement {
  const { onSearch } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rawIds, setRawIds] = useState("");

  const handleSearch = useCallback(() => {
    onClose();
    onSearch(rawIds.split(/\s+/).filter(isValidUuid).map(unsafeSurveyId));
    setRawIds("");
  }, [onClose, onSearch, rawIds]);

  const handleCancel = useCallback(() => {
    onClose();
    setRawIds("");
  }, [onClose]);

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<AiOutlineFileSearch />}
        fontWeight="normal"
        onClick={onOpen}
      >
        By ID
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <FormLabel text="Survey IDs separated by newlines" />
            <TextArea.String
              placeholder="00000000-0000-0000-0000-000000000000"
              value={rawIds}
              onChange={setRawIds}
              rows={10}
            />
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSearch}>
              Add Surveys
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

interface DateRangeModalProps {
  value: NamedInterval | null;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (value: NamedInterval | null) => void;
}

function DateRangeModal(props: DateRangeModalProps): ReactElement {
  const { value, isOpen, onClose, onChange } = props;

  const contentRef = useRef<HTMLElement | null>(null);

  useOnClickOutside(contentRef, onClose);

  const [namedInterval, setNamedInterval] = useVolatileState(
    useCallback(() => value, [value])
  );

  const openInterval = useMemo(
    (): OpenInterval =>
      namedInterval != null
        ? namedToOpenInterval(namedInterval)
        : emptyOpenInterval,
    [namedInterval]
  );

  const handleNamedIntervalChange = useCallback(
    (type: NamedInterval["type"] | null) => {
      switch (type) {
        case null:
          setNamedInterval(null);
          break;
        case "week":
        case "month":
        case "year":
          setNamedInterval({ type });
          break;
        case "custom": {
          const openInterval =
            namedInterval != null
              ? namedToOpenInterval(namedInterval)
              : emptyOpenInterval;
          setNamedInterval({ type, ...openInterval });
          break;
        }
        default:
          return checkExhausted(type);
      }
    },
    [namedInterval, setNamedInterval]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent ref={contentRef} boxShadow="lg" width="fit-content">
        <ModalBody>
          <Spaced spacing="4" mt="5">
            <Select.Nullable
              value={namedInterval?.type ?? null}
              options={namedIntervalOptions}
              onChange={handleNamedIntervalChange}
              placeholder="All Dates"
            />
            <Calendar.Interval
              value={openInterval}
              onChange={openInterval =>
                setNamedInterval(labeled("custom", openInterval))
              }
            />
          </Spaced>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            variant="outline"
            onClick={() => {
              onClose();
              onChange?.(null);
            }}
          >
            Clear
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose();
              onChange?.(namedInterval);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface BBoxFilterModalProps {
  value: BBox4 | null;
  onChange?: (value: BBox4 | null) => void;
  mapSettings: ProjectMapSettings;
  isOpen: boolean;
  onClose: () => void;
}

function BBoxFilterModal(props: BBoxFilterModalProps): ReactElement {
  const { value, isOpen, mapSettings, onClose, onChange } = props;

  const contentRef = useRef<HTMLElement | null>(null);

  useOnClickOutside(contentRef, onClose);

  const [pointSw, setPointSw] = useVolatileState(
    useCallback(() => (value != null ? point(...bboxSw(value)) : null), [value])
  );
  const [pointNe, setPointNe] = useVolatileState(
    useCallback(() => (value != null ? point(...bboxNe(value)) : null), [value])
  );

  const bbox = useMemo(
    () =>
      pointSw == null || pointNe == null
        ? null
        : Result.pure([...pointSw.coordinates, ...pointNe.coordinates])
            .guard(isBBox4)
            .getOrNull(),
    [pointNe, pointSw]
  );

  const handleBboxChange = useCallback(
    (value: BBox4 | null) => {
      if (value != null) {
        setPointSw(point(...bboxSw(value)));
        setPointNe(point(...bboxNe(value)));
      } else {
        setPointSw(null);
        setPointNe(null);
      }
    },
    [setPointNe, setPointSw]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} size="2xl">
      <ModalOverlay />
      <ModalContent ref={contentRef} boxShadow="lg">
        <ModalBody>
          <Spaced spacing="4" mt="5">
            <FormControl>
              <FormLabel text="Bounding Box" />
              <BBoxField
                value={bbox}
                onChange={handleBboxChange}
                defaultBounds={toBBox4(mapSettings.defaultBounds)}
              />
            </FormControl>
            <HStack>
              <FormControl>
                <FormLabel text="South West Point" />
                <PointTextField value={pointSw} onChange={setPointSw} />
              </FormControl>
              <FormControl>
                <FormLabel text="North East Point" />
                <PointTextField value={pointNe} onChange={setPointNe} />
              </FormControl>
            </HStack>
          </Spaced>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            variant="outline"
            onClick={() => {
              onClose();
              onChange?.(null);
            }}
          >
            Clear
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose();
              onChange?.(bbox);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
