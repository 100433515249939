import {
  BillingInterval,
  InvoiceTotal,
  Subscription,
  WorkspaceAlias,
} from "@cartographerio/types";
import { Card, CardBody, List, ListItem } from "@chakra-ui/react";

import ButtonLink from "../components/ButtonLink";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { routes } from "../routes";
import { formatIntervalAmount } from "./format";
import { invoiceTotalAmount } from "./helpers";

export interface BillCardProps {
  workspaceAlias: WorkspaceAlias;
  subscription: Subscription;
}

export default function BillCard({
  workspaceAlias,
  subscription,
}: BillCardProps) {
  return (
    <Card w="30ch" shadow="xs">
      <CardBody>
        <Spaced spacing="4">
          <Para textAlign="center" fontWeight="bold">
            Your Bill *
          </Para>
          <List spacing="4">
            <InvoiceTotalView
              invoiceTotal={subscription.nextInvoiceTotal}
              billingInterval={subscription.billingInterval}
            />
          </List>
          <ButtonLink.Internal
            to={routes.workspace.billing.portal.url([workspaceAlias])}
            display="block"
            mx="auto"
            w="fit-content"
            size="sm"
            buttonProps={{ w: "100%" }}
          >
            Billing Portal
          </ButtonLink.Internal>
        </Spaced>
      </CardBody>
    </Card>
  );
}

interface InvoiceTotalViewProps {
  invoiceTotal: InvoiceTotal;
  billingInterval: BillingInterval;
}

function InvoiceTotalView(props: InvoiceTotalViewProps) {
  const { invoiceTotal, billingInterval } = props;

  return (
    <ListItem textAlign="center" fontWeight="semibold">
      {formatIntervalAmount(invoiceTotalAmount(invoiceTotal), billingInterval)}
    </ListItem>
  );
}
