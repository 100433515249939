import { Box, ResponsiveValue } from "@chakra-ui/react";
import * as CSS from "csstype";
import { ReactElement } from "react";

export interface ProgressBarProps {
  trackColor?: ResponsiveValue<CSS.Property.Color>;
  barColor: ResponsiveValue<CSS.Property.Color>;
  count: number | undefined;
  maxCount: number | undefined;
}

export default function ProgressBar(props: ProgressBarProps): ReactElement {
  const { trackColor = "gray.100", barColor, count, maxCount } = props;

  return count != null && maxCount != null && maxCount > 0 ? (
    <Box w="100%">
      <Box rounded="full" bg={trackColor}>
        <Box
          w={`${(100 * count) / maxCount}%`}
          h="1"
          rounded="full"
          bg={barColor}
        ></Box>
      </Box>
    </Box>
  ) : (
    <Box h="1" />
  );
}
