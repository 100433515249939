import { Option } from "@cartographerio/fp";
import {
  Point,
  formatLatLng,
  ngrDigits,
  ngrToPoint,
  parseLatLng,
  safePointToNgr,
} from "@cartographerio/geometry";
import { checkExhausted } from "@cartographerio/util";
import {
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { isEqual } from "lodash";
import outdent from "outdent";
import {
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useLocationMode } from "../contexts/location";
import { Highlight, useInputHighlight } from "../hooks/highlight";
import { useInputFocus } from "../hooks/useInputFocus";
import Markdown from "./Markdown";
import Spaced from "./Spaced";

export interface PointTextFieldProps {
  value: Point | null;
  size?: "md" | "sm";
  onChange: (newValue: Point | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  highlight?: Highlight;
  disabled?: boolean;
  enableHelp?: boolean;
}

export default function PointTextField(
  props: PointTextFieldProps
): ReactElement {
  const {
    value,
    size = "md",
    onChange,
    onFocus,
    onBlur,
    highlight,
    disabled,
    enableHelp = false,
  } = props;

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const [textValue, setTextValue] = useState("");
  const [locationMode, setLocationMode] = useLocationMode();

  const validTextValue = useMemo(
    () =>
      locationMode === "NGR"
        ? textValue.length === 10 && ngrToPoint(textValue) != null
        : parseLatLng(textValue) != null,
    [locationMode, textValue]
  );

  const { borderColor, borderWidth } = useInputHighlight(
    !focused || validTextValue ? highlight : "error",
    focused
  );

  let buttonSize: "xs" | "sm";
  switch (size) {
    case "md":
      buttonSize = "sm";
      break;
    case "sm":
      buttonSize = "xs";
      break;
    default:
      checkExhausted(size);
      break;
  }

  useEffect(() => {
    if (!focused) {
      if (value == null) {
        setTextValue("");
      } else if (locationMode === "NGR") {
        setTextValue(safePointToNgr(value));
      } else if (locationMode === "GPS") {
        setTextValue(formatLatLng(value));
      } else {
        checkExhausted(locationMode);
      }
    }
  }, [focused, locationMode, value]);

  // `touched` is a hack to work around NGR rounding errors.
  //
  // Because we reformat the field on blur,
  // consistently focusing and blurring the field
  // can cause the selected point to drift.
  //
  // To avoid this, we see if the field was `touched` when we blur.
  // We only reformat if it was.
  const [touched, setTouched] = useState(false);

  const handleTextChange = useCallback(
    (text: string) => {
      setTouched(true);
      setTextValue(text);

      if (text.trim() === "") {
        onChange(null);
      } else if (locationMode === "NGR") {
        if (ngrDigits(text) === 10) {
          Option.wrap(ngrToPoint(text)).forEach(onChange);
        }
      } else if (locationMode === "GPS") {
        Option.wrap(parseLatLng(text)).forEach(onChange);
      } else {
        checkExhausted(locationMode);
      }
    },
    [locationMode, onChange]
  );

  const handleFocus = useCallback(() => {
    setTouched(false);
    onFocus?.();
    _handleFocus();
  }, [_handleFocus, onFocus]);

  const handleBlur = useCallback(() => {
    if (touched && locationMode === "NGR" && ngrDigits(textValue) != null) {
      Option.wrap(ngrToPoint(textValue)).forEach(onChange);
    }

    setTouched(false);
    onBlur?.();
    _handleBlur();
  }, [touched, locationMode, textValue, onBlur, _handleBlur, onChange]);

  const input = (
    <Input
      disabled={disabled}
      borderColor={borderColor}
      borderWidth={borderWidth}
      value={textValue}
      onChange={evt => handleTextChange(evt.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      outline="0"
      _focusVisible={
        !validTextValue ? { borderColor, boxShadow: "0" } : undefined
      }
      aria-label={locationMode === "NGR" ? "National Grid Reference" : "GPS"}
      placeholder={
        locationMode === "NGR"
          ? "AB 12345 12345"
          : "0.123456, 0.123456 (lat, long)"
      }
    />
  );

  const wrappedInput = enableHelp ? (
    <PopoverTrigger>{input}</PopoverTrigger>
  ) : (
    input
  );

  const inputGroup = (
    <InputGroup alignItems="center" size={size}>
      {wrappedInput}
      <InputRightElement w="auto" mr="1">
        <ButtonGroup isAttached={true} size={buttonSize}>
          <Button
            onClick={() => setLocationMode("NGR")}
            variant={locationMode === "NGR" ? undefined : "outline"}
            bg={locationMode === "NGR" ? "gray.200" : "transparent"}
            me="0 !important"
            px={
              locationMode === "NGR"
                ? undefined
                : "calc(var(--chakra-space-3) - 1.6px)"
            }
            borderColor="gray.200"
            _focus={{ boxShadow: "0" }}
          >
            NGR
          </Button>
          <Button
            onClick={() => setLocationMode("GPS")}
            variant={locationMode === "GPS" ? undefined : "outline"}
            bg={locationMode === "GPS" ? "gray.200" : "transparent"}
            px={
              locationMode === "GPS"
                ? undefined
                : "calc(var(--chakra-space-3) - 1.6px)"
            }
            borderColor="gray.200"
            _focus={{ boxShadow: "0" }}
          >
            GPS
          </Button>
        </ButtonGroup>
      </InputRightElement>
    </InputGroup>
  );

  return enableHelp ? (
    <Popover
      placement="bottom"
      // isOpen={isHelpOpen}
      // onOpen={setHelpOpen.on}
      // onClose={setHelpOpen.off}
      autoFocus={false}
      returnFocusOnClose={false}
      closeOnBlur={true}
      closeOnEsc={true}
      isLazy={true}
      lazyBehavior="keepMounted"
      trigger="hover"
    >
      {inputGroup}
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          {locationMode === "NGR" ? <NgrHelp /> : <GpsHelp />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    inputGroup
  );
}

const NgrHelp = memo(function NgrHelp() {
  return (
    <Spaced fontSize="xs">
      <Markdown
        text={outdent`
        Enter a 10-digit *National Grid Reference (NGR)*.

        National Grid References are two letters followed by ten digits.
        For example, the location of the Royal Observatory in Greenwich is \`TQ 38885 77332\`.

        <!--
        See the [Help Site](https://help.cartographer.io/path/to/help) for more information.
        -->
        `}
      />
    </Spaced>
  );
}, isEqual);

const GpsHelp = memo(function GpsHelp() {
  return (
    <Spaced fontSize="xs">
      <Markdown
        text={outdent`
        Enter a pair of decimal GPS coordinates in *latitude, longitude* order.

        Cartographer uses the WGS84 coordinate system that is common to most phone GPS apps.
        For example, the location of the Royal Observatory in Greenwich is \`51.478, 0.000\`.

        <!--
        See the [Help Site](https://help.cartographer.io/) for more information.
        -->
        `}
      />
    </Spaced>
  );
}, isEqual);
