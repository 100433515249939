import { errorMessage } from "@cartographerio/topo-core";
import { UserTransferRequest } from "@cartographerio/types";

import { SomeNullable, rule } from "./rule";

export type TransferMapping<T> = [T, T | null];

export type PartialUserTransferRequest = SomeNullable<
  UserTransferRequest,
  "srcWorkspace" | "desWorkspace"
>;

export const {
  rule: userTransferRequestRule,
  keys: userTransferRequestErrorKeys,
} = rule
  .build<PartialUserTransferRequest>()
  .field(
    "srcWorkspace",
    rule.required([errorMessage("You must have a source workspace")])
  )
  .field(
    "desWorkspace",
    rule.required([errorMessage("You must have a destination workspace")])
  )
  .field(
    "users",
    rule.nonEmpty([errorMessage("You must select some user(s) to transfer")])
  )
  .finish();
