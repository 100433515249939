import {
  KnownWorkspaceFeatureEnum,
  Project,
  Workspace,
} from "@cartographerio/types";
import { useMemo } from "react";

export function useProjectHasTeams(
  workspace: Workspace,
  project: Project
): boolean {
  return useMemo(
    () =>
      workspace.features.includes(KnownWorkspaceFeatureEnum.EnableTeams) &&
      project.teamIds.length > 0,
    [project, workspace.features]
  );
}
