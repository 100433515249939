import {
  hasKey,
  hasKeyOfAnyType,
  hasOptionalKey,
  isNullable,
  isObject,
} from "@cartographerio/guard";
import {
  Survey,
  UserRef,
  isProjectId,
  isSchemaVersion,
  isSurveyId,
  isSurveyModuleId,
  isSurveyStatus,
  isTeamId,
  isTimestamp,
  isUserRef,
} from "@cartographerio/types";

export type PartialSurvey = Omit<Survey, "surveyor"> & {
  surveyor?: UserRef | null;
};

export function isPartialSurvey(value: unknown): value is PartialSurvey {
  return (
    isObject(value) &&
    hasKey(value, "id", isSurveyId) &&
    hasKey(value, "moduleId", isSurveyModuleId) &&
    hasKey(value, "projectId", isProjectId) &&
    hasOptionalKey(value, "teamId", isNullable(isTeamId)) &&
    hasKey(value, "created", isTimestamp) &&
    hasKey(value, "updated", isTimestamp) &&
    hasOptionalKey(value, "surveyor", isNullable(isUserRef)) &&
    hasKey(value, "status", isSurveyStatus) &&
    hasKey(value, "schemaVersion", isSchemaVersion) &&
    hasKeyOfAnyType(value, "data")
  );
}
