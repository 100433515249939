import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Subscription,
  SubscriptionUpdate,
  WorkspaceRef,
  internalError,
  timestampGte,
  timestampToDate,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { QueryClient } from "@tanstack/react-query";

import { STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT } from "../../../config";
import { UseQueryOpts } from "../../base";

export type BillingSubscriptionKey =
  | ["billing", "workspace", "subscription"]
  | ["billing", "workspace", "subscription", "v1", "readOrFail", WorkspaceRef]
  | ["billing", "workspace", "subscription", "v1", "readOrNull", WorkspaceRef];

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<Subscription, BillingSubscriptionKey> {
  return {
    queryKey: [
      "billing",
      "workspace",
      "subscription",
      "v1",
      "readOrFail",
      workspaceRef,
    ],
    queryFn: () =>
      endpoints.billing.subscription.v1
        .readOrFail(apiParams, workspaceRef)
        .unsafeRun(),
  };
}

export function readOrNull(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<Subscription | null, BillingSubscriptionKey> {
  return {
    queryKey: [
      "billing",
      "workspace",
      "subscription",
      "v1",
      "readOrNull",
      workspaceRef,
    ],
    queryFn: () =>
      endpoints.billing.subscription.v1
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

function pollForUpdate(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  pollInterval: number = 500
): IO<void> {
  return IO.callback((res, rej) => {
    function poll() {
      endpoints.billing.subscription.v1
        .readOrFail(apiParams, workspaceRef)
        .tap(sub => {
          if (sub.lastInternalUpdate == null) {
            raise(
              internalError(
                "Something went wrong updating the subscription ..."
              )
            );
          } else if (
            timestampGte(sub.lastStripeUpdate, sub.lastInternalUpdate)
          ) {
            res();
          } else if (
            Date.now() >
            timestampToDate(sub.lastInternalUpdate).getTime() +
              STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT
          ) {
            rej(new Error("Timeout reached"));
          } else {
            setTimeout(poll, pollInterval);
          }
        })
        .unsafeRun();
    }

    poll();
  });
}

export function update(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  update: SubscriptionUpdate
): IO<void> {
  return endpoints.billing.subscription.v1
    .update(apiParams, workspaceRef, update)
    .andThen(pollForUpdate(apiParams, workspaceRef))
    .cleanup(() => queryClient.invalidateQueries(["billing", "workspace"]));
}
