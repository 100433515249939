import { InvitationAcceptResponse } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, Flex } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, ReactNode, useEffect, useMemo, useState } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import CartographerLogo from "../../components/CartographerLogo";
import Container from "../../components/Container";
import Link from "../../components/Link";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import Para from "../../components/Para";
import { useApiConfig } from "../../contexts/apiConfig";
import { routes } from "../../routes";
import InvitationAccepted from "./InvitationAccepted";
import InvitationSignup from "./InvitationSignup";

export default function InvitationAcceptPage(
  props: RouteProps<typeof routes.invitation.accept>
): ReactElement {
  const {
    path: { invitationId },
  } = props;

  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();

  const [acceptResponse, setAcceptResponse] =
    useState<InvitationAcceptResponse | null>(null);

  useEffect(() => {
    queries.invitation.v3
      .accept(queryClient, apiConfig, invitationId)
      .tap(setAcceptResponse)
      .unsafeRun();
  }, [apiConfig, invitationId, queryClient]);

  const content: ReactElement = useMemo(() => {
    if (acceptResponse != null) {
      switch (acceptResponse.type) {
        case "InvitationAccepted":
          return <InvitationAccepted userId={acceptResponse.userId} />;

        case "InvitationChallengeSignupRequired":
          return (
            <>
              <InvitationSignup
                invitationId={invitationId}
                signupRequired={acceptResponse}
              />
            </>
          );

        case "InvitationChallengeNotFound":
          return (
            <>
              <Box as="header">
                <CartographerLogo />
              </Box>
              <Para textAlign="center" fontSize="lg">
                Sorry! We couldn&apos;t find that invitation.
              </Para>
              <Para textAlign="center">
                <Link.Internal to={routes.signin.url([])}>
                  Return to the signin page
                </Link.Internal>
              </Para>
            </>
          );

        case "InvitationChallengeExpired":
          return (
            <>
              <Box as="header">
                <CartographerLogo />
              </Box>
              <Para textAlign="center" fontSize="lg">
                Sorry! This invitation has expired.
              </Para>
              <Para textAlign="center">
                <Link.Internal to={routes.signin.url([])}>
                  Return to the signin page
                </Link.Internal>
              </Para>
            </>
          );

        case "InvitationChallengeAlreadyAccepted":
          return (
            <>
              <Box as="header">
                <CartographerLogo />
              </Box>
              <Para textAlign="center" fontSize="lg">
                Sorry! This invitation has already been accepted.
              </Para>
              <Para textAlign="center">
                <Link.Internal to={routes.signin.url([])}>
                  Return to the signin page
                </Link.Internal>
              </Para>
            </>
          );

        case "InvitationChallengeIncorrectEmail":
          return (
            <>
              <Para textAlign="center" fontSize="lg">
                Emails don&apos;t match. Something went wrong...
              </Para>
              <Para textAlign="center">
                <Link.Internal to={routes.signin.url([])}>
                  Return to the signin page
                </Link.Internal>
              </Para>
            </>
          );

        case "InvitationChallengeCanceled":
          return (
            <>
              <Para textAlign="center" fontSize="lg">
                Sorry! This invitation was canceled.
              </Para>
              <Para textAlign="center">
                <Link.Internal to={routes.signin.url([])}>
                  Return to the signin page
                </Link.Internal>
              </Para>
            </>
          );
        default:
          return checkExhausted(acceptResponse);
      }
    } else {
      return <LoadingPlaceholder label="Loading invitation" />;
    }
  }, [acceptResponse, invitationId]);

  return (
    <Container width="narrow">
      <VCenter>{content}</VCenter>
    </Container>
  );
}

function VCenter({ children }: { children: ReactNode }) {
  return (
    <Flex py="8" minH="100vh" gap="8" direction="column" justify="center">
      {children}
    </Flex>
  );
}
