import { Card, Radio } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

interface CardRadioOptionProps {
  value: string;
  children: ReactNode;
}

export default function CardRadioOption(
  props: CardRadioOptionProps
): ReactElement {
  const { value, children } = props;

  return (
    <Card
      __css={{
        [":has(:checked)"]: {
          outline: "3px solid var(--chakra-colors-blue-400)",
        },
      }}
    >
      <Radio px="4" py="3" w="100%" h="100%" value={value} spacing="4">
        {children}
      </Radio>
    </Card>
  );
}
