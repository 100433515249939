import { raise } from "@cartographerio/util";

export const DEFAULT_TERRAIN_MAP_STYLE: string =
  process.env.REACT_APP_MAP_TERRAIN_STYLE ??
  process.env.STORYBOOK_MAP_TERRAIN_STYLE ??
  raise(new Error("Default terrain map style not configured"));

export const DEFAULT_SATELLITE_MAP_STYLE: string =
  process.env.REACT_APP_MAP_SATELLITE_STYLE ??
  process.env.STORYBOOK_MAP_SATELLITE_STYLE ??
  raise(new Error("Default satellite map style not configured"));

export const DEFAULT_PAGE_SIZE = 25;

// 3 is react query's default
export const QUERY_RETRY_COUNT = 3;

// In milliseconds
export const STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT = 30000;
