import {
  GlobalMemberSearchOptions,
  PartialParams,
  SearchResultsFormat,
  endpoints,
} from "@cartographerio/client";
import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import {
  GlobalRoleNameEnum,
  WorkspaceAlias,
  addRole,
} from "@cartographerio/types";
import { findAndMap } from "@cartographerio/util";
import { useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { ReactElement, useCallback, useMemo } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import AddButton from "../../../components/AddButton";
import { useIOErrorAlert } from "../../../components/Alert";
import MemberList, {
  useApproveCallback,
  useRejectCallback,
} from "../../../components/MemberList";
import { OnMemberRoleChange } from "../../../components/MemberList/column";
import MemberListToolbar from "../../../components/MemberListToolbar";
import PageContainer from "../../../components/PageContainer";
import PageTopBar from "../../../components/PageTopBar";
import ResetFiltersAlert from "../../../components/ResetFiltersAlert";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import { useApiParams } from "../../../contexts/auth";
import { useApiUrlFormatter } from "../../../hooks/useApiUrl";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../routes";
import AdminPageHeader from "../AdminPageHeader";
import { adminMemberListColumns } from "./columns";

export default function AdminMemberListPage(
  props: RouteProps<typeof routes.admin.member.list>
): ReactElement {
  const { query, updateQuery } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const {
    q: searchTerm,
    order = "name-asc",
    role = null,
    workspace: workspaceFilter,
    page,
    count,
    type: memberType,
  } = query;

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.admin.home.url([])
  );

  usePageTitle("Members - Admin");

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.search(apiParams)
  );

  const selectedWorkspace = useMemo(
    () =>
      findAndMap(workspaces, workspace =>
        workspaceFilter === workspace.alias ? workspace : null
      ),
    [workspaceFilter, workspaces]
  );

  const workspaceOptions = useMemo(
    (): SelectOption<WorkspaceAlias>[] =>
      workspaces.map(({ name, alias }) => ({
        label: name,
        value: alias,
      })),
    [workspaces]
  );

  const handleRoleChange = useCallback<OnMemberRoleChange>(
    (member, role, include = true) => {
      queries.user.v2
        .save(queryClient, apiParams, member.userId, {
          ...member,
          roles: include
            ? addRole(member.roles, role, "replace")
            : member.roles.filter(otherRole => otherRole !== role),
          qualificationRoles: undefined,
        })
        .tap(() =>
          toast({
            title: "Role updated successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun();
    },
    [apiParams, errorAlert, queryClient, toast]
  );

  const handleApprove = useApproveCallback();
  const handleReject = useRejectCallback();

  const columns = useMemo(
    () =>
      adminMemberListColumns({
        workspaces,
        selectedWorkspace: selectedWorkspace?.id,
        onRoleChange: handleRoleChange,
      }),
    [handleRoleChange, selectedWorkspace, workspaces]
  );

  const searchOpts = useMemo(
    (): PartialParams<GlobalMemberSearchOptions> => ({
      q: query.q,
      memberType,
      workspace: selectedWorkspace?.id,
      role: query.role,
      order: query.order,
      skip: query.page * query.count,
      limit: query.count,
    }),
    [
      memberType,
      query.count,
      query.order,
      query.page,
      query.q,
      query.role,
      selectedWorkspace?.id,
    ]
  );

  const { data, error } = useQuery(
    queries.member.v1.globalSearch(apiParams, searchOpts)
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.member.v1.globalSearchUrl({
          ...omit(searchOpts, "skip", "limit"),
          format,
        })
      ),
    [formatApiUrl, searchOpts]
  );

  return (
    <>
      <PageTopBar admin={true} basePage="members" />
      <AdminPageHeader title="Members" />
      <PageContainer width="wide">
        <Spaced>
          <MemberListToolbar
            searchTerm={searchTerm}
            memberType={memberType}
            roleSelect={
              <Select.Nullable
                value={role}
                options={GlobalRoleNameEnum.entries}
                onChange={role => updateQuery({ ...query, role, page: 0 })}
                placeholder="All Roles"
                background="transparent"
                w="fit-content"
                maxW="52"
              />
            }
            inGroup={workspaceFilter}
            defaultGroupLabel="All Workspaces"
            groupOptions={workspaceOptions}
            onSearchTermChange={q =>
              updateQuery({ ...query, q: q ?? undefined, page: 0 })
            }
            onGroupChange={workspace =>
              updateQuery({
                ...query,
                workspace: workspace ?? undefined,
                page: 0,
              })
            }
            onMemberTypeChange={type => updateQuery({ ...query, type })}
            workspace={selectedWorkspace}
            downloadUrl={downloadUrl}
            addButton={
              <AddButton
                options={[
                  {
                    key: "add",
                    label: "Add a Member",
                    to: routes.admin.member.create.url([]),
                  },
                  {
                    key: "invite",
                    label: "Invite by Email",
                    to: routes.admin.invitation.create.url([]),
                  },
                ]}
              />
            }
          />
          <ResetFiltersAlert
            route={routes.admin.member.list}
            query={query}
            updateQuery={updateQuery}
          />
          <MemberList
            data={data ?? null}
            error={error}
            page={page}
            count={count}
            order={order}
            columns={columns}
            onApprove={handleApprove}
            onReject={handleReject}
            changeIdentityRedirect={routes.home.url([])}
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
            itemLink={member =>
              member.type === "InvitedMember"
                ? routes.admin.invitation.view.url([member.invitationId])
                : routes.admin.member.view.url([member.userId])
            }
            workspace={selectedWorkspace}
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
