import { checks } from "@cartographerio/permission";
import {
  Project,
  ProjectBreadcrumb,
  ProjectId,
  UserId,
  WorkspaceRef,
} from "@cartographerio/types";
import { partition } from "lodash";
import { useMemo } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import { PartitionedResults } from "./usePartitionedProjects";
import usePermissionCheckRunner from "./usePermissionCheckRunner";
import { useSuspenseSearchResults } from "./useSuspenseSearchResults";

export default function usePartitionedProjectBreadcrumbs(
  workspaceRef: WorkspaceRef,
  userId: UserId
): PartitionedResults<ProjectBreadcrumb> {
  const apiParams = useApiParams();
  const permissionCheckPasses = usePermissionCheckRunner();

  const projectBreadcrumbs: ProjectBreadcrumb[] = useSuspenseSearchResults(
    queries.project.breadcrumb.v1.visible(apiParams, userId, workspaceRef)
  );

  const allProjects = useSuspenseSearchResults(
    queries.project.v2.visible(apiParams, userId)
  );

  const projectLookup = useMemo<Record<ProjectId, Project>>(
    () =>
      allProjects.reduce(
        (acc, project) => ({ ...acc, [project.id]: project }),
        {}
      ),
    [allProjects]
  );

  return useMemo(
    () =>
      partition(
        projectBreadcrumbs.filter(
          ({ desProjectId }) => projectLookup[desProjectId] != null
        ),
        ({ desProjectId }) =>
          permissionCheckPasses(
            checks.project.member(projectLookup[desProjectId])
          )
      ),
    [permissionCheckPasses, projectBreadcrumbs, projectLookup]
  );
}
