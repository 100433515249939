import { errorMessage } from "@cartographerio/topo-core";
import { ProjectTransferRequest } from "@cartographerio/types";

import { SomeNullable, rule } from "./rule";

export type PartialProjectTransferRequest = SomeNullable<
  ProjectTransferRequest,
  "desWorkspace"
>;

export const {
  rule: projectTransferRequestRule,
  keys: projectTransferRequestErrorKeys,
} = rule
  .build<PartialProjectTransferRequest>()
  .field(
    "desWorkspace",
    rule.required([errorMessage("You must select a destination workspace")])
  )
  .finish();
