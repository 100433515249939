import {
  Feature,
  GeoJsonProperties,
  Geometry,
  Picked,
  Point,
  point as makePoint,
  picked,
} from "@cartographerio/geometry";
import {
  FeatureFieldMapOptions,
  featureFieldSelectableLayer,
} from "@cartographerio/topo-form";
import { authHeader, randomUuid } from "@cartographerio/types";
import { Box } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { RequestParameters, ResourceType } from "mapbox-gl";
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactMapGL, {
  Point as MapBoxPoint,
  MapLayerMouseEvent,
  MapRef,
  PointLike,
} from "react-map-gl";

import FieldMapControls from "../../components/FieldMapControls";
import {
  DEFAULT_MAP_CSS,
  featureCentroid,
  pointToCoordinate,
} from "../../components/mapFieldHelpers";
import MapMarker from "../../components/MapMarker";
import { useApiParams } from "../../contexts/auth";
import { useMapboxToken } from "../../contexts/MapboxToken";
import { Highlight } from "../../hooks/highlight";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import useResizeObserver from "../../hooks/useResizeObserver";
import { calcViewState, fromBounds, fromGeometry } from "../../map/mapHelpers";
import { useMapFieldContext } from "../context/MapFieldContext";
import { DEFAULT_SELECT_MIN_ZOOM } from "../FormPointField";
import FeatureFieldLayers from "./FeatureFieldLayers";
import { distance } from "./nearestFeature";

export interface BaseFeatureFieldProps<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
> {
  defaultValue?: Picked<G, P> | null;
  onChange: (newValue: Picked<G, P> | null) => void;
  disabled?: boolean;
  highlight?: Highlight;
  showPointMarker?: boolean | null;
  mapOptions: FeatureFieldMapOptions;
}

const FEATURE_FIELD_MAP_CSS: CSSProperties = {
  ...DEFAULT_MAP_CSS,
  borderTopLeftRadius: "var(--chakra-radii-md)",
  borderTopRightRadius: "var(--chakra-radii-md)",
};

function queryMapFeatures<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
>(
  map: MapRef,
  selectableLayer: string,
  selectBox?: [PointLike, PointLike]
): Feature<G, P>[] {
  const ans = map
    .queryRenderedFeatures(selectBox, { layers: [selectableLayer] })
    .map(f => ({ ...f, geometry: f.geometry }));

  // We assume the features returned by queryRenderedFeatures
  // have the correct geometry and property types:
  return ans as unknown as Feature<G, P>[];
}

export default function FeatureFieldMap<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
>(props: BaseFeatureFieldProps<G, P>): ReactElement {
  const {
    defaultValue,
    onChange,
    disabled,
    highlight,
    showPointMarker,
    mapOptions,
  } = props;

  const {
    mapStyle,
    attribution,
    selectMinZoom = DEFAULT_SELECT_MIN_ZOOM,
    selectTolerancePixels,
  } = mapOptions;

  const {
    getStyleUrl,
    registerSyncMap,
    syncEaseTo,
    handleMoveEnd,
    defaultBounds,
  } = useMapFieldContext();

  const apiParams = useApiParams();

  const transformRequest = useCallback(
    (url: string, _resourceType: ResourceType): RequestParameters => {
      if (
        apiParams.auth != null &&
        url.startsWith(apiParams.apiConfig.baseUrl)
      ) {
        return {
          url,
          headers: {
            Authorization: authHeader(apiParams.auth),
          },
        };
      } else {
        return { url };
      }
    },
    [apiParams.apiConfig.baseUrl, apiParams.auth]
  );

  const [satelliteView, setSatelliteView] = useState(false);

  const styleUrl = useMemo(
    () => getStyleUrl(mapStyle, satelliteView ? "satellite" : "terrain"),
    [getStyleUrl, mapStyle, satelliteView]
  );

  const selectableLayer = useMemo(
    () => featureFieldSelectableLayer(mapStyle),
    [mapStyle]
  );

  const map = useRef<MapRef>(null);
  const point = defaultValue?.point ?? null;
  const selection = defaultValue?.feature ?? null;

  const [pointMarker, setPointMarker] = useState<Point | null>(point);

  const internalId = useMemo(() => randomUuid(), []);
  useEffectOnce(() => registerSyncMap(internalId, map));
  const onMoveEnd = useMemo(
    () => handleMoveEnd(internalId),
    [handleMoveEnd, internalId]
  );

  const initialViewState = useMemo(
    () =>
      calcViewState(
        () =>
          selection != null && //
          fromGeometry(selection.geometry, selectMinZoom),
        () =>
          defaultBounds != null && //
          fromBounds(defaultBounds)
      ),
    [defaultBounds, selectMinZoom, selection]
  );

  const pickFeature = useCallback(
    (worldPoint: Point, screenPoint?: MapBoxPoint): Feature<G, P> | null => {
      if (map.current == null) {
        return null;
      }

      const selectBox: [PointLike, PointLike] | undefined =
        selectTolerancePixels == null || screenPoint == null
          ? undefined
          : [
              [
                screenPoint.x - selectTolerancePixels,
                screenPoint.y - selectTolerancePixels,
              ],
              [
                screenPoint.x + selectTolerancePixels,
                screenPoint.y + selectTolerancePixels,
              ],
            ];

      const featuresInViewport = queryMapFeatures<G, P>(
        map.current,
        selectableLayer.id,
        selectBox
      );

      const featuresAndDistances = featuresInViewport.map((feat): [
        number,
        Feature<G, P>
      ] => {
        return [distance(worldPoint, feat.geometry) ?? Infinity, feat];
      });

      const feature =
        featuresAndDistances.length > 0
          ? sortBy(featuresAndDistances, item => item[0])[0]?.[1]
          : null;

      return feature == null ? null : sanitiseFeature(feature);
    },
    [selectTolerancePixels, selectableLayer.id]
  );

  const easeTo = useCallback(
    (point: Point | null, minZoom?: number) => {
      // Fly close to the clicked point in the map, but don't select anything:
      if (point != null && map.current != null) {
        const center = pointToCoordinate(point);
        const zoom = Math.max(
          map.current.getZoom(),
          minZoom ?? map.current.getZoom()
        );
        map.current.easeTo({ center, zoom });
        syncEaseTo(internalId, { center, zoom });
      }
    },
    [internalId, syncEaseTo]
  );

  const handlePointChange = useCallback(
    (worldPoint: Point | null, screenPoint?: MapBoxPoint) => {
      if (map.current != null && map.current.getZoom() < selectMinZoom) {
        // Fly close to the clicked point in the map, but don't select anything:
        easeTo(worldPoint, selectMinZoom);
      } else {
        setPointMarker(worldPoint);

        if (worldPoint == null) {
          onChange(null);
        } else {
          // TODO: May need to choose between multiple features when they overlap
          const feat = pickFeature(worldPoint, screenPoint);

          const newValue: Picked<G, P> | null =
            feat != null
              ? picked({ point: worldPoint, feature: feat })
              : picked({ point: worldPoint, feature: null });

          onChange(newValue);

          if (newValue.feature != null && map.current != null) {
            easeTo(featureCentroid(newValue.feature), selectMinZoom);
          }
        }
      }
    },
    [selectMinZoom, easeTo, onChange, pickFeature]
  );

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const pos = event.lngLat.toArray();
      const worldPoint = makePoint(pos[0], pos[1]);
      handlePointChange(worldPoint, event.point);
    },
    [handlePointChange]
  );

  const [dragging, setDragging] = useState(false);

  // TODO: Cannot search for features that aren't rendered, so only moving the viewport for now.
  const handleSearchPoint = useCallback(
    (searchPoint: Point) => {
      easeTo(searchPoint);
    },
    [easeTo]
  );

  const handleFindMarker = useCallback(() => {
    if (map.current != null && pointMarker != null) {
      easeTo(pointMarker);
    }
  }, [easeTo, pointMarker]);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(mapContainerRef, () => map.current?.resize());

  const mapboxToken = useMapboxToken();

  return (
    <Box ref={mapContainerRef}>
      <ReactMapGL
        ref={map}
        mapboxAccessToken={mapboxToken}
        transformRequest={transformRequest}
        style={FEATURE_FIELD_MAP_CSS}
        initialViewState={initialViewState}
        cursor={dragging ? "grabbing" : "crosshair"}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
        onMove={({ target }) =>
          syncEaseTo(internalId, {
            center: target.getCenter(),
            zoom: target.getZoom(),
            animate: false,
          })
        }
        onMoveEnd={onMoveEnd}
        onClick={disabled ? undefined : handleMapClick}
        scrollZoom={false}
        mapStyle={styleUrl}
        customAttribution={attribution}
        styleDiffing={true}
      >
        {showPointMarker && pointMarker && (
          <MapMarker
            position={pointMarker}
            fillColor={satelliteView ? "white" : "red"}
            strokeColor={satelliteView ? "rgba(0, 0, 0, .5)" : "white"}
            strokeWidth={1.5}
            size={40}
          />
        )}

        <FeatureFieldLayers
          mapStyle={mapStyle}
          mapOptions={mapOptions}
          selection={selection}
          selectableLayer={selectableLayer}
        />

        <FieldMapControls
          onZoomIn={() => map.current?.zoomIn()}
          onZoomOut={() => map.current?.zoomOut()}
          onFindMarker={handleFindMarker}
          onSearchPoint={handleSearchPoint}
          defaultSearchPoint={pointMarker}
          highlight={highlight}
          showGeolocate={false}
          satelliteView={satelliteView}
          onSatelliteViewChange={setSatelliteView}
        />
      </ReactMapGL>
    </Box>
  );
}

/** Remove hidden fields added to the feature by Mapbox. */
function sanitiseFeature<G extends Geometry, P>(
  feature: Feature<G, P>
): Feature<G, P> {
  const { type, id, geometry, properties, bbox } = feature;
  return { type, id, geometry, properties, bbox };
}
