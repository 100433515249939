import { BillingInterval, PlanId, WorkspaceRef } from "@cartographerio/types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import SuspenseBoundary from "../components/SuspenseBoundary";
import PlanChooser from "./PlanChooser";

interface PlanChooserModalProps {
  workspaceRef: WorkspaceRef;
  defaultPlanId?: PlanId;
  defaultBillingInterval?: BillingInterval;
  isOpen: boolean;
  onClose: () => void;
  planLink?: (planId: PlanId, billingInterval: BillingInterval) => string;
  onPlanClick?: (planId: PlanId, billingInterval: BillingInterval) => void;
}

export default function PlanChooserModal(
  props: PlanChooserModalProps
): ReactElement {
  const {
    workspaceRef,
    defaultPlanId,
    defaultBillingInterval,
    isOpen,
    onClose,
    planLink,
    onPlanClick,
  } = props;

  const handlePlanClick = useCallback(
    (planId: PlanId, billingInterval: BillingInterval) => {
      onClose();
      onPlanClick?.(planId, billingInterval);
    },
    [onClose, onPlanClick]
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="3xl" marginInline="6">
        <ModalHeader>Choose a Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="0" pb="0">
          <SuspenseBoundary>
            <PlanChooser
              workspaceRef={workspaceRef}
              defaultPlanId={defaultPlanId}
              defaultBillingInterval={defaultBillingInterval}
              planLink={planLink}
              onPlanClick={handlePlanClick}
            />
          </SuspenseBoundary>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
