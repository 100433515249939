import { SurveyModule } from "@cartographerio/inventory-surveys";
import { checks } from "@cartographerio/permission";
import { MapSchema } from "@cartographerio/topo-map";
import { Project, Workspace } from "@cartographerio/types";
import {
  Card,
  CardBody,
  Flex,
  Icon,
  IconButton,
  SystemProps,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { BiPlus } from "react-icons/bi";
import { IoMdDocument } from "react-icons/io";
import { IoMap, IoSettingsSharp } from "react-icons/io5";

import queries from "../../queries";
import { emptyArray } from "../../util";
import Link from "../components/Link";
import {
  projectMapLabel,
  projectSurveyModuleLabel,
} from "../components/Sidebar/base";
import { useApiParams, useCredentials } from "../contexts/auth";
import { usePermissionCheckPasses } from "../hooks/usePermissionCheckPasses";
import { useProjectHasTeams } from "../hooks/useProjectHasTeams";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { useVisibleMaps } from "../hooks/useVisibleMaps";
import { useVisibleSurveyModules } from "../hooks/useVisibleSurveyModules";
import { routes } from "../routes";
import CardTitle from "./CardTitle";
import CreateSurveyButton from "./CreateSurveyButton";
import IconButtonLink from "./IconButtonLink";
import ProjectLabel from "./ProjectLabel";
import ProjectTeamSettingsButton from "./ProjectTeamSettingsButton";
import RequirePermission from "./RequirePermission";
import RequirePermissionLink from "./RequirePermissionLink";
import RequirePermissionPopover from "./RequirePermissionPopover";
import Spaced from "./Spaced";

export interface ProjectCardProps extends SystemProps {
  workspace: Workspace;
  project: Project;
  showSettingsLink?: boolean;
}

export default function ProjectCard(props: ProjectCardProps): ReactElement {
  const { workspace, project, showSettingsLink = true, ...rest } = props;

  const apiParams = useApiParams();

  const teams = useSuspenseSearchResults(
    queries.team.v2.forProject(apiParams, project.id)
  );

  const canSurvey = usePermissionCheckPasses(
    checks.survey.createWithAnyTeam(project)
  );

  const modules = useVisibleSurveyModules(project);
  const maps = useVisibleMaps(project);

  return (
    <Card key={project.id} {...rest}>
      <CardTitle display="flex" alignItems="center">
        <Flex shrink={1} grow={1}>
          <RequirePermission
            check={checks.project.view(project)}
            fallback={project.name}
          >
            <Link.Internal
              display="flex"
              flexDirection="row"
              alignItems="center"
              columnGap="1"
              to={routes.workspace.project.home.url([
                workspace.alias,
                project.alias,
              ])}
            >
              <ProjectLabel project={project} />
            </Link.Internal>
          </RequirePermission>
        </Flex>
        {showSettingsLink && (
          <Flex shrink={0} grow={0}>
            <ProjectTeamSettingsButton
              workspace={workspace}
              project={project}
              teams={teams}
              ButtonElement={SettingsButton}
            />
          </Flex>
        )}
      </CardTitle>
      <CardBody overflowX="hidden">
        <Spaced spacing="1">
          {modules.map(module => (
            <ProjectCardModuleItem
              key={module.moduleId}
              workspace={workspace}
              project={project}
              canSurvey={canSurvey}
              module={module}
            />
          ))}

          {maps.map(map => (
            <ProjectCardMapItem
              key={map.mapId}
              workspace={workspace}
              project={project}
              map={map}
            />
          ))}
        </Spaced>
      </CardBody>
    </Card>
  );
}

interface SettingsButtonProps {
  label?: string;
  value?: string;
}

function SettingsButton(props: SettingsButtonProps): ReactElement {
  const { label, value } = props;

  return (
    <IconButtonLink.Internal
      label={label ? `${label} Settings` : "Choose a team's settings to view"}
      icon={<IoSettingsSharp />}
      size="sm"
      variant="outline"
      to={value}
    />
  );
}

interface ProjectCardModuleItemProps {
  workspace: Workspace;
  project: Project;
  canSurvey: boolean;
  module: SurveyModule;
}

function ProjectCardModuleItem(
  props: ProjectCardModuleItemProps
): ReactElement {
  const { workspace, project, canSurvey, module } = props;

  const apiParams = useApiParams();
  const multiTeam = useProjectHasTeams(workspace, project);

  const { identity } = useCredentials();

  const canCreateWithNoTeam = usePermissionCheckPasses(
    checks.survey.createWithTeam(project, null)
  );

  const teams =
    useSuspenseSearchResults(
      queries.when(multiTeam, () =>
        queries.team.v2.search(apiParams, {
          project: project.id,
          user: identity.userId,
          role: "Surveyor",
        })
      )
    ) ?? emptyArray;

  return (
    <ProjectCardItem
      left={<Icon as={IoMdDocument} color="gray.300" mr="2" />}
      right={
        canSurvey && (
          <CreateSurveyButton
            teams={teams}
            includeNullOption={canCreateWithNoTeam}
            createSurveyLink={teamAlias =>
              routes.workspace.project.survey.create.url(
                [workspace.alias, project.alias, module.moduleId],
                { team: teamAlias ?? undefined }
              )
            }
            placement="bottom-end"
          >
            <IconButton
              as={chakra.span}
              size="sm"
              variant="outline"
              icon={<Icon as={BiPlus} fontSize="lg" />}
              aria-label={`Add ${module.names.shortName} Survey`}
              title={`Add ${module.names.shortName} Survey`}
            />
          </CreateSurveyButton>
        )
      }
    >
      <RequirePermissionPopover
        check={checks.survey.view(project)}
        failMessage="Cannot list surveys"
      >
        {passes => (
          <Link.Internal
            to={routes.workspace.project.survey.list.url([
              workspace.alias,
              project.alias,
              module.moduleId,
            ])}
            title="List Surveys"
            disabled={!passes}
          >
            {projectSurveyModuleLabel(project, module)}
          </Link.Internal>
        )}
      </RequirePermissionPopover>
    </ProjectCardItem>
  );
}

interface ProjectCardMapItemProps {
  workspace: Workspace;
  project: Project;
  map: MapSchema;
}

function ProjectCardMapItem(props: ProjectCardMapItemProps): ReactElement {
  const { workspace, project, map } = props;

  return (
    <ProjectCardItem left={<Icon as={IoMap} color="gray.300" mr="2" />}>
      <RequirePermissionLink.Internal
        check={checks.map.view(project)}
        to={routes.workspace.project.map.url([
          workspace.alias,
          project.alias,
          map.mapId,
        ])}
        failMode="popover"
        popoverFailMessage="Cannot view the map"
        disableOnFail={true}
      >
        {projectMapLabel(project, map)}
      </RequirePermissionLink.Internal>
    </ProjectCardItem>
  );
}

interface ProjectCardItemProps {
  left?: ReactNode;
  right?: ReactNode;
  children: ReactNode;
}

function ProjectCardItem(props: ProjectCardItemProps): ReactElement {
  const { left, right, children } = props;

  return (
    <Flex minH="8" direction="row" alignItems="center">
      {left && (
        <Flex shrink={0} grow={0}>
          {left}
        </Flex>
      )}
      <Flex
        shrink={1}
        grow={1}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {children}
      </Flex>
      {right && (
        <Flex shrink={0} grow={0}>
          {right}
        </Flex>
      )}
    </Flex>
  );
}
