import { GeoJsonProperties } from "@cartographerio/geometry";
import { Columns, FeatureFieldAttribute } from "@cartographerio/topo-form";
import {
  PropertyAttribute,
  formatAttributeValue,
  isPropertyAttribute,
} from "@cartographerio/topo-map";
import { TeamId } from "@cartographerio/types";
import {
  BoxProps,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import { useFormContext } from "../context/FormContext";

export interface AttributeGridProps extends BoxProps {
  attributes: FeatureFieldAttribute[];
  properties: GeoJsonProperties | undefined;
  columns?: Columns;
}

export default function AttributeGrid(props: AttributeGridProps): ReactElement {
  const { attributes, properties, columns = 2, ...rest } = props;

  const { workspaceGraph } = useFormContext();

  const teamLabel = useCallback(
    (teamId: TeamId) =>
      workspaceGraph.optFindTeamById(teamId)?.name ?? `Team ${teamId}`,
    [workspaceGraph]
  );

  return (
    <SimpleGrid
      columns={[1, columns, columns]}
      gap="4"
      borderRadius="md"
      bg="gray.200"
      px="2"
      py="2"
      {...rest}
    >
      {attributes.map(attr =>
        isPropertyAttribute(attr) ? (
          <AttributeStat
            key={attr.propertyName}
            attr={attr}
            properties={properties}
            teamLabel={teamLabel}
          />
        ) : null
      )}
      {/* <Stat>
        <StatLabel>Debugging Information</StatLabel>
        <Pre text={JSON.stringify({ attributes, properties }, null, 2)} />
      </Stat> */}
    </SimpleGrid>
  );
}

interface AttributeStatProps {
  attr: PropertyAttribute;
  properties?: GeoJsonProperties;
  teamLabel: (teamId: TeamId) => string | null;
}

function AttributeStat(props: AttributeStatProps) {
  const { attr, properties, teamLabel } = props;

  const value = properties?.[attr.propertyName];

  return isPropertyAttribute(attr) ? (
    <Stat title={attr.propertyName}>
      <StatLabel>{attr.label}</StatLabel>
      <StatNumber fontSize="sm">
        <Flex direction="row" justifyContent="flex-start" gap="1">
          {formatAttributeValue(attr, value, { teamLabel }) ?? "-"}
          {attr.type === "NumberAttribute" &&
            attr.unit != null &&
            value != null && (
              <Flex grow={0} shrink={0}>
                {attr.unit}
              </Flex>
            )}
        </Flex>
      </StatNumber>
    </Stat>
  ) : null;
}
