import { BillingInterval, CurrencyAmount } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";

export function formatCurrencyAmount(
  amount: CurrencyAmount,
  includePence: boolean = false
): string {
  return `£${
    amount % 100 >= 1 && includePence
      ? (Math.round(amount) / 100)
          .toLocaleString(navigator.language)
          .replace(/(\d{0,2})\d*$/, match => match.padEnd(2, "0"))
      : Math.round(amount / 100).toLocaleString(navigator.language)
  }`;
}

export function formatIntervalAmount(
  amount: CurrencyAmount,
  interval: BillingInterval
): string {
  let label: string;
  switch (interval) {
    case "Monthly":
      label = "month";
      break;
    case "Yearly":
      label = "year";
      break;
    default:
      return checkExhausted(interval);
  }
  return `${formatCurrencyAmount(amount)}/${label}`;
}
