import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  InvitationCode,
  InvitationCodeCreate,
  WorkspaceId,
  namedEmail,
  unsafeEmail,
  workspaceActiveRole,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { invitationCodeRule } from "../../../schema/invitationCode";
import { useIOErrorAlert } from "../../components/Alert";
import BackButtonHeading from "../../components/BackButtonHeading";
import InvitationCodeEditor from "../../components/InvitationCodeEditor";
import InvitationCodeShareModal from "../../components/InvitationCodeShareModal";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import WorkspacePageHeader from "./WorkspacePageHeader";

export function blankInvitationCode(
  workspaceId?: WorkspaceId
): InvitationCodeCreate {
  return {
    name: "",
    workspaceId,
    roles: workspaceId == null ? [] : [workspaceActiveRole(workspaceId)],
    qualificationRoles: [],
    expires: null,
    requireApproval: true,
    approvalEmails: [namedEmail("", unsafeEmail(""))],
    approvalDomainAllowList: [],
    delayEmailVerification: null,
  };
}

export default function WorkspaceInvitationCodeCreatePage(
  props: RouteProps<typeof routes.workspace.invitation.code.create>
): ReactElement {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  useRedirectWhen(!useCodesEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  useRequirePermission(checks.workspace.admin(workspace));

  usePageTitle(`New Invitation - ${workspace.name}`);

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  const [code, setCode] = useState(() => blankInvitationCode(workspace.id));

  const [createdInvitation, setCreatedInvitation] =
    useState<InvitationCode | null>(null);

  const handleCreate = useCallback(() => {
    queries.invitation.code.v3
      .create(queryClient, apiParams, code)
      .flatMap(IO.fromResult)
      .tap(() =>
        toast({
          status: "success",
          title: "Invitation Code Created",
          duration: 3000,
          isClosable: true,
        })
      )
      .tap(setCreatedInvitation)
      .tapError(errorAlert)
      .unsafeRun();
  }, [apiParams, errorAlert, code, queryClient, toast]);

  const onExit = useCallback(
    () =>
      navigate(
        createdInvitation != null
          ? routes.workspace.invitation.code.view.url([
              workspace.alias,
              createdInvitation.alias,
            ])
          : routes.workspace.invitation.code.list.url([workspace.alias])
      ),
    [createdInvitation, navigate, workspace.alias]
  );

  const messages = useMemo(() => invitationCodeRule(code), [code]);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="invitation-code"
        invitation="new"
      />
      <WorkspacePageHeader workspace={workspace} selected="invitation-code" />
      <PageContainer width="narrow">
        <Spaced>
          <BackButtonHeading
            my="0"
            to={routes.workspace.invitation.code.list.url([workspace.alias])}
          >
            Create an Invitation Code
          </BackButtonHeading>
          <InvitationCodeEditor
            value={code}
            messages={messages}
            onChange={setCode}
            workspaceGraph={workspaceGraph}
            defaultWorkspace={workspace.id}
            qualifications={qualifications}
          />
          <SaveButton
            label="Create Code"
            onClick={handleCreate}
            messages={messages}
          />
          {createdInvitation != null && (
            <InvitationCodeShareModal
              code={createdInvitation}
              isOpen={true}
              onClose={onExit}
            />
          )}
        </Spaced>
      </PageContainer>
    </>
  );
}
