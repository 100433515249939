import { WorkspaceRef } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, HStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import Button from "../../components/Button";
import ButtonLink from "../../components/ButtonLink";
import CartographerLogo from "../../components/CartographerLogo";
import Heading from "../../components/Heading";
import Link from "../../components/Link";
import PageContainer from "../../components/PageContainer";
import Para from "../../components/Para";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function WorkspaceHoldingPage(
  props: RouteProps<typeof routes.workspace.holding>
): ReactElement {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();

  const access = useSuspenseQueryData(
    queries.auth.v2.readWorkspaceAccess(apiParams, workspaceRef)
  );

  usePageTitle(`Home - ${access.name}`);

  const { title, message, buttons } = useMemo(() => {
    switch (access.type) {
      case "WorkspaceAccessGranted":
        return {
          title: "Access Granted",
          message: "Congratulations! An administrator has granted you access.",
          buttons: <ContinueButton workspace={access.alias} />,
        };
      case "WorkspaceAccessUnapproved":
        return {
          title: "Awaiting Approval",
          message:
            "Your access is awaiting approval by a workspace administrator. We'll notify you by email as soon as this happens.",
          buttons: (
            <>
              <HomeButton />
              <SignOutButton />
            </>
          ),
        };
      case "WorkspaceAccessDenied":
        switch (access.reason) {
          case "AccountDisabled":
            return {
              title: "Access Disabled",
              message:
                "Your access to this workspace has been temporarily disabled. Please contact the workspace admin team for more information.",
              buttons: (
                <>
                  <HomeButton />
                  <SignOutButton />
                </>
              ),
            };
          case "WorkspaceDisabled":
            return {
              title: "Workspace Disabled",
              message: (
                <>
                  <Para>
                    Sorry! Access to this workspace has been restricted.
                  </Para>

                  <Para>
                    <Link.External to="https://help.cartographer.io/contact">
                      Contact support
                    </Link.External>{" "}
                    for more information.
                  </Para>
                </>
              ),
              buttons: (
                <>
                  <HomeButton />
                  <SignOutButton />
                </>
              ),
            };
          case "NoAccess":
            return {
              title: "Access Denied",
              message: "Sorry! You do not have access to this workspace.",
              buttons: (
                <>
                  <HomeButton />
                  <SignOutButton />
                </>
              ),
            };
          default:
            return checkExhausted(access.reason);
        }
      default:
        return checkExhausted(access);
    }
  }, [access]);

  return (
    <PageContainer width="narrow">
      <Spaced spacing="8" maxW="50ch" mx="auto" my="16">
        <Box as="header">
          <CartographerLogo mb="8" />
        </Box>

        <Heading level="subsection" textAlign="center">
          {access.name}
        </Heading>

        <Heading level="subsubsection" textAlign="center">
          {title}
        </Heading>

        <Box as="p" textAlign="center" fontSize="lg">
          {message}
        </Box>

        <HStack justify="center" gap="4">
          {buttons}
        </HStack>
      </Spaced>
    </PageContainer>
  );
}

function ContinueButton({ workspace }: { workspace: WorkspaceRef }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClick = useCallback(
    () =>
      queryClient
        .invalidateQueries(["auth"])
        .then(() =>
          navigate(routes.workspace.home.url([workspace]), { replace: true })
        ),
    [navigate, queryClient, workspace]
  );

  return <Button colorScheme="blue" onClick={handleClick} label="Continue" />;
}

function HomeButton() {
  return (
    <ButtonLink.Internal variant="outline" to={routes.home.url([])}>
      Select Workspace
    </ButtonLink.Internal>
  );
}

function SignOutButton() {
  return (
    <ButtonLink.Internal variant="outline" to={routes.signout.url([])}>
      Sign Out
    </ButtonLink.Internal>
  );
}
