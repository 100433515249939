import {
  Invitation,
  InvitationStatus,
  formatTimestampAgo,
  invitationStatus,
  timestampToDate,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { RepeatClockIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { ReactElement, useCallback, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";

import queries from "../../queries";
import ClickToCopy from "../components/ClickToCopy";
import Heading from "../components/Heading";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { FrontContext } from "./context";
import { FrontInfoItem, FrontInfoList } from "./FrontInfoList";

export interface FrontInvitationItemProps {
  context: FrontContext;
  invitation: Invitation;
  disabled?: boolean;
}

function InvitationBadge({
  status,
}: {
  status: InvitationStatus;
}): ReactElement {
  switch (status) {
    case "Accepted":
      return <Badge colorScheme="green">Accepted</Badge>;
    case "Canceled":
      return <Badge colorScheme="red">Canceled</Badge>;
    case "Expired":
      return <Badge colorScheme="amber">Expired</Badge>;
    case "Pending":
      return <Badge colorScheme="cyan">Pending</Badge>;
    default:
      return checkExhausted(status);
  }
}

export default function FrontInvitationItem(
  props: FrontInvitationItemProps
): ReactElement {
  const { invitation, disabled } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();

  const workspace = useSuspenseQueryData(
    queries.optional(invitation.workspaceId, workspaceId =>
      queries.workspace.v2.readOrFail(apiParams, workspaceId)
    )
  );

  const status = invitationStatus(invitation);

  const handleResendClick = useCallback(() => {
    queries.invitation.v3
      .resend(queryClient, apiParams, invitation.id)
      .unsafeRun();
  }, [apiParams, invitation.id, queryClient]);

  const handleCancelClick = useCallback(() => {
    queries.invitation.v3
      .cancel(queryClient, apiParams, invitation.id)
      .unsafeRun();
  }, [apiParams, invitation.id, queryClient]);

  const now = useMemo(() => new Date(), []);

  return (
    <Card>
      <CardBody>
        <Spaced spacing="4">
          <HStack justify="space-between" align="center">
            <Heading level="subsubsection" my="0">
              {invitation.firstName} {invitation.lastName}
            </Heading>
            <HStack gap="2" align="center">
              <InvitationBadge status={status} />
              {(status === "Expired" || status === "Canceled") && (
                <IconButton
                  variant="outline"
                  onClick={handleResendClick}
                  isDisabled={disabled}
                  isLoading={disabled}
                  aria-label="Resend"
                  size="sm"
                >
                  <RepeatClockIcon />
                </IconButton>
              )}
              {status === "Pending" && (
                <IconButton
                  variant="outline"
                  onClick={handleCancelClick}
                  isDisabled={disabled}
                  isLoading={disabled}
                  aria-label="Cancel"
                  size="sm"
                  icon={<IoCloseSharp />}
                />
              )}
            </HStack>
          </HStack>

          <FrontInfoList>
            <FrontInfoItem label="Workspace">
              <Box>{workspace?.name ?? "Cartographer Admin"}</Box>
            </FrontInfoItem>

            <FrontInfoItem label="Email">
              <ClickToCopy size="sm" value={invitation.email} />
            </FrontInfoItem>

            <FrontInfoItem label="Invitation ID">
              <ClickToCopy size="sm" value={invitation.id} />
            </FrontInfoItem>

            <FrontInfoItem label="Timestamps">
              <Flex gap="4" wrap="wrap" fontSize="sm">
                <Flex>Sent {formatTimestampAgo(invitation.created)}</Flex>
                {invitation.expires &&
                !invitation.accepted &&
                !invitation.canceled ? (
                  isAfter(timestampToDate(invitation.expires), now) ? (
                    <Flex>
                      Expires in {formatTimestampAgo(invitation.expires)}
                    </Flex>
                  ) : (
                    <Flex>
                      Expired {formatTimestampAgo(invitation.expires)}
                    </Flex>
                  )
                ) : null}
              </Flex>
            </FrontInfoItem>
          </FrontInfoList>
        </Spaced>
      </CardBody>
    </Card>
  );
}
