import { Option } from "@cartographerio/fp";
import { checks } from "@cartographerio/permission";
import {
  DataLicenseEnum,
  KnownProjectFeatureEnum,
  MapSchemaSummary,
  MapVisibilityEnum,
  Message,
  Project,
  ProjectEmailSettings,
  ProjectFeature,
  ProjectMapSettings,
  ProjectPermissionModelEnum,
  ProjectTemplate,
  ProjectVisibilityEnum,
  Qualification,
  SurveyModuleSummary,
  unsafeProjectAlias,
} from "@cartographerio/types";
import { filterAndMap, findAndMap } from "@cartographerio/util";
import { Box, FormControl, SimpleGrid } from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";

import { projectErrorKeys } from "../../schema/project";
import { splitMessages } from "../../schema/rule/errors";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { useControlledSubset } from "../hooks/useSubset";
import AdminContent from "./AdminContent";
import { useIOConfirm } from "./Alert";
import Checkbox from "./Checkbox";
import { ProjectEmailSettingsEditor } from "./EmailSettingsEditor";
import Fieldset from "./Fieldset";
import FormLabel from "./FormLabel";
import Heading from "./Heading";
import LinkButton from "./LinkButton";
import MapSettingsEditor from "./MapSettingsEditor";
import MessageFormControl from "./MessageFormControl";
import Panel from "./Panel";
import PanelRow from "./PanelRow";
import Select from "./Select";
import Spaced from "./Spaced";
import TestTarget from "./TestTarget";
import TextField from "./TextField";

interface Inventory<T, I extends string> {
  items: Record<I, T>;
  byId: (id: I) => Option<T>;
  all: () => T[];
}

function inventory<T, I extends string>(
  items: T[],
  getId: (item: T) => I
): Inventory<T, I> {
  return {
    items: items.reduce(
      (acc, item) => ({ ...acc, [getId(item)]: item }),
      {} as Record<I, T>
    ),
    byId: function (id) {
      return Option.wrap(this.items[id]);
    },
    all: function () {
      return Object.values(this.items);
    },
  };
}

function hashIds(ids: string[]): string {
  return [...ids].sort().join("&");
}

interface HashableProjectTemplate {
  hashedMaps: string;
  hashedModules: string;
  projectTemplate: ProjectTemplate;
}

function hashableProjectTemplate(
  projectTemplate: ProjectTemplate
): HashableProjectTemplate {
  return {
    hashedMaps: hashIds(projectTemplate.mapIds),
    hashedModules: hashIds(projectTemplate.moduleIds),
    projectTemplate,
  };
}

interface ProjectEditorProps {
  project: Project;
  onProjectChange: (value: Project) => void;
  projectMessages: Message[];

  emailSettings: ProjectEmailSettings;
  onEmailSettingsChange: (value: ProjectEmailSettings) => void;
  emailSettingsMessages: Message[];

  mapSettings: ProjectMapSettings;
  onMapSettingsChange: (value: ProjectMapSettings) => void;
  mapSettingsMessages: Message[];

  qualifications: Qualification[];
  mapSchemas: MapSchemaSummary[];
  modules: SurveyModuleSummary[];
  projectTemplates: ProjectTemplate[];
  disabled?: boolean;
}

const NAME_HELP = outdent`
  The name of the project (e.g. 'Water Quality' or 'Badger Spotting').
`;

const ALIAS_HELP = outdent`
  A short name that appears in web addresses for pages in this project.
  The alias must be unique within the workspace
  and can only consist of lowercase letters and numbers.

  For example:

  \`\`\`
  https://app.cartographer.io/ws/myworkspace/project/myproject
  \`\`\`

  is the web address of:

  - the project with alias \`myproject\`;
  - in the workspace with alias \`myworkspace\`.
`;

const TEMPLATE_HELP = outdent`
  Choose from a list of pre-defined templates for the project.
  Changing the template will update:

  - the selected survey forms;
  - the selected maps;
  - the project and map visibility;
  - the set of applicable qualifications.
`;

const PROJECT_VISIBILITY_HELP = outdent`
  This setting controls the general visibility of the project and its associated surveys and maps.
  There are two options:

  - **Private visibility**: The project is only visible to project members and workspace administrators.
    Other members of the workspace cannot see it.

  - **Workspace visibility**: The project is visible to all members of the workspace,
    although only project members can contribute surveys.
`;

const MAP_VISIBILITY_HELP = outdent`
  This setting controls the visibility of maps within the project,
  taking into account the *Project Visibility* setting:

  - **Project visibility**: The *Project Visibility* setting determines the visibility of maps.
    Only people with Cartographer user accounts can see the maps.

  - **Public visibility**: Maps in this project can be viewed by anyone,
    including users without a Cartographer account.
    Choose this option if you want to embed maps on an external website.
`;

const PERMISSION_MODEL_HELP = outdent`
  This setting determines the minimum role need to contribute or approve surveys:

  - **Default**: Members need to be at least a *Surveyor* to contribute surveys,
    and at least an *Approver* to approve them. This is the default and recommended setting.

  - **Coordinator-Only**: Members need to be a *Coordinator* to contribute or approve surveys.
    This setting is situationally useful in certain multi-team setups.
`;

const DATA_LICENSE_HELP = outdent`
  This is a work-in-progress setting that allows you to choose a license for data in the project.
  This setting is not currently used anywhere else in Cartographer.
`;

const FEATURES_HELP = outdent`
These checkboxes control specific features that impact different aspects of Cartographer.
Some features are normally reserved for projects on *plus* and *premium* plans.
`;

const FEATURE_HELP: Record<ProjectFeature, string> = {
  MultiTeam: outdent`
    Enable team controls in project settings.

    Normally reserved for projects on the **premium plan**.
  `,
  OfflineMaps: outdent`
    Enable the mobile app to download offline map data
    so it can display detailed maps in areas with poor network connectivity.

    Normally reserved for projects on the **plus plan**.
  `,
  ArcgisIntegration: outdent`
    Enable *ArcGIS Online* on the *Integrations* tab of project settings.
    If this feature is disabled a prompt to contact support is displayed instead.

    Normally reserved for projects on the **plus plan**.
  `,
};

const QUALIFICATIONS_HELP = outdent`
  By checking the boxes below, you can enforce specific qualifications
  for surveyors contributing data to the project.
  Surveyors who do not meet these qualifications
  will be unable to contribute or approve surveys.
`;

export default function ProjectEditor(props: ProjectEditorProps): ReactElement {
  const {
    project,
    onProjectChange,
    projectMessages,
    emailSettings,
    onEmailSettingsChange,
    emailSettingsMessages,
    mapSettings,
    onMapSettingsChange,
    mapSettingsMessages,
    qualifications,
    mapSchemas,
    modules,
    projectTemplates: _projectTemplates,
    disabled,
  } = props;

  const confirm = useIOConfirm();

  const projectErrors = useMemo(
    () => splitMessages(projectMessages, projectErrorKeys),
    [projectMessages]
  );

  const permissionCheckPasses = usePermissionCheckRunner();

  const { has: hasQualification, toggle: toggleQualification } =
    useControlledSubset(project.qualificationIds, qualificationIds =>
      onProjectChange({ ...project, qualificationIds })
    );

  const { has: hasModule, toggle: toggleModule } = useControlledSubset(
    project.moduleIds,
    moduleIds => onProjectChange({ ...project, moduleIds })
  );

  const { has: hasMap, toggle: toggleMap } = useControlledSubset(
    project.mapIds,
    mapIds => onProjectChange({ ...project, mapIds })
  );

  const { has: hasFeature, toggle: toggleFeature } = useControlledSubset(
    project.features,
    features => onProjectChange({ ...project, features })
  );

  const moduleInventory = useMemo(
    () =>
      inventory(
        modules.sort((a, b) =>
          a.names.shortName > b.names.shortName ? 1 : -1
        ),
        module => module.moduleId
      ),
    [modules]
  );

  const mapInventory = useMemo(
    () =>
      inventory(
        mapSchemas.sort((a, b) => (a.title > b.title ? 1 : -1)),
        schema => schema.mapId
      ),
    [mapSchemas]
  );

  const projectTemplates = useMemo(
    () =>
      _projectTemplates
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(hashableProjectTemplate),
    [_projectTemplates]
  );

  const templateSelectOptions = useMemo(
    () => [
      ...projectTemplates.map(({ projectTemplate }, i) => ({
        label: projectTemplate.name,
        value: i,
      })),
      { label: "Custom", value: -1 },
    ],
    [projectTemplates]
  );

  const [showMatrix, setShowMatrix] = useState(false);

  const handleTemplateChange = useCallback(
    (index: number | null) => {
      if (index === null) {
        onProjectChange({ ...project, moduleIds: [], mapIds: [] });
      } else {
        const template =
          index >= 0 ? projectTemplates[index]?.projectTemplate ?? null : null;

        if (template != null) {
          confirm({
            title: "Change Template?",
            message:
              "Selecting a new project template will update most of the settings below. Continue?",
          })
            .tap(confirmed => {
              if (confirmed) {
                onProjectChange({
                  ...project,
                  name: template.name,
                  alias: template.alias,
                  dataLicense: template.dataLicense,
                  projectVisibility: template.projectVisibility,
                  qualificationIds: filterAndMap(
                    template.qualifications,
                    alias =>
                      findAndMap(qualifications, qual =>
                        qual.alias === alias ? qual.id : null
                      )
                  ),
                  mapVisibility: template.mapVisibility,
                  moduleIds: template.moduleIds,
                  mapIds: template.mapIds,
                });
              }
            })
            .unsafeRun();
        } else {
          setShowMatrix(true);
        }
      }
    },
    [onProjectChange, project, projectTemplates, confirm, qualifications]
  );

  const selectedTemplate = useMemo(() => {
    if (project.moduleIds.length > 0 || project.mapIds.length > 0) {
      const hashedModules = hashIds(project.moduleIds);
      const hashedMaps = hashIds(project.mapIds);
      return projectTemplates.findIndex(
        template =>
          hashedModules === template.hashedModules &&
          hashedMaps === template.hashedMaps
      );
    } else {
      return null;
    }
  }, [projectTemplates, project.mapIds, project.moduleIds]);

  const qualificationsWithPermissions = useMemo(
    () =>
      qualifications.map(qualification => ({
        qualification,
        disabled: !permissionCheckPasses(
          checks.project.editQualificationAssociations(
            project,
            qualification.id
          )
        ),
      })),
    [permissionCheckPasses, qualifications, project]
  );

  return (
    <Spaced spacing="8">
      <AdminContent fallback={<TestTarget testId="template-disabled" />}>
        <Fieldset
          legend="Project Template"
          help={TEMPLATE_HELP}
          helpAppearance="popover"
        >
          <Spaced spacing="4">
            <FormControl>
              <Select.Nullable
                value={selectedTemplate}
                options={templateSelectOptions}
                onChange={handleTemplateChange}
                placeholder={"None selected"}
                disabled={disabled}
              />
            </FormControl>
            <LinkButton
              title="Customize template"
              leftIcon={showMatrix ? <IoChevronDown /> : <IoChevronForward />}
              onClick={() => setShowMatrix(!showMatrix)}
              isDisabled={disabled}
            >
              Customize template
            </LinkButton>
            {showMatrix && (
              <Panel>
                <SimpleGrid columns={[1, null, 2]} gap="4">
                  <Box>
                    <Heading level="subsubsection">Surveys</Heading>
                    {moduleInventory.all().map(module => (
                      <Checkbox
                        key={module.moduleId}
                        checkboxLabel={module.names.shortName}
                        value={hasModule(module.moduleId)}
                        onChange={value => toggleModule(module.moduleId, value)}
                        disabled={disabled}
                      />
                    ))}
                  </Box>
                  <Box>
                    <Heading level="subsubsection">Maps</Heading>
                    {mapInventory.all().map(map => (
                      <Checkbox
                        key={map.mapId}
                        checkboxLabel={map.title}
                        value={hasMap(map.mapId)}
                        onChange={value => toggleMap(map.mapId, value)}
                        disabled={disabled}
                      />
                    ))}
                  </Box>
                </SimpleGrid>
              </Panel>
            )}
          </Spaced>
        </Fieldset>
      </AdminContent>

      <Fieldset legend="Project Settings">
        <MessageFormControl
          label="Name"
          messages={projectErrors.name}
          help={NAME_HELP}
        >
          <TextField.String
            value={project.name}
            onChange={name => onProjectChange({ ...project, name })}
            disabled={disabled}
          />
        </MessageFormControl>

        <MessageFormControl
          label="Alias"
          messages={projectErrors.alias}
          help={ALIAS_HELP}
        >
          <TextField.String
            value={project.alias}
            onChange={alias =>
              onProjectChange({
                ...project,
                alias: unsafeProjectAlias(alias),
              })
            }
            disabled={disabled || hasFeature(KnownProjectFeatureEnum.LockAlias)}
          />
        </MessageFormControl>
      </Fieldset>

      <Fieldset legend="Permissions">
        <FormControl>
          <FormLabel text="Project Visibility" help={PROJECT_VISIBILITY_HELP} />
          <Select.Standard
            options={ProjectVisibilityEnum.entries}
            value={project.projectVisibility}
            onChange={projectVisibility =>
              onProjectChange({ ...project, projectVisibility })
            }
            disabled={disabled}
          />
        </FormControl>

        <FormControl>
          <FormLabel text="Map Visibility" help={MAP_VISIBILITY_HELP} />
          <Select.Standard
            options={MapVisibilityEnum.entries}
            value={project.mapVisibility}
            onChange={mapVisibility =>
              onProjectChange({ ...project, mapVisibility })
            }
            disabled={disabled}
          />
        </FormControl>

        <FormControl>
          <FormLabel text="Permission Model" help={PERMISSION_MODEL_HELP} />
          <Select.Standard
            options={ProjectPermissionModelEnum.entries}
            value={project.permissionModel}
            onChange={permissionModel =>
              onProjectChange({ ...project, permissionModel })
            }
            disabled={disabled}
          />
        </FormControl>
      </Fieldset>

      <AdminContent>
        <Fieldset legend="Licensing">
          <FormControl>
            <FormLabel text="Data License" help={DATA_LICENSE_HELP} />
            <Select.Standard
              options={DataLicenseEnum.entries}
              value={project.dataLicense}
              onChange={dataLicense =>
                onProjectChange({ ...project, dataLicense })
              }
              disabled={disabled}
            />
          </FormControl>
        </Fieldset>
      </AdminContent>

      <AdminContent>
        <Fieldset
          legend="Qualification Requirements"
          help={QUALIFICATIONS_HELP}
          helpAppearance="popover"
        >
          {qualificationsWithPermissions.map(
            ({ qualification, disabled: qualDisabled }) => (
              <PanelRow key={qualification.id}>
                <Checkbox
                  checkboxLabel={qualification.name}
                  value={hasQualification(qualification.id)}
                  onChange={value =>
                    toggleQualification(qualification.id, value)
                  }
                  disabled={disabled || qualDisabled}
                />
              </PanelRow>
            )
          )}
        </Fieldset>
      </AdminContent>

      <AdminContent fallback={<TestTarget testId="features-disabled" />}>
        <Fieldset
          legend="Features"
          help={FEATURES_HELP}
          helpAppearance="popover"
        >
          {KnownProjectFeatureEnum.entries.map(({ value, label }) => (
            <PanelRow key={value}>
              <Checkbox
                checkboxLabel={label}
                checkboxHelp={FEATURE_HELP[value]}
                value={hasFeature(value)}
                onChange={isOn => toggleFeature(value, isOn)}
                disabled={disabled}
              />
            </PanelRow>
          ))}
        </Fieldset>
      </AdminContent>

      <Fieldset legend="Map Settings">
        <MapSettingsEditor
          nullable={false}
          initial={mapSettings}
          onChange={onMapSettingsChange}
          messages={mapSettingsMessages}
          disabled={disabled}
        />
      </Fieldset>

      <Fieldset legend="Email Settings">
        <ProjectEmailSettingsEditor
          value={emailSettings}
          onChange={onEmailSettingsChange}
          messages={emailSettingsMessages}
          disabled={disabled}
        />
      </Fieldset>
    </Spaced>
  );
}
