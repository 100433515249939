import { endpoints } from "@cartographerio/client";
import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import { useApiParams } from "../../contexts/auth";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function WorkspaceBillingDashboardPage(
  props: RouteProps<typeof routes.workspace.billing.dashboard>
): ReactElement {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();
  const navigate = useNavigate();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  useRequirePermission(checks.billing.workspace(workspace));

  useEffectOnce(() => {
    endpoints.billing.dashboard.v1
      .redirect(apiParams, workspaceRef)
      .tap(({ url }) => navigate(url))
      .unsafeRun();
  });

  return <LoadingPlaceholder label="Loading dashboard redirect..." />;
}
