import { SelectSection } from "@cartographerio/topo-form";
import { ProjectAlias, TeamId } from "@cartographerio/types";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { IconType } from "react-icons";
import { RiTeamFill, RiTeamLine } from "react-icons/ri";

import SectionedSelect from "../components/SectionedSelect";
import { Highlight } from "../hooks/highlight";
import { useFormContext } from "./context/FormContext";

interface TeamFieldProps {
  defaultValue?: TeamId | null;
  onChange?: (value: TeamId | null) => void;
  project?: ProjectAlias;
  disabled?: boolean;
  highlight?: Highlight;
}

export default function TeamField(props: TeamFieldProps): ReactElement {
  const { defaultValue, onChange, project, disabled, highlight } = props;

  const { workspaceGraph } = useFormContext();

  const teamOptions = useMemo(
    (): SelectSection<TeamId>[] => [
      {
        heading: "Teams",
        options:
          project != null
            ? workspaceGraph
                .findTeamsByProjectRef(project)
                .map(({ id, name }) => ({ label: name, value: id }))
            : [],
      },
    ],
    [project, workspaceGraph]
  );

  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback(
    (teamId: TeamId | null) => {
      onChange?.(teamId);
      setValue(teamId);
    },
    [onChange]
  );

  return (
    <SectionedSelect<TeamId>
      value={value}
      sections={teamOptions}
      fuseOptions={{
        includeMatches: true,
        minMatchCharLength: 1,
      }}
      onChange={handleChange}
      renderIcon={teamIdToIcon}
      highlight={highlight}
      disabled={disabled}
      debounce={200}
      emptyText="No matching teams found. Please adjust your search"
    />
  );
}

function teamIdToIcon(teamId: TeamId | null): IconType {
  return teamId != null ? RiTeamFill : RiTeamLine;
}
