import { checks } from "@cartographerio/permission";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseUserPage from "../base/BaseUserPage";

export default function AdminUserUpdatePage(
  props: RouteProps<typeof routes.admin.member.view>
): ReactElement {
  const {
    path: { userId },
  } = props;

  const apiParams = useApiParams();

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );
  const projects = useSuspenseSearchResults(queries.project.v2.all(apiParams));
  const teams = useSuspenseSearchResults(queries.team.v2.all(apiParams));
  const workspaceGraph = useWorkspaceGraph(workspaces, projects, teams);

  const defaultUser = useSuspenseQueryData(
    queries.user.v2.readOrFail(apiParams, userId)
  );

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.home.url([])
  );

  usePageTitle(
    useMemo(() => {
      const name = [defaultUser.firstName, defaultUser.lastName].join(" ");
      return `${name || "User"} - Admin`;
    }, [defaultUser.firstName, defaultUser.lastName])
  );

  return (
    <>
      <PageTopBar admin={true} basePage="members" user={defaultUser} />
      <BaseUserPage
        asTitle={true}
        defaultUser={defaultUser}
        nextRedirect={routes.admin.member.list.url([])}
        workspaceGraph={workspaceGraph}
      />
    </>
  );
}
