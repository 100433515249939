import {
  BillingInterval,
  PlanLimits,
  PricingScheme,
} from "@cartographerio/types";
import { Box, List, ListItem } from "@chakra-ui/react";
import { ReactNode } from "react";

import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { formatAmount } from "./helpers";

export interface PlanFeaturesProps {
  pricingScheme: PricingScheme;
  limits: PlanLimits;
  billingInterval: BillingInterval;
}

export default function PlanFeatures({
  pricingScheme,
  limits,
  billingInterval,
}: PlanFeaturesProps) {
  const { features } = limits;

  const starter = true;
  const plus = features !== "Starter";
  const premium = features === "Premium";

  const inclusiveProjects =
    pricingScheme.type === "PerProject" ? pricingScheme.inclusiveUnits : 0;

  const inclusiveUsers =
    pricingScheme.type === "PerUser" ? pricingScheme.inclusiveUnits : 0;

  const inclusiveProjectsLabel =
    inclusiveProjects === 0
      ? "Unlimited projects"
      : inclusiveProjects === 1
      ? `${inclusiveProjects} project included *`
      : `${inclusiveProjects} projects included *`;

  const inclusiveUsersLabel =
    inclusiveUsers === 0
      ? "Unlimited users"
      : inclusiveUsers === 1
      ? `${inclusiveUsers} user included *`
      : `${inclusiveUsers} users included *`;

  const intervalLabel = billingInterval === "Monthly" ? "month" : "year";

  return (
    <>
      <FeatureList title="Projects, Users, and Teams">
        <FeatureItem checked={true} text={inclusiveProjectsLabel} />
        <FeatureItem checked={true} text={inclusiveUsersLabel} />
        <FeatureItem checked={premium} text="Regional teams" />
        <Footnote>
          {pricingScheme.flatAmount > 0 && pricingScheme.unitAmount > 0 && (
            <>
              * +{formatAmount(pricingScheme.unitAmount, billingInterval)}
              {pricingScheme.type === "PerUser"
                ? `/${intervalLabel} per extra user`
                : `/${intervalLabel} per extra project`}
            </>
          )}
        </Footnote>
      </FeatureList>
      <FeatureList title="Data Entry" mobile="hidden">
        <FeatureItem checked={starter} text="Upload data and photos" />
        <FeatureItem checked={starter} text="Export data at any time" />
        <FeatureItem checked={starter} text="Web and mobile apps" />
        <FeatureItem checked={starter} text="Offline mode on mobil" />
      </FeatureList>
      <FeatureList title="Location Entry" mobile="hidden">
        <FeatureItem checked={starter} text="Click on map" />
        <FeatureItem checked={starter} text="Click on aerial photo" />
        <FeatureItem checked={starter} text="Geolocation" />
        <FeatureItem checked={starter} text="GPS/NGR" />
        <FeatureItem checked={plus} text="what3words *" />
        <FeatureItem checked={plus} text="Select pre-defined sites" />
      </FeatureList>
      <FeatureList title="Map" mobile="hidden">
        <FeatureItem checked={starter} text="Embed maps on your web site" />
        <FeatureItem checked={plus} text="Offline maps on mobile" />
        <FeatureItem checked={plus} text="Remove branding from maps" />
      </FeatureList>
      <FeatureList title="Integration" mobile="hidden">
        <FeatureItem checked={plus} text="ArcGIS Online" />
        <FeatureItem checked={plus} text="Google Drive *" />
        <FeatureItem checked={plus} text="MailChimp *" />
        <FeatureItem checked={premium} text="Custom integrations *" />
      </FeatureList>
      <FeatureList title="Billing and Support" mobile="hidden">
        <FeatureItem checked={true} text="Assistance with setup" />
        <FeatureItem
          checked={true}
          text={
            premium
              ? "Premium support"
              : plus
              ? "Priority support"
              : "Standard support"
          }
        />
        <FeatureItem checked={starter} text="Pay by card" />
        <FeatureItem checked={premium} text="Pay by invoice" />
      </FeatureList>
      <Footnote mobile="hidden">* Feature coming soon</Footnote>
    </>
  );
}

interface FeatureListProps {
  title: string;
  mobile?: "hidden";
  children: ReactNode;
}

function FeatureList({ title, mobile, children }: FeatureListProps) {
  return (
    <Spaced
      spacing="2"
      display={mobile === "hidden" ? ["none", "block"] : undefined}
    >
      <Para fontWeight="bold" color="black" fontSize="sm">
        {title}
      </Para>
      <List spacing="2" fontSize="sm">
        {children}
      </List>
    </Spaced>
  );
}

interface FeatureItemProps {
  checked: boolean;
  text: string;
}

function FeatureItem({ checked, text }: FeatureItemProps) {
  return (
    <ListItem>
      {checked ? "✅" : "❌"} {text}
    </ListItem>
  );
}

interface FootnoteProps {
  mobile?: "hidden";
  children?: ReactNode;
}

function Footnote({ mobile, children }: FootnoteProps) {
  return (
    <Box
      display={mobile === "hidden" ? ["none", "block"] : undefined}
      fontSize="xs"
      fontWeight="normal"
      color="gray.500"
    >
      {children}&nbsp;
    </Box>
  );
}
