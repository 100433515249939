import { checks } from "@cartographerio/permission";
import {
  Invitation,
  Message,
  Qualification,
  QualificationRoleNameEnum,
  WorkspaceRef,
  unsafeEmail,
} from "@cartographerio/types";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { ReactElement, useMemo } from "react";

import queries from "../../queries";
import { invitationErrorKeys } from "../../schema/invitation";
import { splitMessages } from "../../schema/rule/errors";
import MessageFormControl from "../components/MessageFormControl";
import TextField from "../components/TextField";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import Fieldset from "./Fieldset";
import QualificationRoleEditor from "./QualificationRoleEditor";
import RequirePermission from "./RequirePermission";
import RoleEditor from "./RoleEditor";
import Spaced from "./Spaced";

export interface InvitationEditorProps {
  value: Invitation;
  onChange?: (invitation: Invitation) => void;
  messages?: Message[];
  disabled?: boolean;
  workspaceGraph: WorkspaceGraph;
  defaultWorkspace?: WorkspaceRef;
  qualifications?: Qualification[];
  roleEditorSingleWorkspace?: boolean;
}

export default function InvitationEditor(
  props: InvitationEditorProps
): ReactElement {
  const {
    value,
    messages,
    onChange,
    disabled,
    workspaceGraph,
    defaultWorkspace,
    qualifications = [],
    roleEditorSingleWorkspace,
  } = props;

  const apiParams = useApiParams();
  const workspace = useSuspenseQueryData(
    queries.optional(defaultWorkspace, workspace =>
      queries.workspace.v2.readOrFail(apiParams, workspace)
    )
  );

  const errors = useMemo(
    () => splitMessages(messages, invitationErrorKeys),
    [messages]
  );

  const qualificationsCheck = useMemo(
    () =>
      checks.or(
        ...qualifications.map(({ id }) =>
          checks.qualification.grant(QualificationRoleNameEnum.Trainee, id)
        )
      ),
    [qualifications]
  );

  return (
    <Spaced spacing="8">
      <Spaced spacing="4">
        <MessageFormControl label="First Name" messages={errors.firstName}>
          <TextField.String
            value={value.firstName}
            disabled={disabled}
            onChange={firstName => onChange?.({ ...value, firstName })}
          />
        </MessageFormControl>

        <MessageFormControl label="Last Name" messages={errors.lastName}>
          <TextField.String
            value={value.lastName}
            disabled={disabled}
            onChange={lastName => onChange?.({ ...value, lastName })}
          />
        </MessageFormControl>

        <MessageFormControl label="Email Address" messages={errors.email}>
          <TextField.String
            value={value.email}
            disabled={disabled}
            onChange={email =>
              onChange?.({ ...value, email: unsafeEmail(email) })
            }
          />
        </MessageFormControl>
      </Spaced>

      <RequirePermission check={qualificationsCheck}>
        <Fieldset legend="Qualification Roles" help={QUALIFICATION_ROLES_HELP}>
          <QualificationRoleEditor
            value={value.qualificationRoles}
            qualifications={qualifications}
            disabled={disabled}
            onChange={qualificationRoles =>
              onChange?.({ ...value, qualificationRoles })
            }
          />
        </Fieldset>
      </RequirePermission>

      <Fieldset legend="Roles" help={ROLES_HELP}>
        <RoleEditor
          value={value.roles}
          messages={errors.roles}
          disabled={disabled}
          onChange={roles => onChange?.({ ...value, roles })}
          workspaceGraph={workspaceGraph}
          defaultWorkspace={workspace?.id}
          singleWorkspace={roleEditorSingleWorkspace}
        />
      </Fieldset>
    </Spaced>
  );
}

const QUALIFICATION_ROLES_HELP =
  "When they accept this invitation, the invitee will be given the following qualification roles:";

const ROLES_HELP =
  "When they accept this invitation, the invitee will be given the following roles:";
