import { Card, CardBody, CardBodyProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import Spaced, { SpacedProps } from "./Spaced";

export interface PanelProps extends SpacedProps {
  children: ReactNode;
  cardBodyProps?: Omit<CardBodyProps, "children">;
}

export default function Panel(props: PanelProps): ReactElement {
  const {
    children,
    bg = "white",
    spacing = "1",
    cardBodyProps = {},
    ...rest
  } = props;

  return (
    <Card bg={bg} {...rest}>
      <CardBody {...cardBodyProps}>
        <Spaced spacing={spacing}>{children}</Spaced>
      </CardBody>
    </Card>
  );
}
