import {
  Invitation,
  InvitationCode,
  formatTimestampAgo,
  invitationCodeStatus,
  invitationStatus,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Badge, BadgeProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

interface InvitationStatusBadgeProps extends BadgeProps {
  invitation: Invitation;
}

export function InvitationStatusBadge(
  props: InvitationStatusBadgeProps
): ReactElement {
  const { invitation, ...rest } = props;

  const { label, color } = useMemo((): {
    label: string;
    color: BadgeProps["color"];
  } => {
    const status = invitationStatus(invitation);
    switch (status) {
      case "Accepted":
        return {
          label:
            invitation.accepted == null
              ? "Accepted"
              : `Accepted ${formatTimestampAgo(invitation.accepted)}`,
          color: "green.500",
        };
      case "Canceled":
        return {
          label:
            invitation.canceled == null
              ? "Cancelled"
              : `Cancelled ${formatTimestampAgo(invitation.canceled)}`,
          color: "red.500",
        };
      case "Expired":
        return {
          label:
            invitation.expires == null
              ? "Expired"
              : `Expired ${formatTimestampAgo(invitation.expires)}`,
          color: "red.500",
        };
      case "Pending":
        return {
          label:
            invitation.expires != null
              ? `Expires ${formatTimestampAgo(invitation.expires)}`
              : "Pending",
          color: "orange.500",
        };
      default:
        return checkExhausted(status);
    }
  }, [invitation]);

  return (
    <Badge
      color={color}
      variant="outline"
      borderColor={color}
      borderWidth="2px"
      boxShadow="0"
      display="inline-flex"
      alignItems="center"
      textTransform="none"
      px="3"
      h="8"
      rounded="full"
      fontSize="sm"
      fontWeight="semibold"
      {...rest}
    >
      {label}
    </Badge>
  );
}

interface InvitationCodeStatusBadgeProps extends BadgeProps {
  code: InvitationCode;
}

export function InvitationCodeStatusBadge(
  props: InvitationCodeStatusBadgeProps
): ReactElement {
  const { code, ...rest } = props;

  const { label, color } = useMemo((): {
    label: string;
    color: BadgeProps["color"];
  } => {
    const status = invitationCodeStatus(code);
    switch (status) {
      case "Canceled":
        return {
          label:
            code.canceled == null
              ? "Cancelled"
              : `Cancelled ${formatTimestampAgo(code.canceled)}`,
          color: "red.500",
        };
      case "Expired":
        return {
          label:
            code.expires == null
              ? "Expired"
              : `Expired ${formatTimestampAgo(code.expires)}`,
          color: "red.500",
        };
      case "Pending":
        return {
          label:
            code.expires != null
              ? `Expires ${formatTimestampAgo(code.expires)}`
              : "Active Code",
          color: "orange.500",
        };
      default:
        return checkExhausted(status);
    }
  }, [code]);

  return (
    <Badge
      color={color}
      variant="outline"
      borderColor={color}
      borderWidth="2px"
      boxShadow="0"
      display="inline-flex"
      alignItems="center"
      textTransform="none"
      px="3"
      h="8"
      rounded="full"
      fontSize="sm"
      fontWeight="semibold"
      {...rest}
    >
      {label}
    </Badge>
  );
}
