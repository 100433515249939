import {
  SelectOption,
  SelectSection,
  SelectValue,
} from "@cartographerio/topo-form";
import { Flex } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { TransferMapping } from "../../schema/UserTransferRequest";
import SectionedSelect from "./SectionedSelect";
import Spaced from "./Spaced";

interface TransferListProps<T extends SelectValue> {
  srcOptions: SelectOption<T>[];
  desSections: SelectSection<T>[];
  value: TransferMapping<T>[];
  onChange: (value: TransferMapping<T>[]) => void;
}

export default function TransferList<T extends SelectValue>(
  props: TransferListProps<T>
): ReactElement {
  const { srcOptions, desSections, value, onChange } = props;

  const itemLookup: Map<T, T | null> = useMemo(() => {
    const map = new Map<T, T | null>();
    for (const item of value) {
      map.set(item[0], item[1]);
    }
    return map;
  }, [value]);

  return (
    <Spaced
      spacing="3"
      rounded="md"
      borderColor="gray.100"
      borderWidth="thin"
      borderStyle="solid"
      p="2"
      pl="4"
      bg="white"
    >
      {srcOptions.map(({ label, value: itemValue }, i) => (
        <Flex
          key={i}
          flexGrow={1}
          flexShrink={1}
          align="center"
          justifyContent="space-between"
        >
          {label}
          <SectionedSelect<T>
            sections={desSections}
            value={itemLookup.get(itemValue) ?? null}
            onChange={(toId: T | null) => {
              console.log(toId);
              onChange([
                ...value.filter(([oldMapping]) => oldMapping !== itemValue),
                [itemValue, toId],
              ]);
            }}
            fuseOptions={{
              includeMatches: true,
              minMatchCharLength: 1,
            }}
            debounce={200}
            emptyText="No matching items found. Please adjust your search"
            w="24ch"
            minW="24ch"
          />
        </Flex>
      ))}
    </Spaced>
  );
}
