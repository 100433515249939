import { PermissionCheck } from "@cartographerio/types";
import { useCallback } from "react";

import usePermissionCheckRunner from "./usePermissionCheckRunner";

export type PermissionCheckRunner = (check: PermissionCheck) => boolean;

export default function usePermissionCheckFunc<A extends unknown[]>(
  func: (...params: A) => PermissionCheck
): (...params: A) => boolean {
  const runner = usePermissionCheckRunner();
  return useCallback((...params) => runner(func(...params)), [func, runner]);
}
