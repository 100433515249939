import { checks } from "@cartographerio/permission";
import {
  Invitation,
  Workspace,
  nowTimestamp,
  randomInvitationId,
  unsafeEmail,
  userRef,
  workspaceActiveRole,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { invitationRule } from "../../../schema/invitation";
import { useIOErrorAlert } from "../../components/Alert";
import BackButtonHeading from "../../components/BackButtonHeading";
import InvitationEditor from "../../components/InvitationEditor";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import WorkspacePageHeader from "./WorkspacePageHeader";

function defaultInvitation(workspace: Workspace): Invitation {
  const now = nowTimestamp();
  return {
    firstName: "",
    lastName: "",
    email: unsafeEmail(""),
    workspaceId: workspace.id,
    roles: [workspaceActiveRole(workspace.id)],
    qualificationRoles: [],
    created: now,
    createdBy: userRef(),
    updated: now,
    id: randomInvitationId(),
  };
}

export default function WorkspaceInvitationCreatePage(
  props: RouteProps<typeof routes.workspace.invitation.create>
): ReactElement {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  useRequirePermission(checks.workspace.admin(workspace));

  usePageTitle(`New Invitation - ${workspace.name}`);

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  const [invitation, setInvitation] = useState(() =>
    defaultInvitation(workspace)
  );

  const handleSend = useCallback(() => {
    queries.invitation.v3
      .create(queryClient, apiParams, invitation)
      .tap(() =>
        toast({
          status: "success",
          title: "Invitation Email Sent",
          duration: 3000,
          isClosable: true,
        })
      )
      .tap(() =>
        navigate(routes.workspace.invitation.list.url([workspace.alias]))
      )
      .tapError(errorAlert)
      .unsafeRun();
  }, [
    apiParams,
    errorAlert,
    invitation,
    navigate,
    queryClient,
    toast,
    workspace.alias,
  ]);

  const messages = useMemo(() => invitationRule(invitation), [invitation]);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="invitation-single"
        invitation="new"
      />
      <WorkspacePageHeader workspace={workspace} selected="invitation-single" />
      <PageContainer width="narrow">
        <Spaced>
          <BackButtonHeading
            my="0"
            to={routes.workspace.invitation.list.url([workspace.alias])}
          >
            Invite a User
          </BackButtonHeading>
          <InvitationEditor
            value={invitation}
            messages={messages}
            onChange={setInvitation}
            workspaceGraph={workspaceGraph}
            qualifications={qualifications}
            defaultWorkspace={workspace.id}
          />
          <SaveButton
            label="Send Invitation"
            onClick={handleSend}
            messages={messages}
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
