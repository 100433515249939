import { Credentials } from "@cartographerio/types";
import * as Sentry from "@sentry/react";
import { ReactElement, ReactNode, useEffect } from "react";

import { useOptCredentials } from "../contexts/auth";

export default function useSentry(credentials: Credentials | null | undefined) {
  useEffect(() => {
    Sentry.configureScope(scope =>
      scope.setUser(
        credentials?.identity == null
          ? null
          : {
              id: credentials.identity.userId,
              name: credentials.identity.screenName,
              email: credentials.identity.email,
              accessToken: credentials.token,
            }
      )
    );
  }, [
    credentials?.token,
    credentials?.identity.screenName,
    credentials?.identity.userId,
    credentials?.identity,
  ]);
}

export interface SentryProviderProps {
  children: ReactNode;
}

export function SentryProvider(props: SentryProviderProps): ReactElement {
  const { children } = props;
  const credentials = useOptCredentials();
  useSentry(credentials);
  return <>{children}</>;
}
