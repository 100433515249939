import {
  BillingInterval,
  Plan,
  PlanId,
  PricingScheme,
} from "@cartographerio/types";
import { Box, Card, CardBody, HStack } from "@chakra-ui/react";

import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import Heading from "../components/Heading";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { formatAmount } from "./helpers";
import PlanFeatures from "./PlanFeatures";

export type PlanClickFunc = (
  planId: PlanId,
  billingInterval: BillingInterval
) => void;

export interface PlanChooserColumnProps {
  plan: Plan;
  billingInterval: BillingInterval;
  disabled?: boolean;
  disabledReason?: string;
  planLink?: string;
  onPlanClick?: PlanClickFunc;
}

export default function PlanChooserColumn(props: PlanChooserColumnProps) {
  const {
    plan,
    billingInterval,
    disabled = false,
    disabledReason,
    planLink,
    onPlanClick,
  } = props;

  const { id: planId, name, limits, pricingScheme } = plan;

  return (
    <Card
      as="section"
      shadow="lg"
      border="1px solid"
      borderColor="gray.200"
      w={["100%", "25ch"]}
      minW="25ch"
    >
      <CardBody>
        <Spaced spacing="6" pb="2">
          <Heading
            level="section"
            textAlign="center"
            fontSize="md"
            color="orange.500"
          >
            {name}
          </Heading>
          <Box
            textAlign="center"
            fontSize="2xl"
            fontWeight="semibold"
            color="black"
          >
            <PriceLabel
              pricingScheme={pricingScheme}
              billingInterval={billingInterval}
            />
          </Box>
          <Para textAlign="center">
            {planLink != null ? (
              <ButtonLink.Internal
                to={planLink}
                disabled={disabled}
                title={disabled ? disabledReason : undefined}
              >
                Choose
              </ButtonLink.Internal>
            ) : (
              onPlanClick != null && (
                <Button
                  label="Choose"
                  onClick={() => onPlanClick(planId, billingInterval)}
                  disabled={disabled}
                  title={disabled ? disabledReason : undefined}
                />
              )
            )}
          </Para>
          <PlanFeatures
            pricingScheme={pricingScheme}
            limits={limits}
            billingInterval={billingInterval}
          />
        </Spaced>
      </CardBody>
    </Card>
  );
}

interface PriceLabelProps {
  pricingScheme: PricingScheme;
  billingInterval: BillingInterval;
}

function PriceLabel(props: PriceLabelProps) {
  const { pricingScheme, billingInterval } = props;

  const { flatAmount, unitAmount } = pricingScheme;

  const unitLabel = pricingScheme.type === "PerUser" ? "user" : "project";
  const intervalLabel = billingInterval === "Monthly" ? "month" : "year";

  if (flatAmount > 0) {
    return (
      <Spaced spacing="0">
        <HStack justify="center" gap="1">
          <Box fontSize="2xl" color="black">
            {formatAmount(flatAmount, billingInterval)}
          </Box>
          <Box fontSize="sm" color="gray.500">
            /{intervalLabel}
          </Box>
        </HStack>
      </Spaced>
    );
  } else {
    return (
      <Spaced spacing="0">
        <HStack justify="center" gap="1">
          <Box fontSize="2xl" color="black">
            {formatAmount(unitAmount, billingInterval)}
          </Box>
          <Box fontSize="sm" color="gray.500">
            /{unitLabel}/{intervalLabel}
          </Box>
        </HStack>
      </Spaced>
    );
  }
}
