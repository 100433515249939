import {
  PermissionCheck,
  forbiddenError,
  identityToCheckIdentity,
  nowTimestamp,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { useEffect, useMemo } from "react";

import { useCredentials } from "../contexts/auth";
import usePermissionCheckRunner from "./usePermissionCheckRunner";

export default function useRequirePermission(
  check: PermissionCheck,
  onError?: () => void
): void {
  const { identity } = useCredentials();

  const permissionCheckPasses = usePermissionCheckRunner();

  const passes = useMemo(
    () =>
      permissionCheckPasses(
        check,
        identity.userId,
        identity.roles,
        identity.qualificationRoles
      ),
    [check, identity, permissionCheckPasses]
  );

  useEffect(() => {
    if (!passes) {
      onError == null
        ? raise(
            forbiddenError(
              check,
              identityToCheckIdentity(identity),
              nowTimestamp()
            )
          )
        : onError();
    }
  }, [check, onError, passes, identity]);
}
