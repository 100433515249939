import { FeatureFormat } from "@cartographerio/client";
import { BBox, Feature } from "@cartographerio/geometry";
import { Attribute, AttributeGroup } from "@cartographerio/topo-map";
import { emptyOpenInterval, namedToOpenInterval } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { DownloadIcon } from "@chakra-ui/icons";
import { Radio, chakra } from "@chakra-ui/react";
import {
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { FaChevronRight } from "react-icons/fa";
import { LngLatBoundsLike, useMap } from "react-map-gl";

import Link from "../../components/Link";
import { DownloadUrlFunction } from "../helpers";
import {
  useMapLayers,
  useNamedInterval,
  useSelectedAttribute,
  useSelectedFeatures,
  useSelectedLayer,
  useSupportedAttributes,
} from "../TopoMapContext";
import AttributeValues from "./AttributeValues";
import InspectorButton from "./InspectorButton";
import InspectorHelp from "./InspectorHelp";
import InspectorMenu from "./InspectorMenu";
import InspectorMenuItem from "./InspectorMenuItem";
import InspectorPanel, { InspectorPanelProps } from "./InspectorPanel";
import InspectorSection from "./InspectorSection";
import InspectorTitle from "./InspectorTitle";
import LayerSelect from "./LayerSelect";

type OnBackClick = () => void;
type OnAttributeClick = (attr: Attribute) => void;

export interface AttributeMenuPanelProps extends InspectorPanelProps {
  onBackClick: OnBackClick;
  onAttributeClick: OnAttributeClick;
  cartographerDownloadUrl: DownloadUrlFunction;
}

export default function AttributeMenuPanel(
  props: AttributeMenuPanelProps
): ReactElement {
  const { onBackClick, onAttributeClick, cartographerDownloadUrl, ...rest } =
    props;

  const layers = useMapLayers();
  const [layer] = useSelectedLayer();

  const showLayerSelect = layers.length > 1;

  const [attribute, setAttribute] = useSelectedAttribute(layer.layerId);
  const { uniqueSelectedFeatures } = useSelectedFeatures(layer.layerId);

  const source = layer.source;

  const map = useMap();
  const [namedInterval] = useNamedInterval();

  const downloadUrl = useMemo(
    () =>
      source.type === "CartographerSource"
        ? (format: FeatureFormat, simplify: boolean = false): string => {
            const bounds0: LngLatBoundsLike | undefined =
              map.current?.getBounds();

            const bounds1: BBox | null =
              bounds0 == null
                ? null
                : [
                    bounds0.getSouthWest().lng,
                    bounds0.getSouthWest().lat,
                    bounds0.getNorthEast().lng,
                    bounds0.getNorthEast().lat,
                  ];

            const openInterval =
              namedInterval != null
                ? namedToOpenInterval(namedInterval)
                : emptyOpenInterval;

            return cartographerDownloadUrl(
              source,
              format,
              bounds1,
              openInterval.from,
              openInterval.to,
              simplify
            );
          }
        : null,
    [cartographerDownloadUrl, map, namedInterval, source]
  );

  const handleAttributeChange = useCallback<OnAttributeClick>(
    attr => {
      setAttribute(attr);
    },
    [setAttribute]
  );

  const handleAttributeClick = useCallback<OnAttributeClick>(
    attr => {
      setAttribute(attr);
      onAttributeClick(attr);
    },
    [onAttributeClick, setAttribute]
  );

  return (
    <InspectorPanel {...rest}>
      <InspectorTitle title={layer.title} onBackClick={onBackClick} />

      {showLayerSelect && (
        <InspectorSection title="Layer">
          <InspectorMenuItem>
            <LayerSelect />
          </InspectorMenuItem>
        </InspectorSection>
      )}

      {layer.attributes.map((group, index) => (
        <AttributeGroupItem
          key={index}
          group={group}
          selectedFeatures={uniqueSelectedFeatures}
          selectedAttribute={attribute}
          onAttributeChange={handleAttributeChange}
          onAttributeClick={handleAttributeClick}
        />
      ))}

      <InspectorSection title="Downloads">
        {downloadUrl ? (
          <>
            <InspectorHelp>
              Download data for the currently visible region of the map:
            </InspectorHelp>
            <InspectorMenuItem left={<DownloadIcon color="gray.500" />}>
              <Link.External to={downloadUrl("geojson", false)}>
                GeoJSON
              </Link.External>
            </InspectorMenuItem>
            <InspectorMenuItem left={<DownloadIcon color="gray.500" />}>
              <Link.External to={downloadUrl("kml", false)}>KML</Link.External>
            </InspectorMenuItem>
            <InspectorMenuItem left={<DownloadIcon color="gray.500" />}>
              <Link.External to={downloadUrl("csv", true)}>CSV</Link.External>
            </InspectorMenuItem>
          </>
        ) : (
          <InspectorHelp>
            This map layer cannot be downloaded because it isn&apos;t hosted on
            Cartographer.
          </InspectorHelp>
        )}
      </InspectorSection>
    </InspectorPanel>
  );
}

interface AttributeGroupItemProps {
  group: AttributeGroup;
  selectedFeatures: Feature[];
  selectedAttribute?: Attribute;
  onAttributeChange: OnAttributeClick;
  onAttributeClick: OnAttributeClick;
}

function AttributeGroupItem(props: AttributeGroupItemProps): ReactElement {
  const {
    group,
    selectedFeatures,
    selectedAttribute,
    onAttributeChange,
    onAttributeClick,
  } = props;

  const attributes = useSupportedAttributes(group.attributes);

  return (
    <InspectorSection title={group.label}>
      <InspectorMenu>
        {attributes.map(attr => (
          <AttributeItem
            key={attr.attributeId}
            attribute={attr}
            features={selectedFeatures}
            selectedAttribute={selectedAttribute}
            onAttributeChange={onAttributeChange}
            onAttributeClick={onAttributeClick}
          />
        ))}
      </InspectorMenu>
    </InspectorSection>
  );
}

interface AttributeItemProps {
  attribute: Attribute;
  features: Feature[];
  selectedAttribute?: Attribute;
  onAttributeChange: OnAttributeClick;
  onAttributeClick: OnAttributeClick;
}

function AttributeItem(props: AttributeItemProps): ReactElement {
  const {
    attribute,
    features,
    selectedAttribute,
    onAttributeChange,
    onAttributeClick,
  } = props;

  const handleAttributeChange = useCallback(
    (evt: MouseEvent<HTMLInputElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      onAttributeChange(attribute);
    },
    [attribute, onAttributeChange]
  );

  const handleAttributeClick = useCallback(
    (evt: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      onAttributeClick(attribute);
    },
    [attribute, onAttributeClick]
  );

  const radio = (
    <Radio
      w="100%"
      minW="0"
      flex="1"
      name={attribute.attributeId}
      isChecked={attribute === selectedAttribute}
      onClick={handleAttributeChange}
    />
  );

  let button: ReactNode;
  switch (attribute.type) {
    case "AttachmentAttribute":
      button = null;
      break;
    case "FeatureAttribute":
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
      button = (
        <InspectorButton
          as={FaChevronRight}
          ariaLabel="Attribute Details"
          onClick={handleAttributeClick}
        />
      );
      break;
    default:
      checkExhausted(attribute);
  }

  return (
    <InspectorMenuItem
      left={radio}
      right={button}
      selectable={true}
      onClick={handleAttributeClick}
    >
      <chakra.label
        htmlFor={attribute.attributeId}
        display="flex"
        w="100%"
        minW="0"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="stretch"
        className="legend-outer"
        cursor="pointer"
      >
        {attribute.label}
        {features.length > 0 && (
          <AttributeValues
            attribute={attribute}
            features={features}
            fullView={false}
          />
        )}
      </chakra.label>
    </InspectorMenuItem>
  );
}
