import {
  InvitationCodeSearchOptionsV3,
  PartialParams,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  InvitationCode,
  InvitationCodeCreate,
  InvitationCodeRef,
  InvitationCodeUpdate,
  SearchResults,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type InvitationCodeKey =
  | ["invitation", "code"]
  | [
      "invitation",
      "code",
      "v3",
      "search",
      PartialParams<InvitationCodeSearchOptionsV3>
    ]
  | ["invitation", "code", "v3", "read", InvitationCodeRef];

export function search(
  apiParams: ApiParams,
  options: PartialParams<InvitationCodeSearchOptionsV3> = {}
): UseQueryOpts<SearchResults<InvitationCode>, InvitationCodeKey> {
  return {
    queryKey: ["invitation", "code", "v3", "search", options],
    queryFn: () =>
      endpoints.invitation.code.v3.search(apiParams, options).unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  invitation: InvitationCodeRef
): UseQueryOpts<InvitationCode, InvitationCodeKey> {
  return {
    queryKey: ["invitation", "code", "v3", "read", invitation],
    queryFn: () =>
      endpoints.invitation.code.v3
        .readOrFail(apiParams, invitation)
        .unsafeRun(),
  };
}

export function create(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationCodeCreate
) {
  return endpoints.invitation.code.v3
    .create(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["invitation"]));
}

export function cancel(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationCodeRef
) {
  return endpoints.invitation.code.v3
    .cancel(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["invitation"]));
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationCodeRef,
  create: InvitationCodeCreate | InvitationCodeUpdate
) {
  return endpoints.invitation.code.v3
    .save(apiParams, invitation, create)
    .tap(_ => queryClient.invalidateQueries(["invitation"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationCodeRef
) {
  return endpoints.invitation.code.v3
    .remove(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["invitation"]));
}
