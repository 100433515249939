import { endpoints } from "@cartographerio/client";
import { checks } from "@cartographerio/permission";
import { badRequestError } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { ReactElement } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import { useApiParams } from "../../contexts/auth";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function WorkspaceBillingCheckoutPage(
  props: RouteProps<typeof routes.workspace.billing.checkout>
): ReactElement {
  const {
    path: { workspaceRef },
    query: { plan, interval },
  } = props;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );
  useRequirePermission(checks.billing.workspace(workspace));

  useEffectOnce(() => {
    if (plan != null && interval != null) {
      endpoints.billing.checkout.v1
        .startUrl(apiParams, workspaceRef, {
          plan,
          interval,
        })
        .tap(({ url }) => window.location.replace(url))
        .unsafeRun();
    } else {
      raise(badRequestError("Missing plan/interval parameters.", props.query));
    }
  });

  return <LoadingPlaceholder label="Starting checkout session..." />;
}
