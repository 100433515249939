import { permissionCheckPasses } from "@cartographerio/permission";
import {
  PermissionCheck,
  QualificationRole,
  Role,
  UserId,
} from "@cartographerio/types";
import {
  WorkspaceGraph,
  workspaceGraph,
} from "@cartographerio/workspace-graph";
import { useCallback, useMemo } from "react";

import queries from "../../queries";
import { useApiParams, useOptCredentials } from "../contexts/auth";
import { useSuspenseSearchResults } from "./useSuspenseSearchResults";

export type PermissionCheckRunner = (
  check: PermissionCheck,
  userId?: UserId | null,
  roles?: Role[],
  qualificationRoles?: QualificationRole[]
) => boolean;

export default function usePermissionCheckRunner(
  _graph?: WorkspaceGraph
): PermissionCheckRunner {
  const apiParams = useApiParams();
  const credentials = useOptCredentials();

  const workspaces = useSuspenseSearchResults(
    queries.when(_graph == null, () =>
      queries.optional(credentials?.identity.userId, userId =>
        queries.workspace.v2.registered(apiParams, userId)
      )
    )
  );

  const projects = useSuspenseSearchResults(
    queries.when(_graph == null, () =>
      queries.optional(credentials?.identity.userId, userId =>
        queries.project.v2.visible(apiParams, userId)
      )
    )
  );

  const teams = useSuspenseSearchResults(
    queries.when(_graph == null, () =>
      queries.optional(credentials?.identity.userId, userId =>
        queries.team.v2.visible(apiParams, userId)
      )
    )
  );

  const graph = useMemo(
    () =>
      workspaces != null && projects != null && teams != null
        ? workspaceGraph(workspaces, projects, teams)
        : _graph,
    [_graph, projects, teams, workspaces]
  );

  return useCallback(
    (
      check,
      id = credentials?.identity.userId ?? null,
      roles = credentials?.identity.roles ?? [],
      qualificationRoles = credentials?.identity.qualificationRoles ?? []
    ) => permissionCheckPasses(check, { id, roles, qualificationRoles }, graph),
    [credentials?.identity, graph]
  );
}
