import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import { TeamAlias } from "@cartographerio/types";
import { useDisclosure } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import Button from "../../../components/Button";
import InvitationCodeModal from "../../../components/InvitationCodeModal";
import PageTopBar from "../../../components/PageTopBar";
import WithPermission from "../../../components/WithPermission";
import { useApiParams } from "../../../contexts/auth";
import useCodesEnabled from "../../../hooks/useCodesEnabled";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useProjectHasTeams } from "../../../hooks/useProjectHasTeams";
import useRedirectWhen from "../../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../routes";
import BaseInvitationCodeListPage from "../../base/BaseInvitationCodeListPage";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectInvitationCodeListPage(
  props: RouteProps<typeof routes.workspace.project.invitation.code.list>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  const teams = useSuspenseSearchResults(
    queries.when(multiTeam, () =>
      queries.team.v2.forProject(apiParams, project.id)
    )
  );

  const selectedTeam = useMemo(
    () => teams?.find(({ alias }) => query.team === alias),
    [query.team, teams]
  );

  const teamOptions = useMemo<SelectOption<TeamAlias>[] | undefined>(
    () =>
      teams == null || teams.length === 0
        ? undefined
        : teams.map(({ name, alias }) => ({ label: name, value: alias })),
    [teams]
  );

  useRedirectWhen(!useCodesEnabled(workspace), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  useRequirePermissionRedirect(checks.project.viewInvitations(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  usePageTitle(`Invitation Codes - ${project.name} - ${workspace.name}`);

  const {
    isOpen: isAddModalOpen,
    onOpen: onAddModalOpen,
    onClose: onAddModalClose,
  } = useDisclosure();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="invitation-code"
      />

      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="invitation-code"
      />

      <WithPermission check={checks.project.grantAccess(project)}>
        {canCreate => (
          <BaseInvitationCodeListPage
            route={routes.workspace.project.invitation.code.list}
            query={query}
            updateQuery={updateQuery}
            project={project}
            team={selectedTeam}
            teamOptions={teamOptions}
            onTeamChange={team =>
              updateQuery({ ...query, team: team ?? undefined })
            }
            addButton={
              canCreate && (
                <Button
                  colorScheme="blue"
                  label="Add"
                  onClick={onAddModalOpen}
                />
              )
            }
            itemLink={invitation =>
              routes.workspace.project.invitation.code.view.url([
                workspace.alias,
                project.alias,
                invitation.alias,
              ])
            }
          />
        )}
      </WithPermission>

      <InvitationCodeModal.Project
        title="Create Invitation Code"
        isOpen={isAddModalOpen}
        onClose={onAddModalClose}
        workspace={workspace}
        project={project}
        teams={teams}
      />
    </>
  );
}
