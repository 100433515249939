import { Button, ButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";

import Link, { ExternalLinkProps, InternalLinkProps } from "./Link";

interface ButtonLinkProps {
  colorScheme?: ButtonProps["colorScheme"];
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  leftIcon?: ReactElement;
  buttonProps?: Omit<
    ButtonProps,
    "colorScheme" | "variant" | "size" | "leftIcon"
  >;
}

/** A link that looks like a button! */
function ExternalButtonLink(
  props: ButtonLinkProps & ExternalLinkProps
): ReactElement {
  const {
    colorScheme,
    variant,
    size,
    leftIcon,
    buttonProps = {},
    children,
    ...rest
  } = props;

  return (
    <Link.External color="unset" _hover={{ textDecoration: "unset" }} {...rest}>
      <Button
        leftIcon={leftIcon}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        {...buttonProps}
      >
        {children}
      </Button>
    </Link.External>
  );
}

function InternalButtonLink(
  props: ButtonLinkProps & InternalLinkProps
): ReactElement {
  const {
    colorScheme,
    variant,
    size,
    leftIcon,
    buttonProps,
    children,
    ...rest
  } = props;

  return (
    <Link.Internal color="unset" _hover={{ textDecoration: "unset" }} {...rest}>
      <Button
        leftIcon={leftIcon}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        {...buttonProps}
      >
        {children}
      </Button>
    </Link.Internal>
  );
}

export default { External: ExternalButtonLink, Internal: InternalButtonLink };
