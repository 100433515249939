import {
  ProjectId,
  Role,
  TeamId,
  WorkspaceId,
  addRole as addRoleToArray,
  isGlobalRole,
  parseRole,
  removeRole as removeRoleFromArray,
  roleProjectId,
  roleTeamId,
  roleWorkspaceId,
  workspaceActiveRole,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { useCallback } from "react";

interface RolesProps {
  roles: Role[];
  onChange?: (roles: Role[]) => void;
}

export function useRoles(props: RolesProps) {
  const { roles, onChange } = props;

  const hasRole = useCallback(
    (role: Role): boolean =>
      roles.find(otherRole => role === otherRole) !== undefined,
    [roles]
  );

  const getGlobalRole = useCallback(
    (): Role | null => roles.find(isGlobalRole) ?? null,
    [roles]
  );

  const getWorkspaceRole = useCallback(
    (workspaceId: WorkspaceId): Role | null =>
      roles.find(role => roleWorkspaceId(role) === workspaceId) ?? null,
    [roles]
  );

  const getProjectRole = useCallback(
    (projectId: ProjectId): Role | null =>
      roles.find(role => roleProjectId(role) === projectId) ?? null,
    [roles]
  );

  const getTeamRole = useCallback(
    (teamId: TeamId): Role | null =>
      roles.find(role => roleTeamId(role) === teamId) ?? null,
    [roles]
  );

  const addRole = useCallback(
    (role: Role) => onChange?.(addRoleToArray(roles, role, "replace")),
    [onChange, roles]
  );

  const removeRole = useCallback(
    (role: Role) => onChange?.(removeRoleFromArray(roles, role)),
    [onChange, roles]
  );

  return {
    hasRole,
    addRole,
    removeRole,
    getGlobalRole,
    getWorkspaceRole,
    getProjectRole,
    getTeamRole,
  };
}

export function useWorkspaceRoles(
  graph: WorkspaceGraph,
  roles: Role[],
  onChange?: (roles: Role[]) => void
) {
  const rolesOutput = useRoles({ roles, onChange });
  const { getWorkspaceRole, getProjectRole, getTeamRole } = rolesOutput;

  const addRole = useCallback(
    (role: Role) => {
      let newRoles = addRoleToArray(roles, role, "replace");
      const parts = parseRole(role);
      if (parts[0] === "T") {
        const workspace = graph.findWorkspaceByTeamId(parts[2]);
        if (getWorkspaceRole(workspace.id) == null) {
          newRoles = addRoleToArray(
            newRoles,
            workspaceActiveRole(workspace.id),
            "keepBest"
          );
        }
      } else if (parts[0] === "P") {
        const workspace = graph.findWorkspaceByProjectId(parts[2]);
        if (getWorkspaceRole(workspace.id) == null) {
          newRoles = addRoleToArray(
            newRoles,
            workspaceActiveRole(workspace.id),
            "keepBest"
          );
        }
      }
      onChange?.(newRoles);
    },
    [roles, onChange, graph, getWorkspaceRole]
  );

  const removeRole = useCallback(
    (role: Role) => {
      let newRoles = removeRoleFromArray(roles, role);
      const workspaceId = roleWorkspaceId(role);

      if (workspaceId != null) {
        const projects = graph.findProjectsByWorkspaceId(workspaceId);
        const teams = graph.findTeamsByWorkspaceId(workspaceId);
        const rolesToRemove = [
          ...filterAndMap(projects, ({ id }) => getProjectRole(id)),
          ...filterAndMap(teams, ({ id }) => getTeamRole(id)),
        ];
        newRoles = rolesToRemove.reduce(
          (acc, role) => removeRoleFromArray(acc, role),
          newRoles
        );
      }
      onChange?.(newRoles);
    },
    [roles, onChange, graph, getProjectRole, getTeamRole]
  );

  return { ...rolesOutput, addRole, removeRole };
}
