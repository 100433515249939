import { FeatureFormat, endpoints } from "@cartographerio/client";
import {
  BBox,
  FeatureCollection,
  Point,
  bboxNe,
  bboxSw,
  commonLocations,
  createPoint,
  featureCollection,
  point,
  toBBox4,
} from "@cartographerio/geometry";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  Attribute,
  CartographerSource,
  MapLayer,
  MapSource,
} from "@cartographerio/topo-map";
import {
  MapLayerId,
  PlainDate,
  ProjectMapSettings,
} from "@cartographerio/types";
import { checkExhausted, filterAndMap } from "@cartographerio/util";
import { AllGeoJSON, centroid } from "@turf/turf";
import { isEqual } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { ViewStateChangeEvent } from "react-map-gl";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import { useLocalMapViewportState } from "../../hooks/useLocalMapViewport";
import useMapInventory from "../../hooks/useMapInventory";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useProjectHasTeams } from "../../hooks/useProjectHasTeams";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { WebAppTopoMapContextProvider } from "../../map";
import {
  DownloadUrlFunction,
  InitialViewState,
  TileUrlFunction,
} from "../../map/helpers";
import { DEFAULT_PROMOTE_ID } from "../../map/layerHelpers";
import {
  calcViewState,
  fromCenter,
  fromMapSettings,
  fromMultiLayerSelection,
  fromViewState,
  useTransformRequestFunction,
} from "../../map/mapHelpers";
import { OnBaseChange } from "../../map/TopoMapContext/BaseStyleContext";
import { OnNamedIntervalChange } from "../../map/TopoMapContext/FilterContext";
import { FetchMapSettings } from "../../map/TopoMapContext/MapSettingsContext";
import { OnAttributeSelected } from "../../map/TopoMapContext/SelectedAttributesContext";
import TopoMapWithInspector, {
  OnShowInspectorChange,
} from "../../map/TopoMapWithInspector";
import { routes } from "../../routes";
import ResetViewportBanner from "./ResetViewportBanner";

export function queryViewport(
  center: Point | undefined,
  zoom: number | undefined
): InitialViewState {
  return {
    longitude: center?.coordinates[0],
    latitude: center?.coordinates[1],
    zoom,
  };
}

export function selectionViewport(
  selection: Record<MapLayerId, FeatureCollection> | null | undefined
): InitialViewState {
  if (selection == null) {
    return {};
  } else {
    const features = Object.values(selection).flatMap(coll => coll.features);

    const center =
      features.length === 0
        ? undefined
        : centroid(featureCollection({ features }) as AllGeoJSON);

    return {
      longitude: center?.geometry.coordinates[0],
      latitude: center?.geometry.coordinates[1],
      zoom: 12,
    };
  }
}

function mapSettingsViewport(settings: ProjectMapSettings): InitialViewState {
  return { bounds: toBBox4(settings.defaultBounds) };
}

export default function ProjectMapPage(
  props: RouteProps<typeof routes.workspace.project.map>
): ReactElement {
  const {
    path: { workspaceRef, projectRef, mapId },
    query,
    updateQuery: _updateQuery,
  } = props;

  const {
    base: defaultBase,
    inspector: defaultShowInspector,
    attribute: defaultSelectedAttribute,
    when: defaultNamedInterval,
    scrollwheel: scrollZoom = true,
    survey: defaultSurveySelection,
    team: teamRef,
  } = query;

  const apiParams = useApiParams();

  const updateQuery = useCallback<typeof _updateQuery>(
    (props, _opts) => {
      // ignore opts and replace with our own
      _updateQuery(props, { replace: true });
    },
    [_updateQuery]
  );

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  const projectMapSettings = useSuspenseQueryData(
    queries.project.mapSettings.v1.readOrDefault(apiParams, project.id)
  );

  const fetchMapSettings = useMemo<FetchMapSettings>(
    () =>
      multiTeam
        ? team =>
            team != null
              ? endpoints.team.mapSettings.v2.readOrNull(apiParams, team)
              : IO.pure(projectMapSettings)
        : () => IO.pure(projectMapSettings),
    [apiParams, multiTeam, projectMapSettings]
  );

  const team = useSuspenseQueryData(
    queries.optional(multiTeam ? teamRef : null, teamRef =>
      queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
    )
  );

  const teamMapSettings = useSuspenseQueryData(
    queries.optional(multiTeam ? team : null, ({ id }) =>
      queries.team.mapSettings.v2.readOrNull(apiParams, id)
    )
  );

  const projects = useSuspenseSearchResults(
    queries.project.v2.forWorkspace(apiParams, workspace.id)
  );

  const teams = useSuspenseSearchResults(
    queries.when(multiTeam, () =>
      queries.team.v2.forWorkspace(apiParams, workspace.id)
    )
  );

  useEffect(() => {
    if (team != null && (!multiTeam || !project.teamIds.includes(team.id))) {
      updateQuery({ ...query, team: undefined });
    }
  }, [multiTeam, project.teamIds, query, team, updateQuery]);

  const [localStorageViewport, setLocalStorageViewport] =
    useLocalMapViewportState();

  useRedirectWhen(!project.mapIds.includes(mapId), () =>
    routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  useRequirePermission(checks.map.view(project));

  const schema = useMapInventory(mapId);

  usePageTitle(`${schema.title} Map - ${project.name} - ${workspace.name}`);

  const { apiConfig, auth } = apiParams;

  const defaultExternalSelection = useSuspenseQueryData({
    ...queries.optional(defaultSurveySelection, survey =>
      queries.map.feature.v2.searchAll(
        apiParams,
        filterAndMap(schema.layers, layer =>
          layerSupportsDefaultSurveySearch(layer) &&
          isCartographerSource(layer.source)
            ? [layer.source.layerId, layer.layerId]
            : null
        ),
        {
          project: [project.id],
          survey,
          promoteId: DEFAULT_PROMOTE_ID,
        }
      )
    ),
    // Prevent this refetching data after it's fetched it the first time.
    // Otherwise we keep resetting the user's selection to this feature set.
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const defaultViewport = useMemo(
    (): InitialViewState =>
      calcViewState(
        () =>
          query.center != null && //
          fromCenter(
            query.center,
            query.zoom ?? commonLocations.greatBritain.zoom
          ),
        () =>
          defaultExternalSelection != null && //
          fromMultiLayerSelection(defaultExternalSelection),
        () =>
          localStorageViewport != null && //
          fromViewState(localStorageViewport),
        () =>
          multiTeam &&
          teamMapSettings != null && //
          fromMapSettings(teamMapSettings),
        () => fromMapSettings(projectMapSettings)
      ),
    [
      defaultExternalSelection,
      localStorageViewport,
      multiTeam,
      projectMapSettings,
      query.center,
      query.zoom,
      teamMapSettings,
    ]
  );

  const usingCustomViewport = useMemo(
    () =>
      localStorageViewport != null &&
      isEqual(defaultViewport, localStorageViewport) &&
      !isEqual(localStorageViewport, mapSettingsViewport(projectMapSettings)),
    [defaultViewport, localStorageViewport, projectMapSettings]
  );

  const transformRequest = useTransformRequestFunction(apiConfig, auth);

  const formatApiUrl = useApiUrlFormatter();

  const cartographerDownloadUrl = useCallback<DownloadUrlFunction>(
    (
      {
        layerId,
        projects: projectRefs,
        workspace: workspaceRef,
      }: CartographerSource,
      format: FeatureFormat,
      bounds: BBox | null = null,
      from: PlainDate | null = null,
      to: PlainDate | null = null,
      simplify: boolean = false
    ) =>
      formatApiUrl(
        endpoints.map.feature.v2.searchUrl(layerId, {
          project: projectRefs ?? [project.id],
          workspace: workspaceRef ?? workspace.id,
          sw: bounds == null ? null : createPoint(bboxSw(bounds)),
          ne: bounds == null ? null : createPoint(bboxNe(bounds)),
          from,
          to,
          format,
          simplify,
        })
      ),
    [formatApiUrl, project.id, workspace.id]
  );

  const cartographerTileUrl = useCallback<TileUrlFunction>(
    (
      {
        layerId,
        projects: projectRefs,
        workspace: workspaceRef,
      }: CartographerSource,
      simplify: boolean
    ) =>
      formatApiUrl(
        endpoints.map.feature.v2.tileUrl(
          layerId,
          projectRefs ?? [project.id],
          workspaceRef ?? workspace.id,
          simplify
        ),
        false
      ),
    [formatApiUrl, project.id, workspace.id]
  );

  const handleBaseChange = useCallback<OnBaseChange>(
    base => updateQuery({ ...query, base }),
    [query, updateQuery]
  );

  const handleShowInspectorChange = useCallback<OnShowInspectorChange>(
    inspector => updateQuery({ ...query, inspector }),
    [query, updateQuery]
  );

  const handleAttributeSelected = useCallback<OnAttributeSelected>(
    (_layer, attribute) => {
      updateQuery({
        ...query,
        attribute:
          attribute == null ? undefined : [null, attribute.attributeId],
      });
    },
    [query, updateQuery]
  );

  const handleNamedIntervalChange = useCallback<OnNamedIntervalChange>(
    when => updateQuery({ ...query, when: when ?? undefined }),
    [query, updateQuery]
  );

  const handleMoveEnd = useCallback(
    ({ viewState: { longitude, latitude, zoom } }: ViewStateChangeEvent) => {
      setLocalStorageViewport({ longitude, latitude, zoom });
      updateQuery({ ...query, center: point(longitude, latitude), zoom });
    },
    [query, setLocalStorageViewport, updateQuery]
  );

  return (
    <>
      <PageTopBar
        variant="transparent"
        workspace={workspace}
        workspacePage="projects"
        project={project}
        map={schema}
      />

      <WebAppTopoMapContextProvider
        schema={schema}
        defaultBase={defaultBase}
        defaultSelectedAttribute={defaultSelectedAttribute}
        defaultExternalSelection={defaultExternalSelection ?? undefined}
        workspace={workspace}
        project={project}
        projects={projects}
        teams={teams}
        defaultTeam={team?.id}
        defaultNamedInterval={defaultNamedInterval}
        fetchMapSettings={fetchMapSettings}
        onBaseChange={handleBaseChange}
        onAttributeSelected={handleAttributeSelected}
        onNamedIntervalChange={handleNamedIntervalChange}
      >
        <TopoMapWithInspector
          defaultViewport={defaultViewport}
          defaultShowInspector={defaultShowInspector}
          scrollZoom={scrollZoom}
          transformRequest={transformRequest}
          cartographerDownloadUrl={cartographerDownloadUrl}
          cartographerTileUrl={cartographerTileUrl}
          onShowInspectorChange={handleShowInspectorChange}
          onMoveEnd={handleMoveEnd}
        >
          <ResetViewportBanner show={usingCustomViewport} />
        </TopoMapWithInspector>
      </WebAppTopoMapContextProvider>
    </>
  );
}

function isDefaultSurveyIdAttribute(attr: Attribute): boolean {
  switch (attr.type) {
    case "SurveyAttribute":
      return attr.propertyName === "surveyId";
    case "FeatureAttribute":
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "TeamAttribute":
    case "AttachmentAttribute":
      return false;
    default:
      return checkExhausted(attr);
  }
}

export function layerSupportsDefaultSurveySearch(layer: MapLayer): boolean {
  switch (layer.source.type) {
    case "CartographerSource":
      return layer.attributes.some(group =>
        group.attributes.some(isDefaultSurveyIdAttribute)
      );
    case "RemoteGeojsonSource":
    case "RemoteTileSource":
    case "LocalGeojsonSource":
      return false;
    default:
      checkExhausted(layer.source);
  }
}

export function isCartographerSource(
  source: MapSource
): source is CartographerSource {
  return source.type === "CartographerSource";
}
