import { FeatureCollection } from "@cartographerio/geometry";
import { MapSchema } from "@cartographerio/topo-map";
import {
  MapBase,
  MapLayerId,
  NamedInterval,
  Project,
  Team,
  TeamId,
  Workspace,
} from "@cartographerio/types";
import { ReactNode } from "react";
import { MapProvider } from "react-map-gl";

import { MapAttributeQueryParam } from "../../routes/queryParams";
import { BaseStyleContextProvider, OnBaseChange } from "./BaseStyleContext";
import { FilterContextProvider, OnNamedIntervalChange } from "./FilterContext";
import { LayerBucketsContextProvider } from "./LayerBucketsContext";
import { LayerVisibilityContextProvider } from "./LayerVisibilityContext";
import {
  MapEmbedLinkContextProvider,
  WebAppLinkContextProvider,
} from "./LinkContext";
import { MapSchemaContextProvider } from "./MapSchemaContext";
import {
  FetchMapSettings,
  MapSettingsContextProvider,
} from "./MapSettingsContext";
import { ProjectTeamContextProvider } from "./ProjectTeamContext";
import {
  OnAttributeSelected,
  SelectedAttributesContextProvider,
} from "./SelectedAttributesContext";
import { SelectedFeaturesContextProvider } from "./SelectedFeaturesContext";
import { SelectedLayerContextProvider } from "./SelectedLayerContext";
import { VisibleFeaturesContextProvider } from "./VisibleFeaturesContext";

export {
  useMapBaseContext as useBaseStyleContext,
  useBaseStyle as useBaseStyleId,
  useBaseStyleUrl,
} from "./BaseStyleContext";
export { useFilterContext, useNamedInterval } from "./FilterContext";
export type { OnNamedIntervalChange } from "./FilterContext";
export { useLayerBuckets, useLayerBucketsContext } from "./LayerBucketsContext";
export {
  useLayerVisibility,
  useLayerVisibilityContext,
} from "./LayerVisibilityContext";
export { useLinkContext } from "./LinkContext";
export { useMapLayers, useMapSchema } from "./MapSchemaContext";
export {
  useProjectTeamContextGraph,
  useSupportedAttributes,
} from "./ProjectTeamContext";
export {
  useSelectedAttribute,
  useSelectedAttributesContext,
} from "./SelectedAttributesContext";
export {
  useSelectedFeatures,
  useSelectedFeaturesContext,
} from "./SelectedFeaturesContext";
export { useSelectedLayer } from "./SelectedLayerContext";
export {
  useVisibleFeatures,
  useVisibleFeaturesContext,
} from "./VisibleFeaturesContext";

interface MapEmbedTopoMapContextProviderProps {
  schema: MapSchema;
  defaultBase?: MapBase;
  defaultSelectedAttribute?: MapAttributeQueryParam;
  defaultExternalSelection?: Record<MapLayerId, FeatureCollection>;
  workspace: Workspace;
  project: Project;
  projects: Project[];
  teams: Team[];
  defaultTeam?: TeamId;
  defaultNamedInterval?: NamedInterval;
  uiEmbed?: boolean;
  fetchMapSettings: FetchMapSettings;
  onBaseChange?: OnBaseChange;
  onAttributeSelected?: OnAttributeSelected;
  onNamedIntervalChange?: OnNamedIntervalChange;
  children: ReactNode;
}

export function MapEmbedTopoMapContextProvider(
  props: MapEmbedTopoMapContextProviderProps
) {
  const {
    schema,
    defaultBase,
    defaultSelectedAttribute,
    defaultExternalSelection,
    workspace,
    project,
    projects,
    teams,
    defaultTeam,
    defaultNamedInterval,
    uiEmbed = false,
    fetchMapSettings,
    onBaseChange,
    onAttributeSelected,
    onNamedIntervalChange,
    children,
  } = props;

  return (
    <MapProvider>
      <MapSchemaContextProvider schema={schema}>
        <FilterContextProvider
          defaultTeam={defaultTeam}
          defaultNamedInterval={defaultNamedInterval}
          onNamedIntervalChange={onNamedIntervalChange}
        >
          <ProjectTeamContextProvider
            workspace={workspace}
            project={project}
            projects={projects}
            teams={teams}
          >
            <MapSettingsContextProvider fetchMapSettings={fetchMapSettings}>
              <MapEmbedLinkContextProvider
                uiEmbed={uiEmbed}
                workspaceRef={workspace.alias}
                projectRef={project.alias}
              >
                <SelectedLayerContextProvider
                  defaultSelectedLayer={
                    defaultSelectedAttribute?.[0] ?? undefined
                  }
                >
                  <LayerVisibilityContextProvider>
                    <VisibleFeaturesContextProvider>
                      <SelectedFeaturesContextProvider
                        defaultExternalSelection={defaultExternalSelection}
                      >
                        <SelectedAttributesContextProvider
                          defaultSelectedAttribute={defaultSelectedAttribute}
                          onAttributeSelected={onAttributeSelected}
                        >
                          <LayerBucketsContextProvider>
                            <BaseStyleContextProvider
                              defaultBase={defaultBase}
                              onBaseChange={onBaseChange}
                            >
                              {children}
                            </BaseStyleContextProvider>
                          </LayerBucketsContextProvider>
                        </SelectedAttributesContextProvider>
                      </SelectedFeaturesContextProvider>
                    </VisibleFeaturesContextProvider>
                  </LayerVisibilityContextProvider>
                </SelectedLayerContextProvider>
              </MapEmbedLinkContextProvider>
            </MapSettingsContextProvider>
          </ProjectTeamContextProvider>
        </FilterContextProvider>
      </MapSchemaContextProvider>
    </MapProvider>
  );
}

interface WebAppTopoMapContextProviderProps {
  schema: MapSchema;
  defaultBase?: MapBase;
  defaultSelectedAttribute?: MapAttributeQueryParam;
  defaultExternalSelection?: Record<MapLayerId, FeatureCollection>;
  workspace: Workspace;
  project: Project;
  projects: Project[];
  teams: Team[] | null;
  defaultTeam?: TeamId;
  defaultNamedInterval?: NamedInterval;
  fetchMapSettings: FetchMapSettings;
  onBaseChange?: OnBaseChange;
  onAttributeSelected?: OnAttributeSelected;
  onNamedIntervalChange?: OnNamedIntervalChange;
  children: ReactNode;
}

export function WebAppTopoMapContextProvider(
  props: WebAppTopoMapContextProviderProps
) {
  const {
    schema,
    defaultBase,
    defaultSelectedAttribute,
    defaultExternalSelection,
    workspace,
    project,
    projects,
    teams,
    defaultTeam,
    defaultNamedInterval,
    fetchMapSettings,
    onBaseChange,
    onAttributeSelected,
    onNamedIntervalChange,
    children,
  } = props;

  return (
    <MapProvider>
      <MapSchemaContextProvider schema={schema}>
        <FilterContextProvider
          defaultTeam={defaultTeam}
          defaultNamedInterval={defaultNamedInterval}
          onNamedIntervalChange={onNamedIntervalChange}
        >
          <ProjectTeamContextProvider
            workspace={workspace}
            project={project}
            projects={projects}
            teams={teams ?? []}
          >
            <MapSettingsContextProvider fetchMapSettings={fetchMapSettings}>
              <WebAppLinkContextProvider
                workspaceRef={workspace.alias}
                projectRef={project.alias}
              >
                <SelectedLayerContextProvider
                  defaultSelectedLayer={
                    defaultSelectedAttribute?.[0] ?? undefined
                  }
                >
                  <LayerVisibilityContextProvider>
                    <VisibleFeaturesContextProvider>
                      <SelectedFeaturesContextProvider
                        defaultExternalSelection={defaultExternalSelection}
                      >
                        <SelectedAttributesContextProvider
                          defaultSelectedAttribute={defaultSelectedAttribute}
                          onAttributeSelected={onAttributeSelected}
                        >
                          <LayerBucketsContextProvider>
                            <BaseStyleContextProvider
                              defaultBase={defaultBase}
                              onBaseChange={onBaseChange}
                            >
                              {children}
                            </BaseStyleContextProvider>
                          </LayerBucketsContextProvider>
                        </SelectedAttributesContextProvider>
                      </SelectedFeaturesContextProvider>
                    </VisibleFeaturesContextProvider>
                  </LayerVisibilityContextProvider>
                </SelectedLayerContextProvider>
              </WebAppLinkContextProvider>
            </MapSettingsContextProvider>
          </ProjectTeamContextProvider>
        </FilterContextProvider>
      </MapSchemaContextProvider>
    </MapProvider>
  );
}
