import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function ShortInvitationPage(
  props: RouteProps<typeof routes.short.invitation>
): ReactElement {
  const {
    path: { invitationId },
    query,
  } = props;

  const apiParams = useApiParams();

  const invitation = useSuspenseQueryData(
    queries.invitation.v3.readOrFail(apiParams, invitationId)
  );

  const workspace = useSuspenseQueryData(
    queries.optional(invitation.workspaceId, workspaceId =>
      queries.workspace.v2.readOrFail(apiParams, workspaceId)
    )
  );

  return workspace != null ? (
    <Navigate
      to={routes.workspace.invitation.view.url(
        [workspace.alias, invitation.id],
        query
      )}
      replace={true}
    />
  ) : (
    <Navigate to={routes.home.url([])} />
  );
}
