import { SelectOption, SelectValue } from "@cartographerio/topo-form";
import { ReactElement } from "react";

import { useControlledSubset } from "../hooks/useSubset";
import Checkbox from "./Checkbox";
import Spaced from "./Spaced";

export interface AssociationItem<V extends SelectValue>
  extends SelectOption<V> {
  disabled?: boolean;
}

interface AssociationsEditorProps<V extends SelectValue> {
  value: V[];
  options: AssociationItem<V>[];
  onChange: (value: V[]) => void;
}

export default function AssociationsEditor<V extends SelectValue>(
  props: AssociationsEditorProps<V>
): ReactElement {
  const { value, options, onChange } = props;

  const { has, toggle } = useControlledSubset(value, onChange);

  return (
    <Spaced spacing="2">
      {options.map(({ label, value, disabled }) => (
        <Checkbox
          key={`${value}`}
          checkboxLabel={label}
          value={has(value)}
          onChange={include => toggle(value, include)}
          disabled={disabled}
        />
      ))}
    </Spaced>
  );
}
