import { checks } from "@cartographerio/permission";
import { Survey } from "@cartographerio/types";
import { ReactElement, useCallback, useEffect, useState } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useIOErrorAlert } from "../../components/Alert";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import RequirePermission from "../../components/RequirePermission";
import { useApiParams, useCurrentUserRef } from "../../contexts/auth";
import useModuleInventory from "../../hooks/useModuleInventory";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useProjectHasTeams } from "../../hooks/useProjectHasTeams";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import { ProjectSurveyBasePage } from "./ProjectSurveyBasePage";

export default function ProjectSurveyCreatePage(
  props: RouteProps<typeof routes.workspace.project.survey.create>
): ReactElement {
  const {
    path: { workspaceRef, projectRef, moduleId },
    query,
    updateQuery,
  } = props;

  const {
    page: defaultPageIndex = 0,
    team: queryTeamRef,
    template: templateId,
  } = query;

  const apiParams = useApiParams();
  const currentUserRef = useCurrentUserRef();
  const errorAlert = useIOErrorAlert();

  const handlePageIndexChange = useCallback(
    (page: number) => {
      updateQuery({ ...query, page });
    },
    [query, updateQuery]
  );

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  const team = useSuspenseQueryData(
    queries.optional(multiTeam ? queryTeamRef : null, teamRef =>
      queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
    )
  );

  const module = useModuleInventory(moduleId);

  useEffect(() => {
    if (team != null && (!multiTeam || !project.teamIds.includes(team.id))) {
      updateQuery({ ...query, team: undefined });
    }
  }, [multiTeam, project.teamIds, query, team, updateQuery]);

  useRedirectWhen(!project.moduleIds.includes(moduleId), () =>
    routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  useRequirePermission(checks.survey.createWithAnyTeam(project));

  usePageTitle(
    `New ${module.names.shortName} Survey - ${project.name} - ${workspace.name}`
  );

  const [defaultSurvey, setDefaultSurvey] = useState<Survey | null>(null);

  // We can't use react-query to load this state as it's effectively a mutation.
  // It assigns a random primary key and saves the survey to the server after creation.
  // We can't let react-query re-run the query
  // otherwise it'll regenerate and resave the new survey.
  useEffect(() => {
    queries.survey.v3
      .blank(
        apiParams,
        module,
        project.id,
        currentUserRef,
        multiTeam ? team?.id : null,
        templateId
      )
      .tap(setDefaultSurvey)
      .tapError(errorAlert)
      .unsafeRun();
  }, [
    apiParams,
    currentUserRef,
    errorAlert,
    module,
    multiTeam,
    project,
    project.id,
    team?.id,
    templateId,
  ]);

  useRedirectWhen(!project.moduleIds.includes(moduleId), () =>
    routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  usePageTitle(
    `New ${module.names.shortName} Survey - ${project.name} - ${workspace.name}`
  );

  return defaultSurvey != null ? (
    <RequirePermission
      failMode="forbid"
      check={checks.survey.createWithTeam(
        project,
        multiTeam
          ? (templateId != null ? defaultSurvey.teamId : team?.id) ?? null
          : null
      )}
    >
      <ProjectSurveyBasePage
        creating={true}
        defaultSurvey={defaultSurvey}
        module={module}
        workspace={workspace}
        project={project}
        defaultPageIndex={defaultPageIndex}
        onPageIndexChange={handlePageIndexChange}
      />
    </RequirePermission>
  ) : (
    <LoadingPlaceholder />
  );
}
