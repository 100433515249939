import { checks } from "@cartographerio/permission";
import { PermissionCheck } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { FunctionComponent, ReactElement } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import queries from "../../queries";
import { Redirect, RouteProps } from "../../routes";
import { AnyPathPart, AnyQueryParams } from "../../routes/base";
import { DeriveOutgoingQueryProps, DerivePathProps } from "../../routes/derive";
import { RedirectProps } from "../../routes/Redirect";
import { Route } from "../../routes/Route";
import CommandBarProvider from "../components/CommandBarProvider";
import ForbidSignin from "../components/ForbidSignin";
import PageLayout from "../components/PageLayout";
import RequirePermission from "../components/RequirePermission";
import RequireSignin from "../components/RequireSignin";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import AccountPage from "../pages/AccountPage";
import AdminBillingListPage from "../pages/admin/AdminBillingListPage";
import AdminHomePage from "../pages/admin/AdminHomePage";
import AdminInvitationCodeCreatePage from "../pages/admin/AdminInvitationCodeCreatePage";
import AdminInvitationCodeListPage from "../pages/admin/AdminInvitationCodeListPage";
import AdminInvitationCodeViewPage from "../pages/admin/AdminInvitationCodeViewPage";
import AdminInvitationCreatePage from "../pages/admin/AdminInvitationCreatePage";
import AdminInvitationListPage from "../pages/admin/AdminInvitationListPage";
import AdminInvitationViewPage from "../pages/admin/AdminInvitationViewPage";
import AdminMemberListPage from "../pages/admin/AdminMemberListPage";
import AdminRcaSetupPage from "../pages/admin/AdminRcaSetupPage";
import AdminRcaWorkspaceStatusListPage from "../pages/admin/AdminRcaWorkspaceStatusListPage";
import AdminUserCreatePage from "../pages/admin/AdminUserCreatePage";
import AdminUserTransferPage from "../pages/admin/AdminUserTransferPage";
import AdminUserUpdatePage from "../pages/admin/AdminUserUpdatePage";
import AdminWorkspaceCreatePage from "../pages/admin/AdminWorkspaceCreatePage";
import AdminWorkspaceListPage from "../pages/admin/AdminWorkspaceListPage";
import AttachmentFolderPage from "../pages/attachment/AttachmentFolderPage";
import AttachmentPage from "../pages/attachment/AttachmentPage";
import ChangeIdentityPage from "../pages/ChangeIdentityPage";
import EmbedAttachmentFolderPage from "../pages/embed/EmbedAttachmentFolderPage";
import EmbedAttachmentPage from "../pages/embed/EmbedAttachmentPage";
import EmbedMapPage from "../pages/embed/EmbedMapPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import FrontPluginPage from "../pages/FrontPluginPage";
import HomePage from "../pages/HomePage";
import InvitationAcceptPage from "../pages/InvitationAcceptPage";
import MapboxStyleViewerPage from "../pages/MapboxStyleViewerPage";
import ProjectHomePage from "../pages/project/ProjectHomePage";
import ProjectMapPage from "../pages/project/ProjectMapPage";
import ProjectSurveyCreatePage from "../pages/project/ProjectSurveyCreatePage";
import ProjectSurveyUpdatePage from "../pages/project/ProjectSurveyUpdatePage";
import ArcgisIntegrationAuthorizePage from "../pages/project/settings/ArcgisIntegrationAuthorize";
import ArcgisIntegrationCreatePage from "../pages/project/settings/ArcgisIntegrationCreatePage";
import ArcgisIntegrationListPage from "../pages/project/settings/ArcgisIntegrationListPage";
import ArcgisIntegrationUpdatePage from "../pages/project/settings/ArcgisIntegrationPage";
import ProjectCreatePage from "../pages/project/settings/ProjectCreatePage";
import ProjectIntegrationsPage from "../pages/project/settings/ProjectIntegrationsPage";
import ProjectInvitationCodeListPage from "../pages/project/settings/ProjectInvitationCodeListPage";
import ProjectInvitationCodeViewPage from "../pages/project/settings/ProjectInvitationCodeViewPage";
import ProjectInvitationListPage from "../pages/project/settings/ProjectInvitationListPage";
import ProjectInvitationViewPage from "../pages/project/settings/ProjectInvitationViewPage";
import ProjectMemberListPage from "../pages/project/settings/ProjectMemberListPage";
import ProjectSettingsPage from "../pages/project/settings/ProjectSettingsPage";
import ProjectSurveyTransferPage from "../pages/project/settings/ProjectSurveyTransferPage";
import ProjectTeamListPage from "../pages/project/settings/ProjectTeamListPage";
import ProjectTransferPage from "../pages/project/settings/ProjectTransferPage";
import ProjectUserUpdatePage from "../pages/project/settings/ProjectUserPage";
import ProjectSurveyListPage from "../pages/project/SurveyListPage";
import QualificationCertificatePage from "../pages/qualification/QualificationCertificatePage";
import QualificationRegisterPage from "../pages/qualification/QualificationRegisterPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import RestoreIdentityPage from "../pages/RestoreIdentityPage";
import InvitationCodeSignupApprovePage from "../pages/short/InvitationCodeSignupApprovePage";
import InvitationCodeSignupRejectPage from "../pages/short/InvitationCodeSignupRejectPage";
import ShortInvitationCodePage from "../pages/short/ShortInvitationCodeUrlPage";
import ShortInvitationPage from "../pages/short/ShortInvitationUrlPage";
import ShortProjectUrlPage from "../pages/short/ShortProjectUrlPage";
import ShortSurveyUrlPage from "../pages/short/ShortSurveyUrlPage";
import { ShortTeamUrlPage } from "../pages/short/ShortTeamUrlPage";
import ShortWorkspaceUrlPage from "../pages/short/ShortWorkspaceUrlPage";
import {
  SigninPage,
  SigninWithCodePage,
  SignupPage,
} from "../pages/SigninPage";
import SignoutPage from "../pages/SignoutPage";
import TeamInvitationCodeListPage from "../pages/team/TeamInvitationCodeListPage";
import TeamInvitationCodeViewPage from "../pages/team/TeamInvitationCodeViewPage";
import TeamInvitationListPage from "../pages/team/TeamInvitationListPage";
import TeamInvitationViewPage from "../pages/team/TeamInvitationViewPage";
import TeamMemberListPage from "../pages/team/TeamMemberListPage";
import TeamSettingsPage from "../pages/team/TeamSettingsPage";
import TeamUserPage from "../pages/team/TeamUserPage";
import WorkspaceBillingCheckoutPage from "../pages/workspace/WorkspaceBillingCheckoutPage";
import WorkspaceBillingDashboardPage from "../pages/workspace/WorkspaceBillingDashboardPage";
import WorkspaceBillingPortalPage from "../pages/workspace/WorkspaceBillingPortalPage";
import WorkspaceBillingSettingsPage from "../pages/workspace/WorkspaceBillingSettingsPage";
import WorkspaceHoldingPage from "../pages/workspace/WorkspaceHoldingPage";
import WorkspaceHomePage from "../pages/workspace/WorkspaceHomePage";
import WorkspaceInvitationCodeCreatePage from "../pages/workspace/WorkspaceInvitationCodeCreatePage";
import WorkspaceInvitationCodeListPage from "../pages/workspace/WorkspaceInvitationCodeListPage";
import WorkspaceInvitationCodeViewPage from "../pages/workspace/WorkspaceInvitationCodeViewPage";
import WorkspaceInvitationCreatePage from "../pages/workspace/WorkspaceInvitationCreatePage";
import WorkspaceInvitationListPage from "../pages/workspace/WorkspaceInvitationListPage";
import WorkspaceInvitationViewPage from "../pages/workspace/WorkspaceInvitationViewPage";
import WorkspaceMemberListPage from "../pages/workspace/WorkspaceMemberListPage";
import WorkspaceProjectListPage from "../pages/workspace/WorkspaceProjectListPage";
import WorkspaceSettingsPage from "../pages/workspace/WorkspaceSettingsPage";
import WorkspaceTeamCreatePage from "../pages/workspace/WorkspaceTeamCreatePage";
import WorkspaceTeamListPage from "../pages/workspace/WorkspaceTeamListPage";
import WorkspaceUserCreatePage from "../pages/workspace/WorkspaceUserCreatePage";
import WorkspaceUserUpdatePage from "../pages/workspace/WorkspaceUserUpdatePage";
import * as path from "./pathParams";
import * as routes from "./routes";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function forbidSignin<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return (
      <ForbidSignin go={props.query.go}>
        <Page {...props} />
      </ForbidSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function requireSignin<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return (
      <RequireSignin>
        <Page {...props} />
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function passRoute<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return (
      <CommandBarProvider>
        <Page {...props} />
      </CommandBarProvider>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function embedRoute<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return <Page {...props} />;
  };
}

interface RouteWithWorkspace {
  path: DerivePathProps<[typeof path.workspaceRef]>;
  query: DeriveOutgoingQueryProps<AnyQueryParams>;
}

function workspaceRoute<A extends RouteWithWorkspace>(
  Page: FunctionComponent<A>,
  showFooter: boolean = true
) {
  return function Wrapper(props: A): ReactElement {
    const {
      path: { workspaceRef },
    } = props;

    const apiParams = useApiParams();
    const accessLevel = useSuspenseQueryData(
      queries.auth.v2.optReadWorkspaceAccess(apiParams, workspaceRef)
    );

    switch (accessLevel?.type) {
      case "WorkspaceAccessUnapproved":
      case "WorkspaceAccessDenied":
        return <Redirect route={routes.workspace.holding} {...props} />;
      case "WorkspaceAccessGranted":
      case undefined: // The user isn't authenticated yet. Let the underlying route handle it.
      case null:
        return (
          <RequireSignin>
            <CommandBarProvider>
              <PageLayout workspaceRef={workspaceRef} showFooter={showFooter}>
                <Page {...props} />
              </PageLayout>
            </CommandBarProvider>
          </RequireSignin>
        );
      default:
        return checkExhausted(accessLevel);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function plainRoute<A extends RouteProps<any>>(
  Page: FunctionComponent<A>,
  showSwitcher: boolean = true,
  showFooter: boolean = true
) {
  return function Wrapper(props: A): ReactElement {
    return (
      <RequireSignin>
        <PageLayout
          workspaceRef={null}
          showSwitcher={showSwitcher}
          showFooter={showFooter}
        >
          <CommandBarProvider>
            <Page {...props} />
          </CommandBarProvider>
        </PageLayout>
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function adminRoute<A extends RouteProps<any>>(
  Page: FunctionComponent<A>,
  check: PermissionCheck = checks.auth.globalAdmin
) {
  return function Wrapper(props: A): ReactElement {
    return (
      <RequireSignin>
        <RequirePermission
          check={check}
          fallback={<Navigate to={routes.home.url([])} />}
        >
          <CommandBarProvider>
            <PageLayout admin={true} workspaceRef={null}>
              <Page {...props} />
            </PageLayout>
          </CommandBarProvider>
        </RequirePermission>
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function frontPluginRoute<A extends RouteProps<any>>(
  Page: FunctionComponent<A>
) {
  return function Wrapper(props: A): ReactElement {
    return (
      <RequireSignin>
        <Page {...props} />
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function redirect<
  P extends AnyPathPart[],
  Q extends AnyQueryParams,
  A extends Omit<RedirectProps<P, Q>, "route">
>(to: Route<P, Q>) {
  return function Wrapper(props: A) {
    return <Redirect route={to} {...props} />;
  };
}

// prettier-ignore
const appRoutes: RouteObject[] = [
  routes.home.to(plainRoute(HomePage, false, true)),
  routes.login.to(redirect(routes.signin)),
  routes.signin.to(forbidSignin(SigninPage)),
  routes.signinWithCode.to(forbidSignin(SigninWithCodePage)),
  routes.logout.to(requireSignin(SignoutPage)),
  routes.signout.to(requireSignin(SignoutPage)),
  routes.password.reset.to(ResetPasswordPage),
  routes.password.forgot.to(forbidSignin(ForgotPasswordPage)),
  routes.signup.to(forbidSignin(SignupPage)),
  routes.account.to(plainRoute(AccountPage)),
  routes.identity.change.to(requireSignin(ChangeIdentityPage)),
  routes.identity.restore.to(requireSignin(RestoreIdentityPage)),
  routes.invitation.accept.to(passRoute(InvitationAcceptPage)),
  routes.embed.map.to(embedRoute(EmbedMapPage)),
  routes.embed.attachment.view.to(embedRoute(EmbedAttachmentPage)),
  routes.embed.attachment.folder.to(embedRoute(EmbedAttachmentFolderPage)),
  routes.qualification.register.to(embedRoute(QualificationRegisterPage)),
  routes.qualification.certificate.to(embedRoute(QualificationCertificatePage)),
  routes.attachment.view.to(requireSignin(AttachmentPage)),
  routes.attachment.folder.to(requireSignin(AttachmentFolderPage)),
  routes.short.survey.to(requireSignin(ShortSurveyUrlPage)),
  routes.short.invitation.to(requireSignin(ShortInvitationPage)),
  routes.short.invitationCode.view.to(requireSignin(ShortInvitationCodePage)),
  routes.short.invitationCode.signup.approve.to(
    requireSignin(InvitationCodeSignupApprovePage)
  ),
  routes.short.invitationCode.signup.reject.to(
    requireSignin(InvitationCodeSignupRejectPage)
  ),
  routes.short.project.to(requireSignin(ShortProjectUrlPage)),
  routes.short.team.to(requireSignin(ShortTeamUrlPage)),
  routes.short.workspace.to(requireSignin(ShortWorkspaceUrlPage)),
  routes.dev.mapboxViewer.to(MapboxStyleViewerPage),
  routes.dev.frontPlugin.to(frontPluginRoute(FrontPluginPage)),
  routes.workspace.home.to(workspaceRoute(WorkspaceHomePage)),
  routes.workspace.holding.to(plainRoute(WorkspaceHoldingPage, false)),
  routes.workspace.settings.to(workspaceRoute(WorkspaceSettingsPage)),
  routes.workspace.billing.settings.to(
    workspaceRoute(WorkspaceBillingSettingsPage)
  ),
  routes.workspace.billing.checkout.to(
    workspaceRoute(WorkspaceBillingCheckoutPage)
  ),
  routes.workspace.billing.dashboard.to(
    workspaceRoute(WorkspaceBillingDashboardPage)
  ),
  routes.workspace.billing.portal.to(
    workspaceRoute(WorkspaceBillingPortalPage)
  ),
  routes.workspace.project.list.to(workspaceRoute(WorkspaceProjectListPage)),
  routes.workspace.project.create.to(workspaceRoute(ProjectCreatePage)),
  routes.workspace.project.home.to(workspaceRoute(ProjectHomePage)),
  routes.workspace.project.settings.to(workspaceRoute(ProjectSettingsPage)),
  routes.workspace.project.integrations.list.to(
    workspaceRoute(ProjectIntegrationsPage)
  ),
  routes.workspace.project.integrations.arcgis.list.to(
    workspaceRoute(ArcgisIntegrationListPage)
  ),
  routes.workspace.project.integrations.arcgis.create.to(
    workspaceRoute(ArcgisIntegrationCreatePage)
  ),
  routes.workspace.project.integrations.arcgis.update.to(
    workspaceRoute(ArcgisIntegrationUpdatePage)
  ),
  routes.workspace.project.integrations.arcgis.authorize.to(
    workspaceRoute(ArcgisIntegrationAuthorizePage)
  ),
  routes.workspace.project.teams.to(workspaceRoute(ProjectTeamListPage)),
  routes.workspace.project.transfer.survey.to(
    workspaceRoute(ProjectSurveyTransferPage)
  ),
  routes.workspace.project.transfer.project.to(
    workspaceRoute(ProjectTransferPage)
  ),
  routes.workspace.project.member.list.to(
    workspaceRoute(ProjectMemberListPage)
  ),
  routes.workspace.project.member.update.to(
    workspaceRoute(ProjectUserUpdatePage)
  ),
  routes.workspace.project.invitation.list.to(
    workspaceRoute(ProjectInvitationListPage)
  ),
  routes.workspace.project.invitation.view.to(
    workspaceRoute(ProjectInvitationViewPage)
  ),
  routes.workspace.project.invitation.code.list.to(
    workspaceRoute(ProjectInvitationCodeListPage)
  ),
  routes.workspace.project.invitation.code.view.to(
    workspaceRoute(ProjectInvitationCodeViewPage)
  ),
  routes.workspace.project.survey.list.to(
    workspaceRoute(ProjectSurveyListPage)
  ),
  routes.workspace.project.survey.create.to(
    workspaceRoute(ProjectSurveyCreatePage)
  ),
  routes.workspace.project.survey.view.to(
    workspaceRoute(ProjectSurveyUpdatePage)
  ),
  routes.workspace.project.map.to(workspaceRoute(ProjectMapPage, false)),
  routes.workspace.team.list.to(workspaceRoute(WorkspaceTeamListPage)),
  routes.workspace.team.create.to(workspaceRoute(WorkspaceTeamCreatePage)),
  routes.workspace.team.settings.to(workspaceRoute(TeamSettingsPage)),
  routes.workspace.team.member.list.to(workspaceRoute(TeamMemberListPage)),
  routes.workspace.team.member.update.to(workspaceRoute(TeamUserPage)),
  routes.workspace.team.invitation.list.to(
    workspaceRoute(TeamInvitationListPage)
  ),
  routes.workspace.team.invitation.view.to(
    workspaceRoute(TeamInvitationViewPage)
  ),
  routes.workspace.team.invitation.code.list.to(
    workspaceRoute(TeamInvitationCodeListPage)
  ),
  routes.workspace.team.invitation.code.view.to(
    workspaceRoute(TeamInvitationCodeViewPage)
  ),
  routes.workspace.member.list.to(workspaceRoute(WorkspaceMemberListPage)),
  routes.workspace.member.create.to(workspaceRoute(WorkspaceUserCreatePage)),
  routes.workspace.member.update.to(workspaceRoute(WorkspaceUserUpdatePage)),
  routes.workspace.invitation.create.to(
    workspaceRoute(WorkspaceInvitationCreatePage)
  ),
  routes.workspace.invitation.view.to(
    workspaceRoute(WorkspaceInvitationViewPage)
  ),
  routes.workspace.invitation.list.to(
    workspaceRoute(WorkspaceInvitationListPage)
  ),
  routes.workspace.invitation.code.create.to(
    workspaceRoute(WorkspaceInvitationCodeCreatePage)
  ),
  routes.workspace.invitation.code.list.to(
    workspaceRoute(WorkspaceInvitationCodeListPage)
  ),
  routes.workspace.invitation.code.view.to(
    workspaceRoute(WorkspaceInvitationCodeViewPage)
  ),
  routes.workspace.catchAll.to(redirect(routes.workspace.home)),
  routes.admin.home.to(adminRoute(AdminHomePage)),
  routes.admin.workspace.list.to(adminRoute(AdminWorkspaceListPage)),
  routes.admin.workspace.create.to(adminRoute(AdminWorkspaceCreatePage)),
  routes.admin.billing.list.to(
    adminRoute(AdminBillingListPage, checks.billing.global)
  ),
  routes.admin.member.list.to(adminRoute(AdminMemberListPage)),
  routes.admin.member.create.to(adminRoute(AdminUserCreatePage)),
  routes.admin.member.view.to(adminRoute(AdminUserUpdatePage)),
  routes.admin.invitation.list.to(adminRoute(AdminInvitationListPage)),
  routes.admin.invitation.create.to(adminRoute(AdminInvitationCreatePage)),
  routes.admin.invitation.view.to(adminRoute(AdminInvitationViewPage)),
  routes.admin.invitation.code.list.to(adminRoute(AdminInvitationCodeListPage)),
  routes.admin.invitation.code.create.to(
    adminRoute(AdminInvitationCodeCreatePage)
  ),
  routes.admin.invitation.code.view.to(adminRoute(AdminInvitationCodeViewPage)),
  routes.admin.transfer.user.to(adminRoute(AdminUserTransferPage)),
  routes.admin.rcaSetup.to(adminRoute(AdminRcaSetupPage)),
  routes.admin.rcaWorkspaceStatusList.to(
    adminRoute(AdminRcaWorkspaceStatusListPage)
  ),
  routes.admin.catchAll.to(redirect(routes.admin.home)),
  routes.catchAll.to(redirect(routes.home)),
];

export default function AppRoutes(): ReactElement | null {
  const routes = useRoutes(appRoutes);
  return <div className="AppRoutes">{routes}</div>;
}
