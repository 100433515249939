import { Workspace } from "@cartographerio/types";
import { Box, HStack, SystemProps, VStack, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

import { REACT_APP_VERSION } from "../../../sentry";
import Link from "../Link";

interface FooterProps extends SystemProps {
  workspace?: Workspace | null;
}

export default function Footer(props: FooterProps): ReactElement {
  const { workspace, py = "12", color = "gray.400", ...rest } = props;

  return (
    <chakra.footer py={py} px="4" color={color} {...rest}>
      <VStack spacing="2">
        {workspace?.operator != null && (
          <Box textAlign="center">
            Workspace operated by{" "}
            {workspace.homepage == null ? (
              workspace.operator
            ) : (
              <Link.External
                tabIndex={-1}
                color={color}
                to={workspace.homepage}
              >
                {workspace.operator}
              </Link.External>
            )}
            {workspace.privacyPolicy != null && (
              <Link.External
                tabIndex={-1}
                ml="2"
                color="blue.400"
                to={workspace.privacyPolicy}
              >
                Privacy
              </Link.External>
            )}
          </Box>
        )}
        <VStack spacing="0">
          <Box>
            Cartographer {REACT_APP_VERSION} &copy;{" "}
            <Link.External
              tabIndex={-1}
              color={color}
              to="https://cartographer.io"
            >
              Cartographer Studios Ltd
            </Link.External>
          </Box>
          <HStack>
            <Box>
              <Link.External
                tabIndex={-1}
                color="blue.400"
                to="https://cartographer.io/legal/platform-terms/"
              >
                Terms
              </Link.External>
            </Box>
            <Box>
              <Link.External
                tabIndex={-1}
                color="blue.400"
                to="https://cartographer.io/legal/privacy-policy/"
              >
                Privacy
              </Link.External>
            </Box>
            <Box>
              <Link.External
                tabIndex={-1}
                color="blue.400"
                to="https://cartographer.io/legal/cookie-policy/"
              >
                Cookies
              </Link.External>
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </chakra.footer>
  );
}
