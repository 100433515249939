import { ReactElement } from "react";
import { FaPhone } from "react-icons/fa";

import Link, { ExternalLinkProps } from "./Link";

interface CallUsLinkProps extends ExternalLinkProps {}

export default function CallUsLink(props: CallUsLinkProps): ReactElement {
  return (
    <Link.External
      to="tel:442030955339"
      display="flex"
      flexDirection="row"
      alignItems="center"
      color="inherit"
      gap="1"
      {...props}
    >
      <FaPhone />
      020 309 55339
    </Link.External>
  );
}
