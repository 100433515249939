import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Attachment,
  AttachmentCreate,
  AttachmentFolder,
  AttachmentId,
  SurveyId,
  internalError,
  isNotFoundError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { useQueryClient } from "@tanstack/react-query";
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { AttachmentGalleryItem } from "../../../galleryItem";
import queries from "../../../queries";

type SearchFunc = (folder: AttachmentFolder) => IO<AttachmentGalleryItem[]>;
type CreateFunc = (attachment: AttachmentCreate, file: File) => IO<Attachment>;
type DeleteFunc = (id: AttachmentId) => IO<void>;

export interface AttachmentFieldContext {
  searchAttachments: SearchFunc;
  createAttachment: CreateFunc;
  deleteAttachment: DeleteFunc;
  attachmentUrl?: (id: AttachmentId) => string;
  attachmentFolderUrl?: (
    surveyId: SurveyId,
    folder: AttachmentFolder
  ) => string;
}

export const AttachmentFieldContext =
  createContext<AttachmentFieldContext | undefined>(undefined);

export function useAttachmentFieldContext(): AttachmentFieldContext {
  return (
    useContext(AttachmentFieldContext) ??
    raise(internalError("AttachmentFieldContext not available"))
  );
}

interface AttachmentFieldContextProviderProps {
  apiParams: ApiParams;
  surveyId: SurveyId;
  attachmentUrl?: (id: AttachmentId) => string;
  attachmentFolderUrl?: (
    surveyId: SurveyId,
    folder: AttachmentFolder
  ) => string;
  children: ReactNode;
}

export function AttachmentFieldContextProvider(
  props: AttachmentFieldContextProviderProps
): ReactElement {
  const { apiParams, surveyId, attachmentUrl, attachmentFolderUrl, children } =
    props;

  const queryClient = useQueryClient();

  const searchAttachments = useCallback<SearchFunc>(
    folder =>
      queries.attachment.v3
        .searchGalleryItemsIO(apiParams, surveyId, folder)
        .partialRecover(isNotFoundError, () => []),
    [apiParams, surveyId]
  );

  const createAttachment = useCallback<CreateFunc>(
    (attachment, file) => {
      return queries.attachment.v3.create(
        queryClient,
        apiParams,
        attachment,
        file
      );
    },
    [apiParams, queryClient]
  );

  const deleteAttachment = useCallback<DeleteFunc>(
    id => {
      return queries.attachment.v3.remove(queryClient, apiParams, id);
    },
    [apiParams, queryClient]
  );

  const context: AttachmentFieldContext = useMemo(
    () => ({
      searchAttachments,
      createAttachment,
      deleteAttachment,
      attachmentUrl,
      attachmentFolderUrl,
    }),
    [
      searchAttachments,
      createAttachment,
      deleteAttachment,
      attachmentUrl,
      attachmentFolderUrl,
    ]
  );

  return (
    <AttachmentFieldContext.Provider value={context}>
      {children}
    </AttachmentFieldContext.Provider>
  );
}
