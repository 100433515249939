import { Message, SurveyCompleteEmailSettings } from "@cartographerio/types";
import { Card, CardBody, FormControl, chakra } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { surveyCompleteEmailSettingsErrorKeys } from "../../../schema/emailSettings";
import { splitMessages } from "../../../schema/rule/errors";
import CardTitle from "../CardTitle";
import Checkbox from "../Checkbox";
import ListEditor from "../ListEditor";
import Spaced from "../Spaced";

export interface SurveyCompleteEmailSettingsEditorProps {
  group: "project" | "team";
  value: SurveyCompleteEmailSettings;
  onChange: (value: SurveyCompleteEmailSettings) => void;
  messages: Message[];
  disabled?: boolean;
}

export function SurveyCompleteEmailSettingsEditor(
  props: SurveyCompleteEmailSettingsEditorProps
): ReactElement {
  const { group, value, onChange, messages, disabled = false } = props;

  const errors = useMemo(
    () => splitMessages(messages, surveyCompleteEmailSettingsErrorKeys),
    [messages]
  );

  return (
    <Card>
      <CardTitle>Survey Complete Emails</CardTitle>
      <CardBody>
        <Spaced maxW="72ch">
          <chakra.p>
            When a surveyor marks a survey as <em>complete</em>:
          </chakra.p>
          <Spaced spacing="2">
            <FormControl>
              <Checkbox
                checkboxLabel={`Send an email to approvers and coordinators in the ${group}`}
                value={value.toApprovers}
                onChange={toApprovers => onChange({ ...value, toApprovers })}
                disabled={disabled}
              />
            </FormControl>
            <FormControl>
              <ListEditor.NamedEmailCheckbox
                checkboxLabel="Send an email to the following email addresses:"
                value={value.toAddresses}
                onChange={toAddresses => onChange({ ...value, toAddresses })}
                disabled={disabled}
              />
            </FormControl>
          </Spaced>
          <Spaced spacing="4" ml="6">
            <ListEditor.NamedEmail
              value={value.toAddresses}
              onChange={toAddresses => onChange({ ...value, toAddresses })}
              messages={errors.toAddresses}
              disabled={disabled}
            />
          </Spaced>
        </Spaced>
      </CardBody>
    </Card>
  );
}
