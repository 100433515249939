import {
  SurveySearchKeyV3,
  SurveySearchOptionsV3,
  endpoints,
} from "@cartographerio/client";
import { checks } from "@cartographerio/permission";
import { errorMessage } from "@cartographerio/topo-core";
import { SelectOption, SelectSection } from "@cartographerio/topo-form";
import {
  ProjectId,
  SurveyId,
  SurveyModuleId,
  SurveySummary,
  TeamId,
  WorkspaceId,
  isSurveyTransferRequest,
} from "@cartographerio/types";
import { filterAndMap, tuple } from "@cartographerio/util";
import {
  Button,
  FormControl,
  Input,
  SimpleGrid,
  chakra,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { DEFAULT_PAGE_SIZE } from "../../../../../config";
import queries from "../../../../../queries";
import { RouteProps, RouteQueryProps } from "../../../../../routes";
import { splitMessages } from "../../../../../schema/rule/errors";
import {
  PartialSurveyTransferRequest,
  surveyTransferRequestErrorKeys,
  surveyTransferRequestRule,
} from "../../../../../schema/SurveyTransferRequest";
import recordWithId from "../../../../../util/recordWithId";
import { useIOAlert, useIOErrorAlert } from "../../../../components/Alert";
import Container from "../../../../components/Container";
import FormLabel from "../../../../components/FormLabel";
import JsonView from "../../../../components/JsonView";
import MessageFormControl from "../../../../components/MessageFormControl";
import MessageList from "../../../../components/MessageList";
import PageTopBar from "../../../../components/PageTopBar";
import Placeholder from "../../../../components/Placeholder";
import RadioGroup from "../../../../components/RadioGroup";
import SearchResultsList from "../../../../components/SearchResultsList";
import Select from "../../../../components/Select";
import Spaced from "../../../../components/Spaced";
import TransferList from "../../../../components/TransferList";
import { useApiParams } from "../../../../contexts/auth";
import { useEffectOnce } from "../../../../hooks/useEffectOnce";
import useModuleInventory from "../../../../hooks/useModuleInventory";
import { usePageTitle } from "../../../../hooks/usePageTitle";
import useRequirePermission from "../../../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../../hooks/useSuspenseSearchResults";
import { useVolatileState } from "../../../../hooks/useVolatileState";
import { routes } from "../../../../routes";
import SurveyListToolbar from "../../SurveyListPage/SurveyListToolbar";
import ProjectPageHeader from "../ProjectPageHeader";
import surveyListColumns from "./columns";

type SurveySearchOpts = RouteQueryProps<
  typeof routes.workspace.project.survey.list
> & {
  module: SurveyModuleId;
};

export default function ProjectSurveyTransferPage(
  props: RouteProps<typeof routes.workspace.project.transfer.survey>
): ReactElement {
  const {
    path: { workspaceRef: srcWorkspaceRef, projectRef: srcProjectRef },
  } = props;
  const apiParams = useApiParams();

  useRequirePermission(checks.auth.globalAdmin);

  const srcProject = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, srcProjectRef, srcWorkspaceRef)
  );

  const srcWorkspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, srcProject.workspaceId)
  );

  const allSrcTeams = useSuspenseSearchResults(
    queries.team.v2.forWorkspace(apiParams, srcWorkspace.id)
  );

  usePageTitle(`Survey Transfer - ${srcProject.name} - ${srcWorkspace.name}`);

  const [value, setValue] = useState<PartialSurveyTransferRequest>(() => ({
    srcProject: srcProject.id,
    srcModule: null,
    desProject: null,
    desModule: null,
    copy: false,
    surveys: [],
    teamMappings: [],
  }));

  const [selection, setSelection] = useState<SurveySummary[]>([]);

  const [surveyOpts, setSurveyOpts] = useVolatileState<SurveySearchOpts | null>(
    useCallback(
      () =>
        value.srcModule != null
          ? {
              workspace: srcWorkspace.id,
              module: value.srcModule,
              page: 0,
              count: DEFAULT_PAGE_SIZE,
            }
          : null,
      [srcWorkspace.id, value.srcModule]
    )
  );

  const [desWorkspace, setDesWorkspace] = useState<WorkspaceId | undefined>();

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const workspaceOptions: SelectOption<WorkspaceId>[] = useMemo(
    () => workspaces.map(({ name, id }) => ({ label: name, value: id })),
    [workspaces]
  );

  const { data: allDesProjects } = useQuery(
    queries.optional(desWorkspace, workspace =>
      queries.project.v2.forWorkspace(apiParams, workspace)
    )
  );

  const desProjectOptions: SelectOption<ProjectId>[] | null = useMemo(
    () =>
      allDesProjects?.results.map(({ name, id }) => ({
        label: name,
        value: id,
      })) ?? null,
    [allDesProjects?.results]
  );

  const moduleInventory = useModuleInventory();

  const srcModuleOptions: SelectOption<SurveyModuleId>[] | null =
    useMemo(() => {
      const srcModuleIds = new Set(srcProject.moduleIds);
      return moduleInventory
        .surveyModules()
        .filter(module => srcModuleIds.has(module.moduleId))
        .map(({ moduleId, names: { shortName } }) => ({
          label: shortName,
          value: moduleId,
        }));
    }, [moduleInventory, srcProject.moduleIds]);

  useEffectOnce(() => {
    if (srcModuleOptions.length === 1) {
      setValue({ ...value, srcModule: srcModuleOptions[0].value });
    }
  });

  const desModuleOptions: SelectOption<SurveyModuleId>[] | null =
    useMemo(() => {
      const desProject = allDesProjects?.results.find(
        project => project.id === value.desProject
      );
      if (desProject != null) {
        const desModuleIds = new Set(desProject.moduleIds);
        return moduleInventory
          .surveyModules()
          .filter(module => desModuleIds.has(module.moduleId))
          .map(({ moduleId, names: { shortName } }) => ({
            label: shortName,
            value: moduleId,
          }));
      } else {
        return null;
      }
    }, [allDesProjects?.results, value.desProject, moduleInventory]);

  const { data, error } = useQuery(
    queries.optional(surveyOpts, ({ module, page, count, ...rest }) =>
      queries.survey.v3.searchSummaries(apiParams, module, {
        workspace: srcWorkspace.id,
        project: srcProject.id,
        skip: page * count,
        limit: count,
        ...rest,
      })
    )
  );

  const [selectionTeamIds, setSelectionTeamIds] = useState(
    new Set<TeamId | null>()
  );

  const srcTeamOptions: SelectOption<TeamId | null>[] = useMemo(
    () =>
      [
        { label: "Surveys with no team", value: null },
        ...allSrcTeams.map(team => ({ label: team.name, value: team.id })),
      ].filter(({ value }) => selectionTeamIds.has(value)),
    [allSrcTeams, selectionTeamIds]
  );

  const allDesTeams = useQuery(
    queries.optional(desWorkspace, workspace =>
      queries.team.v2.forWorkspace(apiParams, workspace)
    )
  ).data?.results;

  useEffect(() => {
    if (allDesTeams != null) {
      const desTeamIds = new Set(allDesTeams.map(({ id }) => id));

      setValue(value => ({
        ...value,
        teamMappings: value.teamMappings.map(([from, to]) => [
          from,
          to != null && desTeamIds.has(to) ? to : null,
        ]),
      }));
    }
  }, [allDesTeams]);

  const desTeamSections: SelectSection<TeamId | null>[] | null = useMemo(() => {
    const desProject = allDesProjects?.results.find(
      project => project.id === value.desProject
    );
    return allDesTeams == null || desProject == null
      ? null
      : allDesTeams
          .map(team => ({ label: team.name, value: team.id }))
          .reduce(
            (acc, option) => {
              const index = desProject.teamIds.includes(option.value) ? 1 : 2;
              acc.splice(index, 1, {
                ...acc[index],
                options: acc[index].options.concat(option),
              });
              return acc;
            },
            [
              { options: [{ label: "No team", value: null }] },
              { heading: "Associated Teams", options: [] },
              { heading: "Other Teams", options: [] },
            ] as SelectSection<TeamId | null>[]
          )
          .filter(({ options }) => options.length > 0);
  }, [allDesProjects?.results, allDesTeams, value.desProject]);

  const messages = useMemo(() => surveyTransferRequestRule(value), [value]);
  const errors = useMemo(
    () => splitMessages(messages, surveyTransferRequestErrorKeys),
    [messages]
  );

  const queryClient = useQueryClient();
  const alert = useIOAlert();
  const errorAlert = useIOErrorAlert();

  const handleChange = useCallback(
    (newValue: PartialSurveyTransferRequest) => {
      // Making sure there's no error state
      if (value.srcModule !== newValue.srcModule) {
        newValue.surveys = [];
        setSurveyOpts(null);
      }

      if (value.desProject !== newValue.desProject) {
        newValue.desModule = null;
        newValue.teamMappings = newValue.teamMappings.map(([from]) =>
          tuple(from, newValue.srcProject === newValue.desProject ? from : null)
        );
      }

      setValue(newValue);
    },
    [setSurveyOpts, value.desProject, value.srcModule]
  );

  const handleDesWorkspaceChange = useCallback(
    (workspaceId: WorkspaceId) => {
      setDesWorkspace(workspaceId);
      handleChange({ ...value, desProject: null });
    },
    [handleChange, value]
  );

  const [allSelected, setAllSelected] = useState(false);
  const [allResults, setAllResults] = useState<SurveySummary[] | null>(null);

  const allResultsOpts = useMemo<Partial<SurveySearchOptionsV3>>(
    () => ({
      project: srcProject.id,
      ...omit(surveyOpts ?? {}, ["page", "count"]),
    }),
    [srcProject.id, surveyOpts]
  );

  useEffect(() => {
    setAllSelected(false);
    setAllResults(null);
  }, [allResultsOpts]);

  const handleSelectionChange = useCallback((surveys: SurveySummary[]) => {
    const selectionTeamIds = new Set(
      surveys.map(({ teamId }) => teamId ?? null)
    );

    setSelection(surveys);
    setSelectionTeamIds(selectionTeamIds);
    setValue(value => ({
      ...value,
      surveys: surveys.map(recordWithId),
      teamMappings: value.teamMappings
        .filter(([from]) => selectionTeamIds.has(from))
        .concat(
          filterAndMap([...selectionTeamIds], teamId =>
            value.teamMappings.find(([from]) => from === teamId) == null
              ? tuple(
                  teamId,
                  value.srcProject === value.desProject ? teamId : null
                )
              : null
          )
        ),
    }));
  }, []);

  const onSelectItem = useCallback(
    (survey: SurveySummary) => {
      const filtered = selection.filter(({ id }) => id !== survey.id);
      handleSelectionChange(
        selection.length === filtered.length ? [...selection, survey] : filtered
      );
      setAllSelected(false);
    },
    [handleSelectionChange, selection]
  );

  const onSelectAll = useCallback(() => {
    if (allSelected) {
      setAllSelected(false);
      handleSelectionChange([]);
    } else if (allResults != null) {
      setAllSelected(true);
      handleSelectionChange(allResults);
    } else if (surveyOpts?.module != null) {
      endpoints.survey.v3
        .searchSummaries(apiParams, surveyOpts.module, allResultsOpts)
        .tap(({ results }) => {
          setAllResults(results);
          setAllSelected(true);
          handleSelectionChange(results);
        })
        .unsafeRun();
    }
  }, [
    allResults,
    allResultsOpts,
    allSelected,
    apiParams,
    handleSelectionChange,
    surveyOpts?.module,
  ]);

  const onSelectById = useCallback(
    (surveyIds: SurveyId[]) => {
      const idsSet = new Set(surveyIds);
      if (allResults != null) {
        handleSelectionChange([
          ...new Set([
            ...selection,
            ...allResults.filter(({ id }) => idsSet.has(id)),
          ]),
        ]);
      } else if (surveyOpts?.module != null) {
        endpoints.survey.v3
          .searchSummaries(apiParams, surveyOpts.module, allResultsOpts)
          .tap(({ results }) => {
            setAllResults(results);
            handleSelectionChange([
              ...new Set([
                ...selection,
                ...results.filter(({ id }) => idsSet.has(id)),
              ]),
            ]);
          })
          .unsafeRun();
      }
    },
    [
      allResults,
      allResultsOpts,
      apiParams,
      handleSelectionChange,
      selection,
      surveyOpts?.module,
    ]
  );

  const [submitting, setSubmitting] = useState(false);
  const submittable = useMemo(
    () =>
      isSurveyTransferRequest(value) && !submitting && messages.length === 0,
    [messages.length, submitting, value]
  );

  const onSave = useCallback(() => {
    if (isSurveyTransferRequest(value)) {
      setSubmitting(true);
      queries.transfer.v2
        .survey(queryClient, apiParams, value)
        .tap(result => {
          setSubmitting(false);
          const anyFailures = result.surveys.failed.length > 0;

          return alert({
            title: anyFailures
              ? "Transfer Partially Complete"
              : "Transfer Complete",
            message: (
              <>
                <chakra.p>The following transfers were successful:</chakra.p>
                <JsonView
                  maxH="20em"
                  overflow="auto"
                  value={result.surveys.successful}
                />
                {anyFailures && (
                  <>
                    <chakra.p>The following transfers failed:</chakra.p>
                    <JsonView
                      maxH="20em"
                      overflow="auto"
                      value={
                        <JsonView
                          overflow="auto"
                          value={result.surveys.failed}
                        />
                      }
                    />
                  </>
                )}
              </>
            ),
          });
        })
        .tap(result =>
          handleChange({
            ...value,
            surveys: value.surveys.filter(
              id => !result.surveys.successful.includes(id)
            ),
          })
        )
        .tapError(() => setSubmitting(false))
        .tapError(errorAlert)
        .unsafeRun();
    } else {
      alert({
        title: "Oops!",
        message: (
          <>
            <chakra.p>The request data wasn&apos;t complete:</chakra.p>
            <JsonView overflow="auto" value={value} />
          </>
        ),
      }).unsafeRun();
    }
  }, [alert, apiParams, errorAlert, handleChange, queryClient, value]);

  const columns = useMemo(
    () => surveyListColumns({ teams: allSrcTeams }),
    [allSrcTeams]
  );

  return (
    <>
      <PageTopBar
        workspace={srcWorkspace}
        workspacePage="projects"
        project={srcProject}
        projectPage="transfer-survey"
      />
      <ProjectPageHeader
        workspace={srcWorkspace}
        project={srcProject}
        selected="transfer-survey"
      />
      <Container width="wide">
        <Spaced spacing="4">
          <chakra.p fontSize="sm">
            Use this tool to move or copy surveys and attachments to another
            project. Surveyors will not be moved.
          </chakra.p>
          <SimpleGrid columns={[1, null, 3]} gap="2">
            <FormControl>
              <FormLabel text="Source Workspace" />
              <Input
                value={srcWorkspace.name}
                disabled={true}
                fontStyle="italic"
              />
            </FormControl>
            <FormControl>
              <FormLabel text="Source Project" />
              <Input
                value={srcProject.name}
                disabled={true}
                fontStyle="italic"
              />
            </FormControl>
            <MessageFormControl
              label="Source Module"
              messages={errors.srcModule}
            >
              <Select.Nullable
                value={value.srcModule}
                options={srcModuleOptions}
                onChange={srcModule => handleChange({ ...value, srcModule })}
              />
            </MessageFormControl>
            <FormControl isInvalid={desWorkspace == null}>
              <FormLabel text="Destination Workspace" />
              <Select.Searchable
                value={desWorkspace}
                options={workspaceOptions}
                onChange={desWorkspace =>
                  handleDesWorkspaceChange(desWorkspace)
                }
                minMatchCharLength={0}
                debounce={500}
              />
              <MessageList
                messages={
                  desWorkspace == null
                    ? [errorMessage("You must select a destination workspace")]
                    : undefined
                }
              />
            </FormControl>
            <MessageFormControl
              label="Destination Project"
              messages={errors.desProject}
            >
              <Select.Nullable
                value={value.desProject ?? null}
                options={desProjectOptions ?? []}
                onChange={desProject => handleChange({ ...value, desProject })}
                disabled={desProjectOptions == null}
              />
            </MessageFormControl>
            <MessageFormControl
              label="Destination Module"
              messages={errors.desModule}
            >
              <Select.Nullable
                value={value.desModule ?? null}
                options={desModuleOptions ?? []}
                onChange={desModule => handleChange({ ...value, desModule })}
                disabled={desModuleOptions == null}
              />
            </MessageFormControl>
          </SimpleGrid>

          <FormLabel text="Move or copy" />
          <RadioGroup
            value={value.copy}
            options={[
              { label: "Move the surveys", value: false },
              { label: "Copy the surveys", value: true },
            ]}
            onChange={copy => setValue({ ...value, copy })}
          />

          <FormLabel text="Surveys" />
          {surveyOpts != null ? (
            <>
              <SurveyListToolbar
                query={surveyOpts}
                updateQuery={query =>
                  setSurveyOpts({ ...surveyOpts, ...query })
                }
                module={surveyOpts.module}
                workspace={srcWorkspace}
                project={srcProject}
                onSearchById={onSelectById}
              />
              <SearchResultsList<SurveySummary, SurveySearchKeyV3, SurveyId>
                results={data}
                error={error}
                columns={columns}
                count={surveyOpts.count}
                page={surveyOpts.page}
                topPagination={true}
                onPageChange={page => setSurveyOpts({ ...surveyOpts, page })}
                order={surveyOpts.order}
                onOrderChange={order => setSurveyOpts({ ...surveyOpts, order })}
                itemKey={recordWithId}
                selection={selection}
                onSelectItem={onSelectItem}
                allSelected={allSelected}
                onSelectAll={onSelectAll}
              />
            </>
          ) : (
            <Placeholder text="Please select a source and destination first" />
          )}
          <FormLabel text="Team Memberships" />
          {desWorkspace != null && srcTeamOptions.length > 0 ? (
            <TransferList<TeamId | null>
              srcOptions={srcTeamOptions}
              desSections={desTeamSections ?? []}
              value={value.teamMappings}
              onChange={teamMappings =>
                handleChange({ ...value, teamMappings })
              }
            />
          ) : (
            <Placeholder
              text={
                srcTeamOptions.length === 0 && value.teamMappings.length > 0
                  ? "No teams found for the selected surveys."
                  : srcTeamOptions.length === 0
                  ? "Please select some surveys first."
                  : "Please select a destination for the transfer first."
              }
            />
          )}
          <FormControl>
            <FormLabel text="API Request Body" />
            <JsonView value={value} copyToClipboard={true} maxH="50vh" />
          </FormControl>
          <Button
            colorScheme="blue"
            onClick={onSave}
            isLoading={submitting}
            loadingText="Transferring"
            isDisabled={!submittable}
          >
            Transfer!
          </Button>
        </Spaced>
      </Container>
    </>
  );
}
