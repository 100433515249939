import { PermissionCheckRunner, checks } from "@cartographerio/permission";
import {
  InvitationCode,
  InvitationCodeSignup,
  KnownUserFeatureEnum,
  Message,
  Qualification,
  QualificationHistoryEvent,
  QualificationId,
  QualificationRegisterEntry,
  QualificationRegisterSettings,
  User,
  UserFeature,
  WorkspaceId,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { ButtonGroup, IconButton, Table, Td, Th, Tr } from "@chakra-ui/react";
import outdent from "outdent";
import { Dispatch, ReactElement, SetStateAction, useMemo } from "react";
import { IoCheckmarkSharp, IoStopCircleOutline } from "react-icons/io5";

import { splitMessages } from "../../schema/rule/errors";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { useControlledSubset } from "../hooks/useSubset";
import AdminContent from "./AdminContent";
import Checkbox from "./Checkbox";
import Fieldset from "./Fieldset";
import { useApproveCallback } from "./MemberList";
import { ApproveRejectCallback } from "./MemberList/column";
import Placeholder from "./Placeholder";
import QualificationsEditor from "./QualificationsEditor";
import RoleEditor from "./RoleEditor";
import Spaced from "./Spaced";
import TestTarget from "./TestTarget";
import UserDetailsEditor from "./UserDetailsEditor";

export interface InternalQualificationHistoryEvent {
  event: QualificationHistoryEvent;
  fromRoleSelect: boolean;
}

export function toInternalQualificationHistoryEvent(
  event: QualificationHistoryEvent
): InternalQualificationHistoryEvent {
  return { event, fromRoleSelect: false };
}

export function fromInternalQualificationHistoryEvent({
  event,
}: InternalQualificationHistoryEvent): QualificationHistoryEvent {
  return event;
}

interface UserEditorProps {
  myAccount: boolean;
  value: User;
  invitationCodes?: InvitationCode[] | null;
  invitationCodeSignups?: InvitationCodeSignup[] | null;
  qualifications: Qualification[];
  defaultQualificationHistories: Record<
    QualificationId,
    QualificationHistoryEvent[]
  >;
  qualificationHistories: Record<QualificationId, QualificationHistoryEvent[]>;
  qualificationRegisterSettings: Record<
    QualificationId,
    QualificationRegisterSettings
  >;
  qualificationRegisterEntries: Record<
    QualificationId,
    QualificationRegisterEntry
  >;
  workspaceGraph: WorkspaceGraph;
  messages?: Message[];
  onChange?: Dispatch<SetStateAction<User>>;
  onQualificationHistoryChange?: Dispatch<
    SetStateAction<Record<QualificationId, QualificationHistoryEvent[]>>
  >;
  onQualificationRegisterSettingsChange?: Dispatch<
    SetStateAction<Record<QualificationId, QualificationRegisterSettings>>
  >;
  defaultWorkspace?: WorkspaceId;
  disabled?: boolean;
  roleEditorSingleWorkspace?: boolean;
}

export default function UserEditor(props: UserEditorProps): ReactElement {
  const {
    myAccount,
    value,
    invitationCodes,
    invitationCodeSignups,
    qualifications,
    defaultQualificationHistories,
    qualificationHistories,
    qualificationRegisterSettings,
    qualificationRegisterEntries,
    workspaceGraph,
    messages,
    onChange,
    onQualificationHistoryChange,
    onQualificationRegisterSettingsChange,
    defaultWorkspace,
    disabled = false,
    roleEditorSingleWorkspace,
  } = props;

  const errors = useMemo(() => splitMessages(messages, ["roles"]), [messages]);

  const { has: hasFeature, toggle: toggleFeature } = useControlledSubset(
    value.features,
    features => onChange?.({ ...value, features })
  );

  return (
    <Spaced spacing="8">
      <Fieldset legend="Account Details">
        <UserDetailsEditor
          value={value}
          onChange={onChange}
          messages={errors._rest_}
          disabled={disabled}
        />
      </Fieldset>

      {invitationCodes != null && invitationCodeSignups != null && (
        <Fieldset
          legend="Invitation Code History"
          help={
            myAccount
              ? MY_ACCOUNT_INVITATION_CODES_HELP
              : STANDARD_INVITATION_CODES_HELP
          }
        >
          <UserEditorSignupList
            myAccount={myAccount}
            codes={invitationCodes}
            signups={invitationCodeSignups}
          />
        </Fieldset>
      )}

      <AdminContent fallback={<TestTarget testId="features-disabled" />}>
        <Fieldset legend="Features">
          <Spaced spacing="1">
            {KnownUserFeatureEnum.entries
              .filter(({ value }) => checks.user.editFeature(value))
              .map(({ value, label }) => (
                <Checkbox
                  key={value}
                  checkboxLabel={label}
                  checkboxHelp={FEATURE_HELP[value]}
                  value={hasFeature(value)}
                  onChange={checked => toggleFeature(value, checked)}
                  disabled={disabled || FEATURE_DISABLED[value]}
                />
              ))}
          </Spaced>
        </Fieldset>
      </AdminContent>

      <Fieldset
        legend="Qualifications"
        help={
          myAccount
            ? MY_ACCOUNT_QUALIFICATIONS_HELP
            : STANDARD_QUALIFICATIONS_HELP
        }
      >
        <QualificationsEditor
          myAccount={myAccount}
          userId={value.id}
          qualifications={qualifications}
          defaultHistories={defaultQualificationHistories}
          histories={qualificationHistories}
          settings={qualificationRegisterSettings}
          entries={qualificationRegisterEntries}
          onHistoriesChange={onQualificationHistoryChange}
          onSettingsChange={onQualificationRegisterSettingsChange}
          disabled={disabled}
        />
      </Fieldset>

      <Fieldset
        legend="Roles"
        help={myAccount ? MY_ACCOUNT_ROLES_HELP : STANDARD_ROLES_HELP}
      >
        <RoleEditor
          value={value.roles}
          onChange={roles => onChange?.({ ...value, roles })}
          messages={errors.roles}
          workspaceGraph={workspaceGraph}
          defaultWorkspace={defaultWorkspace}
          disabled={disabled || myAccount}
          singleWorkspace={roleEditorSingleWorkspace}
        />
      </Fieldset>
    </Spaced>
  );
}

const FEATURE_HELP: Record<UserFeature, string> = {
  IntegrationTest: outdent`
    An automatically created user that will be deleted and recreated
    along with other integration testing data.
  `,
  NonBillable: outdent`
    The user does not count towards billing-related limits or costs.
  `,
};

const FEATURE_DISABLED: Record<UserFeature, boolean> = {
  IntegrationTest: true,
  NonBillable: false,
};

const MY_ACCOUNT_QUALIFICATIONS_HELP = outdent`
  Qualifications are records of training you have received.
  They are required to interact with certain special projects.
`;

const STANDARD_QUALIFICATIONS_HELP = outdent`
  Qualifications are records of training the user has received.
  They are required to interact with certain special projects.
`;

const MY_ACCOUNT_INVITATION_CODES_HELP = outdent`
  Invitation codes are a way of signing up to new workspaces.
  If you have used any codes, they will appear here.
`;

const STANDARD_INVITATION_CODES_HELP = outdent`
  Invitation codes are a way of letting people sign up to join your workspaces.
  If the user has used any codes, you can approve/reject them here.
`;

const MY_ACCOUNT_ROLES_HELP = outdent`
  Roles determine what your Cartographer account is able to do.
  [Read more](https://help.cartographer.io/roles-and-permissions).
`;

const STANDARD_ROLES_HELP = outdent`
  Roles determine what the user can do.
  [Read more](https://help.cartographer.io/roles-and-permissions).
`;

interface UserEditorSignupListProps {
  myAccount: boolean;
  codes: InvitationCode[];
  signups: InvitationCodeSignup[];
}

function UserEditorSignupList(props: UserEditorSignupListProps) {
  const { myAccount, codes, signups } = props;

  const pairs = useMemo(
    () =>
      signups.flatMap(signup => {
        const code = codes.find(code => code.id === signup.invitationCodeId);
        return code == null ? [] : [{ signup, code }];
      }),
    [codes, signups]
  );

  const permissionCheckPasses = usePermissionCheckRunner();

  const handleApprove = useApproveCallback();
  const handleReject = useApproveCallback();

  return pairs.length === 0 ? (
    <Placeholder
      text={
        myAccount
          ? "You haven't used any invitation codes"
          : "The user hasn't used any invitation codes"
      }
    />
  ) : (
    <Table bg="white" rounded="md" shadow="sm" fontSize="sm">
      <Tr>
        <Th px="4" py="3" w="0">
          Code
        </Th>
        <Th px="4" py="3" w="0">
          Date Used
        </Th>
        <Th px="4" py="3" w="0">
          Status
        </Th>
        <Th px="4" py="3" w="0"></Th>
      </Tr>
      {pairs.map(({ signup, code }, index) => (
        <UserEditorSignupRow
          key={index}
          signup={signup}
          code={code}
          onApprove={handleApprove}
          onReject={handleReject}
          permissionCheckPasses={permissionCheckPasses}
        />
      ))}
    </Table>
  );
}

interface UserEditorSignupRowProps {
  code: InvitationCode;
  signup: InvitationCodeSignup;
  onApprove: ApproveRejectCallback;
  onReject: ApproveRejectCallback;
  permissionCheckPasses: PermissionCheckRunner;
}

function UserEditorSignupRow(props: UserEditorSignupRowProps) {
  const { code, signup, onApprove, onReject, permissionCheckPasses } = props;

  const isRejected = signup.rejected != null;
  const isApproved = signup.approved != null;
  const isPending = !isApproved && !isRejected;

  const canApproveOrReject = useMemo(
    () =>
      isPending &&
      permissionCheckPasses(
        checks.invitationCode.approveOrRejectSignup(
          code.workspaceId ?? null,
          code.alias,
          code.roles,
          code.qualificationRoles
        )
      ),
    [
      code.alias,
      code.qualificationRoles,
      code.roles,
      code.workspaceId,
      permissionCheckPasses,
      isPending,
    ]
  );

  const status = useMemo(() => {
    switch (true) {
      case isApproved:
        return `Approved`;
      case isRejected:
        return `Rejected`;
      default:
        return `Pending`;
    }
  }, [isApproved, isRejected]);

  return (
    <Tr>
      <Td w="0" px="4" py="3" whiteSpace="nowrap">
        {code.name}
      </Td>
      <Td w="0" px="4" py="3">
        {formatTimestamp(signup.created, { format: ddmmyyyy })}
      </Td>
      <Td w="0" px="4" py="3">
        {status}
      </Td>
      <Td w="0" px="4" py="3" p="0">
        {isPending && canApproveOrReject && (
          <ButtonGroup size="sm">
            <IconButton
              key="approve"
              variant="outline"
              title="Approve Signup"
              aria-label="Approve Signup"
              icon={<IoCheckmarkSharp size="1.25rem" />}
              onClick={evt => {
                evt.stopPropagation();
                onApprove({
                  invitationCode: signup.invitationCodeId,
                  user: signup.userId,
                });
              }}
            />
            <IconButton
              key="reject"
              variant="outline"
              title="Reject Signup"
              aria-label="Reject Signup"
              icon={<IoStopCircleOutline size="1.25rem" />}
              onClick={evt => {
                evt.stopPropagation();
                onReject({
                  invitationCode: signup.invitationCodeId,
                  user: signup.userId,
                });
              }}
            />
          </ButtonGroup>
        )}
      </Td>
    </Tr>
  );
}
