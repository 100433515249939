import {
  PartialParams,
  ProjectSearchOptions,
  UrlParts,
  contentAs,
  endpoints,
  fetch,
} from "@cartographerio/client";
import {
  ApiParams,
  MapVisibility,
  Project,
  ProjectId,
  ProjectRef,
  SearchResults,
  UserId,
  WorkspaceId,
  WorkspaceRef,
  isProject,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../base";

export type ProjectKey =
  | ["project"]
  | ["project", "v2", "read", ProjectRef, WorkspaceRef | undefined]
  | ["project", "v2", "search", PartialParams<ProjectSearchOptions>];

export function search(
  apiParams: ApiParams,
  options: PartialParams<ProjectSearchOptions>
): UseQueryOpts<SearchResults<Project>, ProjectKey> {
  return {
    queryKey: ["project", "v2", "search", options],
    queryFn: () => endpoints.project.v2.search(apiParams, options).unsafeRun(),
  };
}

export function forWorkspace(
  apiParams: ApiParams,
  workspace: WorkspaceId,
  maps?: MapVisibility
): UseQueryOpts<SearchResults<Project>, ProjectKey> {
  return search(apiParams, { workspace, maps });
}

export function all(
  apiParams: ApiParams
): UseQueryOpts<SearchResults<Project>, ProjectKey> {
  return search(apiParams, {});
}

export function visible(
  apiParams: ApiParams,
  user: UserId,
  workspace?: WorkspaceRef | null
): UseQueryOpts<SearchResults<Project>, ProjectKey> {
  return search(apiParams, { user, workspace });
}

export function readOrFail(
  apiParams: ApiParams,
  projectId: ProjectId
): UseQueryOpts<Project, ProjectKey>;
export function readOrFail(
  apiParams: ApiParams,
  projectRef: ProjectRef,
  workspaceRef?: WorkspaceRef
): UseQueryOpts<Project, ProjectKey>;
export function readOrFail(
  apiParams: ApiParams,
  projectRef: ProjectRef,
  workspaceRef?: WorkspaceRef
): UseQueryOpts<Project, ProjectKey> {
  const url: UrlParts = {
    path: `/project/v2/${projectRef}`,
    query: { workspace: workspaceRef },
  };

  return {
    queryKey: ["project", "v2", "read", projectRef, workspaceRef],
    queryFn: () =>
      fetch
        .get({
          apiParams,
          url,
        })
        .flatMap(contentAs("Project", isProject))
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  project: Project
) {
  return endpoints.project.v2.save(apiParams, project).tap(_ => {
    queryClient.invalidateQueries(["project"]);
    queryClient.invalidateQueries(["team"]);
  });
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  projectId: ProjectId
) {
  return endpoints.project.v2.remove(apiParams, projectId).tap(_ => {
    queryClient.invalidateQueries(["project"]);
    queryClient.invalidateQueries(["team"]);
  });
}
