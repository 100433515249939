import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { RouteProps } from "../../routes";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import {
  completeSignoutAction,
  useAccessTokenCookie,
  useAuthContext,
  useOptCredentials,
} from "../contexts/auth";
import { usePageTitle } from "../hooks/usePageTitle";
import { routes } from "../routes";

export default function SignoutPage(
  props: RouteProps<typeof routes.signout | typeof routes.logout>
): ReactElement {
  const {
    query: { go = routes.home.url([]) },
  } = props;
  const navigate = useNavigate();
  const credentials = useOptCredentials();

  usePageTitle("Signout");

  const [_authCookie, _setAuthCookie, clearAuthCookie] = useAccessTokenCookie();

  const { dispatch: authDispatch } = useAuthContext();

  useEffect(() => {
    if (credentials == null) {
      navigate(routes.signin.url([], { go }));
    } else {
      clearAuthCookie();
      authDispatch(completeSignoutAction());
      navigate(go, { replace: true });
    }
  }, [authDispatch, clearAuthCookie, credentials, go, navigate]);

  return <LoadingPlaceholder label="Signing you out..." />;
}
