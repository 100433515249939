import { Result } from "@cartographerio/fp";
import {
  CheckResetPasswordTokenResult,
  unsafeResetPasswordToken,
  unsafeUnencryptedPassword,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Box,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  VStack,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import CartographerLogo from "../components/CartographerLogo";
import FormLabel from "../components/FormLabel";
import Link from "../components/Link";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import Para from "../components/Para";
import PasswordField from "../components/PasswordField";
import Spaced from "../components/Spaced";
import { useApiConfig } from "../contexts/apiConfig";
import {
  completeInitialiseAction,
  useAuthContext,
  useOptCredentials,
} from "../contexts/auth";
import { usePageTitle } from "../hooks/usePageTitle";
import { routes } from "../routes";

export function isPasswordStrong(pwd: string): boolean {
  return [/.{6,}/g, /[a-z]/g, /[A-Z]/g, /\d/g, /[^a-z0-9]/gi].every(regex =>
    regex.test(pwd)
  );
}

export default function ResetPasswordPage(
  props: RouteProps<typeof routes.password.reset>
): ReactElement {
  const {
    query: { token },
  } = props;

  usePageTitle("Reset Password");

  const toast = useToast();
  const navigate = useNavigate();
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();

  const { dispatch: authDispatch } = useAuthContext();

  const [checkResult, setCheckResult] =
    useState<CheckResetPasswordTokenResult | null>(null);
  const [newPassword, setNewPassword] = useState(unsafeUnencryptedPassword(""));
  const [newPassword2, setNewPassword2] = useState(
    unsafeUnencryptedPassword("")
  );
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPasswords, setShowPasswords] = useState(false);

  const credentials = useOptCredentials();

  const handleSubmit = useCallback(() => {
    if (newPassword !== newPassword2) {
      setPasswordError(
        "Passwords don't match! Make sure you re-enter the exact same password."
      );
    } else if (!isPasswordStrong(newPassword)) {
      setPasswordError(
        "Passwords must be at least six characters and contain upper and lower case letters, numbers, and punctuation."
      );
    } else if (token != null) {
      queries.auth.v2
        .resetPassword(queryClient, apiConfig, {
          token: unsafeResetPasswordToken(token),
          password: unsafeUnencryptedPassword(newPassword),
        })
        .tap(result => {
          switch (result.type) {
            case "ResetPasswordSignedIn": {
              authDispatch(
                completeInitialiseAction(Result.pass(result.credentials.token))
              );
              toast({
                title: "Password reset",
                description:
                  "Reset your password and automatically signed you in",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              navigate(routes.home.url([]));
              break;
            }
            case "ResetPasswordSignInRequired": {
              toast({
                title: "Password reset",
                description: "Please sign in using your new password",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              navigate(routes.signin.url([]));
              break;
            }
            case "ResetPasswordFailed": {
              toast({
                title: "Error when resetting password",
                description:
                  result.result === "invalid"
                    ? "Invalid password"
                    : "Reset password token has expired. Please try again",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
              break;
            }
            default:
              return checkExhausted(result);
          }
        })
        .unsafeRun();
    }
  }, [
    apiConfig,
    authDispatch,
    navigate,
    newPassword,
    newPassword2,
    queryClient,
    toast,
    token,
  ]);

  useEffect(() => {
    if (token === undefined) {
      setCheckResult("invalid");
    } else {
      queries.auth.v2
        .checkPasswordToken(apiConfig, unsafeResetPasswordToken(token))
        .tap(({ result }) => setCheckResult(result))
        .recover(() => setCheckResult("invalid"))
        .unsafeRun();
    }
  }, [apiConfig, token]);

  return (
    <Flex
      w="100vw"
      minH="100vh"
      direction="column"
      justify="center"
      align="center"
      bg="gray.50"
    >
      <Container maxW="50ch">
        {checkResult === "valid" ? (
          <chakra.form
            onSubmit={evt => {
              evt.preventDefault();
              handleSubmit();
            }}
          >
            <VStack spacing="8" alignItems="stretch">
              <Box as="header">
                <CartographerLogo />
              </Box>
              <Para textAlign="center" fontSize="lg">
                Please enter your new password
              </Para>
              <Box bg="white" p="8" shadow="md" rounded="md">
                <Spaced spacing="8">
                  <FormControl id="new-password">
                    <FormLabel text="Enter your new password" />
                    <PasswordField
                      value={newPassword}
                      onChange={setNewPassword}
                      showPassword={showPasswords}
                      onShowPasswordChange={setShowPasswords}
                    />
                  </FormControl>

                  <FormControl
                    id="new-password2"
                    isInvalid={passwordError != null}
                  >
                    <FormLabel text="Re-enter your new password" />
                    <PasswordField
                      value={newPassword2}
                      onChange={setNewPassword2}
                      showPassword={showPasswords}
                      onShowPasswordChange={setShowPasswords}
                    />
                    {passwordError != null && (
                      <FormErrorMessage>{passwordError}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Button type="submit" colorScheme="blue" label="Submit" />
                </Spaced>
              </Box>

              <Box h="24" />
            </VStack>
          </chakra.form>
        ) : checkResult != null ? (
          <VStack spacing="8">
            <Box as="header">
              <CartographerLogo />
            </Box>

            {credentials != null ? (
              <>
                <Para textAlign="center">You are currently signed in.</Para>
                <ButtonLink.Internal
                  to={routes.signout.url([], {
                    go: routes.password.reset.url(props.path, props.query),
                  })}
                >
                  Sign Out
                </ButtonLink.Internal>
              </>
            ) : checkResult === "invalid" ? (
              <>
                <Para textAlign="center" fontSize="lg">
                  Sorry! This web address is invalid.
                </Para>
                <Para textAlign="center">
                  Make sure you copy/paste the full URL from your email.
                </Para>
              </>
            ) : (
              <>
                <Para textAlign="center" fontSize="lg">
                  Sorry! This web address has expired.
                </Para>
                <Para textAlign="center">
                  <Link.Internal to={routes.signin.url([])}>
                    Return to the signin page
                  </Link.Internal>{" "}
                  and use the <em>Forgot password</em> link to send yourself a
                  new link.
                </Para>
              </>
            )}
            <Box h="24" />
          </VStack>
        ) : (
          <LoadingPlaceholder label="Loading..." />
        )}
      </Container>
    </Flex>
  );
}
