import { MapLayer, MapLayerId, MapSchema } from "@cartographerio/topo-map";
import { raise } from "@cartographerio/util";
import { ReactNode, createContext, useContext } from "react";

const MapSchemaContext = createContext<MapSchema | undefined>(undefined);

export function useMapSchema(): MapSchema {
  return (
    useContext(MapSchemaContext) ??
    raise(new Error("No current topo map context"))
  );
}

export function useMapLayers(): MapLayer[] {
  return useMapSchema().layers;
}

export function useMapLayer(
  layerId: MapLayerId | undefined
): MapLayer | undefined {
  return useMapSchema().layers.find(layer => layer.layerId === layerId);
}

interface MapSchemaContextProviderProps {
  schema: MapSchema;
  children: ReactNode;
}

export function MapSchemaContextProvider(props: MapSchemaContextProviderProps) {
  const { schema, children } = props;

  return (
    <MapSchemaContext.Provider value={schema}>
      {children}
    </MapSchemaContext.Provider>
  );
}
