import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  WorkspaceBillingSettings,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type BillingSettingsKey =
  | ["billing", "workspace", "settings"]
  | ["billing", "workspace", "settings", "v1", "read", WorkspaceRef];

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceBillingSettings, BillingSettingsKey> {
  return {
    queryKey: ["billing", "workspace", "settings", "v1", "read", workspaceRef],
    queryFn: () =>
      endpoints.billing.settings.v1
        .readOrFail(apiParams, workspaceRef)
        .unsafeRun(),
  };
}

export function update(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  settings: WorkspaceBillingSettings
): IO<WorkspaceBillingSettings> {
  return (
    endpoints.billing.settings.v1
      .update(apiParams, workspaceRef, settings)
      // Invalidating all of billing since the plan calculations also change
      .tap(_ => queryClient.invalidateQueries(["billing"]))
  );
}
