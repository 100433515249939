import { endpoints, formatUrl } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Attachment,
  AttachmentCreate,
  AttachmentFolder,
  AttachmentId,
  AttachmentUpdate,
  AttachmentVariant,
  ContentType,
  SearchResults,
  SurveyId,
  attachmentVariant,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import {
  AttachmentGalleryItem,
  attachmentGalleryItem,
} from "../../galleryItem";
import { UseQueryOpts } from "../base";

export function isImageContentType(contentType: ContentType) {
  return ["image/pjpeg", "image/jpeg", "image/gif", "image/png"].includes(
    contentType
  );
}

export function attachmentUrl(
  apiParams: ApiParams,
  id: AttachmentId,
  variant: AttachmentVariant = attachmentVariant.original
): string {
  return formatUrl(apiParams.apiConfig.baseUrl, {
    path: `/attachment/v3/${id}/${variant.id}`,
    query: {
      authorization:
        apiParams.auth?.type === "BearerAuth" ? apiParams.auth.token : "",
    },
  });
}

export type AttachmentKey =
  | ["attachment"]
  | ["attachment", "v3", "search", SurveyId, AttachmentFolder | null]
  | [
      "attachment",
      "v3",
      "searchGalleryItems",
      SurveyId,
      AttachmentFolder | null
    ]
  | ["attachment", "v3", "read", AttachmentId];

export function searchGalleryItems(
  apiParams: ApiParams,
  surveyId: SurveyId,
  folder: AttachmentFolder | null = null
): UseQueryOpts<AttachmentGalleryItem[], AttachmentKey> {
  return {
    queryKey: ["attachment", "v3", "searchGalleryItems", surveyId, folder],
    queryFn: () =>
      searchGalleryItemsIO(apiParams, surveyId, folder).unsafeRun(),
  };
}

export function searchGalleryItemsIO(
  apiParams: ApiParams,
  surveyId: SurveyId,
  folder: AttachmentFolder | null
): IO<AttachmentGalleryItem[]> {
  return endpoints.attachment.v3
    .search(apiParams, surveyId, { folder: folder ?? undefined })
    .flatMap(search =>
      IO.parallel(
        search.results.map(attach =>
          IO.when(
            endpoints.attachment.v3.resolveThumbnail(apiParams, attach.id),
            endpoints.attachment.v3.resolvePreview(apiParams, attach.id),
            endpoints.attachment.v3.resolve(apiParams, attach.id)
          )((thumbnail, preview, original) =>
            isImageContentType(attach.contentType)
              ? attachmentGalleryItem(
                  attach,
                  thumbnail.url,
                  preview.url,
                  original.url
                )
              : attachmentGalleryItem(attach, null, null, original.url)
          )
        )
      )
    );
}

export function search(
  apiParams: ApiParams,
  surveyId: SurveyId,
  folder: AttachmentFolder | null
): UseQueryOpts<SearchResults<Attachment>, AttachmentKey> {
  return {
    queryKey: ["attachment", "v3", "search", surveyId, folder],
    queryFn: () =>
      endpoints.attachment.v3
        .search(apiParams, surveyId, {
          folder: folder ?? undefined,
        })
        .unsafeRun(),
  };
}

export function readGalleryItem(
  apiParams: ApiParams,
  attachmentId: AttachmentId
): UseQueryOpts<AttachmentGalleryItem, AttachmentKey> {
  return {
    queryKey: ["attachment", "v3", "read", attachmentId],
    queryFn: () => readGalleryItemIO(apiParams, attachmentId).unsafeRun(),
  };
}

export function readGalleryItemIO(
  apiParams: ApiParams,
  attachmentId: AttachmentId
): IO<AttachmentGalleryItem> {
  return endpoints.attachment.v3
    .readOrFail(apiParams, attachmentId)
    .flatMap(attach =>
      IO.when(
        endpoints.attachment.v3.resolveThumbnail(apiParams, attach.id),
        endpoints.attachment.v3.resolvePreview(apiParams, attach.id),
        endpoints.attachment.v3.resolve(apiParams, attach.id)
      )((thumbnail, preview, original) =>
        isImageContentType(attach.contentType)
          ? attachmentGalleryItem(
              attach,
              thumbnail.url,
              preview.url,
              original.url
            )
          : attachmentGalleryItem(attach, null, null, original.url)
      )
    );
}

export function create(
  queryClient: QueryClient,
  apiParams: ApiParams,
  attachmentCreate: AttachmentCreate,
  file: File
): IO<Attachment> {
  return endpoints.attachment.v3
    .create(apiParams, attachmentCreate, file)
    .tap(() => queryClient.invalidateQueries(["attachment"]));
}

export function update(
  queryClient: QueryClient,
  apiParams: ApiParams,
  attachmentId: AttachmentId,
  attachmentUpdate: AttachmentUpdate
): IO<Attachment> {
  return endpoints.attachment.v3
    .update(apiParams, attachmentId, attachmentUpdate)
    .tap(() => queryClient.invalidateQueries(["attachment"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  attachmentId: AttachmentId
): IO<void> {
  return endpoints.attachment.v3
    .remove(apiParams, attachmentId)
    .tap(() => queryClient.invalidateQueries(["attachment"]));
}
