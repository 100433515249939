import { MapInventory, productionMaps } from "@cartographerio/inventory-maps";
import { MapSchema } from "@cartographerio/topo-map";
import { MapId, notFoundError } from "@cartographerio/types";
import { useEffect, useMemo } from "react";

import { unsafePublishToWindow } from "../../util";

export default function useMapInventory(): MapInventory;
export default function useMapInventory(map: MapId): MapSchema;
export default function useMapInventory(map?: MapId): MapInventory | MapSchema {
  const maps = useMemo(() => productionMaps(), []);

  useEffect(() => {
    unsafePublishToWindow(maps, "maps", "inventory");
  }, [maps]);

  return useMemo(
    () =>
      map != null
        ? maps.mapById(map).getOrThrow(() => notFoundError("map", map))
        : maps,
    [map, maps]
  );
}
