import { SortOrder } from "@cartographerio/client";
import { Checkbox, SystemProps, Th, Tr, theme } from "@chakra-ui/react";
import { ReactElement } from "react";

import HelpPopover from "../HelpPopover";
import { Column } from "./column";
import SortIcon from "./SortIcon";

interface HeaderRowProps<A, O extends string> {
  selectable: boolean;
  allSelected?: boolean;
  toggleAllSelected?: () => void;
  columns: Column<A, O>[];
  hasActions?: boolean;
  rowProps: SystemProps;
  cellProps: SystemProps[];
  sortOrder?: SortOrder<O>;
  onSort: (col: Column<A, O>) => void;
}

export default function HeaderRow<A, O extends string>(
  props: HeaderRowProps<A, O>
): ReactElement {
  const {
    selectable,
    allSelected,
    toggleAllSelected,
    columns,
    hasActions,
    rowProps,
    cellProps,
    sortOrder,
    onSort,
  } = props;

  return (
    <Tr {...rowProps}>
      {selectable && allSelected != null && (
        <Th py="3" width="0">
          <Checkbox
            zIndex={theme.zIndices.sticky - 1}
            isChecked={allSelected}
            onChange={toggleAllSelected}
          />
        </Th>
      )}
      {columns.map((column, colIndex) => (
        <Th
          key={colIndex}
          {...cellProps[colIndex]}
          cursor={column.orderBy == null ? undefined : "pointer"}
          py="3"
          onClick={() => onSort(column)}
        >
          {column.title}
          {column.help != null && <HelpPopover text={column.help} />}
          <SortIcon sortId={column.orderBy} sortOrder={sortOrder} />
        </Th>
      ))}
      {hasActions && <Th py="3"></Th>}
    </Tr>
  );
}
