import { Message, WorkspaceJoinerEmailSettings } from "@cartographerio/types";
import { Card, CardBody, FormControl, chakra } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { workspaceJoinerEmailSettingsErrorKeys } from "../../../schema/emailSettings";
import { splitMessages } from "../../../schema/rule/errors";
import CardTitle from "../CardTitle";
import Checkbox from "../Checkbox";
import ListEditor from "../ListEditor";
import Spaced from "../Spaced";

export interface WorkspaceJoinerEmailSettingsEditorProps {
  value: WorkspaceJoinerEmailSettings;
  onChange: (value: WorkspaceJoinerEmailSettings) => void;
  messages: Message[];
  disabled?: boolean;
}

export function WorkspaceJoinerEmailSettingsEditor(
  props: WorkspaceJoinerEmailSettingsEditorProps
): ReactElement {
  const { value, onChange, messages, disabled = false } = props;

  const errors = useMemo(
    () => splitMessages(messages, workspaceJoinerEmailSettingsErrorKeys),
    [messages]
  );

  return (
    <Card>
      <CardTitle>Joiner Emails</CardTitle>
      <CardBody>
        <Spaced maxW="72ch">
          <chakra.p>When a new user joins the workspace...</chakra.p>
          <FormControl>
            <Checkbox
              checkboxLabel={`Send a notification email to workspace administrators`}
              value={value.toAdmins}
              onChange={toAdmins => onChange({ ...value, toAdmins })}
              disabled={disabled}
            />
          </FormControl>
          <FormControl>
            <ListEditor.NamedEmailCheckbox
              checkboxLabel="Send an email to the following email addresses:"
              value={value.toAddresses}
              onChange={toAddresses => onChange({ ...value, toAddresses })}
              disabled={disabled}
            />
          </FormControl>
          <Spaced spacing="4" ml="6">
            <ListEditor.NamedEmail
              value={value.toAddresses}
              onChange={toAddresses => onChange({ ...value, toAddresses })}
              messages={errors.toAddresses}
              disabled={disabled}
            />
          </Spaced>
        </Spaced>
      </CardBody>
    </Card>
  );
}
