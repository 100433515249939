import { Project } from "@cartographerio/types";
import { chakra } from "@chakra-ui/react";

import ProjectVisibilityIcon from "./ProjectVisibilityIcon";

export interface ProjectLabelProps {
  project: Project;
  suffix?: string;
}

export default function ProjectLabel(props: ProjectLabelProps) {
  const { project, suffix } = props;

  return (
    <chakra.span display="inline-flex" alignItems="center" gap="0.5ex">
      {project.name} {suffix}{" "}
      <ProjectVisibilityIcon visibility={project.projectVisibility} />
    </chakra.span>
  );
}
