import {
  Attachment,
  AttachmentFolder,
  SurveyId,
  attachmentVariant,
} from "@cartographerio/types";
import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo } from "react";

import { attachmentGalleryItem } from "../../galleryItem";
import queries from "../../queries";
import { absoluteRouteUrl } from "../../util";
import { useApiParams } from "../contexts/auth";
import { useVolatileState } from "../hooks/useVolatileState";
import { routes } from "../routes";
import GalleryItemModal from "./GalleryItemModal";
import MetadataGrid from "./GalleryItemModal/MetadataGrid";

interface CarouselProgress {
  survey: SurveyId;
  folder: AttachmentFolder;
  attach: Attachment;
  attachIndex: number;
  folderIndex: number;
  lastOffset: number | null;
}

export interface GalleryPosition {
  survey: SurveyId;
  folder: AttachmentFolder;
  attach: Attachment;
  attachIndex: number;
}

interface FolderGalleryModalProps {
  galleryPosition: GalleryPosition;
  folders: Array<[SurveyId, AttachmentFolder]>;
  isOpen: boolean;
  disabled?: boolean;
  absoluteUrls?: boolean;
  onClose: () => void;
}

export default function FolderGalleryModal(
  props: FolderGalleryModalProps
): ReactElement {
  const {
    galleryPosition,
    folders,
    isOpen,
    disabled,
    absoluteUrls = false,
    onClose,
  } = props;

  const apiParams = useApiParams();

  const [carouselProgress, setCarouselProgress] =
    useVolatileState<CarouselProgress>(
      useCallback(
        () => ({
          ...galleryPosition,
          folderIndex: Math.max(
            folders.findIndex(path =>
              isEqual(path, [galleryPosition.survey, galleryPosition.folder])
            ),
            0
          ),
          lastOffset: null,
        }),
        [folders, galleryPosition]
      )
    );

  const attachments = useQuery(
    queries.attachment.v3.search(
      apiParams,
      carouselProgress.survey,
      carouselProgress.folder
    )
  ).data?.results;

  // Only manages the current index of the carousel, not setting the attachment
  // Goes to the next/prev folder if the current folder has ran out of images
  const offsetIndex = useCallback(
    (
      carouselProgress: CarouselProgress,
      attachments: Attachment[],
      offset: number
    ): Partial<CarouselProgress> => {
      const attachIndex = carouselProgress.attachIndex + offset;
      if (0 <= attachIndex && attachIndex < attachments.length) {
        return {
          attachIndex: carouselProgress.attachIndex + 1,
          lastOffset: offset,
        };
      } else {
        // Making sure the index is valid and circular
        const folderIndex = posMod(
          carouselProgress.folderIndex + Math.sign(offset),
          folders.length
        );
        return {
          survey: folders[folderIndex][0],
          folder: folders[folderIndex][1],
          folderIndex,
          attachIndex: offset >= 0 ? 0 : -1,
          lastOffset: offset,
        };
      }
    },
    [folders]
  );

  const onNextClick = useCallback(
    () =>
      attachments != null
        ? setCarouselProgress({
            ...carouselProgress,
            ...offsetIndex(carouselProgress, attachments, 1),
          })
        : null,
    [attachments, carouselProgress, offsetIndex, setCarouselProgress]
  );
  const onPrevClick = useCallback(
    () =>
      attachments != null
        ? setCarouselProgress({
            ...carouselProgress,
            ...offsetIndex(carouselProgress, attachments, -1),
          })
        : null,
    [attachments, carouselProgress, offsetIndex, setCarouselProgress]
  );

  // In charge of setting the current attachment if the index has changed
  // The only way the index would have changed is if lastOffset is not null (aka 1 or -1)
  //
  // If there aren't any attachments in the current folder, it'll change to the next/prev
  //  folder, using offsetIndex, since it offsets, and then only runs when it's retrieved
  //  the next set of attachments (attachments != null) also entirely using react-query,
  //  so everything is cached.
  useEffect(() => {
    if (attachments != null && carouselProgress.lastOffset != null) {
      if (attachments.length > 0) {
        const attachIndex = posMod(
          carouselProgress.attachIndex,
          attachments.length
        );
        setCarouselProgress({
          ...carouselProgress,
          attachIndex,
          attach: attachments[attachIndex],
          lastOffset: null,
        });
      } else {
        setCarouselProgress({
          ...carouselProgress,
          ...offsetIndex(
            carouselProgress,
            attachments,
            carouselProgress.lastOffset
          ),
        });
      }
    }
  }, [attachments, carouselProgress, offsetIndex, setCarouselProgress]);

  const galleryItem = useMemo(
    () =>
      attachmentGalleryItem(
        carouselProgress.attach,
        queries.attachment.v3.attachmentUrl(
          apiParams,
          carouselProgress.attach.id,
          attachmentVariant.largeThumbnail
        ),
        queries.attachment.v3.attachmentUrl(
          apiParams,
          carouselProgress.attach.id,
          attachmentVariant.preview
        ),
        queries.attachment.v3.attachmentUrl(
          apiParams,
          carouselProgress.attach.id,
          attachmentVariant.original
        )
      ),
    [apiParams, carouselProgress.attach]
  );

  return (
    <GalleryItemModal
      isOpen={isOpen}
      onClose={onClose}
      item={galleryItem}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
      disabled={disabled}
      attachmentUrl={
        absoluteUrls
          ? id => absoluteRouteUrl(routes.attachment.view.url([id]))
          : id => routes.attachment.view.url([id])
      }
    >
      <MetadataGrid item={galleryItem} mx="2" />
    </GalleryItemModal>
  );
}

function posMod(a: number, b: number) {
  return ((a % b) + b) % b;
}
