import { Message, unsafeEmail } from "@cartographerio/types";
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { splitMessages } from "../../../../schema/rule/errors";
import MessageFormControl from "../../../components/MessageFormControl";
import TextField from "../../../components/TextField";
import { Person, personErrorKeys } from "./schema";

interface PersonSectionProps extends Omit<SimpleGridProps, "onChange"> {
  value: Person;
  messages: Message[];
  onChange: (value: Person) => void;
}

export function PersonSection(props: PersonSectionProps): ReactElement {
  const { value, messages, onChange, ...rest } = props;

  const errors = useMemo(
    () => splitMessages(messages, personErrorKeys),
    [messages]
  );

  return (
    <SimpleGrid columns={[1, null, 3]} gap="2" {...rest}>
      <MessageFormControl label="First Name" messages={errors.firstName}>
        <TextField.String
          value={value.firstName}
          onChange={firstName => onChange({ ...value, firstName })}
        />
      </MessageFormControl>
      <MessageFormControl label="Last Name" messages={errors.lastName}>
        <TextField.String
          value={value.lastName}
          onChange={lastName => onChange({ ...value, lastName })}
        />
      </MessageFormControl>
      <MessageFormControl label="Email" messages={errors.email}>
        <TextField.String
          value={value.email}
          onChange={email => onChange({ ...value, email: unsafeEmail(email) })}
        />
      </MessageFormControl>
    </SimpleGrid>
  );
}
