import { MapBase } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { useVolatileState } from "../../hooks/useVolatileState";
import { useMapSchema } from "./MapSchemaContext";

interface BaseStyleContextValue {
  base: MapBase;
  setBase: (styleId: MapBase) => void;
}

const BaseStyleContext =
  createContext<BaseStyleContextValue | undefined>(undefined);

export function useMapBaseContext(): BaseStyleContextValue {
  return (
    useContext(BaseStyleContext) ??
    raise(new Error("No current topo map context"))
  );
}

export function useBaseStyle(): [MapBase, (styleId: MapBase) => void] {
  const { base: baseStyleId, setBase: setBaseStyleId } = useMapBaseContext();
  return [baseStyleId, setBaseStyleId];
}

export function useBaseStyleUrl(): string {
  const { baseStyles } = useMapSchema();
  const { base: baseStyleId } = useMapBaseContext();
  return baseStyles[baseStyleId].url;
}

export type OnBaseChange = (base: MapBase) => void;

interface BaseStyleContextProviderProps {
  defaultBase?: MapBase;
  onBaseChange?: OnBaseChange;
  children: ReactNode;
}

export function BaseStyleContextProvider(props: BaseStyleContextProviderProps) {
  const { defaultBase, onBaseChange, children } = props;

  const schema = useMapSchema();

  const [base, _setBase] = useVolatileState(
    useCallback(
      () => defaultBase ?? schema.defaultBase,
      // We need schema as a dependency, not schema.defaultBaseStyleId,
      // to ensure the state recalculates whenever the schema changes:
      [defaultBase, schema]
    )
  );

  const setBase = useCallback<OnBaseChange>(
    base => {
      _setBase(base);
      onBaseChange?.(base);
    },
    [_setBase, onBaseChange]
  );

  const value = useMemo<BaseStyleContextValue>(
    () => ({ base, setBase }),
    [base, setBase]
  );

  return (
    <BaseStyleContext.Provider value={value}>
      {children}
    </BaseStyleContext.Provider>
  );
}
