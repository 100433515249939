import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  BannerMessage,
  Workspace,
  randomWorkspaceId,
  unsafeWorkspaceAlias,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { defaultEmailSettings } from "../../../queries/workspace/emailSettings/v1";
import { RouteProps } from "../../../routes";
import { workspaceEmailSettingsRule } from "../../../schema/emailSettings";
import { workspaceRule } from "../../../schema/workspace";
import { useIOErrorAlert } from "../../components/Alert";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import WorkspaceEditor from "../../components/WorkspaceEditor";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { routes } from "../../routes";

export function initialWorkspace(): Workspace {
  return {
    id: randomWorkspaceId(),
    name: "",
    alias: unsafeWorkspaceAlias(""),
    features: [],
    accessLevel: "Full",
  };
}

function initialBanner(): BannerMessage {
  return { text: "" };
}

export default function AdminWorkspaceCreatePage(
  _props: RouteProps<typeof routes.admin.workspace.create>
): ReactElement {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.admin.home.url([])
  );

  usePageTitle("New Workspace - Admin");

  const [workspace, setWorkspace] = useState<Workspace>(initialWorkspace);
  const [banner, setBanner] = useState(initialBanner);
  const [emailSettings, setEmailSettings] = useState(defaultEmailSettings);

  const workspaceMessages = useMemo(
    () => workspaceRule(workspace),
    [workspace]
  );
  const bannerMessages = useMemo(() => [], []);
  const emailSettingsMessages = useMemo(
    () => workspaceEmailSettingsRule(emailSettings),
    [emailSettings]
  );

  const allMessages = useMemo(
    () =>
      workspaceMessages.concat(bannerMessages).concat(emailSettingsMessages),
    [bannerMessages, emailSettingsMessages, workspaceMessages]
  );

  const handleSave = useCallback(() => {
    if (allMessages.length === 0) {
      IO.sequence<unknown>([
        queries.workspace.v2.save(queryClient, apiParams, workspace).tap(() =>
          toast({
            status: "success",
            title: "Created workspace",
            duration: 3000,
            isClosable: true,
          })
        ),
        queries.workspace.banner.v1.save(
          queryClient,
          apiParams,
          workspace.id,
          banner
        ),
        queries.workspace.emailSettings.v1.save(
          queryClient,
          apiParams,
          workspace.id,
          emailSettings
        ),
      ])
        .tap(() => navigate(routes.workspace.home.url([workspace.alias])))
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [
    allMessages.length,
    apiParams,
    banner,
    emailSettings,
    errorAlert,
    navigate,
    queryClient,
    toast,
    workspace,
  ]);

  return (
    <>
      <PageTopBar admin={true} basePage="workspaces" workspace="new" />
      <PageContainer>
        <Spaced>
          <PageHeader title="New Workspace" />
          <WorkspaceEditor
            workspace={workspace}
            onWorkspaceChange={setWorkspace}
            workspaceMessages={workspaceMessages}
            banner={banner}
            onBannerChange={setBanner}
            bannerMessages={bannerMessages}
            emailSettings={emailSettings}
            onEmailSettingsChange={setEmailSettings}
            emailSettingsMessages={emailSettingsMessages}
          />
          <SaveButton onClick={handleSave} messages={allMessages} />
        </Spaced>
      </PageContainer>
    </>
  );
}
