import {
  KnownWorkspaceFeatureEnum,
  Project,
  Workspace,
} from "@cartographerio/types";
import { useMemo } from "react";

export function usePreferTeams(
  workspace: Workspace,
  project?: Project
): boolean {
  return useMemo(
    () =>
      [
        KnownWorkspaceFeatureEnum.EnableTeams,
        KnownWorkspaceFeatureEnum.PreferTeams,
      ].every(feature => workspace.features.includes(feature)) &&
      (project == null || project.teamIds.length > 0),
    [project, workspace.features]
  );
}
