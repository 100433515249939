import {
  Attribute,
  Bucket,
  MapLayer,
  MarkerModeName,
} from "@cartographerio/topo-map";
import { checkExhausted } from "@cartographerio/util";
import { isNil, omitBy } from "lodash";
import {
  Expression,
  FillLayout,
  FillPaint,
  LineLayout,
  LinePaint,
} from "mapbox-gl";
import { ReactElement, useMemo } from "react";

import { MapboxExpr, expr } from "../../mapbox";
import usePointLayerLayout from "../hooks/usePointLayerLayout";
import usePointLayerPaint from "../hooks/usePointLayerPaint";
import { styleExpr } from "./layerHelpers";
import { default as ReactMapGLLayer } from "./UpdateInPlaceLayer";

export interface LayerViewProps {
  id: string;
  beforeId?: string;
  type: MapLayer["type"];
  attribute: Attribute;
  buckets: Bucket[];
  sourceId: string;
  sourceLayerId?: string;
  visible: boolean;
  simplify: boolean;
  defaultZOrder?: string;
  markerMode?: MarkerModeName;
  filter?: Expression;
  invisibilityExpr?: MapboxExpr;
}

export default function LayerView(props: LayerViewProps): ReactElement {
  const {
    id,
    beforeId,
    type,
    attribute,
    buckets,
    sourceId,
    sourceLayerId,
    visible,
    simplify,
    defaultZOrder,
    markerMode = "normal",
    filter: _filter,
    invisibilityExpr,
  } = props;

  const filter = useMemo<Expression | undefined>(
    () => (visible ? _filter ?? expr.boolean(true) : expr.boolean(false)),
    [_filter, visible]
  );

  switch (type) {
    case "PointLayer":
      return (
        <PointLayerView
          id={id}
          beforeId={beforeId}
          attribute={attribute}
          buckets={buckets}
          sourceId={sourceId}
          sourceLayerId={sourceLayerId}
          filter={filter}
          defaultZOrder={defaultZOrder}
          markerMode={markerMode}
          invisibilityExpr={invisibilityExpr}
        />
      );

    case "LineLayer":
      return simplify ? (
        <PointLayerView
          id={id}
          beforeId={beforeId}
          attribute={attribute}
          buckets={buckets}
          sourceId={sourceId}
          sourceLayerId={sourceLayerId}
          filter={filter}
          defaultZOrder={defaultZOrder}
          markerMode={markerMode}
          invisibilityExpr={invisibilityExpr}
        />
      ) : (
        <LineLayerView
          id={id}
          beforeId={beforeId}
          attribute={attribute}
          buckets={buckets}
          sourceId={sourceId}
          sourceLayerId={sourceLayerId}
          filter={filter}
          defaultZOrder={defaultZOrder}
          markerMode={markerMode}
          useFillColor={true}
          invisibilityExpr={invisibilityExpr}
        />
      );

    case "PolygonLayer":
      return simplify ? (
        <PointLayerView
          id={id}
          beforeId={beforeId}
          attribute={attribute}
          buckets={buckets}
          sourceId={sourceId}
          sourceLayerId={sourceLayerId}
          filter={filter}
          defaultZOrder={defaultZOrder}
          markerMode={markerMode}
          invisibilityExpr={invisibilityExpr}
        />
      ) : (
        <>
          <LineLayerView
            id={`${id}/outline`}
            beforeId={beforeId}
            attribute={attribute}
            buckets={buckets}
            sourceId={sourceId}
            sourceLayerId={sourceLayerId}
            filter={filter}
            defaultZOrder={defaultZOrder}
            markerMode={markerMode}
            isPolygonOutline={true}
            invisibilityExpr={invisibilityExpr}
          />
          <FillLayerView
            id={id}
            beforeId={`${id}/outline`}
            attribute={attribute}
            buckets={buckets}
            sourceId={sourceId}
            sourceLayerId={sourceLayerId}
            filter={filter}
            defaultZOrder={defaultZOrder}
            markerMode={markerMode}
            invisibilityExpr={invisibilityExpr}
          />
        </>
      );

    default:
      return checkExhausted(type);
  }
}

interface PointLayerViewProps {
  id: string;
  beforeId?: string;
  attribute: Attribute;
  buckets: Bucket[];
  sourceId: string;
  sourceLayerId?: string;
  filter?: Expression;
  defaultZOrder?: string;
  markerMode: MarkerModeName;
  invisibilityExpr?: MapboxExpr;
}

function PointLayerView(props: PointLayerViewProps) {
  const {
    id,
    beforeId,
    attribute,
    buckets,
    sourceId,
    sourceLayerId,
    filter,
    defaultZOrder,
    markerMode,
    invisibilityExpr,
  } = props;

  const size = useMemo(
    () => styleExpr(attribute, buckets, "size", markerMode),
    [attribute, buckets, markerMode]
  );

  const paint = usePointLayerPaint(
    attribute,
    buckets,
    markerMode,
    size,
    invisibilityExpr
  );

  const layout = usePointLayerLayout(defaultZOrder);

  const rest = useMemo(
    () => omitBy({ "source-layer": sourceLayerId }, isNil),
    [sourceLayerId]
  );

  return (
    <ReactMapGLLayer
      id={id}
      beforeId={beforeId}
      type="circle"
      source={sourceId}
      paint={paint}
      layout={layout}
      filter={filter}
      {...rest}
    />
  );
}

interface LineLayerViewProps {
  id: string;
  beforeId?: string;
  attribute: Attribute;
  buckets: Bucket[];
  sourceId: string;
  sourceLayerId?: string;
  filter?: Expression;
  defaultZOrder?: string;
  markerMode: MarkerModeName;
  useFillColor?: boolean;
  isPolygonOutline?: boolean;
  invisibilityExpr?: MapboxExpr;
}

function LineLayerView(props: LineLayerViewProps) {
  const {
    id,
    beforeId,
    attribute,
    buckets,
    sourceId,
    sourceLayerId,
    filter,
    defaultZOrder,
    markerMode,
    useFillColor,
    isPolygonOutline,
    invisibilityExpr,
  } = props;

  const paint = useMemo<LinePaint>(() => {
    const lineColorKey = useFillColor ? "fillColor" : "strokeColor";

    const lineColor = styleExpr(
      attribute,
      buckets,
      lineColorKey,
      markerMode,
      invisibilityExpr
    );
    const lineWidth = styleExpr(attribute, buckets, "strokeWidth", markerMode);

    return {
      "line-color": lineColor,
      "line-width": isPolygonOutline
        ? expr.interpolate({
            type: ["linear"],
            input: expr.zoom(),
            stops: [
              [4, expr.mul(2, lineWidth)],
              [16, expr.mul(3, lineWidth)],
            ],
          })
        : // : expr.interpolate({
          //     type: ["linear"],
          //     input: expr.zoom(),
          //     stops: [
          //       [4, expr.mul(3, lineWidth)],
          //       [16, expr.mul(4, lineWidth)],
          //     ],
          //   }),
          expr.mul(4, lineWidth),
    };
  }, [
    attribute,
    buckets,
    invisibilityExpr,
    isPolygonOutline,
    markerMode,
    useFillColor,
  ]);

  const layout = useMemo<LineLayout>(() => {
    return omitBy(
      {
        "line-cap": "round",
        "line-sort-key":
          defaultZOrder == null
            ? undefined
            : expr.coalesce(expr.get(defaultZOrder), 0),
      },
      isNil
    );
  }, [defaultZOrder]);

  const rest = useMemo(
    () => omitBy({ "source-layer": sourceLayerId }, isNil),
    [sourceLayerId]
  );

  return (
    <ReactMapGLLayer
      id={id}
      beforeId={beforeId}
      type="line"
      source={sourceId}
      paint={paint}
      layout={layout}
      filter={filter}
      {...rest}
    />
  );
}

interface FillLayerViewProps {
  id: string;
  beforeId?: string;
  attribute: Attribute;
  buckets: Bucket[];
  sourceId: string;
  sourceLayerId?: string;
  filter?: Expression;
  defaultZOrder?: string;
  markerMode: MarkerModeName;
  invisibilityExpr?: MapboxExpr;
}

function FillLayerView(props: FillLayerViewProps) {
  const {
    id,
    beforeId,
    attribute,
    buckets,
    sourceId,
    sourceLayerId,
    filter,
    defaultZOrder,
    markerMode,
    invisibilityExpr,
  } = props;

  const paint = useMemo<FillPaint>(() => {
    const fillColor = styleExpr(
      attribute,
      buckets,
      "fillColor",
      markerMode,
      invisibilityExpr
    );

    return { "fill-color": fillColor };
  }, [attribute, buckets, invisibilityExpr, markerMode]);

  const layout = useMemo<FillLayout>(() => {
    return omitBy(
      {
        "fill-sort-key":
          defaultZOrder == null
            ? undefined
            : expr.coalesce(expr.get(defaultZOrder), 0),
      },
      isNil
    );
  }, [defaultZOrder]);

  const rest = useMemo(
    () => omitBy({ "source-layer": sourceLayerId }, isNil),
    [sourceLayerId]
  );

  return (
    <ReactMapGLLayer
      id={id}
      beforeId={beforeId}
      type="fill"
      source={sourceId}
      paint={paint}
      layout={layout}
      filter={filter}
      {...rest}
    />
  );
}
