import { KnownWorkspaceFeatureEnum, Workspace } from "@cartographerio/types";
import { useMemo } from "react";

export default function useCodesEnabled(workspace: Workspace): boolean {
  return useMemo(
    () =>
      workspace.features.includes(
        KnownWorkspaceFeatureEnum.EnableInvitationCodes
      ),
    [workspace]
  );
}
