import {
  Timestamp,
  defaultTimestampFormat,
  formatTimestamp,
  nowTimestamp,
} from "@cartographerio/types";
import {
  Button,
  Flex,
  Icon,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  LayoutProps,
} from "@chakra-ui/react";
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FiCalendar } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

import { Highlight, useInputHighlight } from "../../hooks/highlight";
import { useInputFocus } from "../../hooks/useInputFocus";
import TimestampFieldModal from "./Modal";

export interface TimestampFieldProps extends LayoutProps {
  label?: string;
  value: Timestamp | null;
  onChange?: (date: Timestamp | null) => void;
  highlight?: Highlight;
  minValue?: Timestamp | "now" | null;
  maxValue?: Timestamp | "now" | null;
  validateTime?: boolean;
  disabled?: boolean;
  nullable?: boolean;
  placeholder?: string;
  size?: InputGroupProps["size"];
}

export default function TimestampField(
  props: TimestampFieldProps
): ReactElement {
  const {
    label,
    value,
    onChange,
    highlight,
    minValue: _minValue,
    maxValue: _maxValue,
    validateTime = true,
    disabled,
    nullable,
    placeholder = "Select",
    size = "md",
    ...rest
  } = props;

  const minValue = useMemo(
    () => (_minValue === "now" ? nowTimestamp() : _minValue),
    [_minValue]
  );
  const maxValue = useMemo(
    () => (_maxValue === "now" ? nowTimestamp() : _maxValue),
    [_maxValue]
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [focused, _handleFocus, handleBlur] = useInputFocus();

  const { color, borderColor, borderWidth } = useInputHighlight(
    highlight,
    focused
  );

  const handleChange = useCallback(
    (timestamp: Timestamp | null) => {
      handleBlur();
      onChange?.(timestamp);
    },
    [handleBlur, onChange]
  );

  const handleClearClick = useCallback(
    (evt: MouseEvent<unknown>) => {
      evt.stopPropagation();
      evt.preventDefault();
      handleChange(null);
    },
    [handleChange]
  );

  let height: InputGroupProps["h"];
  let iconSpacing: InputGroupProps["mx"];
  let fontSize: InputGroupProps["size"];
  let rounded: InputGroupProps["rounded"];
  switch (size) {
    case "lg":
      height = "12";
      iconSpacing = "12";
      fontSize = "lg";
      rounded = "lg";
      break;
    case "md":
      height = "10";
      iconSpacing = "10";
      fontSize = "md";
      rounded = "md";
      break;
    case "sm":
      height = "8";
      iconSpacing = "8";
      fontSize = "sm";
      rounded = "sm";
      break;
    case "xs":
      height = "6";
      iconSpacing = "6";
      fontSize = "xs";
      rounded = "xs";
      break;
  }

  const showRightElement = nullable && !disabled && value != null;

  return (
    <>
      <InputGroup
        position="relative"
        cursor="pointer"
        onClick={() => setModalOpen(true)}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        background="white"
        bg={disabled ? "gray.100" : undefined}
        borderRadius="md"
        borderWidth={borderWidth}
        borderColor={borderColor}
        rounded={rounded}
        h={height}
        size={size}
        {...rest}
      >
        <InputLeftElement pointerEvents="none">
          <Icon as={FiCalendar} color={color} fontSize={fontSize} />
        </InputLeftElement>

        <Flex
          w="100%"
          ml={iconSpacing}
          mr={showRightElement ? iconSpacing : "4"}
          alignItems="center"
          borderWidth={0}
          borderColor="red"
          color={value == null && !disabled ? "gray.300" : undefined}
          fontSize={fontSize}
          whiteSpace="nowrap"
        >
          {value == null
            ? placeholder
            : formatTimestamp(value, { format: defaultTimestampFormat })}
        </Flex>

        {showRightElement && (
          <InputRightElement>
            <Button
              background="transparent"
              _active={{ background: "transparent" }}
              _hover={{ background: "transparent" }}
              _focus={{ boxShadow: "none" }}
              onClick={handleClearClick}
            >
              <Icon as={IoClose} onClick={handleClearClick} />
            </Button>
          </InputRightElement>
        )}
      </InputGroup>

      {!disabled && (
        <TimestampFieldModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          title={label}
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          validateTime={validateTime}
          onChange={handleChange}
        />
      )}
    </>
  );
}
