import { PrimaryTeamFieldState } from "@cartographerio/topo-form";
import { useSurveyorTeamContext } from "@cartographerio/topo-form-context";
import { TeamId } from "@cartographerio/types";
import { ReactElement } from "react";
import { IconType } from "react-icons";
import { RiTeamFill, RiTeamLine } from "react-icons/ri";

import SectionedSelect from "../components/SectionedSelect";

export interface PrimaryTeamFieldProps {
  state: PrimaryTeamFieldState;
  disabled?: boolean;
}

export function PrimaryTeamField(props: PrimaryTeamFieldProps): ReactElement {
  const { state, disabled } = props;

  const { primaryTeamOptions, onPrimaryTeamChange } = useSurveyorTeamContext();

  return (
    <SectionedSelect<TeamId | null>
      value={state.teamId}
      sections={primaryTeamOptions}
      fuseOptions={{
        includeMatches: true,
        minMatchCharLength: 1,
      }}
      onChange={onPrimaryTeamChange}
      renderIcon={teamIdToIcon}
      highlight={state.highlight}
      disabled={disabled}
      debounce={200}
      emptyText="No matching teams found. Please adjust your search"
    />
  );
}

function teamIdToIcon(teamId: TeamId | null): IconType {
  return teamId != null ? RiTeamFill : RiTeamLine;
}
