import { Email, User } from "@cartographerio/types";
import { Box, VStack } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import { ReactElement, useMemo } from "react";

import queries from "../../queries";
import { emptyArray } from "../../util";
import { useApiParams } from "../contexts/auth";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { FrontContext } from "./context";
import { FrontUserItem } from "./FrontUserItem";

export interface FrontUserListProps {
  context: FrontContext;
  searchString?: string | null;
  emails?: Email[];
  onUserClick: (user: User) => void;
}

export default function FrontUserList(props: FrontUserListProps): ReactElement {
  const { context, searchString = null, emails = [], onUserClick } = props;

  const apiParams = useApiParams();

  const searchResults =
    useSuspenseSearchResults(
      queries.optional(searchString, q =>
        queries.user.v2.search(apiParams, { q })
      )
    ) ?? emptyArray;

  const emailResults = useQueries({
    queries: emails
      .map(email => queries.user.v2.readOrNull(apiParams, email))
      .map(query => ({ ...query, suspense: true })),
  });

  const results = useMemo(() => {
    const x: User[] = searchResults;

    const y: User[] = emailResults.flatMap(result =>
      result.data == null ? [] : [result.data]
    );

    return [...x, ...y];
  }, [emailResults, searchResults]);

  return (
    <VStack spacing="4" alignItems="stretch">
      {results.map(user => (
        <FrontUserItem
          key={user.id}
          context={context}
          user={user}
          onClick={onUserClick}
        />
      ))}
      {results.length === 0 && <Box>No users to display</Box>}
    </VStack>
  );
}
