import { HStack } from "@chakra-ui/react";
import { ReactElement } from "react";

import CallUsLink from "../components/CallUsLink";
import EmailUsLink from "../components/EmailUsLink";
import Para from "../components/Para";
import Spaced from "../components/Spaced";

export default function BillingDisabled(): ReactElement {
  return (
    <Spaced spacing="6">
      <Para fontSize="large" textAlign="center">
        Billing Disabled
      </Para>

      <Para textAlign="center" __css={{ textWrap: "balance" }}>
        Subscription billing isn&apos;t set up for this workspace. You are
        either on a free trial or plan, or are being invoiced via external
        means.
      </Para>

      <Para textAlign="center" __css={{ textWrap: "balance" }}>
        Please get in touch if you have any questions about billing:
      </Para>

      <HStack gap="4" justify="center">
        <CallUsLink />
        <EmailUsLink />
      </HStack>
    </Spaced>
  );
}
