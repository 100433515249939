import { GeoJsonProperties, Geometry, Picked } from "@cartographerio/geometry";
import { FeatureFieldMapOptions } from "@cartographerio/topo-form";
import { Box, VStack } from "@chakra-ui/react";
import { defaults } from "lodash";
import { ReactElement, useCallback, useMemo, useState } from "react";

import { Highlight } from "../../hooks/highlight";
import AttributeGrid from "./AttributeGrid";
import FeatureFieldMap from "./FeatureFieldMap";

export const DEFAULT_MAP_OPTIONS: Partial<FeatureFieldMapOptions> = {
  selectMinZoom: 10,
  selectTolerancePixels: 50,
};

export interface NearestFeatureFieldProps<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
> {
  value: Picked<G, P> | null;
  onChange: (newValue: Picked<G, P> | null) => void;
  highlight?: Highlight;
  disabled?: boolean;
  mapOptions: FeatureFieldMapOptions;
}

export default function NearestFeatureField<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
>(props: NearestFeatureFieldProps<G, P>): ReactElement {
  const {
    value,
    onChange,
    highlight,
    disabled,
    mapOptions: _mapOptions,
  } = props;

  const [picked, setPicked] = useState<Picked<G, P> | null>(value);

  const handleChange = useCallback(
    (newValue: Picked<G, P> | null) => {
      setPicked(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  const mapOptions = useMemo(
    () => defaults(_mapOptions, DEFAULT_MAP_OPTIONS),
    [_mapOptions]
  );

  return (
    <VStack spacing="2" alignItems="stretch">
      <Box borderRadius="md" overflow="hidden">
        <FeatureFieldMap<G, P>
          defaultValue={picked}
          onChange={handleChange}
          disabled={disabled}
          highlight={highlight}
          showPointMarker={true}
          mapOptions={mapOptions}
        />

        {mapOptions.attributes.length > 0 && (
          <AttributeGrid
            roundedTop="none"
            roundedBottom="md"
            columns={mapOptions.attributeColumns}
            attributes={mapOptions.attributes}
            properties={value?.feature?.properties}
          />
        )}
      </Box>
    </VStack>
  );
}
