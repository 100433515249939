import {
  InvitationCodeSortKey,
  InvitationSortKey,
  SearchResultsFormat,
  SortOrder,
  endpoints,
} from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import {
  GlobalRoleName,
  InvitationCode,
  InvitationCodeStatus,
  InvitationCodeStatusEnum,
  Project,
  ProjectAlias,
  ProjectRoleName,
  SearchResults,
  Team,
  TeamAlias,
  TeamRoleName,
  Workspace,
  WorkspaceAlias,
  WorkspaceRoleName,
} from "@cartographerio/types";
import { HStack, useToast } from "@chakra-ui/react";
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ReactElement, ReactNode, useCallback, useMemo, useState } from "react";

import queries from "../../../queries";
import recordWithId from "../../../util/recordWithId";
import { useApiParams } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import usePermissionCheckCallback from "../../hooks/usePermissionCheckCallback";
import { useIOConfirm, useIOErrorAlert } from "../Alert";
import DownloadMenu from "../DownloadMenu";
import InvitationCodeShareModal from "../InvitationCodeShareModal";
import SearchField from "../SearchField";
import SearchResultsList from "../SearchResultsList";
import Select from "../Select";
import Spaced from "../Spaced";
import { invitationCodeListColumns, invitationListActions } from "./column";

interface InvitationCodeListProps {
  searchTerm?: string | null;
  page: number;
  count: number;
  order?: SortOrder<InvitationCodeSortKey>;
  workspace?: Workspace | null;
  project?: Project | null;
  team?: Team | null;
  status?: InvitationCodeStatus | null;
  globalRole?: GlobalRoleName | null;
  workspaceRole?: WorkspaceRoleName | null;
  projectRole?: ProjectRoleName | null;
  teamRole?: TeamRoleName | null;
  roleSelect?: ReactNode;
  workspaceOptions?: SelectOption<WorkspaceAlias>[];
  projectOptions?: SelectOption<ProjectAlias>[];
  teamOptions?: SelectOption<TeamAlias>[];
  resetFilters?: ReactNode;
  addButton?: ReactNode;
  itemLink?: (item: InvitationCode) => string;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<InvitationSortKey>) => void;
  onWorkspaceChange?: (workspace: WorkspaceAlias | null) => void;
  onProjectChange?: (project: ProjectAlias | null) => void;
  onTeamChange?: (team: TeamAlias | null) => void;
  onStatusChange: (team: InvitationCodeStatus | null) => void;
}

export default function InvitationCodeList(
  props: InvitationCodeListProps
): ReactElement {
  const {
    searchTerm = null,
    order = "created-desc",
    page,
    count,
    workspace,
    project,
    team,
    status,
    globalRole,
    workspaceRole,
    projectRole,
    teamRole,
    roleSelect,
    workspaceOptions,
    projectOptions,
    teamOptions,
    resetFilters,
    addButton,
    itemLink,
    onSearchTermChange,
    onPageChange,
    onOrderChange,
    onWorkspaceChange,
    onProjectChange,
    onTeamChange,
    onStatusChange,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();
  const confirm = useIOConfirm();
  const canRemove = usePermissionCheckCallback(checks.invitationCode.remove);

  const handleCancel = useCallback(
    (invitation: InvitationCode) =>
      queries.invitation.code.v3
        .cancel(queryClient, apiParams, invitation.id)
        .tap(() =>
          toast({
            title: "Invitation Cancelled",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, queryClient, toast]
  );

  const handleRemove = useCallback(
    (invitation: InvitationCode) => {
      confirm({
        title: "Delete Invitation Code",
        message: "Are you sure?",
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.invitation.code.v3
                .remove(queryClient, apiParams, invitation.id)
                .tap(() =>
                  toast({
                    title: "Invitation Deleted",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
            : IO.noop()
        )
        .tapError(errorAlert)
        .unsafeRun();
    },
    [apiParams, confirm, errorAlert, queryClient, toast]
  );

  const [sharedInvitation, setSharedInvitation] =
    useState<InvitationCode | null>(null);

  const actions = useMemo(
    () =>
      invitationListActions({
        onCancel: handleCancel,
        onShare: setSharedInvitation,
        onRemove: code => (canRemove(code) ? handleRemove(code) : undefined),
      }),
    [canRemove, handleCancel, handleRemove]
  );

  const { data, error } = useQuery(
    queries.invitation.code.v3.search(apiParams, {
      workspace: workspace?.id,
      project: project?.id,
      team: team?.id,
      q: searchTerm,
      globalRole,
      workspaceRole,
      projectRole,
      teamRole,
      status,
      order,
      skip: page * count,
      limit: count,
    })
  ) as UseQueryResult<SearchResults<InvitationCode> | null, unknown>;

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.invitation.v3.searchUrl({
          workspace: workspace?.id,
          project: project?.id,
          team: team?.id,
          q: searchTerm,
          globalRole,
          workspaceRole,
          projectRole,
          teamRole,
          order,
          format,
        })
      ),
    [
      formatApiUrl,
      globalRole,
      order,
      project?.id,
      projectRole,
      searchTerm,
      team?.id,
      teamRole,
      workspace?.id,
      workspaceRole,
    ]
  );

  const columns = useMemo(
    () => invitationCodeListColumns({ link: itemLink }),
    [itemLink]
  );

  const handleSearchChange = useCallback(
    (search: string | null) => onSearchTermChange?.(search),
    [onSearchTermChange]
  );

  return (
    <Spaced spacing="4">
      <HStack w="100%" justifyContent="stretch" wrap="wrap">
        <SearchField
          defaultValue={searchTerm}
          onChange={handleSearchChange}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        {workspaceOptions != null && (
          <Select.Searchable
            placeholder="All Workspaces"
            value={workspace?.alias ?? null}
            options={workspaceOptions}
            onChange={onWorkspaceChange}
            width="fit-content"
            background="transparent"
            maxW="52"
          />
        )}
        {projectOptions != null && (
          <Select.Nullable
            placeholder="All Projects"
            value={project?.alias ?? null}
            options={projectOptions}
            onChange={onProjectChange}
            w="fit-content"
            background="transparent"
            maxW="52"
          />
        )}
        {teamOptions != null && (
          <Select.Nullable
            placeholder="All Teams"
            value={team?.alias ?? null}
            options={teamOptions}
            onChange={onTeamChange}
            w="fit-content"
            background="transparent"
            maxW="52"
          />
        )}
        <Select.Nullable
          placeholder="All Statuses"
          value={status ?? null}
          options={InvitationCodeStatusEnum.entries}
          onChange={onStatusChange}
          w="fit-content"
          background="transparent"
          maxW="52"
        />
        {roleSelect}
        <DownloadMenu downloadUrl={downloadUrl} />
        {addButton}
      </HStack>

      {resetFilters}

      <SearchResultsList
        page={page}
        count={count}
        order={order}
        results={data}
        error={error}
        columns={columns}
        actions={actions}
        onPageChange={onPageChange}
        onOrderChange={onOrderChange}
        itemKey={recordWithId}
      />
      {sharedInvitation != null && (
        <InvitationCodeShareModal
          code={sharedInvitation}
          isOpen={true}
          onClose={() => setSharedInvitation(null)}
        />
      )}
    </Spaced>
  );
}
