import { Feature } from "@cartographerio/geometry";
import { MapLayerId } from "@cartographerio/topo-map";
import { raise } from "@cartographerio/util";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Setter } from "../../hooks/useRecord";
import { useVolatileRecord } from "../../hooks/useVolatileRecord";
import { useMapLayers } from "./MapSchemaContext";

interface VisibleFeaturesContextValue {
  visibleFeatures: Record<MapLayerId, Feature[]>;
  setVisibleFeatures: Setter<MapLayerId, Feature[]>;
}

const VisibleFeaturesContext =
  createContext<VisibleFeaturesContextValue | undefined>(undefined);

export function useVisibleFeaturesContext(): VisibleFeaturesContextValue {
  return (
    useContext(VisibleFeaturesContext) ??
    raise(new Error("No current topo map context"))
  );
}

export function useVisibleFeatures(
  layerId: MapLayerId | undefined
): [Feature[], (features: Feature[]) => void] {
  const { visibleFeatures, setVisibleFeatures } = useVisibleFeaturesContext();

  const value = layerId == null ? [] : visibleFeatures[layerId] ?? [];

  const setter = useCallback(
    (features: Feature[]) => {
      layerId != null && setVisibleFeatures(layerId, features);
    },
    [layerId, setVisibleFeatures]
  );

  return [value, setter];
}

interface VisibleFeaturesContextProviderProps {
  children: ReactNode;
}

export function VisibleFeaturesContextProvider(
  props: VisibleFeaturesContextProviderProps
) {
  const { children } = props;

  const layers = useMapLayers();

  const [visibleFeatures, setVisibleFeatures] = useVolatileRecord<
    MapLayerId,
    Feature[]
  >(
    useCallback(() => {
      layers; // force the function to have this dependency
      return {};
    }, [layers])
  );

  const value = useMemo<VisibleFeaturesContextValue>(
    () => ({ visibleFeatures, setVisibleFeatures }),
    [visibleFeatures, setVisibleFeatures]
  );

  return (
    <VisibleFeaturesContext.Provider value={value}>
      {children}
    </VisibleFeaturesContext.Provider>
  );
}
