import { Feature } from "@cartographerio/geometry";
import { Attribute } from "@cartographerio/topo-map";
import {
  AttachmentFolder,
  SurveyId,
  unsafeAttachmentFolder,
  unsafeSurveyId,
  unsafeTeamId,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box } from "@chakra-ui/react";
import { chain, isString, take } from "lodash";
import {
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import LinkButton from "../../components/LinkButton";
import { useOptAccessToken } from "../../contexts/auth";
import { useLinkContext, useProjectTeamContextGraph } from "../TopoMapContext";

const MAX_UNEXPANDED_VALUES = 5;

export interface AttributeValuesProps {
  attribute: Attribute;
  features: Feature[];
  fullView: boolean;
}

export default function AttributeValues(
  props: AttributeValuesProps
): ReactElement {
  const { attribute, features, fullView } = props;

  const { optFindTeamById } = useProjectTeamContextGraph();

  const { AttachmentCarousel, AttachmentLink, SurveyLink, TeamLink } =
    useLinkContext();

  const token = useOptAccessToken();

  const content = useMemo(() => {
    switch (attribute.type) {
      case "FeatureAttribute":
        return <></>;

      case "StringAttribute":
      case "NumberAttribute":
      case "BooleanAttribute": {
        const values = chain(features)
          .map(feature => feature.properties?.[attribute.propertyName])
          .sort()
          .map(value => attribute.format(value) ?? "-")
          .sortedUniq()
          .value();
        return <Expandable values={values} unit={attribute.unit} />;
      }

      case "TimestampAttribute": {
        const values = chain(features)
          .map(feature => feature.properties?.[attribute.propertyName])
          .sort()
          .map(value => attribute.format(value) ?? "-")
          .sortedUniq()
          .value();
        return <Expandable values={values} />;
      }

      case "SurveyAttribute": {
        const values = chain(features)
          .map(feature => feature.properties?.[attribute.propertyName])
          .flatMap(value => (isString(value) ? [unsafeSurveyId(value)] : []))
          .sort()
          .sortedUniq()
          .map((surveyId, index) => (
            <SurveyLink key={surveyId} to={surveyId}>
              Survey {index + 1}
            </SurveyLink>
          ))
          .value();
        return <Expandable values={values} />;
      }

      case "TeamAttribute": {
        const values = chain(features)
          .map(feature => feature.properties?.[attribute.propertyName])
          .flatMap(value => {
            if (isString(value)) {
              const team = optFindTeamById(unsafeTeamId(value));
              return team == null ? [] : [team];
            } else {
              return [];
            }
          })
          .sortBy(team => team.name)
          .sortedUniqBy(team => team.name)
          .map(team => (
            <TeamLink
              key={team.id}
              to={team.id}
              project={attribute.project}
              moduleId={attribute.module}
            >
              {team.name}
            </TeamLink>
          ))
          .value();
        return <Expandable values={values} />;
      }

      case "AttachmentAttribute": {
        const folders = chain(features)
          .map(feature => [
            feature.properties?.[attribute.surveyPropertyName],
            feature.properties?.[attribute.folderPropertyName],
          ])
          .filter(
            (val): val is [SurveyId, string] =>
              isString(val[0]) && isString(val[1])
          )
          .map<[SurveyId, AttachmentFolder]>(([survey, folder]) => [
            unsafeSurveyId(survey),
            unsafeAttachmentFolder(folder),
          ])
          .sortBy(([survey, _folder]) => survey)
          .sortedUniqBy(([survey, _folder]) => survey)
          .value();
        return token != null ? (
          <AttachmentCarousel folders={folders} fullView={fullView} />
        ) : (
          folders.map((path, i) => (
            <AttachmentLink key={path.join(".")} to={path}>
              Gallery {i + 1}
            </AttachmentLink>
          ))
        );
      }

      default:
        return checkExhausted(attribute);
    }
  }, [
    AttachmentCarousel,
    AttachmentLink,
    SurveyLink,
    TeamLink,
    attribute,
    features,
    optFindTeamById,
    fullView,
    token,
  ]);

  return (
    <Box fontSize="2xs" color="gray.500" w="100%">
      {content}
    </Box>
  );
}

interface ExpandableProps {
  values: ReactNode[];
  unit?: string;
}

function Expandable(props: ExpandableProps) {
  const { values, unit } = props;

  const [expanded, setExpanded] = useState(false);

  const expandable = values.length > MAX_UNEXPANDED_VALUES;

  const showMore = expandable && !expanded;

  const showValues = useMemo(
    () => (showMore ? take(values, MAX_UNEXPANDED_VALUES) : values),
    [showMore, values]
  );

  const children = useMemo(
    () => showValues.flatMap(intersperseCommas),
    [showValues]
  );

  const handleClick = useCallback((evt: MouseEvent<HTMLSpanElement>) => {
    evt.stopPropagation();
    setExpanded(true);
  }, []);

  return (
    <>
      {children} {unit}
      {showMore && (
        <>
          ,{" "}
          <LinkButton onClick={handleClick}>
            {values.length - MAX_UNEXPANDED_VALUES} more...
          </LinkButton>
        </>
      )}
    </>
  );
}

function intersperseCommas(value: ReactNode, index: number): ReactNode[] {
  return index === 0 ? [value] : [", ", value];
}
