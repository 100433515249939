import { UserId } from "@cartographerio/types";
import { useLayoutEffect, useMemo } from "react";
import {
  SetURLSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";

import { BaseQueryUpdate } from "../../routes/base";

export default function useSavedQueryParams(
  updateQuery: SetURLSearchParams,
  currentVersion: string | null,
  userId: UserId | null
): BaseQueryUpdate {
  const location = useLocation();

  const cacheKey = useMemo(
    () =>
      `${userId != null ? `u/${userId}` : ""}${
        location.pathname
      }/v${currentVersion}`,
    [currentVersion, location.pathname, userId]
  );

  useLayoutEffect(() => {
    if (currentVersion != null) {
      const currentQuery = location.search.replace(/^\?/, "");
      const cachedQuery = window.localStorage.getItem(cacheKey) ?? "";

      if (location.search !== "" && currentQuery !== cachedQuery) {
        window.localStorage.setItem(cacheKey, currentQuery);
      } else if (location.search === "" && cachedQuery.length > 0) {
        updateQuery(cachedQuery);
      }
    }
  }, [
    currentVersion,
    cacheKey,
    location.pathname,
    location.search,
    updateQuery,
  ]);

  return useMemo(
    () =>
      currentVersion == null
        ? updateQuery
        : (searchParams, ...args) => {
            const query = createSearchParams(searchParams).toString();
            if (query.length > 0) {
              window.localStorage.setItem(cacheKey, query);
            } else {
              window.localStorage.removeItem(cacheKey);
            }
            updateQuery(searchParams, ...args);
          },
    [cacheKey, currentVersion, updateQuery]
  );
}
