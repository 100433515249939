import { IO } from "@cartographerio/io";
import { useSurveyorTeamContext } from "@cartographerio/topo-form-context";
import {
  AttachmentCategory,
  AttachmentFolder,
  AttachmentId,
  AttachmentUpdate,
  SurveyId,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  ButtonGroup,
  HStack,
  IconButton,
  SimpleGrid,
  Wrap,
  WrapItem,
  chakra,
  useBoolean,
} from "@chakra-ui/react";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { IoMdLink } from "react-icons/io";
import { IoGridOutline, IoListOutline } from "react-icons/io5";

import {
  GalleryItem,
  galleryItemIsUploading,
  inProgressGalleryItem,
  isFullAttachmentItem,
  uploadFailureGalleryItem,
  uploadSuccessGalleryItem,
} from "../../../galleryItem";
import { useAlert, useIOErrorAlert } from "../../components/Alert";
import FormLabel from "../../components/FormLabel";
import GalleryModal, {
  useGallerySelection,
} from "../../components/GalleryItemModal";
import IconButtonLink from "../../components/IconButtonLink";
import Link from "../../components/Link";
import TextArea from "../../components/TextArea";
import TextField from "../../components/TextField";
import { useAttachmentView } from "../../contexts/attachmentView";
import { useVolatileState } from "../../hooks/useVolatileState";
import { useFormContext } from "../context/FormContext";
import { SurveyorField } from "../SurveyorField";
import { useAttachmentFieldContext } from "./context";
import LoadingSpinner from "./LoadingSpinner";
import Thumbnail from "./Thumbnail";
import UploadButton from "./UploadButton";

const replaceItem =
  (item: GalleryItem) =>
  (items: GalleryItem[]): GalleryItem[] =>
    items.map(i => (i.id === item.id ? item : i));

const deleteItem =
  (item: GalleryItem) =>
  (items: GalleryItem[]): GalleryItem[] =>
    items.flatMap(i => (i.id === item.id ? [] : [i]));

const FILE_EXTENSION_REGEX = /\..{1,5}$/;

export interface AttachmentFieldProps {
  folder: AttachmentFolder;
  survey: SurveyId;
  onUpdate?: (id: AttachmentId, update: AttachmentUpdate) => void;
  disabled?: boolean;
  maxAttachments?: number;
  suggestedCategories?: AttachmentCategory[];
}

export default function AttachmentField(
  props: AttachmentFieldProps
): ReactElement {
  const {
    folder,
    survey,
    onUpdate,
    disabled,
    maxAttachments,
    suggestedCategories,
  } = props;

  const {
    searchAttachments,
    createAttachment,
    deleteAttachment,
    attachmentUrl,
    attachmentFolderUrl,
  } = useAttachmentFieldContext();

  const { primarySurveyor } = useSurveyorTeamContext();

  const { creating, defaultDataLicense } = useFormContext();

  const [items, setItems] = useState<GalleryItem[]>([]);

  const setRemoteItems = useCallback((remoteItems: GalleryItem[]) => {
    setItems(items => [
      ...remoteItems,
      ...items.filter(item => item.type !== "AttachmentItem"),
    ]);
  }, []);

  const { setIndex, item, handleNextClick, handlePrevClick } =
    useGallerySelection(items);
  const alert = useAlert();
  const errorAlert = useIOErrorAlert();

  const [refreshing, setRefreshing] = useState(false);

  const loading = useMemo(
    () => refreshing || items.some(galleryItemIsUploading),
    [refreshing, items]
  );

  const [update, setUpdate] = useVolatileState<AttachmentUpdate>(
    useCallback(
      () => ({
        title: item?.attachment.title,
        description: item?.attachment.description,
        author: item?.attachment.author,
        license: item?.attachment.license,
      }),
      [item]
    )
  );

  const showUploadButton =
    !disabled &&
    !refreshing &&
    (maxAttachments == null ? true : items.length < maxAttachments);

  useEffect(() => {
    IO.wrap(() => setRefreshing(true))
      .andThen(searchAttachments(folder))
      .tap(setRemoteItems)
      .cleanup(() => setRefreshing(false))
      .void()
      .unsafeRun();
  }, [folder, searchAttachments, setRemoteItems]);

  const handleSave = useCallback(() => {
    if (item != null && isFullAttachmentItem(item)) {
      onUpdate?.(item.attachment.id, update);

      setItems(
        replaceItem({
          ...item,
          attachment: {
            ...item.attachment,
            ...Object.fromEntries(
              Object.entries(update).filter(([_key, value]) => value != null)
            ),
          },
        })
      );
    }
  }, [item, onUpdate, update]);

  const [modalOpen, { on: openModal, off: closeModal }] = useBoolean(false);

  const handleSelectFile = useCallback(
    (file: File) => {
      const createItem = inProgressGalleryItem(file, {
        title: file.name.replace(FILE_EXTENSION_REGEX, ""),
        surveyId: survey,
        folder,
        license: defaultDataLicense,
        author: primarySurveyor,
      });

      setIndex(items.length);
      setItems([...items, createItem]);
      openModal();

      createAttachment(createItem.attachment, file)
        .tap(att =>
          setItems(replaceItem(uploadSuccessGalleryItem(createItem, att)))
        )
        .tapError(err => {
          setItems(replaceItem(uploadFailureGalleryItem(createItem, err)));
          alert({
            title: "Upload failed",
            message: "Click on the thumbnail to see the error message.",
          });
        })
        .unsafeRun();
    },
    [
      alert,
      createAttachment,
      defaultDataLicense,
      folder,
      items,
      openModal,
      primarySurveyor,
      setIndex,
      survey,
    ]
  );

  const handleDeleteClick = useCallback(() => {
    if (item != null) {
      switch (item.type) {
        case "AttachmentItem":
        case "UploadSuccessItem":
          if (confirm("Are you sure? There is no undo!")) {
            deleteAttachment(item.attachment.id)
              .tap(_ => {
                setItems(deleteItem(item));
                closeModal();
              })
              .tapError(errorAlert)
              .unsafeRun();
          }
          break;

        case "InProgressItem":
        case "UploadFailureItem":
          setItems(deleteItem(item));
          setIndex(null);
          break;

        default:
          checkExhausted(item);
      }
    }
  }, [closeModal, deleteAttachment, errorAlert, item, setIndex]);

  const handleThumbnailClick = useCallback(
    (_item: GalleryItem, index: number) => {
      setIndex(index);
      openModal();
    },
    [openModal, setIndex]
  );

  const handleCloseClick = useCallback(() => {
    setIndex(0);
    closeModal();
  }, [closeModal, setIndex]);

  const [attachmentView, setAttachmentView] = useAttachmentView();

  return (
    <>
      <HStack align="center">
        <HStack gap="2" borderEndWidth="1px" pr="2">
          <ButtonGroup isAttached={true} size="sm">
            <IconButton
              aria-label="Grid view"
              title="Grid view"
              icon={<IoGridOutline size="1.25rem" />}
              variant={attachmentView === "grid" ? undefined : "outline"}
              me="0 !important"
              bg={attachmentView === "grid" ? "gray.200" : "transparent"}
              borderColor="gray.200"
              onClick={() => setAttachmentView("grid")}
            />
            <IconButton
              aria-label="List view"
              title="List view"
              icon={<IoListOutline size="1.25rem" />}
              variant={attachmentView === "list" ? undefined : "outline"}
              bg={attachmentView === "list" ? "gray.200" : "transparent"}
              borderColor="gray.200"
              onClick={() => setAttachmentView("list")}
            />
          </ButtonGroup>
          {!disabled &&
            !creating &&
            maxAttachments != null &&
            attachmentFolderUrl && (
              <IconButtonLink.External
                label="Permalink"
                icon={<IoMdLink size="1.25rem" />}
                variant="outline"
                bg="white"
                size="sm"
                to={attachmentFolderUrl(survey, folder)}
              />
            )}
        </HStack>

        {!disabled && maxAttachments != null && (
          <chakra.p h="100%" color="gray.500" fontSize="sm">
            Attached {items.length} of {maxAttachments} files.{" "}
            <Link.External to="https://cartographer.io/legal/platform-terms/#content">
              Upload Terms
            </Link.External>
          </chakra.p>
        )}
      </HStack>
      <Wrap spacing="4">
        {refreshing ? (
          <WrapItem>
            <LoadingSpinner />
          </WrapItem>
        ) : (
          items.map((item, index) => (
            <WrapItem
              key={item.id}
              w={attachmentView === "grid" ? undefined : "100%"}
            >
              <Thumbnail
                item={item}
                onClick={() => handleThumbnailClick(item, index)}
              />
            </WrapItem>
          ))
        )}
        {showUploadButton && (
          <WrapItem w={attachmentView === "grid" ? undefined : "100%"}>
            <UploadButton
              disabled={loading}
              onSelectFile={handleSelectFile}
              suggestedCategories={suggestedCategories}
            />
          </WrapItem>
        )}
      </Wrap>
      <GalleryModal
        isOpen={modalOpen}
        onClose={handleCloseClick}
        item={item}
        disabled={disabled}
        onDeleteClick={handleDeleteClick}
        onSaveClick={handleSave}
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
        attachmentUrl={attachmentUrl}
      >
        <SimpleGrid
          columns={[1, 2]}
          gap="2"
          gridTemplateColumns={[null, "1fr 3fr"]}
        >
          <FormLabel text="Title" />
          <TextField.String
            value={update.title ?? ""}
            onChange={title => setUpdate({ ...update, title })}
            disabled={disabled || item?.type === "UploadFailureItem"}
          />
          <FormLabel text="Author" />
          <SurveyorField
            value={update.author}
            defaultValue={update.author ?? null}
            onChange={author => setUpdate({ ...update, author })}
            disabled={disabled || item?.type === "UploadFailureItem"}
          />
          <FormLabel text="Description" />
          <TextArea.Nullable
            value={update.description ?? null}
            onChange={description => setUpdate({ ...update, description })}
            disabled={disabled || item?.type === "UploadFailureItem"}
            rows={3}
          />
        </SimpleGrid>
        {item != null && isFullAttachmentItem(item) && (
          <chakra.em textAlign="end" display="block" mt="2">
            Created{" "}
            {formatTimestamp(item.attachment.created, { format: ddmmyyyy })}
          </chakra.em>
        )}
      </GalleryModal>
    </>
  );
}
