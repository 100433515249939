import { MemberSortKey } from "@cartographerio/client";
import { PermissionCheckRunner } from "@cartographerio/permission";
import {
  Member,
  Project,
  ProjectId,
  Qualification,
  Team,
  TeamId,
  WorkspaceId,
} from "@cartographerio/types";

import {
  OnMemberQualificationChange,
  OnMemberRoleChange,
  emailColumn,
  memberTypeColumn,
  nameColumn,
  qualificationRoleColumn,
  selectedProjectColumns,
  selectedTeamColumns,
  workspaceRoleColumn,
} from "../../../components/MemberList/column";
import { Column } from "../../../components/SearchResultsList/column";

interface WorkspaceMemberListColumnsProps {
  workspace: WorkspaceId;
  projects?: Project[];
  teams?: Team[];
  selectedTeam?: TeamId | null;
  selectedProject?: ProjectId | null;
  qualifications: Qualification[];
  permissionCheckPasses: PermissionCheckRunner;
  onRoleChange?: OnMemberRoleChange | null;
  onQualificationChange?: OnMemberQualificationChange | null;
}

export function workspaceMemberListColumns(
  props: WorkspaceMemberListColumnsProps
): Column<Member, MemberSortKey>[] {
  const {
    workspace,
    projects,
    teams,
    selectedTeam,
    selectedProject,
    qualifications,
    permissionCheckPasses,
    onRoleChange,
    onQualificationChange,
  } = props;

  return [
    memberTypeColumn,
    nameColumn,
    emailColumn,
    ...(teams != null
      ? selectedTeamColumns({
          teams,
          selectedTeam,
          onRoleChange,
          permissionCheckPasses,
        })
      : []),
    ...(projects != null
      ? selectedProjectColumns({
          projects,
          selectedProject,
          onRoleChange,
          permissionCheckPasses,
        })
      : []),
    workspaceRoleColumn({ workspace, onRoleChange }),
    ...qualifications.map(qualification =>
      qualificationRoleColumn({
        qualification,
        onQualificationChange,
        permissionCheckPasses,
      })
    ),
  ];
}
