import { endpoints } from "@cartographerio/client";
import { Result } from "@cartographerio/fp";
import {
  AccessToken,
  isMissingAuthorizationError,
  unsafeEmail,
  unsafeUnencryptedPassword,
} from "@cartographerio/types";
import { optEnvvar } from "@cartographerio/util";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, ReactNode, useEffect, useMemo, useReducer } from "react";
import { useSearchParams } from "react-router-dom";

import queries from "../../../queries";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import { useApiConfig } from "../apiConfig";
import { completeInitialiseAction, startInitialiseAction } from "./action";
import { AuthContext } from "./context";
import { useAccessTokenCookie } from "./hooks";
import { authReducer } from "./reducer";
import { initialAuthState } from "./state";

export interface AccessTokenProviderProps {
  children: ReactNode;
}

export function AccessTokenProvider(
  props: AccessTokenProviderProps
): ReactElement {
  const { children } = props;

  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();

  const [accessTokenCookie, setAccessTokenCookie, clearAccessTokenCookie] =
    useAccessTokenCookie();

  const [state, dispatch] = useReducer(
    authReducer,
    initialAuthState(accessTokenCookie)
  );

  const { initialised, loading, accessTokenData } = state;

  const [searchParams] = useSearchParams();
  const accessTokenParam = searchParams.get("authorization") as
    | AccessToken
    | undefined;

  useEffect(() => {
    if (!initialised && !loading) {
      dispatch(startInitialiseAction(apiConfig));

      const storedAccessToken =
        accessTokenParam ?? accessTokenCookie.effective;

      if (storedAccessToken == null) {
        // No access token stored...

        if (process.env.NODE_ENV === "development") {
          // We're in development mode... attempt to sign in using environment variables
          const email = optEnvvar("REACT_APP_CARTOGRAPHER_EMAIL");
          const password = optEnvvar("REACT_APP_CARTOGRAPHER_PASSWORD");

          if (email != null && password != null) {
            console.warn("[Dev] Attempting to sign in using envvars");

            queries.auth.v2
              .signin(queryClient, apiConfig, {
                email: unsafeEmail(email),
                password: unsafeUnencryptedPassword(password),
              })
              .tap(result =>
                result.fold(
                  messages => {
                    dispatch(
                      completeInitialiseAction(
                        Result.fail(messages.map(msg => msg.text).join(" "))
                      )
                    );
                  },
                  response => {
                    switch (response.type) {
                      case "SigninSuccess":
                        dispatch(
                          completeInitialiseAction(
                            Result.pass(response.credentials.token)
                          )
                        );
                        break;
                      case "AccountNotFound":
                      case "InvalidEmail":
                      case "PasswordRequired":
                      case "PasswordIncorrect":
                        dispatch(
                          completeInitialiseAction(
                            Result.fail("Email address or password incorrect")
                          )
                        );
                        break;
                      case "TermsNotAccepted":
                        dispatch(
                          completeInitialiseAction(
                            Result.fail(
                              "You haven't accepted the terms and conditions (and we haven't implemented that part yet)"
                            )
                          )
                        );
                        break;
                      default:
                        console.warn(
                          "[Dev] Could not sign in using envvars (failed signin)",
                          response
                        );
                        dispatch(
                          completeInitialiseAction(
                            Result.fail(
                              "The server returned an unknown response"
                            )
                          )
                        );
                        break;
                    }
                  }
                )
              )
              .recover(error => {
                console.warn(
                  "[Dev] Error signing in using envvars (error response)",
                  error
                );
                dispatch(
                  completeInitialiseAction(
                    Result.fail("The server responded with an error")
                  )
                );
              })
              .unsafeRun();
          } else {
            dispatch(completeInitialiseAction(Result.pass(null)));
          }
        } else {
          dispatch(completeInitialiseAction(Result.pass(null)));
        }
      } else {
        endpoints.auth.v2
          .readOrFail(apiConfig, storedAccessToken)
          .tap(response => {
            dispatch(completeInitialiseAction(Result.pass(response.token)));
          })
          .tapError(error =>
            !isMissingAuthorizationError(error)
              ? console.error("Error restoring signin", error)
              : null
          )
          .recover(() => dispatch(completeInitialiseAction(Result.pass(null))))
          .unsafeRun();
      }
    }
  }, [
    accessTokenCookie,
    accessTokenParam,
    apiConfig,
    initialised,
    loading,
    queryClient,
  ]);

  useEffect(() => {
    if (initialised && accessTokenData?.effective == null) {
      clearAccessTokenCookie();
    } else if (initialised && accessTokenData?.effective != null) {
      setAccessTokenCookie(accessTokenData);
    }
  }, [
    accessTokenData,
    clearAccessTokenCookie,
    initialised,
    setAccessTokenCookie,
  ]);

  useEffect(() => {
    queryClient.clear();
  }, [queryClient, accessTokenData?.effective]);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return initialised && !loading ? (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  ) : (
    <LoadingPlaceholder label="Authenticating" />
  );
}
