import {
  PermissionCheck,
  Timestamp,
  formatTimestamp,
  isApiError,
  nowTimestamp,
  unsafeTimestampFormat,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, Button, HStack, SystemProps, chakra } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import outdent from "outdent";
import { ReactElement, useCallback, useMemo } from "react";

import { routes } from "../routes";
import ButtonLink from "./ButtonLink";
import Heading from "./Heading";
import JsonView from "./JsonView";
import Markdown from "./Markdown";
import Pre from "./Pre";
import Spaced from "./Spaced";

export interface ErrorPlaceholderProps extends SystemProps {
  showSignout?: boolean;
  error: unknown;
  componentStack?: string | null;
  resetErrorBoundary?: (...args: unknown[]) => void;
  sentryEventId?: string | null;
}

export default function ErrorPlaceholder(
  props: ErrorPlaceholderProps
): ReactElement {
  const {
    showSignout,
    error,
    componentStack = null,
    resetErrorBoundary,
    sentryEventId = null,
    ...rest
  } = props;

  const details: ErrorDetails = useErrorDetails(error, sentryEventId);

  const timestamp = details.timestamp ?? nowTimestamp();

  const errorJson = useMemo(
    () =>
      error instanceof Error
        ? { message: error.message, cause: error.cause }
        : error,
    [error]
  );

  const handleShowFeedback = useCallback(() => {
    Sentry.showReportDialog({ eventId: sentryEventId ?? undefined });
  }, [sentryEventId]);

  return (
    <Box {...rest}>
      <Spaced maxW="100%">
        <Heading level="section">{details.title}</Heading>
        <chakra.em>
          {formatTimestamp(timestamp, {
            format: unsafeTimestampFormat("cccc d MMMM y, h:mmaaa"),
          })}
        </chakra.em>
        {details.message != null && <Markdown text={details.message} />}
        <JsonView
          value={errorJson}
          copyToClipboard={true}
          rounded="lg"
          bg="gray.200"
          fontSize="sm"
        />
        {componentStack != null && <Pre text={componentStack} />}
        <HStack spacing="4">
          {sentryEventId != null && (
            <Button variant="outline" onClick={handleShowFeedback}>
              Send Feedback
            </Button>
          )}
          <Button variant="outline" onClick={resetErrorBoundary}>
            Try Again
          </Button>
          {!showSignout && (
            // Don't use React Router here. We may not have access to the context from BrowserRouter:
            <ButtonLink.Internal variant="outline" to={routes.signout.url([])}>
              Sign Out
            </ButtonLink.Internal>
          )}
        </HStack>
      </Spaced>
    </Box>
  );
}

interface ErrorDetails {
  title: string;
  message?: string;
  timestamp?: Timestamp | null;
}

export function errorDetails(
  error: unknown,
  eventId?: string | null
): ErrorDetails {
  if (isApiError(error)) {
    switch (error.type) {
      case "InternalError":
        return {
          title: "Oops! Something Went Wrong!",
          message: outdent`
            An unexpected internal error occurred:

            **${error.message}**

            ${reportMessage(eventId)}
            `,
          timestamp: error.timestamp,
        };

      case "ForbiddenError": {
        const names = permissionCheckNames(error.check);
        return {
          title: "Oops! Something Went Wrong!",
          message: outdent`
            Your account doesn't have one or more required permissions:

            ${
              names.length === 1
                ? `**${names[0]}**`
                : names.map(name => `- **${name}**`).join("\n")
            }

            ${reportMessage(eventId)}
            `,
          timestamp: error.timestamp,
        };
      }

      case "NotFoundError":
        return {
          title: "Oops! Something Went Wrong!",
          message: outdent`
            We couldn't find what you were looking for:

            **${error.itemType} ${error.item}**

            ${reportMessage(eventId)}
            `,
          timestamp: error.timestamp,
        };

      case "BadRequestError":
        return {
          title: "Oops! Something Went Wrong!",
          message: outdent`
            Our servers received an unexpected request:

            **${error.message}**

            ${reportMessage(eventId)}
            `,
          timestamp: error.timestamp,
        };

      case "ExpiredAuthorizationError":
        return {
          title: "Oops! Something Went Wrong!",
          message: "Your session has expired. Please sign in again.",
          timestamp: error.timestamp,
        };

      case "NetworkError":
        return {
          title: "Oops! Something Went Wrong!",
          message: outdent`
            Your web browser is unable to contact the Cartographer servers.
            This could be due to a problem with your Internet connection.

            Please try again in a few minutes. If the problem persists,
            please [contact support](https://help.cartographer.io/contact) and quote this error message.
            `,
          timestamp: error.timestamp,
        };

      default:
        return {
          title: "Unknown Error",
          message: outdent`
            An unexpected error occurred.

            ${reportMessage(eventId)}
            `,
          timestamp: error.timestamp,
        };
    }
  } else if (error instanceof Error) {
    return {
      title: "Oops! Something Went Wrong!",
      message: outdent`
        An unexpected error occurred:

        ${error.name}: ${error.message}

        ${reportMessage(eventId)}
        `,
      timestamp: nowTimestamp(),
    };
  } else {
    return {
      title: "Oops! Something Went Wrong!",
      message: outdent`
        An unexpected error occurred!

        ${reportMessage(eventId)}
        `,
      timestamp: nowTimestamp(),
    };
  }
}

export function useErrorDetails(
  error: unknown,
  eventId: string | null
): ErrorDetails {
  return useMemo(() => errorDetails(error, eventId), [error, eventId]);
}

function reportMessage(eventId?: string | null) {
  return eventId == null
    ? outdent`
      Please report this error to the Cartographer support team,
      by email or using [the contact form](https://help.cartographer.io/contact) on our help site,
      quoting the full error message below. We'll help as soon as we can!
      `
    : outdent`
      The error has been reported to our support team.
      Click "Send Feedback" below to add additional detail (e.g. steps to recreate the problem).
      `;
}

function permissionCheckNames(check: PermissionCheck): string[] {
  function loop(check: PermissionCheck): string[] {
    switch (check.type) {
      case "Always":
      case "CanGrantQualification":
      case "CanGrantRole":
      case "HasAccount":
      case "HasQualification":
      case "HasRole":
      case "HasUserId":
      case "InAnyWorkspace":
      case "Never":
      case "WorkspaceAccess":
        return [];
      case "And":
      case "Or":
        return check.args.flatMap(loop);
      case "Named":
        return [check.name];
      default:
        return checkExhausted(check);
    }
  }

  return loop(check);
}
