import { Result } from "@cartographerio/fp";
import {
  AccessToken,
  ApiParams,
  AuthError,
  Credentials,
  UserRef,
  bearerAuth,
  internalError,
  missingAuthorizationError,
  userRef,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { isEqual } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";

import queries from "../../../queries";
import { CookieApi, useCookie } from "../../hooks/useCookie";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useApiConfig } from "../apiConfig";
import { AuthContext, AuthContextValue } from "./context";
import { AccessTokenData, isAccessTokenData } from "./state";

export function useAuthContext(): AuthContextValue {
  return useContext(AuthContext);
}

export function useApiParams(): ApiParams {
  const apiConfig = useApiConfig();
  const accessToken = useOptAccessToken();

  return useMemo(() => {
    return accessToken != null
      ? { apiConfig, auth: bearerAuth(accessToken) }
      : { apiConfig };
  }, [accessToken, apiConfig]);
}

export function useOptAccessToken(): AccessToken | null {
  return useAuthContext().state.accessTokenData?.effective ?? null;
}

export function useAccessToken(): AccessToken {
  return useOptAccessToken() ?? raise(new Error("No access token"));
}

export function useOptCurrentUserRef(): UserRef | null {
  const v2 = useOptCredentials();

  return useMemo(() => {
    // The survey editor (among other pages) will re-initialise itself if the result of this hook changes.
    //
    // We've therefore engineered this useMemo hook to only depend on the minimal number of value-equality-checked fields.
    if (v2?.identity.userId != null) {
      return userRef(v2.identity.screenName, v2.identity.userId);
    } else {
      return null;
    }
  }, [v2?.identity.userId, v2?.identity.screenName]);
}

export function useCurrentUserRef(): UserRef {
  return useOptCurrentUserRef() ?? raise(new Error("No access token"));
}

function useCredentialsResult(): Result<AuthError, Credentials> {
  const apiConfig = useApiConfig();
  const accessToken = useOptAccessToken();

  const credentials = useSuspenseQueryData(
    queries.optional(accessToken, accessToken =>
      queries.auth.v2.readAttempt(apiConfig, accessToken)
    )
  );

  return (
    credentials ??
    Result.fail<AuthError, Credentials>(missingAuthorizationError())
  );
}

export function useOptCredentials(): Credentials | null {
  const current = useCredentialsResult().getOrNull();

  // So that hooks down the line don't refresh, since the object ref changes.
  const [credentials, setCredentials] = useState<Credentials | null>(current);
  useEffect(() => {
    if (!isEqual(credentials, current)) {
      setCredentials(current);
    }
  }, [credentials, current]);

  return credentials;
}

export function useCredentials(): Credentials {
  return useOptCredentials() ?? raise(internalError("No credentials found"));
}

export function useAccessTokenCookie(): CookieApi<AccessTokenData> {
  return useCookie("Auth", {}, "/", isAccessTokenData);
}
