import { SelectOption, SelectValue } from "@cartographerio/topo-form";
import { filterAndMap } from "@cartographerio/util";
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { union, without } from "lodash";
import { ReactElement, useCallback, useMemo } from "react";

import { useIsNarrow } from "../contexts/breakpoint";
import { Highlight } from "../hooks/highlight";
import Checkbox from "./Checkbox";

export interface CheckboxGroupProps<A extends SelectValue>
  extends Omit<SimpleGridProps, "onChange"> {
  value: A[];
  options: SelectOption<A>[];
  hiddenOptions?: A[];
  noneOption?: A;
  onChange?: (value: A[]) => void;
  highlight?: Highlight;
  disabled?: boolean;
  columns?: 1 | 2 | 3;
}

interface Selection<A extends SelectValue> {
  option: SelectOption<A>;
  selected: boolean;
}

export default function CheckboxGroup<A extends SelectValue>(
  props: CheckboxGroupProps<A>
): ReactElement {
  const {
    disabled,
    value,
    options,
    hiddenOptions,
    noneOption,
    onChange,
    highlight,
    columns,
    ...rest
  } = props;

  const isNarrow = useIsNarrow();

  const selections = useMemo<Selection<A>[]>(
    () =>
      filterAndMap(options, option => {
        if (
          hiddenOptions == null ||
          !hiddenOptions.includes(option.value) ||
          value.includes(option.value)
        ) {
          return {
            option,
            selected: value.includes(option.value),
          };
        } else {
          return null;
        }
      }),
    [hiddenOptions, options, value]
  );

  const handleChange = useCallback(
    (value: A, selected: boolean) => {
      let ans: A[] = selections
        .filter(sel => sel.selected)
        .map(sel => sel.option.value);

      if (selected) {
        if (noneOption == null) {
          ans = union(ans, [value]);
        } else if (value === noneOption) {
          ans = [value];
        } else {
          ans = without(union(ans, [value]), noneOption);
        }
      } else {
        ans = without(ans, value);
      }

      onChange?.(ans);
    },
    [noneOption, onChange, selections]
  );

  return (
    <SimpleGrid
      w="100%"
      rowGap="2"
      columnGap="1"
      columns={isNarrow ? 1 : columns}
      {...rest}
    >
      {selections.map(({ option, selected }, index) => (
        <Checkbox
          disabled={disabled}
          key={index}
          value={selected}
          onChange={() => handleChange(option.value, !selected)}
          highlight={highlight}
          checkboxLabel={option.label}
        />
      ))}
    </SimpleGrid>
  );
}
