import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import Button from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import ProjectList from "../../components/ProjectList";
import RequirePermissionLink from "../../components/RequirePermissionLink";
import ResetFiltersAlert from "../../components/ResetFiltersAlert";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceProjectListPage(
  props: RouteProps<typeof routes.workspace.project.list>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();
  const navigate = useNavigate();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const { order = "name-asc", page, count, q: searchTerm } = query;

  useRequirePermission(checks.workspace.admin(workspace), () =>
    navigate(routes.workspace.home.url([workspace.alias]))
  );

  usePageTitle(`Projects - ${workspace.name}`);

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="projects" />
      <WorkspacePageHeader workspace={workspace} selected="projects" />
      <PageContainer>
        <Spaced>
          <ProjectList
            page={page}
            count={count}
            order={order}
            searchTerm={searchTerm}
            workspace={workspace.alias}
            itemLink={project =>
              routes.workspace.project.home.url([
                workspace.alias,
                project.alias,
              ])
            }
            onSearchTermChange={q =>
              updateQuery({ ...query, q: q ?? undefined, page: 0 })
            }
            resetFilters={
              <ResetFiltersAlert
                route={routes.workspace.project.list}
                query={query}
                updateQuery={updateQuery}
              />
            }
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
            addButton={
              <RequirePermissionLink.Internal
                check={checks.auth.globalAdmin}
                to={routes.workspace.project.create.url([workspace.alias])}
                failMode="hidden"
              >
                {passes => (
                  <Button label="Add" colorScheme="blue" disabled={!passes} />
                )}
              </RequirePermissionLink.Internal>
            }
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
