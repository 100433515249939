import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { Message } from "@cartographerio/topo-core";
import { Flex, chakra, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { projectEmailSettingsRule } from "../../../schema/emailSettings";
import { rule } from "../../../schema/rule";
import { teamRule } from "../../../schema/team";
import { useIOErrorAlert, useIOPrompt } from "../../components/Alert";
import Button from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import Pre from "../../components/Pre";
import RequirePermissionPopover from "../../components/RequirePermissionPopover";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import TeamEditor from "../../components/TeamEditor";
import WithPermission from "../../components/WithPermission";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { routes } from "../../routes";
import RecordMetadata from "../workspace/RecordMetadata";
import TeamPageHeader from "./TeamPageHeader";

export default function TeamSettingsPage(
  props: RouteProps<typeof routes.workspace.team.settings>
): ReactElement {
  const {
    path: { workspaceRef, teamRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();
  const promptAlert = useIOPrompt();
  const toast = useToast();

  const defaultTeam = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, defaultTeam.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const initialEmailSettings = useSuspenseQueryData(
    queries.team.emailSettings.v2.readOrNull(apiParams, teamRef, workspaceRef)
  );

  const initialMapSettings = useSuspenseQueryData(
    queries.team.mapSettings.v2.readOrNull(apiParams, teamRef, workspaceRef)
  );

  const defaultEmailSettings =
    useSuspenseQueryData(
      queries.when(defaultTeam.projectIds.length > 0, () =>
        queries.project.emailSettings.v1.readOrDefault(
          apiParams,
          defaultTeam.projectIds[0]
        )
      )
    ) ?? queries.project.emailSettings.v1.defaultEmailSettings;

  const defaultMapSettings =
    useSuspenseQueryData(
      queries.when(defaultTeam.projectIds.length > 0, () =>
        queries.project.mapSettings.v1.readOrDefault(
          apiParams,
          defaultTeam.projectIds[0]
        )
      )
    ) ?? queries.project.mapSettings.v1.defaultMapSettings;

  const permissionCheckPasses = usePermissionCheckRunner();

  const projectOptions = useSuspenseSearchResults(
    queries.project.v2.forWorkspace(apiParams, workspace.id),
    projects =>
      projects.map(project => ({
        label: project.name,
        value: project.id,
        disabled: !permissionCheckPasses(
          checks.team.associate(defaultTeam, project.id)
        ),
      }))
  );

  useRequirePermissionRedirect(checks.team.viewSettings(defaultTeam), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const [team, setTeam] = useState(defaultTeam);

  usePageTitle(`Settings - ${team.name} - ${workspace.name}`);

  const teamMessages = useMemo(() => teamRule(team), [team]);

  const [mapSettings, setMapSettings] = useState(initialMapSettings);

  const mapSettingsMessages = useMemo<Message[]>(() => [], []);

  const [emailSettings, setEmailSettings] = useState(initialEmailSettings);

  const emailSettingsMessages = useMemo(
    () => rule.nullable(projectEmailSettingsRule)(emailSettings),
    [emailSettings]
  );

  const allMessages = useMemo(
    () => [...teamMessages, ...emailSettingsMessages, ...mapSettingsMessages],
    [emailSettingsMessages, mapSettingsMessages, teamMessages]
  );

  const handleSave = useCallback(() => {
    queries.team.v2
      .save(queryClient, apiParams, team)
      .flatMap(IO.fromResult)
      .tap(setTeam)
      .tap(({ alias }) =>
        navigate(routes.workspace.team.settings.url([workspaceRef, alias]))
      )
      .tap(team =>
        IO.parForEach([
          queries.team.emailSettings.v2.save(
            queryClient,
            apiParams,
            team.id,
            emailSettings
          ),
          queries.team.mapSettings.v2.save(
            queryClient,
            apiParams,
            team.id,
            mapSettings
          ),
        ])
      )
      .tap(() => toast({ status: "success", description: "Settings Saved" }))
      .tapError(errorAlert)
      .unsafeRun();
  }, [
    queryClient,
    apiParams,
    team,
    errorAlert,
    navigate,
    workspaceRef,
    emailSettings,
    mapSettings,
    toast,
  ]);

  const handleDelete = useCallback(
    () =>
      promptAlert({
        title: `Are you sure you want to delete team ${team.name}`,
        message: (
          <Spaced spacing="2">
            <chakra.p>
              Surveys and photographs belonging to this team will be moved to
              the project as a whole.
            </chakra.p>
            <chakra.p>
              User will remain in the project and will continue to have the same
              level of access to other teams.
            </chakra.p>
            <chakra.p>
              To confirm your choice, enter{" "}
              <Pre
                text={team.name}
                containerProps={{
                  display: "inline-flex",
                  w: "fit-content",
                  h: "fit-content",
                  alignItems: "center",
                }}
                py="0"
                px="1"
              />{" "}
              in the text field below:
            </chakra.p>
          </Spaced>
        ),
      })
        .flatMap(({ confirmed, text }) =>
          confirmed && text === team.name
            ? queries.team.v2
                .remove(queryClient, apiParams, team.id)
                .tapError(errorAlert)
            : IO.fail("Cancelled")
        )
        .tap(() => toast({ status: "success", description: "Team Deleted" }))
        .tap(() => navigate(routes.workspace.team.list.url([workspace.alias])))
        .recover(() =>
          toast({ status: "error", description: "Delete Cancelled" })
        )
        .unsafeRun(),
    [
      promptAlert,
      team.name,
      team.id,
      queryClient,
      apiParams,
      errorAlert,
      toast,
      navigate,
      workspace.alias,
    ]
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={defaultTeam}
        teamPage="settings"
      />
      <TeamPageHeader
        workspace={workspace}
        team={defaultTeam}
        selected="settings"
      />
      <PageContainer width="narrow">
        <WithPermission check={checks.team.editSettings(defaultTeam)}>
          {passes => (
            <Spaced spacing="4">
              <TeamEditor
                team={team}
                onTeamChange={setTeam}
                projectOptions={projectOptions}
                teamMessages={teamMessages}
                emailSettings={emailSettings}
                defaultEmailSettings={defaultEmailSettings}
                onEmailSettingsChange={setEmailSettings}
                emailSettingsMessages={emailSettingsMessages}
                mapSettings={mapSettings}
                defaultMapSettings={defaultMapSettings}
                onMapSettingsChange={setMapSettings}
                mapSettingsMessages={mapSettingsMessages}
                disabled={!passes}
              />
              <Flex justifyContent="space-between">
                <SaveButton
                  onClick={handleSave}
                  messages={allMessages}
                  disabled={!passes}
                />
                <RequirePermissionPopover
                  check={checks.workspace.admin(workspace)}
                  failMessage="Only available to workspace admins"
                >
                  {isWsAdmin => (
                    <Button
                      colorScheme="red"
                      label="Delete Team"
                      onClick={handleDelete}
                      disabled={!isWsAdmin}
                    />
                  )}
                </RequirePermissionPopover>
              </Flex>

              <RecordMetadata.Disclosure>
                <RecordMetadata.Item label="Team ID" value={team.id} />
              </RecordMetadata.Disclosure>
            </Spaced>
          )}
        </WithPermission>
      </PageContainer>
    </>
  );
}
