import {
  Message,
  ScreenName,
  User,
  unsafeEmail,
  unsafeScreenName,
} from "@cartographerio/types";
import outdent from "outdent";
import { ReactElement, useMemo, useState } from "react";

import { splitMessages } from "../../schema/rule/errors";
import { userErrorKeys } from "../../schema/user";
import MessageFormControl from "./MessageFormControl";
import MessageList from "./MessageList";
import TextField from "./TextField";

interface UserDetailsEditorProps {
  value: User;
  onChange?: (user: User) => void;
  messages?: Message[];
  disabled?: boolean;
}

export function formatScreenName(first: string, last: string): ScreenName {
  return unsafeScreenName(`${first} ${last}`.trim());
}

export default function UserDetailsEditor(
  props: UserDetailsEditorProps
): ReactElement {
  const { value, onChange, messages = [], disabled } = props;

  const errors = useMemo(
    () =>
      splitMessages(
        messages,
        userErrorKeys.filter(key => key !== "roles")
      ),
    [messages]
  );

  const [screenNameTouched, setScreenNameTouched] = useState(
    () =>
      value.screenName.length === 0 ||
      formatScreenName(value.firstName, value.lastName) !== value.screenName
  );

  return (
    <>
      <MessageFormControl label="First Name" messages={errors.firstName}>
        <TextField.String
          value={value.firstName}
          onChange={firstName =>
            onChange?.({
              ...value,
              screenName: screenNameTouched
                ? value.screenName
                : formatScreenName(firstName, value.lastName),
              firstName,
            })
          }
          disabled={disabled}
        />
      </MessageFormControl>

      <MessageFormControl label="Last Name" messages={errors.lastName}>
        <TextField.String
          value={value.lastName}
          onChange={lastName =>
            onChange?.({
              ...value,
              screenName: screenNameTouched
                ? value.screenName
                : formatScreenName(value.firstName, lastName),
              lastName,
            })
          }
          disabled={disabled}
        />
      </MessageFormControl>

      <MessageFormControl label="Email Address" messages={errors.email}>
        <TextField.String
          value={value.email}
          onChange={email =>
            onChange?.({ ...value, email: unsafeEmail(email) })
          }
          disabled={disabled}
        />
      </MessageFormControl>

      <MessageFormControl
        label="Screen Name"
        messages={errors.screenName}
        help={SCREEN_NAME_HELP}
      >
        <TextField.String
          value={value.screenName}
          onChange={screenName => {
            setScreenNameTouched(true);
            onChange?.({ ...value, screenName: unsafeScreenName(screenName) });
          }}
          disabled={disabled}
        />
      </MessageFormControl>
      <MessageList messages={errors._rest_} />
    </>
  );
}

const SCREEN_NAME_HELP = outdent`
  A publicly visible handle used to provide acknowledgement on surveys
  and uploaded files across Cartographer and the wider internet.
  By default we use your real name as your screen name.

  You can change this if you prefer to keep your identity private.
  Workspace admins can always access real names and email addresses
  for the users in their workspaces.
`;
