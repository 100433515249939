import { Option } from "@cartographerio/fp";
import { SelectOption } from "@cartographerio/topo-form";
import { Role, TeamId, Workspace, teamMemberRole } from "@cartographerio/types";
import { filterAndMap, raise } from "@cartographerio/util";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import {
  Card,
  CardBody,
  CardProps,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, useMemo, useState } from "react";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";

import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import Button from "../Button";
import FormLabel from "../FormLabel";
import Select from "../Select";
import Spaced from "../Spaced";
import {
  PROJECT_ROLE_HELP,
  TEAM_ROLE_HELP,
  WORKSPACE_ROLE_HELP,
} from "./helpText";
import {
  ProjectRoleNode,
  RemoveButton,
  TeamRoleNode,
  WorkspaceRoleNode,
} from "./RoleNode";
import { useWorkspaceRoles } from "./useRoles";

interface WorkspacePanelProps extends Omit<CardProps, "children" | "onChange"> {
  value: Role[];
  workspace: Workspace;
  graph: WorkspaceGraph;
  collapsible: boolean;
  canRemoveWorkspace: boolean;
  initialIsOpen?: boolean;
  disabled?: boolean;
  onChange?: (value: Role[]) => void;
}

export default function WorkspacePanel(
  props: WorkspacePanelProps
): ReactElement {
  const {
    value,
    workspace,
    graph,
    collapsible,
    canRemoveWorkspace,
    initialIsOpen = false,
    disabled,
    onChange,
    ...rest
  } = props;

  const { addRole, removeRole, getWorkspaceRole, getTeamRole, getProjectRole } =
    useWorkspaceRoles(graph, value, onChange);

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: initialIsOpen });

  const workspaceRole =
    useMemo(
      () => getWorkspaceRole(workspace.id),
      [getWorkspaceRole, workspace.id]
    ) ?? raise<Role>(`Could not find workspace ${workspace.name}`);

  const teamRoles = useMemo(
    () =>
      filterAndMap(graph.findTeamsByWorkspaceId(workspace.id), team =>
        Option.wrap(getTeamRole(team.id))
          .nullMap(role => ({ role, team }))
          .getOrNull()
      ),
    [getTeamRole, workspace.id, graph]
  );

  const projectRoles = useMemo(
    () =>
      graph
        .findProjectsByWorkspaceId(workspace.id)
        .map(project => ({ role: getProjectRole(project.id), project })),
    [getProjectRole, workspace.id, graph]
  );

  const teamOptions = useMemo(
    (): SelectOption<TeamId>[] =>
      graph
        .findTeamsByWorkspaceId(workspace.id)
        .map(team => ({ label: team.name, value: team.id })),
    [workspace.id, graph]
  );

  const [selectedTeamId, setSelectedTeamId] = useState<TeamId | null>(null);

  const multiTeam = useTeamsEnabled(workspace);

  return (
    <Card {...rest}>
      <CardBody>
        <HStack align="flex-start" gap="2">
          {collapsible && (
            <Flex grow={0} shrink={0}>
              <IconButton
                variant="ghost"
                title="Expand/collapse workspace"
                aria-label="Expand/collapse workspace"
                size="sm"
                icon={
                  isOpen ? (
                    <IoChevronDown size="1.25rem" />
                  ) : (
                    <IoChevronForward size="1.25rem" />
                  )
                }
                onClick={onToggle}
              />
            </Flex>
          )}
          <Spaced flexGrow={1} flexShrink={1}>
            <HStack justify="space-between" align="center">
              <HStack spacing="0" align="center">
                <FormLabel text={workspace.name} fontSize="lg" mb="0" />
              </HStack>
              {!disabled && canRemoveWorkspace && (
                <RemoveButton
                  label="Remove Workspace"
                  onClick={() => removeRole(workspaceRole)}
                />
              )}
            </HStack>

            {isOpen && (
              <Spaced spacing="2">
                <WorkspaceRoleNode
                  workspace={workspace}
                  graph={graph}
                  value={workspaceRole}
                  disabled={disabled}
                  help={WORKSPACE_ROLE_HELP}
                  onChange={addRole}
                />

                {multiTeam && (
                  <Spaced spacing="4">
                    <FormLabel text="Team Roles" help={TEAM_ROLE_HELP} />
                    {teamRoles.map(({ role, team }) => (
                      <TeamRoleNode
                        key={team.id}
                        value={role}
                        team={team}
                        graph={graph}
                        disabled={disabled}
                        onChange={value =>
                          value != null ? addRole(value) : removeRole(role)
                        }
                      />
                    ))}
                    {!disabled && (
                      <HStack w="100%">
                        <Select.Searchable
                          options={teamOptions}
                          onChange={setSelectedTeamId}
                          placeholder="Search for a team"
                        />
                        <Button
                          label="Add Team"
                          variant="outline"
                          onClick={() =>
                            selectedTeamId != null
                              ? addRole(teamMemberRole(selectedTeamId))
                              : null
                          }
                          disabled={selectedTeamId == null}
                        />
                      </HStack>
                    )}
                  </Spaced>
                )}

                <Spaced spacing="2">
                  <FormLabel
                    text={multiTeam ? "Project-Wide Roles" : "Project Roles"}
                    help={PROJECT_ROLE_HELP}
                    h="12"
                  />
                  {projectRoles.map(({ role, project }) => (
                    <ProjectRoleNode
                      key={project.id}
                      value={role}
                      project={project}
                      graph={graph}
                      disabled={disabled}
                      onChange={value =>
                        value != null
                          ? addRole(value)
                          : role != null
                          ? removeRole(role)
                          : raise(
                              `Could not find role to remove for ${project.name}`
                            )
                      }
                    />
                  ))}
                </Spaced>
              </Spaced>
            )}
          </Spaced>
        </HStack>
      </CardBody>
    </Card>
  );
}
