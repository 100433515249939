import {
  PartialParams,
  WorkspaceBillingSummarySearchOptionsV1,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  SearchResults,
  WorkspaceBillingSummary,
  WorkspaceRef,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type BillingSummaryKey =
  | ["billing", "workspace", "summary"]
  | [
      "billing",
      "workspace",
      "summary",
      "v1",
      "search",
      PartialParams<WorkspaceBillingSummarySearchOptionsV1>
    ]
  | ["billing", "workspace", "summary", "v1", "readOrFail", WorkspaceRef];

export function search(
  apiParams: ApiParams,
  options?: PartialParams<WorkspaceBillingSummarySearchOptionsV1>
): UseQueryOpts<SearchResults<WorkspaceBillingSummary>, BillingSummaryKey> {
  return {
    queryKey: [
      "billing",
      "workspace",
      "summary",
      "v1",
      "search",
      options ?? {},
    ],
    queryFn: () =>
      endpoints.billing.summary.v1.search(apiParams, options).unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceBillingSummary, BillingSummaryKey> {
  return {
    queryKey: [
      "billing",
      "workspace",
      "summary",
      "v1",
      "readOrFail",
      workspaceRef,
    ],
    queryFn: () =>
      endpoints.billing.summary.v1
        .readOrFail(apiParams, workspaceRef)
        .unsafeRun(),
  };
}
