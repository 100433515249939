import { checks } from "@cartographerio/permission";
import { Box, Card, CardBody, CardProps } from "@chakra-ui/react";
import { ReactNode } from "react";

import { useAdminContentVisible } from "../hooks/useAdminContentVisible";
import RequirePermission from "./RequirePermission";
import Spaced from "./Spaced";

interface AdminContentProps extends CardProps {
  fallback?: ReactNode;
  children: ReactNode;
}

export const ADMIN_CONTENT_BACKGROUND = "orange.100";

export default function AdminContent(props: AdminContentProps): ReactNode {
  const { fallback, children, ...rest } = props;

  const [enabled] = useAdminContentVisible();

  return (
    enabled && (
      <RequirePermission check={checks.auth.globalAdmin} fallback={fallback}>
        <Card bg="orange.100" position="relative" {...rest}>
          <CardBody>
            <Spaced spacing="1">
              {children}
              <Box
                fontSize="sm"
                color="orange.500"
                fontStyle="italic"
                textAlign="right"
              >
                Admin Settings
              </Box>
            </Spaced>
          </CardBody>
        </Card>
      </RequirePermission>
    )
  );
}
