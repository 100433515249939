import {
  BoxProps,
  Link as ChakraLink,
  SystemProps,
  ThemingProps,
} from "@chakra-ui/react";
import { To } from "history";
import {
  DOMAttributes,
  ForwardedRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  forwardRef,
} from "react";
import { Link as RouterLink } from "react-router-dom";

export interface ExternalLinkProps
  extends DOMAttributes<HTMLAnchorElement>,
    SystemProps,
    ThemingProps<"Link"> {
  to?: string;
  title?: string;
  pointerEvents?: BoxProps["pointerEvents"];
  disabled?: boolean;
  tabIndex?: number;
  children?: ReactNode;
}

function handleClick(evt: MouseEvent<HTMLAnchorElement>) {
  evt.stopPropagation();
}

function ExternalLink(
  props: ExternalLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
): ReactElement {
  const {
    to,
    title,
    pointerEvents,
    disabled,
    tabIndex,
    children = to,
    ...rest
  } = props;

  return (
    <ChakraLink
      ref={ref}
      href={disabled ? undefined : to}
      title={title}
      tabIndex={tabIndex}
      isExternal={true}
      pointerEvents={pointerEvents}
      onClick={handleClick}
      cursor={disabled ? "not-allowed" : undefined}
      color={disabled ? "blackAlpha.300" : undefined}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
}

export interface InternalLinkProps
  extends DOMAttributes<HTMLAnchorElement>,
    SystemProps,
    ThemingProps<"Link"> {
  to?: To;
  title?: string;
  pointerEvents?: BoxProps["pointerEvents"];
  disabled?: boolean;
  tabIndex?: number;
  children: ReactNode;
}

function InternalLink(
  props: InternalLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
): ReactElement {
  const { to, title, pointerEvents, disabled, tabIndex, children, ...rest } =
    props;

  return (
    <ChakraLink
      ref={ref}
      as={RouterLink}
      to={disabled ? undefined : to}
      title={title}
      tabIndex={tabIndex}
      isExternal={false}
      pointerEvents={pointerEvents}
      onClick={handleClick}
      cursor={disabled ? "not-allowed" : undefined}
      color={disabled ? "blackAlpha.300" : undefined}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
}

export default {
  External: forwardRef(ExternalLink),
  Internal: forwardRef(InternalLink),
};
