import { checks } from "@cartographerio/permission";
import {
  Qualification,
  QualificationAlias,
  QualificationHistoryEvent,
  QualificationId,
  QualificationRegisterEntry,
  QualificationRegisterPrivacyEnum,
  QualificationRegisterSettings,
  QualificationRegisterSharingEnum,
  QualificationRoleName,
  QualificationRoleNameEnum,
  UserId,
  ddmmyyyy,
  formatTimestamp,
  identityToUserRef,
  nowTimestamp,
  timestampGt,
  timestampToDate,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  chakra,
  useBoolean,
} from "@chakra-ui/react";
import { subDays } from "date-fns";
import { isEqual, maxBy } from "lodash";
import outdent from "outdent";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

import mapObject from "../../util/mapObject";
import { useCredentials } from "../contexts/auth";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { routes } from "../routes";
import FormLabel from "./FormLabel";
import Link from "./Link";
import Placeholder from "./Placeholder";
import QualificationHistoryModal from "./QualificationHistoryModal";
import Select from "./Select";
import Spaced from "./Spaced";

interface QualificationEditorProps {
  myAccount: boolean;
  userId: UserId;
  qualifications: Qualification[];
  defaultHistories: Record<QualificationId, QualificationHistoryEvent[]>;
  histories: Record<QualificationId, QualificationHistoryEvent[]>;
  settings: Record<QualificationId, QualificationRegisterSettings>;
  entries: Record<QualificationId, QualificationRegisterEntry>;
  onHistoriesChange?: Dispatch<
    SetStateAction<Record<QualificationId, QualificationHistoryEvent[]>>
  >;
  onSettingsChange?: Dispatch<
    SetStateAction<Record<QualificationId, QualificationRegisterSettings>>
  >;
  disabled: boolean;
}

const defaultQualificationRegisterSettings: QualificationRegisterSettings = {
  privacy: "Name",
  sharing: "Private",
};

export default function QualificationsEditor(props: QualificationEditorProps) {
  const {
    myAccount,
    userId,
    qualifications,
    defaultHistories,
    histories: allHistories,
    settings: allSettings,
    entries: allEntries,
    onHistoriesChange,
    onSettingsChange,
    disabled,
  } = props;

  const { identity } = useCredentials();

  const permissionCheckPasses = usePermissionCheckRunner();

  const latestHistoryEvents = useMemo(
    (): Record<QualificationId, QualificationHistoryEvent | null> =>
      mapObject(allHistories, ([id, history]) => [
        id,
        maxBy(history, event => event.timestamp.iso8601) ?? null,
      ]),
    [allHistories]
  );

  const historiesChanged = useMemo(
    () => !isEqual(defaultHistories, allHistories),
    [defaultHistories, allHistories]
  );

  const handleHistoryChange = useCallback(
    (id: QualificationId, history: QualificationHistoryEvent[]) => {
      onHistoriesChange?.(all => ({ ...all, [id]: history }));
    },
    [onHistoriesChange]
  );

  const handleSettingsChange = useCallback(
    (id: QualificationId, settings: QualificationRegisterSettings) => {
      onSettingsChange?.(all => ({ ...all, [id]: settings }));
    },
    [onSettingsChange]
  );

  const handleRoleChange = useCallback(
    (id: QualificationId, newName: QualificationRoleName | null) => {
      const history = allHistories[id] ?? [];

      const lastEvent = lastQualificationHistoryEvent(history);

      const eventToInsert = {
        updatedBy: identityToUserRef(identity),
        timestamp: nowTimestamp(),
        roleName: newName,
      };

      if (
        lastEvent != null &&
        lastEvent[1].updatedBy?.userId === identity.userId &&
        timestampToDate(lastEvent[1].timestamp) > subDays(Date.now(), 1)
      ) {
        handleHistoryChange(
          id,
          history.toSpliced(lastEvent[0], 1, eventToInsert)
        );
      } else {
        handleHistoryChange(id, history.concat(eventToInsert));
      }
    },
    [allHistories, identity, handleHistoryChange]
  );

  const panelProps = useMemo<QualificationEditorPanelProps[]>(
    () =>
      filterAndMap(qualifications, qualification => {
        const roleName =
          latestHistoryEvents[qualification.id]?.roleName ?? null;

        if (
          roleName != null ||
          (allHistories[qualification.id] != null &&
            allHistories[qualification.id].length > 0)
        ) {
          return {
            myAccount,
            userId,
            qualification,
            roleName,
            history: allHistories[qualification.id],
            settings:
              allSettings[qualification.id] ??
              defaultQualificationRegisterSettings,
            entry: allEntries[qualification.id] ?? null,
            onHistoryChange: (history: QualificationHistoryEvent[]) =>
              handleHistoryChange(qualification.id, history),
            onSettingsChange: (settings: QualificationRegisterSettings) =>
              handleSettingsChange(qualification.id, settings),
            onRoleChange: (roleName: QualificationRoleName | null) =>
              handleRoleChange(qualification.id, roleName),
            disabled,
          };
        } else {
          return null;
        }
      }),
    [
      qualifications,
      latestHistoryEvents,
      allHistories,
      myAccount,
      userId,
      allSettings,
      allEntries,
      disabled,
      handleHistoryChange,
      handleSettingsChange,
      handleRoleChange,
    ]
  );

  const remaining = useMemo(
    () =>
      qualifications
        .filter(
          qualification =>
            !panelProps.some(
              props => props.qualification.id === qualification.id
            )
        )
        .filter(qualification =>
          permissionCheckPasses(
            checks.qualification.grant("Trainee", qualification.id)
          )
        ),
    [permissionCheckPasses, panelProps, qualifications]
  );

  return (
    <Spaced spacing="4">
      {historiesChanged && (
        <Alert status="warning" variant="solid" rounded="md">
          <AlertIcon />
          You have unsaved qualification history changes!
        </Alert>
      )}

      {panelProps.length === 0 ? (
        <Placeholder
          text={
            myAccount
              ? "You don't have any qualifications"
              : "The user doesn't have any qualifications"
          }
        />
      ) : (
        panelProps.map(props => (
          <QualificationEditorPanel
            key={props.qualification.alias}
            {...props}
          />
        ))
      )}

      {!disabled && remaining.length > 0 && (
        <Menu placement="bottom-end">
          <MenuButton as={Button} variant="outline">
            Add Qualification
          </MenuButton>
          <MenuList zIndex="popover">
            {remaining.map(qualification => (
              <MenuItem
                key={qualification.id}
                onClick={() => handleRoleChange(qualification.id, "Qualified")}
              >
                {qualification.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Spaced>
  );
}

interface QualificationEditorPanelProps {
  myAccount: boolean;
  userId: UserId;
  qualification: Qualification;
  roleName: QualificationRoleName | null;
  history: QualificationHistoryEvent[];
  settings: QualificationRegisterSettings;
  entry: QualificationRegisterEntry | null;
  onHistoryChange: (history: QualificationHistoryEvent[]) => void;
  onSettingsChange: (settings: QualificationRegisterSettings) => void;
  onRoleChange: (roleName: QualificationRoleName | null) => void;
  disabled: boolean;
}

function QualificationEditorPanel(props: QualificationEditorPanelProps) {
  const {
    myAccount,
    userId,
    qualification,
    roleName,
    history,
    settings,
    entry,
    onHistoryChange,
    onSettingsChange,
    onRoleChange,
    disabled,
  } = props;

  const [historyModalOpen, { on: openHistoryModal, off: closeHistoryModal }] =
    useBoolean(false);

  const permissionCheckPasses = usePermissionCheckRunner();

  const canUpdateHistory = useMemo(
    () =>
      permissionCheckPasses(
        checks.qualification.grant("Trainee", qualification.id)
      ),
    [permissionCheckPasses, qualification.id]
  );

  const canUpdateSettings = useMemo(
    () => checks.qualification.editRegisterSettings(userId, qualification.id),
    [qualification.id, userId]
  );

  const disabledRoleNameOptions = useMemo(
    () =>
      QualificationRoleNameEnum.values.filter(
        value =>
          !permissionCheckPasses(
            checks.qualification.grant(value, qualification.id)
          )
      ),
    [permissionCheckPasses, qualification.id]
  );

  const lastEvent = useMemo(
    () => lastQualificationHistoryEvent(history),
    [history]
  );

  const certificateLink: ReactNode =
    entry != null ? (
      <>
        (<CertificateLink entryId={entry.id} />)
      </>
    ) : null;

  const registerLink: ReactNode = (
    <>
      (<RegisterLink alias={qualification.alias} />)
    </>
  );

  return (
    <>
      <Card key={qualification.id}>
        <CardHeader pb="0">
          <HStack w="100%" justify="space-between">
            <FormLabel text={qualification.name} fontSize="lg" mb="0" />
            <IconButton
              flexShrink={0}
              flexGrow={0}
              type="button"
              icon={<IoEllipsisHorizontal />}
              variant="outline"
              aria-label="Qualification History"
              onClick={openHistoryModal}
            />
          </HStack>
        </CardHeader>
        <CardBody pt="4">
          <Spaced>
            <Spaced spacing="2">
              <FormLabel
                fontWeight="medium"
                text="Level"
                help={
                  myAccount
                    ? MY_ACCOUNT_QUALIFICATION_ROLE_HELP
                    : STANDARD_QUALIFICATION_ROLE_HELP
                }
              />

              <Select.Nullable
                placeholder="No Role"
                value={roleName}
                options={QualificationRoleNameEnum.entries}
                disabledOptions={disabledRoleNameOptions}
                onChange={onRoleChange}
                disabled={disabled || !canUpdateHistory}
              />

              {lastEvent?.[1].timestamp != null && (
                <Box>
                  <chakra.em fontSize="sm">
                    Last updated{" "}
                    {formatTimestamp(lastEvent[1].timestamp, {
                      format: ddmmyyyy,
                    })}
                  </chakra.em>
                </Box>
              )}
            </Spaced>

            <Spaced spacing="2">
              <FormLabel
                text={<>Certificate Sharing {registerLink}</>}
                help={
                  myAccount
                    ? MY_ACCOUNT_REGISTER_SHARING_HELP
                    : STANDARD_REGISTER_SHARING_HELP
                }
                fontWeight="medium"
              />

              <Select.Standard
                value={settings.sharing}
                options={QualificationRegisterSharingEnum.entries}
                showValues={true}
                onChange={sharing => onSettingsChange({ ...settings, sharing })}
                disabled={disabled || !canUpdateSettings}
              />
            </Spaced>

            <Spaced spacing="2">
              <FormLabel
                text={<>Certificate Appearance {certificateLink}</>}
                help={
                  myAccount
                    ? MY_ACCOUNT_REGISTER_PRIVACY_HELP
                    : STANDARD_REGISTER_PRIVACY_HELP
                }
                fontWeight="medium"
              />

              <Select.Standard
                value={settings.privacy}
                options={QualificationRegisterPrivacyEnum.entries}
                onChange={privacy => onSettingsChange({ ...settings, privacy })}
                disabled={disabled || !canUpdateSettings}
              />
            </Spaced>
          </Spaced>
        </CardBody>
      </Card>

      <Suspense fallback={null}>
        <QualificationHistoryModal
          isOpen={historyModalOpen}
          qualification={qualification}
          value={history}
          onClose={closeHistoryModal}
          onChange={onHistoryChange}
          disabled={disabled || !canUpdateHistory}
        />
      </Suspense>
    </>
  );
}

function RegisterLink({ alias }: { alias: QualificationAlias }) {
  return (
    <Link.External to={routes.qualification.register.url([alias])}>
      View Register
    </Link.External>
  );
}

function CertificateLink({ entryId }: { entryId: string }) {
  return (
    <Link.External to={routes.qualification.certificate.url([entryId])}>
      View Certificate
    </Link.External>
  );
}

function lastQualificationHistoryEvent(
  history: QualificationHistoryEvent[]
): [number, QualificationHistoryEvent] | null {
  return history.reduce<[number, QualificationHistoryEvent] | null>(
    (memo, event, index) =>
      memo == null || timestampGt(event.timestamp, memo[1].timestamp)
        ? [index, event]
        : memo,
    null
  );
}

const MY_ACCOUNT_QUALIFICATION_ROLE_HELP = outdent`
  Your current level in this qualification:

  - *Trainee* - not yet fully qualified.
  - *Qualified* - fully qualified surveyor.
  - *Trainer* - fully qualified trainer.
  - *Coordinator* - programme coordinator.
  - *Expired* - qualification has expired.
`;

const STANDARD_QUALIFICATION_ROLE_HELP = outdent`
  The user's current level in this qualification:

  - *Trainee* - not yet fully qualified.
  - *Qualified* - fully qualified surveyor.
  - *Trainer* - fully qualified trainer.
  - *Coordinator* - programme coordinator.
  - *Expired* - qualification has expired.
`;

const MY_ACCOUNT_REGISTER_PRIVACY_HELP = outdent`
  What personal information should the shared certificate show?

  This also affects the terms people can use
  when searching on the public register.
`;

const STANDARD_REGISTER_PRIVACY_HELP = outdent`
  What personal information should the shared certificate show?

  This also affects the terms people can use
  when searching on the public register.
  `;

const MY_ACCOUNT_REGISTER_SHARING_HELP = outdent`
  How should the certificate be shared with the public:

  - Public - Anyone can search for the certificate on the public register.
    You can also share it via its link and QR code.

  - Unlisted - You can share the certificate via its link and QR code,
    but it cannot be discovered by searching on the public register.

  - Private - Only you (and administrators) can see the certificate.

  Note that expired qualifications are always private regardless of what is selected here.
`;

const STANDARD_REGISTER_SHARING_HELP = outdent`
  How should the user's certificate be shared with the public:

  - Public - Anyone can search for the certificate on the public register.
    You can also share it via its link and QR code.

  - Unlisted - You can share the certificate via its link and QR code,
    but it cannot be discovered by searching on the public register.

  - Private - Only the certificate's subject (and administrators)
    can see the certificate.

  Note that expired qualifications are always private,
  regardless of what is selected here.
`;
