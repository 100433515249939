import { PageState } from "@cartographerio/topo-form";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, useEffect, useRef } from "react";
import { GrMenu } from "react-icons/gr";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

import NavButton from "./NavButton";
import PageLabel from "./PageLabel";

const TAB_OUTLINE_WIDTH_PX = 4;

interface PagerProps {
  pageStates: PageState[];
  currentPageIndex: number;
  onPrevPageClick?: () => void;
  onNextPageClick?: () => void;
  onPageTabClick: (index: number) => void;
}

export default function Pager(props: PagerProps): ReactElement {
  const {
    pageStates,
    currentPageIndex,
    onPageTabClick,
    onPrevPageClick,
    onNextPageClick,
  } = props;

  const tabsRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: modalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (tabsRef.current != null) {
      const pageTabs = tabsRef.current.querySelectorAll<HTMLElement>(
        ".crt-form-pager-scroll-target"
      );
      const currentTab = pageTabs[currentPageIndex];
      if (currentTab != null) {
        const currX = currentTab.offsetLeft;
        const minX = tabsRef.current.scrollWidth - tabsRef.current.clientWidth;
        const left = Math.min(currX, minX) - TAB_OUTLINE_WIDTH_PX;
        tabsRef.current.scrollTo({ left });
      }
    }
  }, [tabsRef, currentPageIndex]);

  const openModalButton = (
    <Box borderBottomWidth={2} borderBottomColor="gray.200">
      <Button
        onClick={onModalOpen}
        aria-label="Pages Menu"
        size="sm"
        variant="ghost"
        leftIcon={<GrMenu />}
      >
        Pages
      </Button>
    </Box>
  );

  const previousPageButton = (
    <NavButton
      icon={<IoChevronBack />}
      ariaLabel="Previous page"
      isDisabled={currentPageIndex === 0}
      onClick={onPrevPageClick}
      pr="1"
    />
  );

  const nextPageButton = (
    <NavButton
      icon={<IoChevronForward />}
      ariaLabel="Next page"
      isDisabled={currentPageIndex === pageStates.length - 1}
      onClick={onNextPageClick}
      pl="1"
    />
  );

  const pageTabs = (
    <Flex
      className="crt-form-pager-scroll-pane"
      align="end"
      flexGrow={1}
      flexShrink={1}
      overflow="hidden"
      position="relative"
      ref={tabsRef}
      h={9}
    >
      <Box position="absolute" minW="100%">
        <Tabs
          isFitted
          size="sm"
          colorScheme="blue"
          index={currentPageIndex}
          flexGrow={1}
          flexShrink={1}
        >
          <TabList>
            {pageStates.map((pageState, i) => (
              <Tab
                key={i}
                isDisabled={!pageState.enabled}
                className="crt-form-pager-scroll-target"
                onClick={() => onPageTabClick(i)}
                _focus={{
                  boxShadow: "none",
                  bg: "cyan.50",
                  borderBottomColor: "cyan.300",
                }}
              >
                <PageLabel pageState={pageState} />
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>
    </Flex>
  );

  return (
    <>
      <HStack spacing={0} alignItems="stretch" width="100%">
        {openModalButton}
        {previousPageButton}
        {pageTabs}
        {nextPageButton}
      </HStack>
      <Modal isOpen={modalOpen} onClose={onModalClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader textAlign="center">Pages</ModalHeader>
            <ModalBody px="2">
              <Stack direction="column" mb="4">
                {pageStates.map((pageState, index) => (
                  <Button
                    isDisabled={
                      currentPageIndex === index || !pageState.enabled
                    }
                    width="100%"
                    backgroundColor="transparent"
                    size="sm"
                    key={index}
                    onClick={() => {
                      onPageTabClick(index);
                      onModalClose();
                    }}
                    justifyContent="space-between"
                  >
                    <PageLabel pageState={pageState} />
                  </Button>
                ))}
              </Stack>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
}
