import { Box, Flex, Image, Text, ThemeTypings } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { To } from "history";
import { ReactElement, ReactNode, useState } from "react";

import Link from "./Link";

const SKIPPABLE_WORDS = ["and", "or", "in"];

export function nameToInitials(name: string): string {
  const match = name.match(/\(([^)]+)\)$/gi);

  const base = match == null ? name : match[0];

  return base
    .replace(/[^a-z0-9 ]/gi, "") // remove punctuation
    .split(" ")
    .filter(word => !SKIPPABLE_WORDS.includes(word.toLowerCase())) // remove and/or/etc
    .flatMap(
      str =>
        str.length === 0
          ? [] // empty word - skip
          : /[a-z]/g.test(str)
          ? [str[0]] // word with at least one lowercase - include initial
          : [str] // all caps - include whole word
    )
    .join("")
    .toUpperCase();
}

const hideMissingImages = css`
  :not([src]) {
    visibility: hidden;
  }
`;

interface InitialsProps {
  initials: string;
  active: boolean;
  hover: boolean;
}

function Initials(props: InitialsProps): ReactElement {
  const { initials, active, hover } = props;

  const len = initials.length;

  let fontSize: ThemeTypings["fontSizes"];
  switch (len) {
    case 1:
      fontSize = "2xl";
      break;
    case 2:
      fontSize = "xl";
      break;
    case 3:
      fontSize = "lg";
      break;
    default:
      fontSize = "md";
      break;
  }

  return (
    <Box
      mt="1"
      fontSize={fontSize}
      fontWeight="extrabold"
      color={active || hover ? "gray.600" : "gray.400"}
    >
      {initials}
    </Box>
  );
}

interface AvatarIconProps {
  name: string;
  image?: string;
  initials?: string;
  active: boolean;
  hover: boolean;
}

function AvatarIcon(props: AvatarIconProps): ReactElement {
  const { name, image, initials: _initials, active, hover } = props;

  const initials = _initials ?? nameToInitials(name);

  return (
    <Flex
      w="12"
      h="12"
      margin="2"
      borderRadius={12}
      justifyContent="center"
      alignItems="center"
    >
      {image == null ? (
        <Initials initials={initials} active={active} hover={hover} />
      ) : (
        <Image
          w="12"
          h="12"
          border="none"
          borderRadius={12}
          src={image}
          css={hideMissingImages}
        />
      )}
    </Flex>
  );
}

interface AvatarBackgroundProps {
  children: ReactNode;
  active: boolean;
  hover: boolean;
}

export function AvatarBackground(props: AvatarBackgroundProps): ReactElement {
  const { children, active, hover } = props;

  return (
    <Flex
      position="relative"
      shrink={0}
      bg="white"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      borderRadius={16}
      borderWidth={3}
      borderColor={hover ? "gray.400" : active ? "gray.300" : "gray.200"}
      padding="2px"
    >
      <Flex w="12" h="12" justifyContent="center" alignItems="center">
        {children}
      </Flex>
    </Flex>
  );
}

export interface AvatarProps {
  to?: To;
  name: string;
  image?: string;
  icon?: ReactNode | null;
  initials?: string;
  active: boolean;
  disabled?: boolean;
}

export default function Avatar(props: AvatarProps): ReactElement {
  const { to, name, image, icon, initials, active, disabled } = props;

  const [hover, setHover] = useState(false);

  return (
    <AvatarBackground active={active} hover={hover}>
      {disabled ? (
        <>
          {icon ?? (
            <Box color="gray.400">
              <AvatarIcon
                name={name}
                image={image}
                initials={initials}
                active={active}
                hover={hover}
              />
            </Box>
          )}
          <Box
            title={name}
            cursor="not-allowed"
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            bg="whiteAlpha.600"
            borderRadius={12}
          />
        </>
      ) : to != null ? (
        <Link.Internal
          to={to}
          title={name}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          color="gray.400"
        >
          {icon ?? (
            <AvatarIcon
              name={name}
              image={image}
              initials={initials}
              active={active}
              hover={hover}
            />
          )}
        </Link.Internal>
      ) : (
        <Text color="gray.400">
          {icon ?? (
            <AvatarIcon
              name={name}
              image={image}
              initials={initials}
              active={active}
              hover={hover}
            />
          )}
        </Text>
      )}
    </AvatarBackground>
  );
}
