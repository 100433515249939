import { checks } from "@cartographerio/permission";
import { RequireRoleState } from "@cartographerio/topo-form";
import { useSurveyorTeamContext } from "@cartographerio/topo-form-context";
import { ReactElement, useMemo } from "react";

import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import BlockView from "./BlockView";
import { useFormContext } from "./context/FormContext";

interface RequireRoleViewProps {
  blockKey: string;
  state: RequireRoleState;
  disabled?: boolean;
}

export default function RequireRoleView(
  props: RequireRoleViewProps
): ReactElement {
  const {
    blockKey,
    state: {
      block: { role: roleName },
      childState,
    },
    disabled: _disabled,
  } = props;

  const { primaryTeamId } = useSurveyorTeamContext();
  const { project } = useFormContext();
  const permissionCheckPasses = usePermissionCheckRunner();

  const disabled = useMemo(
    () =>
      _disabled ||
      !permissionCheckPasses(
        checks.survey.hasRequiredRole(roleName, project, primaryTeamId)
      ),
    [_disabled, permissionCheckPasses, primaryTeamId, project, roleName]
  );

  return (
    <BlockView
      blockKey={blockKey}
      blockState={childState}
      disabled={disabled}
    />
  );
}
