import {
  InvitationSearchOptionsV3,
  PartialParams,
  endpoints,
} from "@cartographerio/client";
import {
  ApiConfig,
  ApiParams,
  Invitation,
  InvitationCreate,
  InvitationId,
  InvitationSignupRequest,
  InvitationUpdate,
  SearchResults,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../base";

export type InvitationKey =
  | ["person", "invitation"]
  | [
      "person",
      "invitation",
      "v3",
      "search",
      PartialParams<InvitationSearchOptionsV3>
    ]
  | ["person", "invitation", "v3", "read", InvitationId];

export function search(
  apiParams: ApiParams,
  options: PartialParams<InvitationSearchOptionsV3> = {}
): UseQueryOpts<SearchResults<Invitation>, InvitationKey> {
  return {
    queryKey: ["person", "invitation", "v3", "search", options],
    queryFn: () =>
      endpoints.invitation.v3.search(apiParams, options).unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  invitation: InvitationId
): UseQueryOpts<Invitation, InvitationKey> {
  return {
    queryKey: ["person", "invitation", "v3", "read", invitation],
    queryFn: () =>
      endpoints.invitation.v3.readOrFail(apiParams, invitation).unsafeRun(),
  };
}

export function accept(
  queryClient: QueryClient,
  apiConfig: ApiConfig,
  invitation: InvitationId
) {
  return endpoints.invitation.v3
    .accept(apiConfig, invitation)
    .tap(() => queryClient.invalidateQueries(["person"]));
}

export function signup(
  queryClient: QueryClient,
  apiConfig: ApiConfig,
  invitation: InvitationId,
  signup: InvitationSignupRequest
) {
  return endpoints.invitation.v3
    .signup(apiConfig, invitation, signup)
    .tap(_ => queryClient.invalidateQueries(["person"]));
}

export function create(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationCreate
) {
  return endpoints.invitation.v3
    .create(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["person"]));
}

export function resend(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationId
) {
  return endpoints.invitation.v3
    .resend(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["person"]));
}

export function cancel(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationId
) {
  return endpoints.invitation.v3
    .cancel(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["person"]));
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationId,
  create: InvitationCreate | InvitationUpdate
) {
  return endpoints.invitation.v3
    .save(apiParams, invitation, create)
    .tap(_ => queryClient.invalidateQueries(["person"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  invitation: InvitationId
) {
  return endpoints.invitation.v3
    .remove(apiParams, invitation)
    .tap(_ => queryClient.invalidateQueries(["person"]));
}
