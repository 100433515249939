import { MemberSortKey } from "@cartographerio/client";
import { PermissionCheckRunner, checks } from "@cartographerio/permission";
import { Member, Qualification, Team } from "@cartographerio/types";

import {
  OnMemberQualificationChange,
  OnMemberRoleChange,
  emailColumn,
  memberTypeColumn,
  nameColumn,
  qualificationRoleColumn,
  teamRoleColumn,
} from "../../../components/MemberList/column";
import { Column } from "../../../components/SearchResultsList/column";

interface TeamMemberListColumnsProps {
  team: Team;
  qualifications?: Qualification[];
  permissionCheckPasses: PermissionCheckRunner;
  onRoleChange?: OnMemberRoleChange | null;
  onQualificationChange?: OnMemberQualificationChange | null;
}

export function teamMemberListColumns(
  props: TeamMemberListColumnsProps
): Column<Member, MemberSortKey>[] {
  const {
    team,
    qualifications,
    permissionCheckPasses,
    onRoleChange,
    onQualificationChange,
  } = props;

  return [
    memberTypeColumn,
    nameColumn,
    emailColumn,
    teamRoleColumn({
      team: team.id,
      onRoleChange: permissionCheckPasses(checks.team.grantAccess(team))
        ? onRoleChange
        : undefined,
    }),
    ...(qualifications != null
      ? qualifications.map(qualification =>
          qualificationRoleColumn({
            qualification,
            onQualificationChange,
            permissionCheckPasses,
          })
        )
      : []),
  ];
}
