import {
  PartialParams,
  SurveySearchOptionsV3,
  endpoints,
} from "@cartographerio/client";
import { SurveyModule } from "@cartographerio/inventory-surveys";
import { IO } from "@cartographerio/io";
import { blankSurvey, defaultBlankSurveyOpts } from "@cartographerio/topo-form";
import {
  ApiParams,
  ProjectId,
  SearchResults,
  Survey,
  SurveyId,
  SurveyModuleId,
  SurveyStatus,
  SurveySummary,
  TeamId,
  UserRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../base";

export type SurveyKey =
  | ["survey"]
  | [
      "survey",
      "v3",
      "search",
      SurveyModuleId,
      PartialParams<SurveySearchOptionsV3>
    ]
  | [
      "survey",
      "v3",
      "searchSummaries",
      SurveyModuleId,
      PartialParams<SurveySearchOptionsV3>
    ]
  | ["survey", "v3", "read", SurveyId];

export function search(
  apiParams: ApiParams,
  moduleId: SurveyModuleId,
  options: PartialParams<SurveySearchOptionsV3>
): UseQueryOpts<SearchResults<Survey>, SurveyKey> {
  return {
    queryKey: ["survey", "v3", "search", moduleId, options],
    queryFn: () =>
      endpoints.survey.v3.search(apiParams, moduleId, options).unsafeRun(),
  };
}

export function searchSummaries(
  apiParams: ApiParams,
  moduleId: SurveyModuleId,
  options: PartialParams<SurveySearchOptionsV3>
): UseQueryOpts<SearchResults<SurveySummary>, SurveyKey> {
  return {
    queryKey: ["survey", "v3", "search", moduleId, options],
    queryFn: () =>
      endpoints.survey.v3
        .searchSummaries(apiParams, moduleId, options)
        .unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  surveyId: SurveyId
): UseQueryOpts<Survey, SurveyKey> {
  return {
    queryKey: ["survey", "v3", "read", surveyId],
    queryFn: () =>
      endpoints.survey.v3.readOrFail(apiParams, surveyId).unsafeRun(),
  };
}

// Note: This should return an IO not a Query.
// It has side-effects on the server so it's effectively a mutation.
// We shouldn't allow react-query to re-run it.
export function blank(
  apiParams: ApiParams,
  module: SurveyModule,
  projectId: ProjectId,
  surveyor: UserRef,
  teamId?: TeamId | null,
  templateId?: SurveyId | null
): IO<Survey> {
  const blank =
    templateId != null
      ? endpoints.survey.v3
          .readOrFail(apiParams, templateId)
          .flatMap(template =>
            IO.fromResult(
              module.copy(template, {
                projectId,
                surveyor,
                teamId: template.teamId ?? teamId,
              })
            )
          )
      : IO.fromResult(
          blankSurvey(
            module.formSchema,
            module.surveySchema(),
            module.moduleId,
            projectId,
            teamId ?? null,
            { ...defaultBlankSurveyOpts, surveyor }
          )
        );

  return blank.tap(survey =>
    endpoints.survey.v3.save(apiParams, survey, { temporary: true })
  );
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  survey: Survey,
  notify?: boolean
) {
  return endpoints.survey.v3
    .save(apiParams, survey, { strict: false, notify })
    .tap(_ => queryClient.invalidateQueries(["survey"]));
}

export function saveStatus(
  queryClient: QueryClient,
  apiParams: ApiParams,
  surveyId: SurveyId,
  status: SurveyStatus
) {
  return endpoints.survey.v3
    .saveStatus(apiParams, surveyId, status)
    .tap(_ => queryClient.invalidateQueries(["survey"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  surveyId: SurveyId,
  onSuccess: () => void = () => undefined
) {
  return endpoints.survey.v3
    .remove(apiParams, surveyId)
    .tap(onSuccess)
    .tap(_ => queryClient.invalidateQueries(["survey"]));
}

export function refreshMetadata(
  queryClient: QueryClient,
  apiParams: ApiParams,
  module?: SurveyModuleId
) {
  return endpoints.survey.v3
    .refreshMetadata(apiParams, module)
    .tap(_ => queryClient.invalidateQueries(["survey"]));
}
