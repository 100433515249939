import { IO } from "@cartographerio/io";
import { unsafeEmail, unsafeScreenName } from "@cartographerio/types";
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { splitMessages } from "../../../schema/rule/errors";
import { signupErrorKeys } from "../../../schema/signup";
import Checkbox from "../../components/Checkbox";
import MessageFormControl from "../../components/MessageFormControl";
import MessageList from "../../components/MessageList";
import Para from "../../components/Para";
import TermsDocuments from "../../components/TermsDocuments";
import {
  Action,
  editEmailAction,
  editSignupAction,
  reenterEmailAction,
} from "./action";
import { Signup } from "./state";

interface SignupFormProps {
  state: Signup;
  dispatch: (action: Action | IO<unknown>) => void;
}

export function SignupForm(props: SignupFormProps): ReactElement {
  const {
    state: { signup, email },
    dispatch,
  } = props;

  const errors = useMemo(
    () => splitMessages(signup.messages, signupErrorKeys),
    [signup]
  );

  return (
    <>
      {errors._rest_.length > 0 && (
        <FormControl>
          <FormHelperText fontWeight="bold" textColor="red.500">
            <MessageList messages={errors._rest_} />
          </FormHelperText>
        </FormControl>
      )}

      <MessageFormControl
        id="email"
        label="Email Address"
        help="You'll use this to sign in to Cartographer."
        messages={errors.email}
      >
        <InputGroup>
          <Input
            value={email}
            placeholder="jane@example.com"
            onChange={evt =>
              dispatch(editEmailAction(unsafeEmail(evt.currentTarget.value)))
            }
            disabled={true}
          />
          <InputRightElement>
            <IconButton
              onClick={() => dispatch(reenterEmailAction)}
              borderLeftRadius={0}
              aria-label="Cancel"
              icon={<IoCloseSharp />}
              variant="ghost"
            />
          </InputRightElement>
        </InputGroup>
      </MessageFormControl>

      <MessageFormControl
        id="firstName"
        label="First Name"
        messages={errors.firstName}
      >
        <Input
          type="text"
          value={signup.firstName}
          onChange={evt =>
            dispatch(editSignupAction({ firstName: evt.currentTarget.value }))
          }
        />
      </MessageFormControl>

      <MessageFormControl
        id="lastName"
        label="Last Name"
        messages={errors.lastName}
      >
        <Input
          type="text"
          value={signup.lastName}
          onChange={evt =>
            dispatch(editSignupAction({ lastName: evt.currentTarget.value }))
          }
        />
      </MessageFormControl>

      <MessageFormControl
        id="screenName"
        label="Screen Name"
        help="A publicly visible handle used to credit you for the data you record.
          Change this to something other than your name if you prefer to keep your
          identity private. Administrators will always have access to your real
          name and email address."
        messages={errors.screenName}
      >
        <Input
          type="text"
          value={signup.screenName}
          onChange={evt =>
            dispatch(
              editSignupAction({
                screenName: unsafeScreenName(evt.currentTarget.value),
              })
            )
          }
        />
      </MessageFormControl>

      <MessageFormControl
        id="acceptTerms"
        label="Terms and Conditions"
        messages={errors.acceptTerms}
      >
        <Para textAlign="center" fontSize="sm">
          You must read and accept our terms and conditions to access
          Cartographer:
        </Para>
        <TermsDocuments />
        <Checkbox
          value={signup.acceptTerms}
          onChange={acceptTerms => dispatch(editSignupAction({ acceptTerms }))}
          checkboxLabel="I have read, understood, and agreed to these documents"
        />
      </MessageFormControl>
    </>
  );
}
