import { checks } from "@cartographerio/permission";
import { Team, Workspace } from "@cartographerio/types";
import { Card, CardBody, Flex, IconButton, SimpleGrid } from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoSettingsSharp } from "react-icons/io5";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import PageTopBar from "../../components/PageTopBar";
import Placeholder from "../../components/Placeholder";
import ProjectCard from "../../components/ProjectCard";
import ProjectLabel from "../../components/ProjectLabel";
import RequirePermissionLink from "../../components/RequirePermissionLink";
import Spaced from "../../components/Spaced";
import { useApiParams, useCredentials } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import usePartitionedTeams from "../../hooks/usePartitionedTeams";
import { useProjectHasTeams } from "../../hooks/useProjectHasTeams";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";

export default function ProjectHomePage(
  props: RouteProps<typeof routes.workspace.project.home>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
  } = props;

  const apiParams = useApiParams();
  const { identity } = useCredentials();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  const teams = useSuspenseSearchResults(
    queries.when(multiTeam, () =>
      queries.team.v2.forProject(apiParams, project.id)
    )
  );

  const [myTeams, otherTeams] = usePartitionedTeams(
    project,
    identity.userId,
    teams ?? undefined
  );

  useRequirePermissionRedirect(checks.project.view(project), () =>
    routes.workspace.home.url([workspace.alias])
  );

  usePageTitle(`${project.name} - ${workspace.name}`);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
      />
      <PageContainer>
        <Spaced spacing="8">
          <PageHeader
            title={<ProjectLabel project={project} suffix="(Project)" />}
            right={
              <RequirePermissionLink.Internal
                check={checks.project.viewSettings(project)}
                to={routes.workspace.project.settings.url([
                  workspace.alias,
                  project.alias,
                ])}
                failMode="hidden"
                alignSelf="start"
              >
                <Button
                  label="Project Admin"
                  leftIcon={<IoSettingsSharp />}
                  variant="outline"
                  responsive={true}
                />
              </RequirePermissionLink.Internal>
            }
          />
          <ProjectCard
            workspace={workspace}
            project={project}
            showSettingsLink={false}
          />
          {teams != null && (
            <>
              <Heading level="subsection">My Teams</Heading>
              {teams.length > 0 ? (
                <TeamsCard workspace={workspace} teams={myTeams} />
              ) : (
                <Placeholder text="You aren't a member of any teams" />
              )}
              {otherTeams.length > 0 && (
                <>
                  <Heading level="subsection">Other Teams</Heading>
                  <TeamsCard workspace={workspace} teams={otherTeams} />
                </>
              )}
            </>
          )}
        </Spaced>
      </PageContainer>
    </>
  );
}

interface TeamsCardProps {
  workspace: Workspace;
  teams: Team[];
}

function TeamsCard(props: TeamsCardProps): ReactElement {
  const { workspace, teams } = props;

  const maxColumns = 1; // teams.length > 5 ? 2 : 1;

  return (
    <Card>
      <CardBody>
        <SimpleGrid columns={[1, null, maxColumns]} rowGap="4" columnGap="6">
          {teams.map(team => (
            <Flex key={team.id} alignItems="center">
              <Flex shrink={1} grow={1}>
                {team.name}
              </Flex>
              <Flex shrink={0} grow={0}>
                <RequirePermissionLink.Internal
                  check={checks.team.viewSettings(team)}
                  to={routes.workspace.team.settings.url([
                    workspace.alias,
                    team.alias,
                  ])}
                  failMode="hidden"
                >
                  {passes => (
                    <IconButton
                      aria-label="Team Admin"
                      icon={<IoSettingsSharp />}
                      size="sm"
                      variant="outline"
                      isDisabled={!passes}
                    />
                  )}
                </RequirePermissionLink.Internal>
              </Flex>
            </Flex>
          ))}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}
