import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import { ArcgisIntegrationStatus, MapLayerId } from "@cartographerio/types";
import { HStack, useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";

import { DEFAULT_PAGE_SIZE } from "../../../../../config";
import queries from "../../../../../queries";
import { RouteProps } from "../../../../../routes";
import { useIOErrorAlert } from "../../../../components/Alert";
import BackButtonHeading from "../../../../components/BackButtonHeading";
import ButtonLink from "../../../../components/ButtonLink";
import Container from "../../../../components/Container";
import PageHeader from "../../../../components/PageHeader";
import PageTopBar from "../../../../components/PageTopBar";
import SearchField from "../../../../components/SearchField";
import SearchResultsList from "../../../../components/SearchResultsList/List";
import Select from "../../../../components/Select";
import Spaced from "../../../../components/Spaced";
import { useApiParams } from "../../../../contexts/auth";
import { usePageTitle } from "../../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../../hooks/useSuspenseQueryData";
import { useVisibleMaps } from "../../../../hooks/useVisibleMaps";
import { routes } from "../../../../routes";
import { redirectUrl } from "../ArcgisIntegrationPage";
import ProjectPageHeader from "../ProjectPageHeader";
import { arcgisActionsColumn, columns } from "./column";

export default function ArcgisIntegrationListPage(
  props: RouteProps<typeof routes.workspace.project.integrations.arcgis.list>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const { q: searchTerm, layer } = query;

  useRequirePermissionRedirect(checks.project.viewSettings(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  usePageTitle(`Integrations - ${project.name} - ${workspace.name}`);

  const maps = useVisibleMaps(project);
  const layerOptions = useMemo<SelectOption<MapLayerId>[]>(
    () =>
      maps.flatMap(schema =>
        schema.layers
          .filter(({ source }) => source.type === "CartographerSource")
          .map(layer => ({
            label:
              schema.title === layer.title
                ? layer.title
                : `${schema.title} - ${layer.title}`,
            value: layer.layerId,
          }))
      ),
    [maps]
  );

  const handleAuthorize = useCallback(
    (status: ArcgisIntegrationStatus) =>
      queries.integration.arcgis.v1
        .loginUrl(
          apiParams,
          redirectUrl(workspace.alias, project.alias, status.integration.id)
        )
        .tap(url => (window.location.href = url))
        .unsafeRun(),
    [apiParams, project.alias, workspace.alias]
  );

  const handleExport = useCallback(
    (status: ArcgisIntegrationStatus) =>
      queries.integration.arcgis.v1
        .exportOne(queryClient, apiParams, status.integration.id)
        .tap(() =>
          toast({
            status: "success",
            title: "Export Started",
            description: "We'll email you when it's complete.",
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, queryClient, toast]
  );

  const actions = arcgisActionsColumn({
    onAuthorize: handleAuthorize,
    onExport: handleExport,
  });

  const { data, error } = useQuery(
    queries.integration.arcgis.v1.search(apiParams, {
      project: project.id,
      layer,
      q: searchTerm,
    })
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="integrations"
        integration="arcgis"
      />
      <Container sticky={true} width="wide">
        <PageHeader title={project.name}>
          <ProjectPageHeader
            workspace={workspace}
            project={project}
            selected="integrations"
          />
        </PageHeader>
      </Container>
      <Container width="wide">
        <Spaced spacing="4">
          <BackButtonHeading
            to={routes.workspace.project.integrations.list.url([
              workspace.alias,
              project.alias,
            ])}
          >
            ArcGIS Integrations
          </BackButtonHeading>
          <HStack>
            <SearchField
              defaultValue={searchTerm}
              onChange={q => updateQuery({ ...query, q: q ?? undefined })}
              w="auto"
              flexShrink={1}
              flexGrow={1}
            />
            <Select.Nullable
              value={layer ?? null}
              options={layerOptions}
              placeholder="All layers"
              onChange={layer =>
                updateQuery({ ...query, layer: layer ?? undefined })
              }
              flexShrink={1}
              w="fit-content"
              maxW="52"
            />
            <ButtonLink.Internal
              colorScheme="blue"
              to={routes.workspace.project.integrations.arcgis.create.url([
                workspace.alias,
                project.alias,
              ])}
            >
              Add
            </ButtonLink.Internal>
          </HStack>
          <SearchResultsList
            results={data}
            error={error}
            page={0}
            count={data?.total ?? DEFAULT_PAGE_SIZE}
            itemKey={status => status.integration.id}
            itemLink={status =>
              routes.workspace.project.integrations.arcgis.update.url([
                workspace.alias,
                project.alias,
                status.integration.id,
              ])
            }
            columns={columns}
            actions={actions}
          />
        </Spaced>
      </Container>
    </>
  );
}
