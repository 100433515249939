import {
  SurveySearchKeyV3,
  SurveySearchOptionsV3,
} from "@cartographerio/client";
import { bboxNe, bboxSw, point } from "@cartographerio/geometry";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import {
  Survey,
  SurveyId,
  SurveyStatus,
  SurveySummary,
  namedToOpenInterval,
  roleGte,
  teamSurveyorRole,
} from "@cartographerio/types";
import { Button, useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { emptyArray } from "../../../../util";
import Container from "../../../components/Container";
import CreateSurveyButton from "../../../components/CreateSurveyButton";
import CreateSurveyPermissionPopover from "../../../components/CreateSurveyPermissionPopover";
import PageHeader from "../../../components/PageHeader";
import PageTopBar from "../../../components/PageTopBar";
import SearchResultsList from "../../../components/SearchResultsList";
import { useApiParams, useCredentials } from "../../../contexts/auth";
import { saveOptions } from "../../../form/FormTopBar/FormSaveButton";
import useModuleInventory from "../../../hooks/useModuleInventory";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { usePermissionCheckPasses } from "../../../hooks/usePermissionCheckPasses";
import usePermissionCheckRunner from "../../../hooks/usePermissionCheckRunner";
import { useProjectHasTeams } from "../../../hooks/useProjectHasTeams";
import useRedirectWhen from "../../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../routes";
import { surveyListActions, surveyListColumns } from "./column";
import SurveyListToolbar from "./SurveyListToolbar";

export default function WorkspaceSurveyListPage(
  props: RouteProps<typeof routes.workspace.project.survey.list>
): ReactElement {
  const {
    path: { workspaceRef, projectRef, moduleId },
    query,
    updateQuery,
  } = props;

  const navigate = useNavigate();
  const toast = useToast();

  const apiParams = useApiParams();
  const { identity } = useCredentials();
  const queryClient = useQueryClient();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const { page, count, order } = query;

  const module = useModuleInventory(moduleId);

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  useRedirectWhen(!project.moduleIds.includes(moduleId), () =>
    routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  useRequirePermissionRedirect(checks.survey.view(project), () =>
    routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  usePageTitle(
    `${module.names.shortName} Surveys - ${project.name} - ${workspace.name}`
  );

  const teams =
    useSuspenseSearchResults(
      queries.when(multiTeam, () =>
        queries.team.v2.forProject(apiParams, project.id)
      )
    ) ?? emptyArray;

  const surveyableTeams = useMemo(
    () =>
      teams.filter(team =>
        identity.roles.some(role => roleGte(role, teamSurveyorRole(team.id)))
      ),
    [identity.roles, teams]
  );

  const canCreateWithNoTeam = usePermissionCheckPasses(
    checks.survey.createWithTeam(project, null)
  );

  useEffect(() => {
    if (
      query.team != null &&
      !teams.some(team => query.team === team.alias || query.team === team.id)
    ) {
      updateQuery({ ...query, team: undefined });
    }
  }, [multiTeam, query, teams, updateQuery]);

  const copySurvey = useCallback(
    (survey: Survey): void => {
      IO.wrap(() =>
        navigate(
          routes.workspace.project.survey.create.url(
            [workspace.alias, project.alias, survey.moduleId],
            { template: survey.id }
          )
        )
      )
        .tap(_ =>
          toast({
            title: "Survey Duplicated",
            description: "You are now editing the copy",
            status: "success",
            duration: null,
            isClosable: true,
          })
        )
        .unsafeRun();
    },
    [navigate, project.alias, toast, workspace.alias]
  );

  const viewOnMapOptions = useCallback(
    (survey: Survey) =>
      module.mapLinks.map(
        ({ label, mapId }): SelectOption<string> => ({
          label,
          value: routes.workspace.project.map.url(
            [workspace.alias, project.alias, mapId],
            { survey: survey.id }
          ),
        })
      ),
    [module.mapLinks, project.alias, workspace.alias]
  );

  const onStatusChange = useCallback(
    (survey: Survey, status: SurveyStatus): void => {
      queries.survey.v3
        .saveStatus(queryClient, apiParams, survey.id, status)
        .tap(_ =>
          toast({
            title: "Status Saved",
            description: "Successfully updated the status",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .unsafeRun();
    },
    [apiParams, queryClient, toast]
  );

  const permissionCheckPasses = usePermissionCheckRunner();

  const canUpdateStatus = useCallback(
    (survey: Survey) =>
      permissionCheckPasses(checks.survey.updateStatus(project, survey)),
    [permissionCheckPasses, project]
  );

  const canApprove = useCallback(
    (survey: Survey) =>
      permissionCheckPasses(
        checks.survey.approveWithTeam(
          project,
          multiTeam ? survey.teamId ?? null : null
        )
      ),
    [multiTeam, permissionCheckPasses, project]
  );

  const surveySaveOptions = useCallback(
    (survey: Survey): SelectOption<SurveyStatus>[] => {
      const locked =
        survey.status === "approved" || survey.status === "rejected";

      return saveOptions(canApprove(survey), locked);
    },
    [canApprove]
  );

  const columns = useMemo(
    () =>
      surveyListColumns({
        teams,
        surveySaveOptions,
        onStatusChange,
        canUpdateStatus,
      }),
    [canUpdateStatus, onStatusChange, surveySaveOptions, teams]
  );

  const actions = useMemo(
    () => surveyListActions({ copySurvey, viewOnMapOptions, project }),
    [copySurvey, project, viewOnMapOptions]
  );

  const searchOpts = useMemo((): Partial<SurveySearchOptionsV3> => {
    const { when, where, ...rest } = query;
    const { from, to } =
      when != null ? namedToOpenInterval(when) : { from: null, to: null };
    return {
      project: project.alias,
      workspace: workspace.alias,
      ...rest,
      from: from ?? undefined,
      to: to ?? undefined,
      sw: where != null ? point(...bboxSw(where)) : undefined,
      ne: where != null ? point(...bboxNe(where)) : undefined,
      order,
      skip: page * count,
      limit: count,
    };
  }, [count, order, page, project.alias, query, workspace.alias]);

  const { data, error } = useQuery(
    queries.survey.v3.searchSummaries(apiParams, moduleId, searchOpts)
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        module={module}
      />

      <Container sticky={true} width="wide">
        <PageHeader
          title={module.names.shortName}
          right={
            <CreateSurveyPermissionPopover project={project}>
              {passes => (
                <CreateSurveyButton
                  teams={surveyableTeams}
                  includeNullOption={canCreateWithNoTeam}
                  createSurveyLink={teamAlias =>
                    routes.workspace.project.survey.create.url(
                      [workspace.alias, project.alias, module.moduleId],
                      { team: teamAlias ?? undefined }
                    )
                  }
                  disabled={!passes}
                  placement="bottom-end"
                >
                  <Button colorScheme="blue" isDisabled={!passes}>
                    Add
                  </Button>
                </CreateSurveyButton>
              )}
            </CreateSurveyPermissionPopover>
          }
          borderBottomWidth={2}
          borderBottomColor="gray.100"
        >
          <SurveyListToolbar
            query={query}
            updateQuery={updateQuery}
            module={moduleId}
            workspace={workspace}
            project={project}
          />
        </PageHeader>
      </Container>

      <Container width="wide">
        <SearchResultsList<SurveySummary, SurveySearchKeyV3, SurveyId>
          page={page}
          count={count}
          order={order}
          results={data}
          error={error}
          columns={columns}
          actions={actions}
          itemKey={survey => survey.id}
          onPageChange={page => updateQuery({ ...query, page })}
          onOrderChange={order => updateQuery({ ...query, order })}
          itemLink={survey => routes.short.survey.url([survey.id])}
        />
      </Container>
    </>
  );
}
