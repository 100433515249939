import "./TimelineChart.css";

import { featureProperty } from "@cartographerio/geometry";
import { MapLayer, NumberAttribute } from "@cartographerio/topo-map";
import { chakra } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { ReactElement, useMemo } from "react";
import {
  CartesianGrid,
  Dot,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";

import { useSelectedFeatures } from "../TopoMapContext";
import InspectorMenuItem from "./InspectorMenuItem";
import InspectorSection from "./InspectorSection";

export interface TimelineChartProps {
  layer: MapLayer;
  attribute: NumberAttribute;
}

function tickFormatter(timestamp: number): string {
  return new Date(timestamp).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export default function TimelineChart(
  props: TimelineChartProps
): ReactElement | null {
  const { layer, attribute } = props;

  const { uniqueSelectedFeatures } = useSelectedFeatures(layer.layerId);

  type DataPoint = {
    timestamp: number;
    value: unknown;
  };

  type OptNum = number | undefined;

  type DataAndBounds = [DataPoint[], OptNum, OptNum];

  const [data, min, max] = useMemo<DataAndBounds>(() => {
    const data: DataPoint[] = [];
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;

    uniqueSelectedFeatures.forEach(feature => {
      const timestamp = feature.properties?.timestamp;
      const value = featureProperty(feature, attribute.propertyName);

      if (typeof timestamp === "number") {
        data.push({ timestamp, value });
        min = Math.min(min ?? timestamp, timestamp);
        max = Math.max(max ?? timestamp, timestamp);
      }
    });

    if (min != null && max != null && min === max) {
      min = min - 30 * 60 * 1000;
      max = max + 30 * 60 * 1000;
    }

    const sorted = sortBy(data, point => point.timestamp);

    return [sorted, min, max];
  }, [attribute.propertyName, uniqueSelectedFeatures]);

  return min == null || max == null ? null : (
    <InspectorSection title="Timeline">
      <ScatterChart width={220} height={160}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="timestamp"
          tick={false}
          domain={[min, max]}
          height={2}
        />
        <YAxis
          dataKey="value"
          scale="linear"
          allowDecimals={false}
          width={30}
        />
        <Scatter
          data={data}
          dataKey="value"
          fill="#8884d8"
          shape={<Dot r={3} />}
          line={{ strokeWidth: 2 }}
          // lineType="fitting"
          isAnimationActive={false}
        />
      </ScatterChart>
      {max > min && (
        <InspectorMenuItem
          left={
            <chakra.span pl="6" fontSize="xs">
              {tickFormatter(min)}
            </chakra.span>
          }
          right={
            <chakra.span pr="2" fontSize="xs">
              {tickFormatter(max)}
            </chakra.span>
          }
        />
      )}
    </InspectorSection>
  );
}
