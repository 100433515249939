import { SurveyModule } from "@cartographerio/inventory-surveys";
import { checks } from "@cartographerio/permission";
import { Project } from "@cartographerio/types";
import { chain } from "lodash";
import { useMemo } from "react";

import useModuleInventory from "./useModuleInventory";
import usePermissionCheckRunner from "./usePermissionCheckRunner";

export function useVisibleSurveyModules(project: Project): SurveyModule[] {
  const modules = useModuleInventory();

  const permissionCheckPasses = usePermissionCheckRunner();

  return useMemo(
    () =>
      permissionCheckPasses(checks.survey.view(project))
        ? chain(modules.surveyModules())
            .filter(module => project.moduleIds.includes(module.moduleId))
            .sortBy(module => module.names.shortName)
            .value()
        : [],
    [permissionCheckPasses, modules, project]
  );
}
