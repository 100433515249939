import { SortOrder, WorkspaceSortKey } from "@cartographerio/client";
import {
  UserId,
  Workspace,
  WorkspaceId,
  WorkspaceRoleName,
} from "@cartographerio/types";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import recordWithId from "../../../util/recordWithId";
import { useApiParams } from "../../contexts/auth";
import SearchResultsList from "../SearchResultsList";
import { workspaceActions, workspaceColumns } from "./column";

interface WorkspaceListProps {
  searchTerm?: string;
  page: number;
  count: number;
  order?: SortOrder<WorkspaceSortKey>;
  user?: UserId | null;
  role?: WorkspaceRoleName | null;
  showFeaturesColumn?: boolean;
  itemLink?: (item: Workspace) => string;
  onItemClick?: (item: Workspace) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<WorkspaceSortKey>) => void;
}

export default function WorkspaceList(props: WorkspaceListProps): ReactElement {
  const {
    searchTerm,
    page,
    count,
    order,
    user,
    role,
    showFeaturesColumn = false,
    itemLink,
    onPageChange,
    onOrderChange,
  } = props;

  const apiParams = useApiParams();

  const { data, error } = useQuery(
    queries.workspace.v2.search(apiParams, {
      user,
      role,
      q: searchTerm,
      order,
      skip: page * count,
      limit: count,
    })
  );

  const columns = useMemo(
    () => workspaceColumns(showFeaturesColumn),
    [showFeaturesColumn]
  );

  return (
    <SearchResultsList<Workspace, WorkspaceSortKey, WorkspaceId>
      results={data}
      error={error}
      columns={columns}
      actions={workspaceActions}
      page={page}
      count={count}
      itemLink={itemLink}
      onPageChange={onPageChange}
      order={order}
      onOrderChange={onOrderChange}
      itemKey={recordWithId}
    />
  );
}
