import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  BillingInterval,
  BillingIntervalEnum,
  PlanId,
  WorkspaceSubscribed,
  ddmmyyyy,
  formatTimestamp,
  nowTimestamp,
  timestampGt,
  timestampToDate,
} from "@cartographerio/types";
import {
  HStack,
  SimpleGrid,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useRef } from "react";

import { STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT } from "../../config";
import queries from "../../queries";
import { useIOConfirm } from "../components/Alert";
import Button from "../components/Button";
import CallUsLink from "../components/CallUsLink";
import EmailUsLink from "../components/EmailUsLink";
import LinkButton from "../components/LinkButton";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useVolatileState } from "../hooks/useVolatileState";
import BillCard from "./BillCard";
import { formatIntervalAmount } from "./format";
import { invoiceTotalAmount } from "./helpers";
import PlanChooserModal from "./PlanChooserModal";
import UsageCard from "./UsageCard";

export interface WorkspaceSubscribedViewProps {
  summary: WorkspaceSubscribed;
}

export default function WorkspaceSubscribedView(
  props: WorkspaceSubscribedViewProps
) {
  const {
    summary: {
      workspaceId,
      workspaceAlias,
      plan: { id: planId, name: planName, limits },
      usage,
      subscription,
    },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const confirm = useIOConfirm();
  const toast = useToast();

  const {
    isOpen: planChooserOpen,
    onOpen: handlePlanChooserOpen,
    onClose: handlePlanChooserClose,
  } = useDisclosure();

  const newPlanAmountRef = useRef<HTMLElement>(null);

  const [lastInternalUpdate, setLastInternalUpdate] = useVolatileState(
    useCallback(
      () => subscription.lastInternalUpdate,
      [subscription.lastInternalUpdate]
    )
  );

  const handlePlanClick = useCallback(
    (newPlanId: PlanId, newBillingInterval: BillingInterval) => {
      const planChanged = planId !== newPlanId;
      IO.parallel([
        endpoints.billing.calculation.v1
          .readOrFail(apiParams, {
            workspace: workspaceId,
            plan: newPlanId,
            interval: newBillingInterval,
          })
          .tap(({ previewTotal }) => {
            if (newPlanAmountRef.current != null) {
              newPlanAmountRef.current.innerHTML = formatIntervalAmount(
                invoiceTotalAmount(previewTotal),
                newBillingInterval
              );
            }
          })
          .void(),
        confirm({
          title: planChanged
            ? `Confirm Plan Change`
            : `Confirm Billing Interval Change`,
          message: (
            <Spaced>
              <p>
                {planChanged
                  ? "Click OK to switch to the new plan."
                  : `Click OK to switch to ${
                      newBillingInterval === "Monthly" ? "monthly" : "yearly"
                    } billing.`}
              </p>
              <p>
                Your new bill will be{" "}
                <strong ref={newPlanAmountRef}>
                  <Spinner w="1em" h="1em" color="gray.500" />
                </strong>
                . Your next bill will be prorated accordingly.
              </p>
            </Spaced>
          ),
        })
          .flatMap(confirmed => {
            if (confirmed) {
              setLastInternalUpdate(nowTimestamp());
              return queries.billing.subscription.v1
                .update(queryClient, apiParams, workspaceId, {
                  planId: newPlanId,
                  billingInterval: newBillingInterval,
                })
                .recover(_err =>
                  toast({
                    title: "Could not update your subscription.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .void();
            } else {
              return IO.noop();
            }
          })
          .void(),
      ]).unsafeRun();
    },
    [
      apiParams,
      confirm,
      planId,
      queryClient,
      setLastInternalUpdate,
      toast,
      workspaceId,
    ]
  );

  const isUpdating = useMemo(
    () =>
      lastInternalUpdate != null &&
      timestampGt(lastInternalUpdate, subscription.lastStripeUpdate),
    [lastInternalUpdate, subscription.lastStripeUpdate]
  );

  const updateTakingTooLong = useMemo(
    () =>
      isUpdating &&
      lastInternalUpdate != null &&
      Date.now() - timestampToDate(lastInternalUpdate).getTime() >=
        STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT,
    [isUpdating, lastInternalUpdate]
  );

  const intervalButton =
    subscription.billingInterval === "Monthly" ? (
      <LinkButton onClick={() => handlePlanClick(planId, "Yearly")}>
        Switch to yearly billing and save
      </LinkButton>
    ) : (
      <LinkButton onClick={() => handlePlanClick(planId, "Monthly")}>
        Switch to monthly billing
      </LinkButton>
    );

  return (
    <>
      <Spaced spacing="6">
        <Spaced spacing="1">
          <Para textAlign="center" fontSize="large">
            {subscription.trialEnd == null ? (
              <>Your current plan</>
            ) : (
              <>Your current trial</>
            )}
          </Para>
          <Para
            fontStyle={isUpdating ? "italic" : undefined}
            color={!updateTakingTooLong && isUpdating ? "gray.400" : undefined}
            textAlign="center"
            fontSize="large"
            fontWeight="bold"
          >
            {planName} (Billed{" "}
            {BillingIntervalEnum.labelOf(subscription.billingInterval)}){" "}
          </Para>
          {subscription.trialEnd != null && (
            <Para textAlign="center" color="gray.400" pt="1">
              (trial ends{" "}
              {formatTimestamp(subscription.trialEnd, { format: ddmmyyyy })})
            </Para>
          )}
        </Spaced>
        {updateTakingTooLong ? (
          <Spaced spacing="6">
            <Para textAlign="center" __css={{ textWrap: "balance" }}>
              We&apos;re expecting an update from our payment provider but
              it&apos;s taking a long time. Please contact support if this
              message persists.
            </Para>

            <HStack gap="4" justify="center">
              <CallUsLink />
              <EmailUsLink />
            </HStack>
          </Spaced>
        ) : isUpdating ? (
          <LoadingPlaceholder h="36" label="Loading plan updates ..." />
        ) : (
          <>
            <SimpleGrid
              columns={[1, 1, 2]}
              w="fit-content"
              alignItems="stretch"
              mx="auto"
              gap="4"
            >
              <UsageCard usage={usage} limits={limits} />
              <BillCard
                workspaceAlias={workspaceAlias}
                subscription={subscription}
              />
            </SimpleGrid>
            <Para textAlign="center">
              <Button label="Change Plan" onClick={handlePlanChooserOpen} />
            </Para>
            <Para textAlign="center">{intervalButton}</Para>
            <Para fontSize="sm" color="gray.400" textAlign="center">
              * Price inclusive of tax and discounts. For a full breakdown see
              the Billing Portal.
            </Para>
          </>
        )}
      </Spaced>

      <PlanChooserModal
        workspaceRef={workspaceId}
        defaultPlanId={planId}
        defaultBillingInterval={subscription.billingInterval}
        isOpen={planChooserOpen}
        onClose={handlePlanChooserClose}
        onPlanClick={handlePlanClick}
      />
    </>
  );
}
