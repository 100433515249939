import { SelectOption } from "@cartographerio/topo-form";
import { InvitationCode, InvitationCodeAlias } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  SystemProps,
  VStack,
  chakra,
} from "@chakra-ui/react";
import outdent from "outdent";
import { QRCodeCanvas } from "qrcode.react";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { IoMdColorFilter } from "react-icons/io";
import { IoDownloadOutline } from "react-icons/io5";

import { absoluteRouteUrl } from "../../util";
import { useApiServerId } from "../contexts/apiConfig";
import { routes } from "../routes";
import Button from "./Button";
import ClickToCopy from "./ClickToCopy";
import { InvitationCodeLabel } from "./InvitationCodeLabel";
import Link from "./Link";
import Pre from "./Pre";
import Spaced from "./Spaced";

interface InvitationCodeShareModalProps {
  code: InvitationCode;
  isOpen: boolean;
  onClose: () => void;
}

export default function InvitationCodeShareModal(
  props: InvitationCodeShareModalProps
): ReactElement {
  const { code, isOpen, onClose } = props;

  // We originally used a Link element
  // for the "Download as Image" button below the QR code.
  //
  // However, due to a race condition, the canvas element was often not initialized
  // at the point we tried to get its data URL to render the Link.
  //
  // This click handler works around the race condition by waiting to get the data URL
  // until the download button (and thus also the canvas) is fully initialised in theDOM.
  //
  // Unfortunately there's no way of embedding a friendly filename in the data URL,
  // so we can't simply redirect to the data URL to download the image.
  // Instead, we create a fake <a> element, set the "download" attribute on it to configure the filename,
  // and "cick" it to start the download.
  const handleQrCodeDownloadClick = useCallback(() => {
    const canvas = document.querySelector<HTMLCanvasElement>(
      "canvas#invitation-qrcode"
    );

    if (canvas == null) {
      return;
    }

    const href = canvas.toDataURL();
    const download = `${code.name
      .trim()
      .replace(/\s+/g, "-")}-invitation-code.png`;

    const fakeLink = document.createElement("a");
    fakeLink.setAttribute("href", href);
    fakeLink.setAttribute("download", download);
    fakeLink.click();
  }, [code.name]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share Your Invitation Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Spaced spacing="8" pb="8">
            {!code.requireApproval && (
              <Alert status="warning" rounded="md">
                <AlertIcon />
                <VStack align="flex-start">
                  <AlertTitle>Private Code</AlertTitle>
                  <AlertDescription>
                    You have opted out of manually approving new signups from
                    this code, so only share it with people you trust!
                  </AlertDescription>
                </VStack>
              </Alert>
            )}

            <Spaced spacing="6">
              <Spaced gap="4">
                <chakra.h2 fontSize="lg" fontWeight="bold">
                  Share as a Code
                </chakra.h2>
                <chakra.p>
                  People can type the invitation code directly into our signup
                  page (
                  <Link.External to={absoluteRouteUrl(routes.signup.url([]))} />
                  ):
                </chakra.p>
                <Flex justify="center">
                  <InvitationCodeLabel alias={code.alias} />
                </Flex>
              </Spaced>

              <hr />

              <Spaced gap="4">
                <chakra.h2 fontSize="lg" fontWeight="bold">
                  Share as a Link
                </chakra.h2>
                <chakra.p>
                  Share the link below in an email or a written document:
                </chakra.p>
                <ClickToCopy
                  value={absoluteRouteUrl(
                    routes.signinWithCode.url([code.alias])
                  )}
                  copyButtonText="Copy Link"
                  px="2"
                />
                <chakra.p>
                  People who use the link will be asked to sign up <em>or</em>{" "}
                  in, depending on whether they already have an account.
                </chakra.p>
              </Spaced>

              <hr />

              <Spaced gap="4">
                <chakra.h2 fontSize="lg" fontWeight="bold">
                  Share as a Button
                </chakra.h2>
                <chakra.p>
                  Copy the following HTML into a web page to add a button
                  allowing people to access Cartographer:
                </chakra.p>
                <ButtonCode code={code.alias} />
                <chakra.p>
                  People who use the button will be asked to sign up <em>or</em>{" "}
                  in, depending on whether they already have an account.
                </chakra.p>
              </Spaced>

              <hr />

              <Spaced gap="4">
                <chakra.h2 fontSize="lg" fontWeight="bold">
                  Share as a QR Code
                </chakra.h2>
                <chakra.p>
                  Share the QR code below in a presentation or print media:
                </chakra.p>
                <Flex direction="column" align="center" gap="2">
                  <QRCodeCanvas
                    id="invitation-qrcode"
                    value={absoluteRouteUrl(
                      routes.signinWithCode.url([code.alias])
                    )}
                    size={1024}
                    style={{
                      width: "160px",
                      height: "160px",
                      margin: "8px auto",
                    }}
                  />
                  <Button
                    leftIcon={<IoDownloadOutline />}
                    label="Download as Image"
                    variant="outline"
                    mx="auto"
                    onClick={handleQrCodeDownloadClick}
                  />
                </Flex>
                <chakra.p>
                  People who scan the code will be asked to sign up <em>or</em>{" "}
                  in, depending on whether they already have an account.
                </chakra.p>
              </Spaced>
            </Spaced>
          </Spaced>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

type ButtonVariant =
  | "rounded-dark-on-light"
  | "rounded-light-on-dark"
  | "standard-dark-on-light"
  | "standard-light-on-dark";

const INVITE_BUTTON_VARIANTS: SelectOption<ButtonVariant>[] = [
  {
    value: "rounded-dark-on-light",
    label: "Rounded dark on light",
  },
  {
    value: "rounded-light-on-dark",
    label: "Rounded light on dark",
  },
  {
    value: "standard-dark-on-light",
    label: "Square dark on light",
  },
  {
    value: "standard-light-on-dark",
    label: "Square light on dark",
  },
];

interface ButtonCodeOptions {
  code: InvitationCodeAlias;
}

function ButtonCode(options: ButtonCodeOptions): ReactElement {
  const { code } = options;

  const [serverId] = useApiServerId();
  const [theme, setTheme] = useState<ButtonVariant>("rounded-dark-on-light");

  const signinUrl = useMemo(
    () => absoluteRouteUrl(routes.signinWithCode.url([code])),
    [code]
  );

  const loaderUrl = useMemo(() => {
    switch (serverId) {
      case "production":
        return "https://embed.cartographer.io/loader.js";
      case "beta":
        return "https://embed.beta.cartographer.io/loader.js";
      case "next":
        return "https://embed.next.cartographer.io/loader.js";
      case "development":
        return "https://embed.beta.cartographer.io/loader.js";
    }
  }, [serverId]);

  const imageSrc = useMemo(
    () => absoluteRouteUrl(`/images/signin-button/${theme}@2x.png`),
    [theme]
  );

  const preview = useMemo(
    () => (
      <a className="cartographer-button" href={signinUrl} data-theme={theme}>
        <img src={imageSrc} alt="Explore Cartographer" style={{ height: 48 }} />
      </a>
    ),
    [imageSrc, theme, signinUrl]
  );

  const source = useMemo(
    () =>
      outdent`
      <!-- Begin Cartographer Button -->
      <a class="cartographer-button" href="${signinUrl}" data-theme="${theme}">Explore Cartographer</a>
      <script async src="${loaderUrl}" charset="utf-8"></script>
      <!-- End Cartographer Button -->
      `,
    [signinUrl, theme, loaderUrl]
  );

  const bg = useMemo<SystemProps["bg"]>(() => {
    switch (theme) {
      case "rounded-dark-on-light":
      case "standard-dark-on-light":
        return "gray.50";
      case "rounded-light-on-dark":
      case "standard-light-on-dark":
        return "blue.700";
      default:
        return checkExhausted(theme);
    }
  }, [theme]);

  return (
    <SimpleGrid columns={1} gridTemplateColumns="100%">
      <Center
        roundedTop="md"
        roundedBottom="none"
        w="100%"
        position="relative"
        minH="20"
        bg={bg}
      >
        <Box py="8">{preview}</Box>
        <ButtonGroup
          size="sm"
          flexGrow="0"
          flexShrink="0"
          position="absolute"
          bottom="2"
          right="2"
          spacing="2"
          zIndex="popover"
        >
          <Menu placement="bottom-end">
            <MenuButton
              as={IconButton}
              title="Configure Button Style"
              aria-label="Configure Button Style"
              icon={<IoMdColorFilter />}
              variant="outline"
              bg="white"
            />
            <MenuList>
              {INVITE_BUTTON_VARIANTS.map(({ label, value }) => (
                <MenuItem
                  key={value}
                  onClick={() => setTheme(value)}
                  bg={value === theme ? "gray.200" : undefined}
                  _hover={value === theme ? { bg: "gray.300" } : undefined}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Center>
      <Pre
        text={source}
        copyToClipboard={true}
        copyButtonText="Copy HTML"
        bg="gray.100"
        maxW="100%"
        roundedTop="none"
        roundedBottom="md"
        borderTopWidth={0}
        fontSize="sm"
        py="4"
      />
    </SimpleGrid>
  );
}
