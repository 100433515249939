import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import {
  Project,
  ProjectRoleName,
  ProjectRoleNameEnum,
  QualificationRoleName,
  QualificationRoleNameEnum,
  Team,
  TeamId,
  TeamRoleName,
  TeamRoleNameEnum,
  User,
  UserId,
  Workspace,
  addRole as addRoleToArray,
  projectRole as createProjectRole,
  teamRole as createTeamRole,
  projectRoleName,
  qualificationRole,
  roleProjectId,
  roleTeamId,
  teamRoleName,
  uniqQualificationRoles,
} from "@cartographerio/types";
import { filterAndMap, findAndMap, raise } from "@cartographerio/util";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import outdent from "outdent";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { useProjectHasTeams } from "../hooks/useProjectHasTeams";
import { useVolatileState } from "../hooks/useVolatileState";
import { useIOErrorAlert } from "./Alert";
import FormLabel from "./FormLabel";
import Select from "./Select";
import Spaced from "./Spaced";

interface ProjectUserAddModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  workspace: Workspace;
  project: Project;
  teams?: Team[] | null;
  defaultTeam?: TeamId | null;
}

function ProjectUserAddModal(props: ProjectUserAddModalProps): ReactElement {
  const { isOpen, onClose, title, workspace, project, teams, defaultTeam } =
    props;

  const apiParams = useApiParams();
  const multiTeam = useProjectHasTeams(workspace, project);
  const permissionCheckPasses = usePermissionCheckRunner();

  const [value, setValue] = useState<User | null>(null);
  const [teamId, setTeamId] = useVolatileState<TeamId | null>(
    useCallback(() => defaultTeam ?? null, [defaultTeam])
  );

  const canEditProjectRole = useMemo(
    () => permissionCheckPasses(checks.project.grantAccess(project)),
    [permissionCheckPasses, project]
  );

  const projectRoleNameOptions = useMemo(
    () =>
      ProjectRoleNameEnum.entries.filter(({ value }) =>
        permissionCheckPasses(
          checks.role.grant([createProjectRole(value, project.id)])
        )
      ),
    [permissionCheckPasses, project.id]
  );

  const teamOptions = useMemo<SelectOption<TeamId>[] | null>(
    () =>
      teams == null || !multiTeam
        ? null
        : filterAndMap(teams, team =>
            permissionCheckPasses(checks.team.grantAccess(team))
              ? { label: team.name, value: team.id }
              : null
          ),
    [permissionCheckPasses, multiTeam, teams]
  );

  const newUsersProjectRole = useMemo(
    () =>
      value != null
        ? findAndMap(value.roles, role =>
            roleProjectId(role) === project.id ? projectRoleName(role) : null
          )
        : null,
    [project.id, value]
  );

  const newUsersTeamRole = useMemo(
    () =>
      value != null
        ? findAndMap(value.roles, role =>
            roleTeamId(role) === teamId ? teamRoleName(role) : null
          )
        : null,
    [teamId, value]
  );

  const teamRoleNameOptions = useMemo(
    () =>
      teamId == null
        ? []
        : TeamRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(
              checks.role.grant([createTeamRole(value, teamId)])
            )
          ),
    [permissionCheckPasses, teamId]
  );

  const qualificationRoleNameOptions = useMemo(
    () =>
      project.qualificationIds
        .map(id =>
          QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(checks.qualification.grant(value, id))
          )
        )
        .reduce(
          (acc: SelectOption<QualificationRoleName>[] | null, curr) =>
            acc == null
              ? curr
              : acc.filter(
                  ({ value }) =>
                    curr.find(other => value === other.value) != null
                ),
          null
        ) ?? [],
    [permissionCheckPasses, project.qualificationIds]
  );

  const [projectRole, setProjectRole] = useVolatileState<ProjectRoleName>(
    useCallback(() => newUsersProjectRole ?? "Member", [newUsersProjectRole])
  );

  const [teamRole, setTeamRole] = useVolatileState<TeamRoleName>(
    useCallback(() => newUsersTeamRole ?? "Member", [newUsersTeamRole])
  );

  const [qualificationRoleName, setQualificationRoleName] =
    useState<QualificationRoleName | null>(null);

  const users = useQuery(
    queries.user.v2.search(apiParams, { workspace: project.workspaceId })
  ).data?.results;

  const userOptions = useMemo<SelectOption<UserId>[]>(
    () =>
      users?.map(user => ({
        label: `${user.firstName} ${user.lastName} (${user.email})`,
        value: user.id,
      })) ?? [],
    [users]
  );

  const saveUser = useUserAddCallback();

  const handleSubmit = useCallback(() => {
    if (value != null) {
      const rolesToMerge = [
        createProjectRole(projectRole, project.id),
        ...(teamId != null ? [createTeamRole(teamRole, teamId)] : []),
      ];

      saveUser({
        ...value,
        roles: rolesToMerge.reduce(
          (roles, role) => addRoleToArray(roles, role, "replace"),
          value.roles
        ),
        qualificationRoles:
          qualificationRoleName != null
            ? uniqQualificationRoles([
                ...value.qualificationRoles,
                ...project.qualificationIds.map(id =>
                  qualificationRole(qualificationRoleName, id)
                ),
              ])
            : value.qualificationRoles,
      })
        .cleanup(() => {
          setValue(null);
          onClose();
        })
        .unsafeRun();
    }
  }, [
    onClose,
    project.id,
    project.qualificationIds,
    projectRole,
    qualificationRoleName,
    saveUser,
    teamId,
    teamRole,
    value,
  ]);

  const modalRef = useRef<HTMLElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Spaced spacing="4">
            <FormControl>
              <FormLabel text="Choose a member of your workspace" />
              <Select.Searchable
                value={value?.id}
                options={userOptions}
                onChange={userId =>
                  setValue(
                    users?.find(({ id }) => id === userId) ??
                      raise<User>(new Error("User not found"))
                  )
                }
                placeholder="Search by name or email"
                debounce={250}
              />
            </FormControl>

            {newUsersProjectRole != null && (
              <Alert status="warning" rounded="md">
                <AlertIcon />
                <AlertDescription>
                  The selected user is already a {newUsersProjectRole} of this{" "}
                  project. Adding them will replace their current role with the
                  new role below.
                </AlertDescription>
              </Alert>
            )}

            {value != null && canEditProjectRole && (
              <FormControl>
                <FormLabel
                  text="Choose their new project role"
                  help={
                    multiTeam
                      ? PROJECT_ROLE_HELP.MULTI_TEAM
                      : PROJECT_ROLE_HELP.SINGLE_TEAM
                  }
                  helpPortalContainerRef={modalRef}
                />
                <Select.Standard
                  value={projectRole}
                  onChange={setProjectRole}
                  options={projectRoleNameOptions}
                />
              </FormControl>
            )}

            {teamOptions != null && teamOptions.length > 0 && (
              <>
                <FormControl>
                  <FormLabel
                    text={
                      canEditProjectRole
                        ? "Choose a team (optional)"
                        : "Choose a team"
                    }
                    help={
                      canEditProjectRole
                        ? TEAM_HELP.PROJECT_COORDINATOR
                        : TEAM_HELP.TEAM_COORDINATOR
                    }
                    helpPortalContainerRef={modalRef}
                  />
                  {canEditProjectRole ? (
                    <Select.Nullable
                      placeholder="No Team"
                      value={teamId ?? null}
                      onChange={setTeamId}
                      options={teamOptions}
                    />
                  ) : (
                    <Select.Standard
                      placeholder="Select a Team"
                      value={teamId}
                      onChange={setTeamId}
                      options={teamOptions}
                    />
                  )}
                </FormControl>

                {newUsersTeamRole != null && (
                  <Alert status="warning" rounded="md">
                    <AlertIcon />
                    <AlertDescription>
                      The selected user is already a {newUsersTeamRole} of this{" "}
                      team. Adding them will replace their current role with the
                      new role below.
                    </AlertDescription>
                  </Alert>
                )}

                <FormControl>
                  <FormLabel
                    text="Choose a team role"
                    help={TEAM_ROLE_HELP}
                    helpPortalContainerRef={modalRef}
                  />
                  <Select.Standard
                    value={teamRole}
                    onChange={setTeamRole}
                    options={teamRoleNameOptions}
                    disabled={teamId == null}
                  />
                </FormControl>
              </>
            )}

            {value != null && qualificationRoleNameOptions.length > 0 && (
              <FormControl>
                <FormLabel
                  text="Choose a Qualification Role (Optional)"
                  help={QUALIFICATION_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Nullable
                  value={qualificationRoleName}
                  onChange={setQualificationRoleName}
                  options={qualificationRoleNameOptions}
                />
              </FormControl>
            )}
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isDisabled={value == null}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface TeamUserAddModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  team: Team;
  teamProjects: Project[];
}

function TeamUserAddModal(props: TeamUserAddModalProps): ReactElement {
  const { title, isOpen, onClose, team, teamProjects } = props;

  const apiParams = useApiParams();
  const permissionCheckPasses = usePermissionCheckRunner();

  const [value, setValue] = useState<User | null>(null);

  const canEditTeamRole = useMemo(
    () => permissionCheckPasses(checks.team.grantAccess(team)),
    [permissionCheckPasses, team]
  );

  const newUsersTeamRole = useMemo(
    () =>
      value != null
        ? findAndMap(value.roles, role =>
            roleTeamId(role) === team.id ? teamRoleName(role) : null
          )
        : null,
    [team, value]
  );

  const teamRoleNameOptions = useMemo(
    () =>
      TeamRoleNameEnum.entries.filter(({ value }) =>
        permissionCheckPasses(
          checks.role.grant([createTeamRole(value, team.id)])
        )
      ),
    [permissionCheckPasses, team.id]
  );

  const qualificationIds = useMemo(
    () => [
      ...new Set(
        teamProjects.flatMap(({ qualificationIds }) => qualificationIds)
      ),
    ],
    [teamProjects]
  );

  const qualificationRoleNameOptions = useMemo(
    () =>
      qualificationIds
        .map(id =>
          QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(checks.qualification.grant(value, id))
          )
        )
        .reduce(
          (acc: SelectOption<QualificationRoleName>[] | null, curr) =>
            acc == null
              ? curr
              : acc.filter(
                  ({ value }) =>
                    curr.find(other => value === other.value) != null
                ),
          null
        ) ?? [],
    [permissionCheckPasses, qualificationIds]
  );

  const [teamRole, setTeamRole] = useVolatileState<TeamRoleName>(
    useCallback(() => newUsersTeamRole ?? "Member", [newUsersTeamRole])
  );
  const [qualificationRoleName, setQualificationRoleName] =
    useState<QualificationRoleName | null>(null);

  const users = useQuery(
    queries.user.v2.search(apiParams, { workspace: team.workspaceId })
  ).data?.results;

  const userOptions = useMemo<SelectOption<UserId>[]>(
    () =>
      users?.map(user => ({
        label: `${user.firstName} ${user.lastName} (${user.email})`,
        value: user.id,
      })) ?? [],
    [users]
  );

  const saveUser = useUserAddCallback();

  const handleSubmit = useCallback(() => {
    if (value != null) {
      saveUser({
        ...value,
        roles: addRoleToArray(
          value.roles,
          createTeamRole(teamRole, team.id),
          "replace"
        ),
        qualificationRoles:
          qualificationRoleName != null
            ? uniqQualificationRoles([
                ...value.qualificationRoles,
                ...qualificationIds.map(id =>
                  qualificationRole(qualificationRoleName, id)
                ),
              ])
            : value.qualificationRoles,
      })
        .cleanup(() => {
          setValue(null);
          onClose();
        })
        .unsafeRun();
    }
  }, [
    onClose,
    qualificationIds,
    qualificationRoleName,
    saveUser,
    team.id,
    teamRole,
    value,
  ]);

  const modalRef = useRef<HTMLElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Spaced spacing="4">
            <FormControl>
              <FormLabel text="Choose a member of your workspace" />
              <Select.Searchable
                value={value?.id}
                options={userOptions}
                onChange={userId =>
                  setValue(
                    users?.find(({ id }) => id === userId) ??
                      raise<User>(new Error("User not found"))
                  )
                }
                placeholder="Search by name or email"
                debounce={250}
              />
            </FormControl>

            {newUsersTeamRole != null && (
              <Alert status="warning" rounded="md">
                <AlertIcon />
                <AlertDescription>
                  The selected user is already a {newUsersTeamRole} of this{" "}
                  team. Adding them will replace their current role with the new
                  role below.
                </AlertDescription>
              </Alert>
            )}

            {value != null && canEditTeamRole && (
              <FormControl>
                <FormLabel
                  text="Choose their new team role"
                  help={TEAM_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Standard
                  value={teamRole}
                  onChange={setTeamRole}
                  options={teamRoleNameOptions}
                />
              </FormControl>
            )}

            {value != null && qualificationRoleNameOptions.length > 0 && (
              <FormControl>
                <FormLabel
                  text="Choose a Qualification Role (Optional)"
                  help={QUALIFICATION_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Nullable
                  value={qualificationRoleName}
                  onChange={setQualificationRoleName}
                  options={qualificationRoleNameOptions}
                />
              </FormControl>
            )}
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isDisabled={value == null}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function useUserAddCallback() {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  return useCallback(
    (user: User) =>
      queries.user.v2
        .save(queryClient, apiParams, user.id, user)
        .flatMap(IO.fromResult)
        .tap(_ => toast({ title: "User Added", status: "success" }))
        .tapError(errorAlert),
    [apiParams, errorAlert, queryClient, toast]
  );
}

export const QUALIFICATION_ROLE_HELP = outdent`
  Optionally give the person a qualification level.
  You can assign a qualification level using the dropdown menu below.
  `;

export const PROJECT_ROLE_HELP = {
  MULTI_TEAM: outdent`
    What role will the person have in the project?

    - *Project Member* - can view surveys and maps in the project
      but can't add, remove, or edit surveys;

    - *Project Surveyor* - can also contribute surveys;

    - *Project Approver* - can also approve surveys
      submitted by other surveyors;

    - *Project Coordinator* - can also invite new project members,
      manage memberships, and edit project settings.
    `,
  SINGLE_TEAM: outdent`
    What role will the person have in the project?

    - *Project Member* - can view surveys and maps in the project
      but can't add, remove, or edit surveys;

    - *Project Surveyor* - can also contribute surveys
      on behalf of any team;

    - *Project Approver* - can also approve surveys submitted
      by other surveyors in any team;

    - *Project Coordinator* - can also invite new project members,
      manage memberships in any team, and edit project and team settings.
    `,
};

export const TEAM_HELP = {
  PROJECT_COORDINATOR: outdent`
    Optionally assign the person to a team.
    You can assign a team role using the dropdown menu below.
    `,
  TEAM_COORDINATOR: outdent`
    Optionally assign the person to a team.
    You can assign a team role using the dropdown menu below.
    `,
};

export const TEAM_ROLE_HELP = outdent`
  What role will the person have in the team?

  - *Team Member* - can view data but can't edit anything;

  - *Team Surveyor* - can also contribute surveys
    on behalf of the team;

  - *Team Approver* - can also approve surveys
    submitted by other team members;

  - *Team Coordinator* - can also invite new team members,
    manage team memberships, and edit team settings.
  `;

export default {
  Project: ProjectUserAddModal,
  Team: TeamUserAddModal,
};
