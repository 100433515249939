import {
  ProjectSortKey,
  SearchResultsFormat,
  SortOrder,
  endpoints,
} from "@cartographerio/client";
import {
  Project,
  ProjectId,
  ProjectRoleName,
  UserId,
  WorkspaceRef,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";

import queries from "../../../queries";
import recordWithId from "../../../util/recordWithId";
import { useApiParams } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import DownloadMenu from "../DownloadMenu";
import SearchField from "../SearchField";
import SearchResultsList from "../SearchResultsList";
import { projectActions, projectColumns } from "./column";

interface ProjectListProps {
  searchTerm?: string;
  page: number;
  count: number;
  order?: SortOrder<ProjectSortKey>;
  workspace?: WorkspaceRef | null;
  user?: UserId | null;
  role?: ProjectRoleName | null;
  addButton?: ReactNode;
  resetFilters?: ReactNode;
  itemLink?: (item: Project) => string;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<ProjectSortKey>) => void;
}

export default function ProjectList(props: ProjectListProps): ReactElement {
  const {
    searchTerm,
    page,
    count,
    order = "name-asc",
    workspace,
    user,
    role,
    addButton,
    resetFilters,
    itemLink,
    onSearchTermChange,
    onPageChange,
    onOrderChange,
  } = props;

  const apiParams = useApiParams();

  const { data, error } = useQuery(
    queries.project.v2.search(apiParams, {
      workspace,
      user,
      role,
      q: searchTerm,
      order,
      skip: page * count,
      limit: count,
    })
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.project.v2.searchUrl({
          workspace,
          user,
          role,
          q: searchTerm,
          order,
          format,
        })
      ),
    [formatApiUrl, order, role, searchTerm, user, workspace]
  );

  const actions = useMemo(() => projectActions({ workspace }), [workspace]);

  const handleSearchChange = useCallback(
    (search: string | null) => onSearchTermChange?.(search),
    [onSearchTermChange]
  );

  const graph = useCurrentWorkspaceGraph();

  const columns = useMemo(() => projectColumns(graph, undefined), [graph]);

  return (
    <>
      <HStack w="100%" justifyContent="stretch">
        <SearchField
          defaultValue={searchTerm ?? ""}
          onChange={handleSearchChange}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        <DownloadMenu downloadUrl={downloadUrl} />
        {addButton}
      </HStack>

      {resetFilters}

      <SearchResultsList<Project, ProjectSortKey, ProjectId>
        results={data}
        error={error}
        page={page}
        count={count}
        order={order}
        columns={columns}
        actions={actions}
        itemKey={recordWithId}
        itemLink={itemLink}
        onPageChange={onPageChange}
        onOrderChange={onOrderChange}
      />
    </>
  );
}
