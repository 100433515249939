import { commonLocations } from "@cartographerio/geometry";
import { hasKey, isArrayOf, isObject, isString } from "@cartographerio/guard";
import { Box, Flex, HStack, VStack, chakra } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CSSProperties, ReactElement, useCallback, useState } from "react";
import ReactMapGL, { MapProvider } from "react-map-gl";
import { useLocalStorage } from "usehooks-ts";

import Button from "../components/Button";
import Disclosure from "../components/Disclosure";
import { useViewportState } from "../components/mapFieldHelpers";
import Spaced from "../components/Spaced";
import TextField from "../components/TextField";
import { useMapboxToken } from "../contexts/MapboxToken";
import { usePageTitle } from "../hooks/usePageTitle";

const MAP_ID = "viewer-map";

export const FULL_MAP_CSS: CSSProperties = {
  width: "100%",
  height: "100%",
};

export default function MapboxStyleViewerPage(): ReactElement {
  usePageTitle("Style Viewer");

  return (
    <MapProvider>
      <MapboxStyleViewerPageInner />
    </MapProvider>
  );
}

function MapboxStyleViewerPageInner(): ReactElement {
  const queryClient = useQueryClient();

  const [styleUrl, setStyleUrl] = useLocalStorage(
    "MapboxViewerUrl",
    "http://localhost:8000/style.json"
  );

  const [textFieldValue, setTextFieldValue] = useState(styleUrl);

  const { data: styleJson } = useQuery({
    queryKey: ["dev", "mapbox", "style", styleUrl],
    queryFn: () => fetch(styleUrl).then(response => response.json()),
  });

  const [viewport, onMove] = useViewportState(
    commonLocations.greatBritain.center,
    commonLocations.greatBritain.zoom
  );

  const mapboxToken = useMapboxToken();

  const handleUpdateClick = useCallback(() => {
    setStyleUrl(textFieldValue);
    queryClient.invalidateQueries({ queryKey: ["dev", "mapbox", "style"] });
  }, [queryClient, setStyleUrl, textFieldValue]);

  return (
    <VStack w="100vw" h="100vh" alignItems="stretch" gap="2">
      <Flex flexGrow={1}>
        <HStack w="100vw" h="100%" alignItems="stretch" gap="2">
          <Flex flexGrow={1}>
            <ReactMapGL
              id={MAP_ID}
              mapboxAccessToken={mapboxToken}
              style={FULL_MAP_CSS}
              mapStyle={styleUrl}
              {...viewport}
              onMove={onMove}
              hash={true}
            />
          </Flex>
          <Flex flexGrow={0} position="relative" w="30ch" h="100%">
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              overflowY="scroll"
            >
              <StyleInspector styleJson={styleJson} />
            </Box>
          </Flex>
        </HStack>
      </Flex>
      <Flex flexGrow={0} px="2" pb="2">
        <HStack spacing="2" w="100%">
          <TextField.String
            placeholder="Style URL"
            value={textFieldValue}
            onChange={setTextFieldValue}
          />
          <Box>
            <TextField.String
              value={[
                viewport.zoom.toFixed(2),
                viewport.latitude.toFixed(2),
                viewport.longitude.toFixed(2),
              ].join("/")}
              disabled={true}
            />
          </Box>
          <Button
            colorScheme="blue"
            onClick={handleUpdateClick}
            label="Update"
          />
        </HStack>
      </Flex>
    </VStack>
  );
}

interface StyleInspectorProps {
  styleJson: unknown;
}

function StyleInspector(props: StyleInspectorProps) {
  const { styleJson } = props;

  const sources: [string, unknown][] =
    isObject(styleJson) && hasKey(styleJson, "sources", isObject)
      ? Object.entries(styleJson.sources)
      : [];

  const layers: { id: string }[] =
    isObject(styleJson) &&
    hasKey(styleJson, "layers", isArrayOf(isObjectWithKey("id", isString)))
      ? styleJson.layers
      : [];

  return (
    <Spaced spacing="2" fontSize="xs">
      {sources.map(([id, source]) => (
        <Disclosure key={id} toggle={`source: ${id}`} spacing="2">
          <chakra.pre fontSize="xx-small">
            {JSON.stringify(source, null, 2)}
          </chakra.pre>
        </Disclosure>
      ))}

      {layers.map((layer, index) => (
        <Disclosure
          key={layer.id}
          toggle={`layer ${index}: ${layer.id}`}
          spacing="2"
        >
          <chakra.pre fontSize="xx-small">
            {JSON.stringify(layer, null, 2)}
          </chakra.pre>
        </Disclosure>
      ))}
    </Spaced>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObjectWithKey<K extends keyof any, A>(
  key: K,
  guard: (value: unknown) => value is A
): (value: unknown) => value is { [_ in K]: A } {
  return (value: unknown): value is { [_ in K]: A } =>
    isObject(value) && hasKey(value, key, guard);
}
