import { TeamSortKey } from "@cartographerio/client";
import { Project, ProjectId, Team, WorkspaceRef } from "@cartographerio/types";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { IconButton } from "@chakra-ui/react";
import { IoCloseSharp, IoSettingsSharp } from "react-icons/io5";

import { routes } from "../../routes";
import Link from "../Link";
import { ActionsColumn, Column } from "../SearchResultsList/column";
import SecondaryLabel from "../SecondaryLabel";

function col(params: Column<Team, TeamSortKey>) {
  return params;
}

const nameColumn = col({
  title: "Name",
  orderBy: "name",
  width: "13ch",
  render: team => team.name,
});

export function teamColumns(
  graph: WorkspaceGraph,
  primaryProjectId?: ProjectId
) {
  return [nameColumn, projectAssociationColumn(graph, primaryProjectId)];
}

function projectAssociationColumn(
  graph: WorkspaceGraph,
  primaryProjectId?: ProjectId
) {
  const teamProjects = (team: Team): Project[] =>
    graph.findProjectsByTeamId(team.id);

  return col({
    title: "Project(s)",
    render: team => {
      const projects = teamProjects(team);

      switch (projects.length) {
        case 0:
          return <span>-</span>;
        case 1:
          return <SecondaryLabel text={projects[0].name} />;
        default: {
          const primary = (
            projects.find(project => project.id === primaryProjectId) ??
            projects[0]
          ).name;

          const secondary =
            projects.length === 1
              ? undefined
              : projects.length === 2
              ? "+1 other project"
              : `+${projects.length - 1} other projects`;

          return <SecondaryLabel text={primary} secondaryText={secondary} />;
        }
      }
    },
  });
}

interface TeamActionsProps {
  workspace: WorkspaceRef;
  onDeleteClick?: (team: Team) => void;
}

export function teamActions(
  props: TeamActionsProps
): ActionsColumn<Team> | undefined {
  const { workspace, onDeleteClick } = props;

  return {
    renderButtons: team => [
      <IconButton
        key="settings"
        as={Link.Internal}
        to={routes.workspace.team.settings.url([workspace, team.alias])}
        variant="outline"
        aria-label="Settings"
        icon={<IoSettingsSharp />}
        onClick={evt => evt.stopPropagation()}
      />,
      ...(onDeleteClick == null
        ? []
        : [
            <IconButton
              key="delete"
              variant="outline"
              aria-label="Delete"
              icon={<IoCloseSharp size="1.25rem" />}
              onClick={() => onDeleteClick(team)}
            />,
          ]),
    ],
  };
}
