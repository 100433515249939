import { BillingInterval, PlanId, WorkspaceRef } from "@cartographerio/types";
import { HStack, Stack, Switch } from "@chakra-ui/react";
import { ReactElement, useState } from "react";

import queries from "../../queries";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import ContactUsPlanColumn from "./ContactUsPlanColumn";
import PlanChooserColumn, { PlanClickFunc } from "./PlanChooserColumn";

export interface PlanChooserProps {
  workspaceRef: WorkspaceRef;
  defaultPlanId?: PlanId | null;
  defaultBillingInterval?: BillingInterval;
  planLink?: (planId: PlanId, billingInterval: BillingInterval) => string;
  onPlanClick?: PlanClickFunc;
}

export default function PlanChooser(props: PlanChooserProps): ReactElement {
  const {
    workspaceRef,
    defaultPlanId = null,
    defaultBillingInterval = "Yearly",
    planLink,
    onPlanClick,
  } = props;

  const apiParams = useApiParams();

  const { options, showCallUs } = useSuspenseQueryData(
    queries.billing.plan.v1.options(apiParams, workspaceRef)
  );

  const [billingInterval, setBillingInterval] = useState(
    defaultBillingInterval
  );

  return (
    <Spaced>
      <HStack justify="center">
        <span>Billed Monthly</span>
        <Switch
          id="isChecked"
          isChecked={billingInterval === "Yearly"}
          onChange={() =>
            setBillingInterval(
              billingInterval === "Monthly" ? "Yearly" : "Monthly"
            )
          }
          colorScheme="green"
        />
        <span>Billed Yearly</span>
      </HStack>

      <Stack
        direction={["column", "row"]}
        alignItems={["center", "stretch"]}
        justify="flex-start"
        overflowX="auto"
        pt="4"
        pb="8"
        px="4"
        bg="gray.50"
        roundedBottom="lg"
        border="1px solid"
        borderColor="gray.100"
        columnGap="4"
        rowGap="8"
      >
        {options.map(({ plan, selectable }) => (
          <PlanChooserColumn
            key={plan.id}
            plan={plan}
            billingInterval={billingInterval}
            disabled={
              !selectable ||
              (plan.id === defaultPlanId &&
                billingInterval === defaultBillingInterval)
            }
            disabledReason={
              plan.id === defaultPlanId &&
              billingInterval === defaultBillingInterval
                ? "You are already on this plan"
                : "Your workspace does not meet this plan's requirements"
            }
            planLink={planLink?.(plan.id, billingInterval)}
            onPlanClick={onPlanClick}
          />
        ))}
        {showCallUs && (
          <ContactUsPlanColumn billingInterval={billingInterval} />
        )}
      </Stack>

      <Para fontSize="sm" color="gray.400" textAlign="center" pt="2" mb="6">
        Prices are pro-rata and discounts are not reflected in this preview. The
        final billed amount may differ.
      </Para>
    </Spaced>
  );
}
