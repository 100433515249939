import { SelectOption, SelectSection } from "@cartographerio/topo-form";
import {
  AnonymisedUser,
  Team,
  UserId,
  UserRef,
  roleGte,
  teamSurveyorRole,
  userToUserRef,
} from "@cartographerio/types";
import { chain } from "lodash";
import { ReactElement, useMemo } from "react";
import { IconType } from "react-icons";
import { IoPerson, IoPersonOutline } from "react-icons/io5";

import SectionedSelect from "../components/SectionedSelect";
import { Highlight } from "../hooks/highlight";

export interface UserRefFieldProps {
  value: UserRef | null;
  onChange: (value: UserRef | null) => void;
  userOptions: AnonymisedUser[];
  teams: Team[];
  highlight?: Highlight;
  disabled?: boolean;
}

export function UserRefField(props: UserRefFieldProps): ReactElement {
  const { value, onChange, userOptions, teams, highlight, disabled } = props;

  const restOptions = useMemo((): SelectSection<UserId>[] => {
    const teamSections = teams.map(({ name, id }) => ({
      heading: name,
      options: userOptions
        .filter(({ roles }) =>
          roles.some(role => roleGte(role, teamSurveyorRole(id)))
        )
        .map(userToOption),
    }));
    const allTeamUserIds = new Set(
      teamSections.flatMap(({ options }) =>
        options.flatMap(({ value }) => value)
      )
    );
    const restSection = {
      heading: "Other users",
      options: chain(userOptions)
        .flatMap(a => a)
        .uniqBy(({ id }) => id)
        .filter(({ id }) => !allTeamUserIds.has(id))
        .map(userToOption)
        .value(),
    };

    return [...teamSections, restSection].filter(
      ({ options }) => options.length > 0
    );
  }, [teams, userOptions]);

  const allOptions = useMemo(
    (): SelectSection<UserId>[] =>
      value != null
        ? [
            {
              heading: "Selected User",
              options:
                value.userId != null
                  ? [
                      {
                        label: value.screenName ?? "Unknown User",
                        value: value.userId,
                      },
                    ]
                  : [],
            },
            ...restOptions,
          ]
        : restOptions,
    [restOptions, value]
  );
  const idLookup = useMemo(
    (): Record<UserId, UserRef> =>
      (value != null
        ? [value, ...userOptions.map(userToUserRef)]
        : userOptions.map(userToUserRef)
      ).reduce(
        (acc: Record<UserId, UserRef>, ref) =>
          ref.userId != null ? { ...acc, [ref.userId]: ref } : acc,
        {}
      ),
    [userOptions, value]
  );

  return (
    <SectionedSelect<UserId>
      value={value?.userId}
      sections={allOptions}
      fuseOptions={{
        includeMatches: true,
        minMatchCharLength: 1,
      }}
      renderIcon={userIdToIcon}
      onChange={id => onChange(id != null ? idLookup[id] ?? null : null)}
      highlight={highlight}
      disabled={disabled}
      debounce={200}
      emptyText="No users found. Please adjust your search."
    />
  );
}

function userToOption(user: AnonymisedUser): SelectOption<UserId> {
  return { label: user.screenName, value: user.id };
}

export function userIdToIcon(id: UserId | null | undefined): IconType {
  return id != null ? IoPerson : IoPersonOutline;
}
