import {
  AttachmentFolder,
  SurveyId,
  attachmentVariant,
} from "@cartographerio/types";
import {
  AspectRatio,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  SimpleGridProps,
  SystemProps,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AiOutlineFile } from "react-icons/ai";
import { IoEllipsisHorizontal } from "react-icons/io5";

import queries from "../../queries";
import { isImageContentType } from "../../queries/attachment/v3";
import { useApiParams } from "../contexts/auth";
import usePrevious from "../hooks/usePrevious";
import { GalleryPosition } from "./FolderGalleryModal";

interface ThumbnailGalleryProps extends SimpleGridProps {
  folders: Array<[SurveyId, AttachmentFolder]>;
  columns: number;
  defaultRows: number;
  loadMoreNumRows?: number;
  fallbackSize?: SystemProps["width"];
  onItemClick?: (galleryPosition: GalleryPosition) => void;
}

export default function ThumbnailGallery(
  props: ThumbnailGalleryProps
): ReactElement {
  const {
    folders,
    columns,
    defaultRows,
    loadMoreNumRows,
    fallbackSize = "6",
    onItemClick,
    gap = "1px",
    ...rest
  } = props;

  const apiParams = useApiParams();

  const [rows, setRows] = useState(defaultRows);

  const [attachments, setAttachments] = useState<Array<GalleryPosition>>([]);
  const [folderIndex, setFolderIndex] = useState(0);

  const prevFolders = usePrevious(folders);

  useEffect(() => {
    if (!isEqual(folders, prevFolders)) {
      setAttachments([]);
      setFolderIndex(0);
      setRows(defaultRows);
    }
  }, [defaultRows, folders, prevFolders]);

  const loadingComplete =
    folderIndex >= folders.length || attachments.length >= rows * columns + 1;

  const nextAttachments = useQuery(
    queries.unless(loadingComplete, () =>
      queries.attachment.v3.search(
        apiParams,
        folders[folderIndex][0],
        folders[folderIndex][1]
      )
    )
  ).data;

  useEffect(() => {
    if (nextAttachments != null) {
      setAttachments(
        attachments.concat(
          nextAttachments.results
            .filter(attach => isImageContentType(attach.contentType))
            .map((attach, i) => ({
              survey: folders[folderIndex][0],
              folder: folders[folderIndex][1],
              attach,
              attachIndex: i,
            }))
        )
      );
      setFolderIndex(folderIndex + 1);
    }
  }, [folderIndex, folders, nextAttachments, setAttachments, attachments]);

  const urlsToDisplay = useMemo(
    () =>
      attachments.slice(
        0,
        attachments.length > columns * rows
          ? columns * rows - 1
          : columns * rows
      ),
    [columns, rows, attachments]
  );

  const handleItemClick = useCallback(
    (evt: MouseEvent, galleryPosition: GalleryPosition) => {
      evt.stopPropagation();
      evt.preventDefault();
      onItemClick?.(galleryPosition);
    },
    [onItemClick]
  );

  return (
    <SimpleGrid columns={columns} {...rest} gap={gap}>
      {urlsToDisplay.map(galleryPosition => (
        <AspectRatio
          key={galleryPosition.attach.id}
          w="100%"
          ratio={1}
          title={
            galleryPosition.attach.description ??
            galleryPosition.attach.filename
          }
          cursor={onItemClick != null ? "pointer" : undefined}
          onClick={evt => handleItemClick(evt, galleryPosition)}
        >
          <Image
            src={queries.attachment.v3.attachmentUrl(
              apiParams,
              galleryPosition.attach.id,
              attachmentVariant.smallThumbnail
            )}
            alt={
              galleryPosition.attach.description ??
              galleryPosition.attach.filename
            }
            fit="cover"
            w="100%"
            h="100%"
            fallback={
              <Flex
                justify="center"
                alignItems="center"
                w="100%"
                h="100%"
                py="2"
                bg="gray.200"
              >
                <Icon as={AiOutlineFile} w={fallbackSize} h={fallbackSize} />
              </Flex>
            }
          />
        </AspectRatio>
      ))}
      {attachments.length > columns * rows && (
        <AspectRatio
          width="100%"
          ratio={1}
          title={
            loadMoreNumRows != null ? "Load More" : "More attachments hidden"
          }
          cursor={loadMoreNumRows != null ? "pointer" : undefined}
          onClick={
            loadMoreNumRows != null
              ? () => setRows(rows + loadMoreNumRows)
              : undefined
          }
        >
          <Flex
            justify="center"
            alignItems="center"
            w="100%"
            h="100%"
            py="2"
            bg="gray.200"
            _hover={loadMoreNumRows != null ? { bg: "gray.200" } : undefined}
          >
            <Icon as={IoEllipsisHorizontal} w={fallbackSize} h={fallbackSize} />
          </Flex>
        </AspectRatio>
      )}
    </SimpleGrid>
  );
}
