import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import {
  MapLayerId,
  SurveyModuleId,
  unsafeMapLayerId,
  unsafeSurveyModuleId,
} from "@cartographerio/types";
import {
  Button,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import outdent from "outdent";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { FaRadiation } from "react-icons/fa";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useIOConfirm, useIOErrorAlert } from "../../components/Alert";
import FormLabel from "../../components/FormLabel";
import Heading from "../../components/Heading";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import PageTopBar from "../../components/PageTopBar";
import Select from "../../components/Select";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import useMapInventory from "../../hooks/useMapInventory";
import useModuleInventory from "../../hooks/useModuleInventory";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { routes } from "../../routes";

export default function AdminHomePage(
  _props: RouteProps<typeof routes.admin.home>
): ReactElement {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();
  const confirmAlert = useIOConfirm();

  useRequirePermissionRedirect(checks.auth.globalAdmin, () =>
    routes.home.url([])
  );

  usePageTitle("Admin");

  const moduleInventory = useModuleInventory();
  const allModules = useMemo(
    (): SelectOption<SurveyModuleId>[] => [
      { label: "", value: unsafeSurveyModuleId("") },
      ...moduleInventory.surveyModules().map(({ names, moduleId }) => ({
        label: names.shortName,
        value: moduleId,
      })),
    ],
    [moduleInventory]
  );
  const [selectedModule, setSelectedModule] =
    useState<SurveyModuleId | undefined>(undefined);

  const refreshMetadata = useCallback(
    () =>
      (selectedModule === ""
        ? confirmAlert({
            message: "Are you sure? This may take up to 30 minutes",
          })
        : IO.pure(true)
      )
        .flatMap(confirmed =>
          confirmed
            ? queries.survey.v3
                .refreshMetadata(queryClient, apiParams, selectedModule)
                .tap(() =>
                  toast({
                    title: "Job Started",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tapError(errorAlert)
            : IO.noop()
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, queryClient, selectedModule, toast]
  );

  const mapInventory = useMapInventory();
  const allLayers = useMemo(
    (): SelectOption<MapLayerId>[] => [
      { label: "", value: unsafeMapLayerId("") },
      ...mapInventory
        .allLayers()
        .map(({ title, layerId }) => ({ label: title, value: layerId })),
    ],
    [mapInventory]
  );
  const [selectedLayer, setSelectedLayer] =
    useState<MapLayerId | undefined>(undefined);

  const recreateMapData = useCallback(
    () =>
      (selectedLayer === ""
        ? confirmAlert({
            message: "Are you sure? This may take a few minutes",
          })
        : IO.pure(true)
      )
        .flatMap(confirmed =>
          confirmed
            ? queries.map.feature.v2
                .reset(queryClient, apiParams, selectedLayer)
                .tap(() =>
                  toast({
                    title: "Job Started",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tapError(errorAlert)
            : IO.noop()
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, queryClient, selectedLayer, toast]
  );

  return (
    <>
      <PageTopBar admin={true} />
      <PageContainer width="narrow">
        <Spaced>
          <PageHeader title="Cartographer Admin" />
          <Heading level="section">Developer Tools</Heading>
          <SimpleGrid columns={[1, 2, 2]} gap="4">
            <VStack align="stretch">
              <FormLabel
                text="Recreate Survey Summaries"
                help={RECREATE_SURVEY_SUMMARIES_HELP}
              />
              <InputGroup colorScheme="yellow">
                <Select.Searchable
                  value={selectedModule}
                  placeholder="All Modules"
                  options={allModules}
                  onChange={setSelectedModule}
                  borderRightRadius="0"
                  borderColor="yellow.400"
                />
                <InputRightAddon px="0">
                  <Button
                    onClick={refreshMetadata}
                    borderLeftRadius="0"
                    colorScheme="yellow"
                  >
                    <FaRadiation />
                  </Button>
                </InputRightAddon>
              </InputGroup>
            </VStack>
            <VStack align="stretch">
              <FormLabel
                text="Recreate Map Data"
                help={RECREATE_MAP_DATA_HELP}
              />
              <InputGroup colorScheme="yellow">
                <Select.Searchable
                  value={selectedLayer}
                  placeholder="All Layers"
                  options={allLayers}
                  onChange={setSelectedLayer}
                  borderRightRadius="0"
                  borderColor="yellow.400"
                />
                <InputRightAddon px="0">
                  <Button
                    onClick={recreateMapData}
                    borderLeftRadius="0"
                    colorScheme="yellow"
                  >
                    <FaRadiation />
                  </Button>
                </InputRightAddon>
              </InputGroup>
            </VStack>
          </SimpleGrid>
        </Spaced>
      </PageContainer>
    </>
  );
}

const RECREATE_SURVEY_SUMMARIES_HELP = outdent`
Recreate the cached summaries of every survey (descriptions, search terms, etc).

This process can take a few minutes. There won't be any noticeable disruption to service while it works.

This is sometimes necessary after a more invasive database update.`;

const RECREATE_MAP_DATA_HELP = outdent`
Delete and recreate the contents of every map.

This process can take up to 30 minutes. The maps will all go blank and will be slowly filled back in.

This is sometimes necessary after a more invasive database update.`;
