import { checks } from "@cartographerio/permission";
import {
  InvitationCodeCreate,
  Message,
  Qualification,
  QualificationRoleNameEnum,
  WorkspaceRef,
} from "@cartographerio/types";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { ReactElement, useMemo } from "react";

import queries from "../../queries";
import { invitationCodeErrorKeys } from "../../schema/invitationCode";
import { splitMessages } from "../../schema/rule/errors";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import Fieldset from "./Fieldset";
import InvitationCodeSettingsEditor from "./InvitationCodeSettingsEditor";
import QualificationRoleEditor from "./QualificationRoleEditor";
import RequirePermission from "./RequirePermission";
import RoleEditor from "./RoleEditor";
import Spaced from "./Spaced";

export interface InvitationCodeEditorProps {
  value: InvitationCodeCreate;
  onChange: (value: InvitationCodeCreate) => void;
  messages?: Message[];
  disabled?: boolean;
  workspaceGraph: WorkspaceGraph;
  defaultWorkspace?: WorkspaceRef;
  qualifications?: Qualification[];
  roleEditorSingleWorkspace?: boolean;
}

export default function InvitationCodeEditor(
  props: InvitationCodeEditorProps
): ReactElement {
  const {
    value,
    onChange,
    messages,
    disabled = false,
    workspaceGraph,
    defaultWorkspace,
    qualifications = [],
    roleEditorSingleWorkspace,
  } = props;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.optional(defaultWorkspace, workspace =>
      queries.workspace.v2.readOrFail(apiParams, workspace)
    )
  );

  const errors = useMemo(
    () => splitMessages(messages, invitationCodeErrorKeys),
    [messages]
  );

  const qualificationsCheck = useMemo(
    () =>
      checks.or(
        ...qualifications.map(({ id }) =>
          checks.qualification.grant(QualificationRoleNameEnum.Trainee, id)
        )
      ),
    [qualifications]
  );

  return (
    <Spaced spacing="8">
      <InvitationCodeSettingsEditor
        value={value}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />

      <RequirePermission check={qualificationsCheck}>
        <Fieldset legend="Qualification Roles" help={QUALIFICATION_ROLES_HELP}>
          <QualificationRoleEditor
            value={value.qualificationRoles}
            qualifications={qualifications}
            disabled={disabled}
            onChange={qualificationRoles =>
              onChange({ ...value, qualificationRoles })
            }
          />
        </Fieldset>
      </RequirePermission>

      <Fieldset legend="Roles" help={ROLES_HELP}>
        <RoleEditor
          value={value.roles}
          messages={errors.roles}
          disabled={disabled}
          onChange={roles => onChange({ ...value, roles })}
          workspaceGraph={workspaceGraph}
          defaultWorkspace={workspace?.id}
          singleWorkspace={roleEditorSingleWorkspace}
        />
      </Fieldset>
    </Spaced>
  );
}

const QUALIFICATION_ROLES_HELP =
  "Once approved, people who sign up using this code will be given the following qualification levels:";

const ROLES_HELP =
  "Once approved, people who sign up using this code will be given the following roles:";
