import { UserId, WorkspaceId, internalError } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { Dispatch, SetStateAction } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

import { useOptCredentials } from "../contexts/auth";

function useRecentWorkspacesStorageKey(passedUser?: UserId): string {
  const credentials = useOptCredentials();
  const user =
    passedUser ??
    credentials?.identity.userId ??
    raise<UserId>(
      internalError("Cannot get the recent workspaces of an unknown user")
    );

  return `recentWorkspaces.${user}`;
}

export function useRecentWorkspaces(user?: UserId): WorkspaceId[] {
  return (
    useReadLocalStorage<WorkspaceId[]>(useRecentWorkspacesStorageKey(user)) ??
    []
  );
}

export function useRecentWorkspacesState(
  user?: UserId
): [WorkspaceId[], Dispatch<SetStateAction<WorkspaceId[]>>] {
  return useLocalStorage<WorkspaceId[]>(
    useRecentWorkspacesStorageKey(user),
    []
  );
}
