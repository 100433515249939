import { checks } from "@cartographerio/permission";
import { MapSchema } from "@cartographerio/topo-map";
import { Project } from "@cartographerio/types";
import { chain } from "lodash";
import { useMemo } from "react";

import useMapInventory from "./useMapInventory";
import usePermissionCheckRunner from "./usePermissionCheckRunner";

export function useVisibleMaps(project: Project): MapSchema[] {
  const inventory = useMapInventory();
  const permissionCheckPasses = usePermissionCheckRunner();

  return useMemo(
    () =>
      permissionCheckPasses(checks.map.view(project))
        ? chain(inventory.maps())
            .filter(map => project.mapIds.includes(map.mapId))
            .sortBy(map => map.title)
            .value()
        : [],
    [permissionCheckPasses, inventory, project]
  );
}
