import { checks } from "@cartographerio/permission";
import { Box, Grid, HStack, Icon, Image, chakra } from "@chakra-ui/react";
import { Fragment, ReactElement } from "react";
import { AiOutlineFile } from "react-icons/ai";

import {
  galleryItemMetadata,
  galleryItemOriginalUrl,
} from "../../../galleryItem";
import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function AttachmentPage(
  props: RouteProps<typeof routes.attachment.view>
): ReactElement {
  const {
    path: { attachmentId },
  } = props;

  const apiParams = useApiParams();

  const galleryItem = useSuspenseQueryData(
    queries.attachment.v3.readGalleryItem(apiParams, attachmentId)
  );

  const survey = useSuspenseQueryData(
    queries.survey.v3.readOrFail(apiParams, galleryItem.attachment.surveyId)
  );

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, survey.projectId)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  useRequirePermission(checks.survey.view(project));

  usePageTitle(galleryItem.attachment.filename);

  return (
    <PageContainer width="narrow">
      <Spaced>
        <PageHeader
          title="Attachment"
          right={
            <HStack>
              <ButtonLink.Internal
                variant="outline"
                to={routes.attachment.folder.url([
                  survey.id,
                  galleryItem.attachment.folder,
                ])}
              >
                View Gallery
              </ButtonLink.Internal>
              <ButtonLink.Internal
                variant="outline"
                to={routes.workspace.project.survey.view.url([
                  workspace.alias,
                  project.alias,
                  survey.moduleId,
                  survey.id,
                ])}
              >
                View Survey
              </ButtonLink.Internal>
              <ButtonLink.External
                colorScheme="blue"
                to={galleryItem.originalUrl}
              >
                Download
              </ButtonLink.External>
            </HStack>
          }
        />
        <Image
          src={galleryItemOriginalUrl(galleryItem)}
          fit="contain"
          mx="auto"
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="100%"
              h="100%"
              color="gray.300"
              py="4"
            >
              <Icon as={AiOutlineFile} w="32" h="32" />
            </Box>
          }
        />
        <Grid templateColumns="16ex auto" gap="4" fontSize="xs">
          {galleryItemMetadata(galleryItem).map(([key, value], index) => (
            <Fragment key={index}>
              <Box>{key}</Box>
              <chakra.pre fontFamily="body" overflowX="hidden">
                {value}
              </chakra.pre>
            </Fragment>
          ))}
        </Grid>
      </Spaced>
    </PageContainer>
  );
}
