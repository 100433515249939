import {
  SearchResultsFormat,
  SortOrder,
  WorkspaceBillingSummarySortKey,
  endpoints,
} from "@cartographerio/client";
import { UserId, WorkspaceRoleName } from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import { useApiUrlFormatter } from "../hooks/useApiUrl";
import DownloadMenu from "./DownloadMenu";
import SearchField from "./SearchField";

interface WorkspaceListProps {
  searchTerm?: string;
  order?: SortOrder<WorkspaceBillingSummarySortKey>;
  user?: UserId | null;
  role?: WorkspaceRoleName | null;
  onSearchTermChange?: (q: string | null) => void;
}

export default function WorkspaceListToolbar(
  props: WorkspaceListProps
): ReactElement {
  const { searchTerm, order, user, role, onSearchTermChange } = props;

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.billing.summary.v1.searchUrl({
          user,
          role,
          q: searchTerm,
          order,
          format,
        })
      ),
    [formatApiUrl, order, role, searchTerm, user]
  );

  return (
    <HStack w="100%" justifyContent="stretch">
      <SearchField
        defaultValue={searchTerm ?? ""}
        onChange={onSearchTermChange}
        w="auto"
        flexShrink={1}
        flexGrow={1}
      />
      <DownloadMenu downloadUrl={downloadUrl} />
    </HStack>
  );
}
