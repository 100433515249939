import { VisibilityState } from "@cartographerio/topo-form";
import { ReactElement } from "react";

import BlockView from "./BlockView";

export interface VisibilityViewProps {
  blockKey: string;
  blockState: VisibilityState;
  disabled?: boolean;
}

export default function VisibilityView(
  props: VisibilityViewProps
): ReactElement | null {
  const { blockKey, blockState, disabled } = props;
  return blockState.visible ? (
    <BlockView
      blockKey={blockKey}
      blockState={blockState.childState}
      disabled={disabled}
    />
  ) : null;
}
