import { SurveyModule } from "@cartographerio/inventory-surveys";
import { MapSchema } from "@cartographerio/topo-map";
import { Project } from "@cartographerio/types";

export function projectSurveyModuleLabel(
  project: Project,
  module: SurveyModule
): string {
  return project.moduleIds.length === 1
    ? project.name.indexOf("Sites") > 0
      ? "Sites"
      : "Surveys"
    : `${module.names.shortName} Surveys`;
}

export function projectMapLabel(project: Project, map: MapSchema): string {
  return project.mapIds.length === 1 ? "Map" : `${map.title} Map`;
}
