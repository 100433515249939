import { checks } from "@cartographerio/permission";
import { Project, Workspace } from "@cartographerio/types";
import { ReactElement } from "react";

import Container from "../../../components/Container";
import {
  InvitationTabOption,
  PageBreadcrumbProps,
  ProjectTransferTabOption,
} from "../../../components/PageBreadcrumb";
import PageHeader from "../../../components/PageHeader";
import PageTab from "../../../components/PageTab";
import PageTabs from "../../../components/PageTabs";
import PageTabSelect, {
  pageTabOption,
} from "../../../components/PageTabSelect";
import ProjectLabel from "../../../components/ProjectLabel";
import RequirePermission from "../../../components/RequirePermission";
import useCodesEnabled from "../../../hooks/useCodesEnabled";
import { useProjectHasTeams } from "../../../hooks/useProjectHasTeams";
import { routes } from "../../../routes";

export interface ProjectPageHeaderProps {
  workspace: Workspace;
  project: Project;
  selected: Exclude<PageBreadcrumbProps["projectPage"], undefined>;
}

export default function ProjectPageHeader(
  props: ProjectPageHeaderProps
): ReactElement {
  const { workspace, project, selected } = props;

  const multiTeam = useProjectHasTeams(workspace, project);

  const codesEnabled = useCodesEnabled(workspace);

  return (
    <Container sticky={true} width="wide">
      <PageHeader title={<ProjectLabel project={project} suffix="(Project)" />}>
        <PageTabs selected={selected}>
          <RequirePermission check={checks.project.viewSettings(project)}>
            <PageTab
              id="settings"
              label="Settings"
              to={routes.workspace.project.settings.url([
                workspace.alias,
                project.alias,
              ])}
            />
          </RequirePermission>
          <RequirePermission check={checks.project.viewSettings(project)}>
            <PageTab
              id="integrations"
              label="Integrations"
              to={routes.workspace.project.integrations.list.url([
                workspace.alias,
                project.alias,
              ])}
            />
          </RequirePermission>
          {multiTeam && (
            <RequirePermission check={checks.project.viewTeams(project)}>
              <PageTab
                id="teams"
                label="Teams"
                to={routes.workspace.project.teams.url([
                  workspace.alias,
                  project.alias,
                ])}
              />
            </RequirePermission>
          )}
          <RequirePermission check={checks.project.viewMembers(project)}>
            <PageTab
              id="members"
              label="Members"
              to={routes.workspace.project.member.list.url([
                workspace.alias,
                project.alias,
              ])}
            />
          </RequirePermission>
          <RequirePermission check={checks.project.viewInvitations(project)}>
            <PageTabSelect<InvitationTabOption>
              id="invitation"
              label="Invitations"
              options={[
                pageTabOption({
                  id: "single",
                  label: "Invitations",
                  to: routes.workspace.project.invitation.list.url([
                    workspace.alias,
                    project.alias,
                  ]),
                }),
                ...(codesEnabled
                  ? [
                      pageTabOption({
                        id: "code",
                        label: "Invitation Codes",
                        to: routes.workspace.project.invitation.code.list.url([
                          workspace.alias,
                          project.alias,
                        ]),
                      }),
                    ]
                  : []),
              ]}
            />
          </RequirePermission>
          <RequirePermission check={checks.auth.globalAdmin}>
            <PageTabSelect<ProjectTransferTabOption>
              id="transfer"
              label="Transfer"
              options={[
                {
                  id: "survey",
                  label: "Surveys",
                  to: routes.workspace.project.transfer.survey.url([
                    workspace.alias,
                    project.alias,
                  ]),
                },
                {
                  id: "project",
                  label: "Project",
                  to: routes.workspace.project.transfer.project.url([
                    workspace.alias,
                    project.alias,
                  ]),
                },
              ]}
            />
          </RequirePermission>
        </PageTabs>
      </PageHeader>
    </Container>
  );
}
