import { Select as ChakraSelect, SelectProps } from "@chakra-ui/react";
import {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from "react";

import { Highlight, useInputHighlight } from "../../hooks/highlight";
import { useInputFocus } from "../../hooks/useInputFocus";

export type SelectValue = string | number | boolean | null;

export interface SelectOption<A extends SelectValue> {
  label: string;
  value: A;
}

type omitted = "value" | "onChange" | "isDisabled";

export interface StandardSelectProps<A extends SelectValue>
  extends Omit<SelectProps, omitted> {
  value: A;
  options: SelectOption<A>[];
  hiddenOptions?: A[];
  disabledOptions?: A[];
  showValues?: boolean;
  onChange?: (value: A) => void;
  highlight?: Highlight;
  disabled?: boolean;
}

export default function StandardSelect<A extends SelectValue>(
  props: StandardSelectProps<A>
): ReactElement {
  const {
    disabled,
    value,
    onChange,
    options,
    hiddenOptions,
    disabledOptions,
    showValues,
    highlight,
    background = "white",
    ...rest
  } = props;

  const visibleOptions = useMemo(
    () =>
      hiddenOptions == null
        ? options
        : options.filter(
            option =>
              option.value === value || !hiddenOptions.includes(option.value)
          ),
    [hiddenOptions, options, value]
  );

  const [focused, handleFocus, handleBlur] = useInputFocus();

  const { borderColor, borderWidth } = useInputHighlight(highlight, focused);

  const selectedIndex = useMemo(
    () => visibleOptions.findIndex(option => option.value === value),
    [value, visibleOptions]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const indexString = event.currentTarget.value;
      const index = parseInt(indexString, 10);
      if (index >= 0 && index < visibleOptions.length) {
        onChange?.(visibleOptions[index].value);
      }
    },
    [visibleOptions, onChange]
  );
  return (
    <ChakraSelect
      value={`${selectedIndex}`}
      borderColor={borderColor}
      borderWidth={borderWidth}
      disabled={disabled}
      background={background}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      {...rest}
    >
      {visibleOptions.map((option, index) => {
        const valueLabel: ReactNode =
          showValues && option.value != null && option.value !== option.label
            ? `${option.value} - `
            : null;

        return (
          <option
            key={index}
            value={`${index}`}
            disabled={disabledOptions?.includes(option.value) || undefined}
          >
            {valueLabel} {option.label}
          </option>
        );
      })}
    </ChakraSelect>
  );
}
