import { KnownUserFeatureEnum, unsafeUserId } from "@cartographerio/types";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import { useIOErrorAlert } from "../components/Alert";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import PageContainer from "../components/PageContainer";
import {
  changeIdentityAction,
  useApiParams,
  useAuthContext,
  useCredentials,
} from "../contexts/auth";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { usePageTitle } from "../hooks/usePageTitle";
import { routes } from "../routes";

export default function ChangeIdentityPage(
  props: RouteProps<typeof routes.identity.change>
): ReactElement {
  const {
    path: { userId },
    query: { go = "/" },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();
  const { identity } = useCredentials();

  useEffect(() => {
    if (!identity.features?.includes(KnownUserFeatureEnum.ChangeIdentity)) {
      navigate(routes.home.url([]), { replace: true });
    }
  }, [identity.features, navigate]);

  usePageTitle("Change Identity");

  const { dispatch: authDispatch } = useAuthContext();

  useEffectOnce(() => {
    queries.auth.v2
      .changeIdentity(queryClient, apiParams, unsafeUserId(userId))
      .tap(res => {
        if (res.type === "SigninSuccess") {
          authDispatch(changeIdentityAction(res.credentials.token));
          navigate(go, { replace: true });
        } else {
          errorAlert(res);
        }
      })
      .unsafeRun();
  });

  return (
    <PageContainer>
      <LoadingPlaceholder label="Loading user" />
    </PageContainer>
  );
}
