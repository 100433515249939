import { SelectOption } from "@cartographerio/topo-form";
import {
  Message,
  Project,
  ProjectId,
  ProjectVisibilityEnum,
  Workspace,
  WorkspaceId,
  unsafeSurveyModuleId,
  unsafeWorkspaceAlias,
} from "@cartographerio/types";
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import queries from "../../../../queries";
import { splitMessages } from "../../../../schema/rule/errors";
import { workspaceErrorKeys } from "../../../../schema/workspace";
import { emptyArray } from "../../../../util";
import Heading from "../../../components/Heading";
import MessageFormControl from "../../../components/MessageFormControl";
import Select from "../../../components/Select";
import Spaced, { SpacedProps } from "../../../components/Spaced";
import TextField from "../../../components/TextField";
import { useApiParams } from "../../../contexts/auth";
import usePrevious from "../../../hooks/usePrevious";
import {
  ExistingWorkspaceDetails,
  NewWorkspaceDetails,
  existingWorkspaceDetailsErrorKeys,
  newWorkspaceDetailsErrorKeys,
} from "./schema";

interface NewWorkspaceSectionProps extends Omit<SpacedProps, "children"> {
  value: NewWorkspaceDetails;
  messages: Message[];
  onChange: (value: NewWorkspaceDetails) => void;
}

export function NewWorkspaceSection(
  props: NewWorkspaceSectionProps
): ReactElement {
  const { value, messages, onChange, ...rest } = props;
  const { workspace } = value;

  const onWorkspaceChange = useCallback(
    (workspace: Workspace) => onChange({ ...value, workspace }),
    [onChange, value]
  );

  const detailsErrors = useMemo(
    () => splitMessages(messages, newWorkspaceDetailsErrorKeys),
    [messages]
  );

  const errors = useMemo(
    () => splitMessages(detailsErrors.workspace, workspaceErrorKeys),
    [detailsErrors.workspace]
  );

  return (
    <Spaced spacing="8" {...rest}>
      <Spaced spacing="4">
        <Heading mt="0" level="subsection">
          Workspace Details
        </Heading>

        <SimpleGrid columns={[1, 2]} gap="4">
          <MessageFormControl label="Workspace Name" messages={errors.name}>
            <TextField.String
              value={workspace.name}
              onChange={name => onWorkspaceChange({ ...workspace, name })}
            />
          </MessageFormControl>
          <MessageFormControl label="Workspace Alias" messages={errors.alias}>
            <TextField.String
              value={workspace.alias}
              onChange={alias =>
                onWorkspaceChange({
                  ...workspace,
                  alias: unsafeWorkspaceAlias(alias),
                })
              }
            />
          </MessageFormControl>
          <MessageFormControl label="Workspace Logo" messages={errors.logo}>
            <TextField.String
              value={workspace.logo ?? undefined}
              onChange={logo => onWorkspaceChange({ ...workspace, logo })}
            />
          </MessageFormControl>
        </SimpleGrid>
      </Spaced>

      <Spaced spacing="4">
        <Heading level="subsection">Project Details</Heading>
        <SimpleGrid columns={[1, 2]}>
          <MessageFormControl label="Project Visibility" messages={[]}>
            <Select.Standard
              value={value.projectVisibility}
              options={ProjectVisibilityEnum.entries}
              onChange={projectVisibility =>
                onChange({ ...value, projectVisibility })
              }
            />
          </MessageFormControl>
        </SimpleGrid>
      </Spaced>

      <Spaced spacing="4">
        <Heading level="subsection" textAlign="start">
          Operator Settings
        </Heading>

        <SimpleGrid columns={[1, 2]} gap="4">
          <MessageFormControl label="Operator Name" messages={[]}>
            <TextField.String
              value={workspace.operator ?? undefined}
              onChange={operator =>
                onWorkspaceChange({ ...workspace, operator })
              }
            />
          </MessageFormControl>
          <MessageFormControl
            label="Operator Homepage"
            messages={errors.homepage}
          >
            <TextField.String
              value={workspace.homepage ?? undefined}
              onChange={homepage =>
                onWorkspaceChange({ ...workspace, homepage })
              }
            />
          </MessageFormControl>
          <MessageFormControl
            label="Operator Privacy Policy"
            messages={errors.privacyPolicy}
          >
            <TextField.String
              value={workspace.privacyPolicy ?? undefined}
              onChange={privacyPolicy =>
                onWorkspaceChange({ ...workspace, privacyPolicy })
              }
            />
          </MessageFormControl>
        </SimpleGrid>
      </Spaced>
    </Spaced>
  );
}

const RCA_MODULE_IDS = ["mrsMorphPro", "mrsRtaPro"].map(unsafeSurveyModuleId);

interface ExistingWorkspaceSectionProps
  extends Omit<SimpleGridProps, "children" | "onChange"> {
  value: ExistingWorkspaceDetails;
  workspaces: Workspace[];
  messages: Message[];
  onChange: (value: ExistingWorkspaceDetails) => void;
}

export function ExistingWorkspaceSection(
  props: ExistingWorkspaceSectionProps
): ReactElement {
  const { value, workspaces, messages, onChange } = props;

  const errors = useMemo(
    () => splitMessages(messages, existingWorkspaceDetailsErrorKeys),
    [messages]
  );

  const apiParams = useApiParams();

  const workspaceOptions = useMemo(
    (): SelectOption<WorkspaceId>[] =>
      workspaces.map(({ name, id }) => ({ label: name, value: id })),
    [workspaces]
  );

  const workspaceLookup = useMemo(
    (): Record<WorkspaceId, Workspace> =>
      workspaces.reduce(
        (acc, workspace) => ({ ...acc, [workspace.id]: workspace }),
        {}
      ),
    [workspaces]
  );

  const allProjects =
    useQuery(
      queries.optional(value.workspace, workspace =>
        queries.project.v2.forWorkspace(apiParams, workspace.id)
      )
    ).data?.results ?? emptyArray;

  const projects = useMemo(
    () =>
      allProjects.filter(project =>
        project.moduleIds.every(moduleId => RCA_MODULE_IDS.includes(moduleId))
      ),
    [allProjects]
  );

  const rcaProject = useMemo(
    () => projects.find(project => project.alias === "rca"),
    [projects]
  );

  const projectValue = useMemo<ProjectId | "new">(
    () => (value.project === "new" ? "new" : value.project.id),
    [value.project]
  );

  const projectOptions = useMemo(
    (): SelectOption<ProjectId | "new">[] => [
      ...projects.map(({ name, id }) => ({ label: name, value: id })),
      { value: "new", label: "Create New Project" },
    ],
    [projects]
  );

  const projectLookup = useMemo(
    (): Record<ProjectId, Project> =>
      projects.reduce(
        (acc, project) => ({ ...acc, [project.id]: project }),
        {}
      ),
    [projects]
  );

  // We set this flag when we change the workspace field.
  // After the workspace changes, the projects reload,
  // and we update the project field to the first RCA project found.
  const [projectDirty, setProjectDirty] = useState(false);

  const handleWorkspaceChange = useCallback(
    (workspace: Workspace) => {
      onChange({ ...value, workspace, project: "new" });
      setProjectDirty(true);
    },
    [onChange, value]
  );

  const handleProjectChange = useCallback(
    (id: ProjectId | "new") => {
      onChange({ ...value, project: id === "new" ? "new" : projectLookup[id] });
    },
    [onChange, projectLookup, value]
  );

  const prevProjects = usePrevious(projects);

  useEffect(() => {
    if (projectDirty && projects !== prevProjects) {
      onChange({
        ...value,
        project: rcaProject ?? projects[0],
      });
      setProjectDirty(false);
    }
  }, [onChange, prevProjects, projectDirty, projects, rcaProject, value]);

  return (
    <>
      <Spaced spacing="4">
        <Heading mt="0" level="subsection">
          Workspace
        </Heading>

        <SimpleGrid columns={[1, 2]} gap="4">
          <MessageFormControl
            label="Select a Workspace"
            messages={errors.workspace}
          >
            <Select.Searchable
              value={value.workspace?.id}
              options={workspaceOptions}
              onChange={id => handleWorkspaceChange(workspaceLookup[id])}
              minMatchCharLength={0}
              debounce={500}
            />
          </MessageFormControl>
        </SimpleGrid>
      </Spaced>

      {allProjects != null && (
        <Spaced spacing="4">
          <Heading level="subsection">Project</Heading>

          {rcaProject != null ? (
            <TextField.String value={rcaProject.name} disabled={true} />
          ) : (
            <SimpleGrid columns={[1, 2]} gap="4">
              <MessageFormControl
                label="Select a Project"
                messages={errors.project}
              >
                <Select.Standard
                  value={projectValue}
                  options={projectOptions}
                  onChange={handleProjectChange}
                  disabled={projectOptions.length === 0}
                />
              </MessageFormControl>

              {value.project === "new" && (
                <MessageFormControl label="Project Visibility" messages={[]}>
                  <Select.Standard
                    value={value.projectVisibility}
                    options={ProjectVisibilityEnum.entries}
                    onChange={projectVisibility =>
                      onChange({ ...value, projectVisibility })
                    }
                  />
                </MessageFormControl>
              )}
            </SimpleGrid>
          )}
        </Spaced>
      )}
    </>
  );
}
