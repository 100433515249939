import { BoxProps, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

export type ParaProps = BoxProps;

export default function Para(props: ParaProps): ReactElement {
  const { children, ...rest } = props;

  return <chakra.p {...rest}>{children}</chakra.p>;
}
