import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { SelectOption } from "@cartographerio/topo-form";
import {
  InvitationCreate,
  Project,
  ProjectRoleName,
  ProjectRoleNameEnum,
  QualificationRoleName,
  QualificationRoleNameEnum,
  Team,
  TeamId,
  TeamRoleName,
  TeamRoleNameEnum,
  Workspace,
  WorkspaceId,
  projectRole as createProjectRole,
  teamRole as createTeamRole,
  qualificationRole,
  unsafeEmail,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import queries from "../../queries";
import {
  invitationModalErrorKeys,
  invitationModalRule,
} from "../../schema/invitation";
import { splitMessages } from "../../schema/rule/errors";
import { useApiParams } from "../contexts/auth";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { useProjectHasTeams } from "../hooks/useProjectHasTeams";
import { useIOErrorAlert } from "./Alert";
import FormLabel from "./FormLabel";
import MessageFormControl from "./MessageFormControl";
import Select from "./Select";
import Spaced from "./Spaced";
import TextField from "./TextField";
import {
  PROJECT_ROLE_HELP,
  QUALIFICATION_ROLE_HELP,
  TEAM_HELP,
  TEAM_ROLE_HELP,
} from "./UserAddModal";

function blankInvitation(workspaceId: WorkspaceId | null): InvitationCreate {
  return {
    workspaceId,
    firstName: "",
    lastName: "",
    email: unsafeEmail(""),
    roles: [],
    qualificationRoles: [],
  };
}

interface ProjectInviteModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  workspace: Workspace;
  project: Project;
  teams?: Team[] | null;
  defaultTeamId?: TeamId | null;
}

function ProjectInviteModal(props: ProjectInviteModalProps): ReactElement {
  const { title, isOpen, onClose, workspace, project, teams, defaultTeamId } =
    props;

  const multiTeam = useProjectHasTeams(workspace, project);
  const permissionCheckPasses = usePermissionCheckRunner();

  const [value, setValue] = useState<InvitationCreate>(
    blankInvitation(project.workspaceId)
  );

  const [projectRole, setProjectRole] = useState<ProjectRoleName>("Member");
  const [teamId, setTeamId] = useState<TeamId | null>(defaultTeamId ?? null);
  const [teamRoleName, setTeamRoleName] = useState<TeamRoleName>("Member");
  const [qualificationRoleName, setQualificationRoleName] =
    useState<QualificationRoleName | null>(null);

  const canEditProjectRole = useMemo(
    () => permissionCheckPasses(checks.project.grantAccess(project)),
    [permissionCheckPasses, project]
  );

  const projectRoleNameOptions = useMemo(
    () =>
      ProjectRoleNameEnum.entries.filter(({ value }) =>
        permissionCheckPasses(
          checks.role.grant([createProjectRole(value, project.id)])
        )
      ),
    [permissionCheckPasses, project.id]
  );

  const teamOptions = useMemo<SelectOption<TeamId>[] | null>(
    () =>
      teams == null || !multiTeam
        ? null
        : filterAndMap(teams, team =>
            permissionCheckPasses(checks.team.grantAccess(team))
              ? { label: team.name, value: team.id }
              : null
          ),
    [permissionCheckPasses, multiTeam, teams]
  );

  const teamRoleNameOptions = useMemo(
    () =>
      teamId == null
        ? []
        : TeamRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(
              checks.role.grant([createTeamRole(value, teamId)])
            )
          ),
    [permissionCheckPasses, teamId]
  );

  const qualificationRoleNameOptions = useMemo(
    () =>
      project.qualificationIds
        .map(id =>
          QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(checks.qualification.grant(value, id))
          )
        )
        .reduce(
          (acc: SelectOption<QualificationRoleName>[] | null, curr) =>
            acc == null
              ? curr
              : acc.filter(
                  ({ value }) =>
                    curr.find(other => value === other.value) != null
                ),
          null
        ) ?? [],
    [permissionCheckPasses, project.qualificationIds]
  );

  useEffect(() => {
    if (
      teamId != null &&
      teamRoleName != null &&
      !permissionCheckPasses(
        checks.role.grant([createTeamRole(teamRoleName, teamId)])
      )
    ) {
      setTeamRoleName("Member");
    }
  }, [permissionCheckPasses, teamId, teamRoleName]);

  const messages = useMemo(() => invitationModalRule(value), [value]);

  const errors = useMemo(
    () => splitMessages(messages, invitationModalErrorKeys),
    [messages]
  );

  const saveInvitation = useInvitationSaveCallback();

  const handleSubmit = useCallback(
    () =>
      saveInvitation({
        ...value,
        roles: [
          createProjectRole(projectRole, project.id),
          ...(teamId != null ? [createTeamRole(teamRoleName, teamId)] : []),
        ],
        qualificationRoles:
          qualificationRoleName != null
            ? project.qualificationIds.map(id =>
                qualificationRole(qualificationRoleName, id)
              )
            : [],
      })
        .cleanup(() => {
          onClose();
          setValue(blankInvitation(project.workspaceId));
        })
        .unsafeRun(),
    [
      saveInvitation,
      value,
      projectRole,
      project.id,
      project.qualificationIds,
      project.workspaceId,
      teamId,
      teamRoleName,
      qualificationRoleName,
      onClose,
    ]
  );

  useEffect(() => {
    setTeamId(defaultTeamId ?? null);
    setTeamRoleName("Member");
  }, [defaultTeamId]);

  const modalRef = useRef<HTMLElement>(null);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Spaced spacing="4">
            <MessageFormControl label="First Name" messages={errors.firstName}>
              <TextField.String
                value={value.firstName}
                onChange={firstName =>
                  setValue(value => ({ ...value, firstName }))
                }
              />
            </MessageFormControl>

            <MessageFormControl label="Last Name" messages={errors.lastName}>
              <TextField.String
                value={value.lastName}
                onChange={lastName =>
                  setValue(value => ({ ...value, lastName }))
                }
              />
            </MessageFormControl>

            <MessageFormControl label="Email Address" messages={errors.email}>
              <TextField.String
                value={value.email}
                onChange={email =>
                  setValue(value => ({
                    ...value,
                    email: unsafeEmail(email),
                  }))
                }
              />
            </MessageFormControl>

            {canEditProjectRole && (
              <FormControl>
                <FormLabel
                  text="Project Role"
                  help={
                    multiTeam
                      ? PROJECT_ROLE_HELP.MULTI_TEAM
                      : PROJECT_ROLE_HELP.SINGLE_TEAM
                  }
                  helpPortalContainerRef={modalRef}
                />
                <Select.Standard
                  value={projectRole}
                  onChange={setProjectRole}
                  options={projectRoleNameOptions}
                />
              </FormControl>
            )}

            {teamOptions != null && (
              <>
                <FormControl>
                  <FormLabel
                    text={canEditProjectRole ? "Team (Optional)" : "Team"}
                    help={
                      canEditProjectRole
                        ? TEAM_HELP.PROJECT_COORDINATOR
                        : TEAM_HELP.TEAM_COORDINATOR
                    }
                    helpPortalContainerRef={modalRef}
                  />
                  {canEditProjectRole ? (
                    <Select.Nullable
                      placeholder="No Team"
                      value={teamId ?? null}
                      onChange={setTeamId}
                      options={teamOptions}
                    />
                  ) : (
                    <Select.Standard
                      placeholder="Select a Team"
                      value={teamId ?? null}
                      onChange={setTeamId}
                      options={teamOptions}
                    />
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel
                    text="Team Role"
                    help={TEAM_ROLE_HELP}
                    helpPortalContainerRef={modalRef}
                  />
                  <Select.Standard
                    value={teamRoleName}
                    onChange={setTeamRoleName}
                    options={teamRoleNameOptions}
                    disabled={
                      teamId == null || teamRoleNameOptions.length === 0
                    }
                  />
                </FormControl>
              </>
            )}

            {qualificationRoleNameOptions.length > 0 && (
              <FormControl>
                <FormLabel
                  text="Qualification Role (Optional)"
                  help={QUALIFICATION_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Nullable
                  value={qualificationRoleName}
                  onChange={setQualificationRoleName}
                  options={qualificationRoleNameOptions}
                />
              </FormControl>
            )}
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isDisabled={messages.length > 0}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface TeamInviteModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  team: Team;
  teamProjects: Project[];
}

function TeamInviteModal(props: TeamInviteModalProps): ReactElement {
  const { title, isOpen, onClose, team, teamProjects } = props;

  const permissionCheckPasses = usePermissionCheckRunner();

  const [value, setValue] = useState<InvitationCreate>(
    blankInvitation(team.workspaceId)
  );
  const [teamRole, setTeamRole] = useState<TeamRoleName>("Member");
  const [qualificationRoleName, setQualificationRoleName] =
    useState<QualificationRoleName | null>(null);

  const teamRoleNameOptions = useMemo(
    () =>
      TeamRoleNameEnum.entries.filter(({ value }) =>
        permissionCheckPasses(
          checks.role.grant([createTeamRole(value, team.id)])
        )
      ),
    [permissionCheckPasses, team.id]
  );

  const qualificationIds = useMemo(
    () => [
      ...new Set(
        teamProjects.flatMap(({ qualificationIds }) => qualificationIds)
      ),
    ],
    [teamProjects]
  );

  const qualificationRoleNameOptions = useMemo(
    () =>
      qualificationIds
        .map(id =>
          QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(checks.qualification.grant(value, id))
          )
        )
        .reduce(
          (acc: SelectOption<QualificationRoleName>[] | null, curr) =>
            acc == null
              ? curr
              : acc.filter(
                  ({ value }) =>
                    curr.find(other => value === other.value) != null
                ),
          null
        ) ?? [],
    [permissionCheckPasses, qualificationIds]
  );

  const messages = useMemo(() => invitationModalRule(value), [value]);

  const errors = useMemo(
    () => splitMessages(messages, invitationModalErrorKeys),
    [messages]
  );

  const saveInvitation = useInvitationSaveCallback();

  const handleSubmit = useCallback(
    () =>
      saveInvitation({
        ...value,
        roles: [createTeamRole(teamRole, team.id)],
        qualificationRoles:
          qualificationRoleName != null
            ? qualificationIds.map(id =>
                qualificationRole(qualificationRoleName, id)
              )
            : [],
      })
        .cleanup(() => {
          setValue(blankInvitation(team.workspaceId));
          onClose();
        })
        .unsafeRun(),
    [
      onClose,
      qualificationIds,
      qualificationRoleName,
      saveInvitation,
      team.id,
      team.workspaceId,
      teamRole,
      value,
    ]
  );

  const canEditTeamRole = useMemo(
    () => permissionCheckPasses(checks.team.grantAccess(team)),
    [permissionCheckPasses, team]
  );

  const modalRef = useRef<HTMLElement>(null);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Spaced spacing="4">
            <MessageFormControl label="First Name" messages={errors.firstName}>
              <TextField.String
                value={value.firstName}
                onChange={firstName =>
                  setValue(value => ({ ...value, firstName }))
                }
              />
            </MessageFormControl>

            <MessageFormControl label="Last Name" messages={errors.lastName}>
              <TextField.String
                value={value.lastName}
                onChange={lastName =>
                  setValue(value => ({ ...value, lastName }))
                }
              />
            </MessageFormControl>

            <MessageFormControl label="Email Address" messages={errors.email}>
              <TextField.String
                value={value.email}
                onChange={email =>
                  setValue(value => ({
                    ...value,
                    email: unsafeEmail(email),
                  }))
                }
              />
            </MessageFormControl>

            {canEditTeamRole && (
              <FormControl>
                <FormLabel
                  text="Team Role"
                  help={TEAM_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Standard
                  value={teamRole}
                  onChange={setTeamRole}
                  options={teamRoleNameOptions}
                />
              </FormControl>
            )}

            {qualificationRoleNameOptions.length > 0 && (
              <FormControl>
                <FormLabel
                  text="Qualification Role (Optional)"
                  help={QUALIFICATION_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Nullable
                  value={qualificationRoleName}
                  onChange={setQualificationRoleName}
                  options={qualificationRoleNameOptions}
                />
              </FormControl>
            )}
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isDisabled={messages.length > 0}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function useInvitationSaveCallback() {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  return useCallback(
    (invite: InvitationCreate) =>
      queries.invitation.v3
        .create(queryClient, apiParams, invite)
        .flatMap(IO.fromResult)
        .tap(_ => toast({ title: "Invitation email sent", status: "success" }))
        .tapError(errorAlert),
    [apiParams, errorAlert, queryClient, toast]
  );
}

export default {
  Project: ProjectInviteModal,
  Team: TeamInviteModal,
};
