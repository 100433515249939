import { Email, Invitation } from "@cartographerio/types";
import { Box, VStack } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import { ReactElement, useMemo } from "react";

import queries from "../../queries";
import { emptyArray } from "../../util";
import { useApiParams } from "../contexts/auth";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { FrontContext } from "./context";
import FrontInvitationItem from "./FrontInvitationItem";

export interface FrontInvitationListProps {
  context: FrontContext;
  searchString?: string | null;
  emails?: Email[];
}

export default function FrontInvitationList(
  props: FrontInvitationListProps
): ReactElement {
  const { context, searchString = null, emails = [] } = props;

  const apiParams = useApiParams();

  const searchResults =
    useSuspenseSearchResults(
      queries.optional(searchString, q =>
        queries.invitation.v3.search(apiParams, { q, limit: 5 })
      )
    ) ?? emptyArray;

  const emailResults = useQueries({
    queries: emails
      .map(email => queries.invitation.v3.search(apiParams, { q: email }))
      .map(query => ({ ...query, suspense: true })),
  });

  const results = useMemo(() => {
    const x: Invitation[] = searchResults;

    const y: Invitation[] = emailResults.flatMap(
      result => result.data?.results ?? []
    );

    return [...x, ...y];
  }, [emailResults, searchResults]);

  return (
    <VStack spacing="4" alignItems="stretch">
      {results.map(invite => (
        <FrontInvitationItem
          key={invite.id}
          context={context}
          invitation={invite}
        />
      ))}
      {results.length === 0 && <Box>No invitations to display</Box>}
    </VStack>
  );
}
