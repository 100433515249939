import { checks } from "@cartographerio/permission";
import { Project, Team, UserId } from "@cartographerio/types";
import { partition } from "lodash";
import { useMemo } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import { PartitionedResults } from "./usePartitionedProjects";
import usePermissionCheckRunner from "./usePermissionCheckRunner";
import { useSuspenseSearchResults } from "./useSuspenseSearchResults";

export default function usePartitionedTeams(
  project: Project,
  userId: UserId,
  _teams?: Team[]
): PartitionedResults<Team> {
  const apiParams = useApiParams();
  const permissionCheckPasses = usePermissionCheckRunner();

  const teams =
    useSuspenseSearchResults(
      queries.when(_teams == null, () =>
        queries.team.v2.visible(apiParams, userId, project.workspaceId)
      )
    ) ?? _teams;

  return useMemo(
    () =>
      partition(teams, team => permissionCheckPasses(checks.team.member(team))),
    [permissionCheckPasses, teams]
  );
}
