import { envvar } from "@cartographerio/util";
import { Card, chakra } from "@chakra-ui/react";

export const HEADWAY_ACCOUNT = envvar("REACT_APP_HEADWAY_ACCOUNT");

export default function HeadwayPanel() {
  const iframeUrl = `https://headway-widget.net/widgets/${HEADWAY_ACCOUNT}`;

  return (
    <Card>
      <chakra.iframe height="64" src={iframeUrl}></chakra.iframe>
    </Card>
  );
}
